import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DisplayDate from '../../util/DisplayDate';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const AutoPlaySwipeableViews = SwipeableViews;
const AutoPlayChildSwipeableViews = SwipeableViews;



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        backgroundColor: theme.palette.background.default,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    description: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 110,
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    action: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
        gap: theme.spacing(2),
        width: '100%',
        marginTop: 'auto !important'
    },
    img: {
        position: 'relative',
        height: 'auto',
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        zIndex: 999,
        backgroundColor: 'white'
    },
}));


function NewsLetterBox(props) {

    const {
        isOpen = true,
        onClose = () => null,
        data = []
    } = props

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(isOpen);

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = data.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const isYoutubeUrl = (url) => {
        if (url.indexOf('youtube') !== -1 || url.indexOf('youtu') !== -1) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            {data.length ? (
                <>
                    <Dialog
                        open={open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        onClose={() => {
                            setOpen(false);
                            onClose()
                        }}
                        fullWidth={false}
                        maxWidth={false}
                        PaperProps={{
                            style: {
                                width: data[activeStep].modal.width,
                                height: data[activeStep].modal.height
                            }
                        }}
                    >
                        <div className='position-relative'>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={() => {
                                setOpen(false);
                                onClose()
                            }}>
                                <CloseIcon />
                            </IconButton>

                            <AutoPlaySwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                            >
                                {data.map((item, index) => (
                                    <Item data={item} key={index} onClose={() => {
                                        setOpen(false);
                                        onClose()
                                    }} />
                                ))}

                            </AutoPlaySwipeableViews>
                            {data.length > 1 && (
                                // <MobileStepper
                                //     variant="dots"
                                //     steps={maxSteps}
                                //     position="static"
                                //     activeStep={activeStep}
                                //     nextButton={
                                //         <>
                                //             {data.length > 1 && (
                                //                 <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                //                     {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                //                 </Button>
                                //             )}
                                //         </>
                                //     }
                                //     backButton={
                                //         <>
                                //             {data.length > 1 && (
                                //                 <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                //                     {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                //                 </Button>
                                //             )}
                                //         </>
                                //     }
                                // />
                                <Paper className={classes.header}>
                                    {data.map((_, i) => (
                                        <div className='pointer' key={i} onClick={() => setActiveStep(i)}>
                                            <FiberManualRecordIcon
                                                color={activeStep === i ? 'primary' : 'disabled'}
                                                style={{
                                                    fontSize: 14
                                                }}
                                            />
                                        </div>
                                    ))}
                                </Paper>
                            )}


                        </div>
                    </Dialog>
                </>
            ) : null}
        </>
    );
}

function Item(props) {

    const {
        onClose = () => null,
        data = []
    } = props

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = data.items.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const isYoutubeUrl = (url) => {
        if (url.indexOf('youtube') !== -1 || url.indexOf('youtu') !== -1) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <DialogTitle id="alert-dialog-title" color='primary' style={{ paddingRight: 50 }}>
                <Paper square elevation={0}>
                    <Typography><b>{data.items[activeStep].title}</b></Typography>
                </Paper>
                <Typography>
                    <DisplayDate date={data.release_date} onlyDate={true} />
                </Typography>
            </DialogTitle>
            <DialogContent
                dividers
                style={{
                    padding: 0
                }}>
                <DialogContentText
                    id="alert-dialog-description"
                    style={{
                        padding: 0
                    }}>

                    <div className={classes.root}>
                        <div style={{ position: 'relative' }}>
                            <AutoPlayChildSwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep}
                                onChangeIndex={handleStepChange}
                                enableMouseEvents
                                dateTime=''
                            >
                                {data.items.map((step, index) => {

                                    if (isYoutubeUrl(step.preview_url)) {
                                        return (
                                            <div key={step.preview_url} style={{
                                                aspectRatio: '16 / 9',
                                                width: '100%',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: '#eee'
                                            }}>

                                                <iframe
                                                    src={step.preview_url}
                                                    title="YouTube video player"
                                                    frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen
                                                    style={{
                                                        width: '100%',
                                                        aspectRatio: '16 / 9'
                                                    }}
                                                >
                                                </iframe>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={step.preview_url} style={{
                                                aspectRatio: '16 / 9',
                                                width: '100%',
                                                overflow: 'hidden',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: '#eee'
                                            }}>
                                                {Math.abs(activeStep - index) <= 2 ? (
                                                    <img className={classes.img} src={step.preview_url} alt={''} />
                                                ) : <div />}


                                            </div>
                                        )
                                    }
                                })}

                            </AutoPlayChildSwipeableViews>
                            <div style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }}>
                                <Button size="small" variant="contained" color='primary' style={{ minWidth: 36 }} onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                </Button>
                            </div>
                            <div style={{ position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)' }}>
                                <Button size="small" variant="contained" color='primary' style={{ minWidth: 36 }} onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                </Button>
                            </div>
                        </div>
                        <Paper square elevation={0} className={classes.description}>
                            <Typography variant='body2'>{data.items[activeStep].description}</Typography>
                        </Paper>
                        <Paper square elevation={0} className={classes.action}>
                            {data.items[activeStep].redirect_url.length > 0 ? (
                                <a href={data.items[activeStep].redirect_url} target='_blank'>
                                    <Button size="small" variant="contained" color='primary'>
                                        Ver más
                                    </Button>
                                </a>
                            ) : (
                                <Button size="small" variant="contained" onClick={onClose}>
                                    Cerrar
                                </Button>
                            )}

                        </Paper>

                    </div>
                </DialogContentText>
            </DialogContent>
        </>
    );
}

export default NewsLetterBox;
