
import React from 'react';
import Popover from "@mui/material/Popover";
import { IconButton, Divider, Button, Box } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import IntlMessages from "util/IntlMessages";


export default function ConfirmPopover({ triggerButton, onConfirm, text }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isOpen = Boolean(anchorEl);

    const handleClick = React.useCallback((event) => {
        setAnchorEl(event?.currentTarget);
    }, []);

    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleConfirm = () => {
        setAnchorEl(null);
        onConfirm()
    };

    return (
        <>
            <span className='trigger' onClick={handleClick}>
                {triggerButton}
            </span>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box width={260}>
                    <div className="px-2 py-4 text-center">
                        <IntlMessages id={text} />
                    </div>
                    <Divider />
                    <div className="d-flex justify-content-between p-2">
                        <div></div>
                        <div>
                            <Button
                                type="button"
                                color="error"
                                size="small"
                                className="mr-2"
                                onClick={handleClose}

                            >
                                <IntlMessages id="cancel" />
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={handleConfirm}
                            >
                                <IntlMessages id="yes" />
                            </Button>
                        </div>
                    </div>
                </Box>
            </Popover>
        </>
    );
};

