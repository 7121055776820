
import { green, indigo, blue, red, orange } from "@material-ui/core/colors";

export const newLetterStatus = [
    { value: 'scheduled', i18n: 'scheduled', color: indigo[600] },
    { value: 'inprogress', i18n: 'inprogress', color: orange[700] },
    { value: 'completed', i18n: 'completed', color: green[600] }
]

export const newLetterCategories = [
    { value: 'newfeature', i18n: 'newfeature', color: blue[500] },
    { value: 'maintenance', i18n: 'maintenance', color: green[500] },
    { value: 'bugfix', i18n: 'bugfix', color: indigo[500] }]


export const newLetterImpacts = [
    { value: 'low', i18n: 'low' },
    { value: 'medium', i18n: 'medium' },
    { value: 'high', i18n: 'high' }
]