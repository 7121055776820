import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useConversationService } from "../Conversations/use-conversation-service";

import { Box, Paper, Fade, Zoom, List, CircularProgress } from '@mui/material';
import HeaderConversation from "./ui/HeaderConversation";
import NohasMore from "../Conversations/ui/NohasMore";
import CircularProgressContent from "../Conversations/ui/CircularProgressContent";
import Messages from "./ui/Messages"
import MessageEditor from "./ui/MessageEditor";

export default function Conversation({ oneConversation, isWidget = false }) {

    const { chatContactSelected } = useSelector(({ chat }) => chat)
    const contact_id = useRef(null)

    const {
        onfetchMessages,
        messages,
        setMessages,
        paginationConversation,
        loaderConversation: loader
    } = useConversationService()

    const { total, page } = paginationConversation

    useEffect(() => {
        if (chatContactSelected) {
            if (chatContactSelected.id !== contact_id.current) {
                onfetchMessages(chatContactSelected.id, 1)
                contact_id.current = chatContactSelected.id
            }
        } else {
            contact_id.current = null
            setMessages([])
        }
    }, [chatContactSelected])

    return (
        <Popover open={chatContactSelected}>
            <HeaderConversation isWidget={isWidget} oneConversation={oneConversation}/>
            <Box
                component={'div'}
                id="scroll-conversation"
                className="module-side-scroll scrollbar"
                sx={{
                    height: isWidget ? 'calc(100vh - 130px)' : `calc(100vh - 300px)`,
                    scrollBehavior: "smooth",
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >

                {chatContactSelected ? (
                    <>
                        <InfiniteScroll
                            inverse={true}
                            dataLength={messages.length}
                            next={() => {
                                if (!loader) {
                                    onfetchMessages(chatContactSelected.id, page + 1)
                                    const el = document.getElementById('scroll-conversation')
                                    const marked = document.querySelector('.marked')

                                    if (el && marked) {
                                        el.scrollTop = el.scrollTop + 500
                                    }

                                }
                            }}
                            hasMore={messages.length !== total}
                            scrollableTarget={'scroll-conversation'}
                            style={{ display: 'flex', flexDirection: 'column-reverse' }}
                            loader={
                                <Box sx={{ position: 'relative', minHeight: loader ? 100 : 0 }}>
                                    <CircularProgressContent show={loader} />
                                </Box>
                            }
                            endMessage={
                                <NohasMore show={!loader && total > 0} />
                            }
                        >
                            <Messages
                                loader={loader}
                                pagination={paginationConversation}
                                contact={chatContactSelected}
                                data={messages}
                                messages={[]}
                                loaderStore={false}
                                onDeleted={() => null}
                            />
                        </InfiniteScroll>

                    </>
                ) : null}
            </Box>
            <Box>
                {chatContactSelected ? (
                    <>
                        <MessageEditor contact={chatContactSelected} />
                    </>
                ) : null}
            </Box>
        </Popover >
    )
}

function Popover({ children, open }) {

    const [visible, setVisible] = useState(open)

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                setVisible(false)
            }, 1000);
        } else {
            setVisible(open)
        }
    }, [open])

    return (
        <>
            <Zoom
                in={open}
                style={{ transformOrigin: 'center' }}
                {...(true ? { timeout: 250 } : {})}
            >
                <Box sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                }}>
                    <Fade in={open}
                        {...(true ? { timeout: 250 } : {})}
                    >
                        <Paper
                            sx={{
                                width: '100%',
                                height: '100%'
                            }}>
                            {children}
                        </Paper>
                    </Fade>
                </Box>
            </Zoom >

        </>
    )
}