import {
  FETCH_ALL_DEVICES,
  ADD_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE
} from "../constants/ActionTypes";

export const fetchDevices = data => {
  return {
    type: FETCH_ALL_DEVICES,
    payload: data
  };
};

export const fetchSetting = data => {
  return {
    type: "FETCH_SETTING",
    payload: data
  };
};

export const addDevice = data => {
  return {
    type: ADD_DEVICE,
    payload: data
  };
};

export const updateDevice = (id, data) => {
  return {
    type: UPDATE_DEVICE,
    payload: { id: id, data: data }
  };
};

export const deleteDevice = id => {
  return {
    type: DELETE_DEVICE,
    payload: id
  };
};
