import React from 'react';
import ExtraToolbar from "components/sofia/ExtraToolbar"
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import IntlMessages from "util/IntlMessages"
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ReleaseConversationsAction from './ui/ReleaseConversationsAction';
import BookMarkAction from './ui/BookMarkAction';

import CustomViewAction from './ui/CustomViewAction';
import FilterAction from './ui/FilterAction';


export default function SubHeaderConversation({ total = 0, dataLength = 0, isWidget = false }) {

    const [showSearchEl, setShowSearchEl] = React.useState(false)
    const [search, setSearch] = React.useState('')

    return (
        <Box>
            <ExtraToolbar
                withPaperBackground
                primary={
                    <>
                        <Typography variant="caption">
                            {`Total (${dataLength}/${total})`}
                        </Typography>
                    </>
                }
                secondary={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 6
                        }}
                    >
                        {/* <FilterAction /> */}
                        <div>
                            <ReleaseConversationsAction />
                        </div>
                        {!isWidget ? (
                            <div>
                                <CustomViewAction />
                            </div>
                        ) : null}
                        {/* <BookMarkAction /> */}
                    </div>
                }
                style={{
                    minHeight: 36
                }}
            />
        </Box>
    )
}