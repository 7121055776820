import React from 'react';
import { IconButton, Tooltip, CircularProgress, Menu, MenuItem } from '@material-ui/core';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { injectIntl } from 'react-intl';
import { socket } from "util/Socket";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import ConfirmPopover from "components/ConfirmPopover";
import ContactService from "services/ajax/contact.service";

function ReleaseConversationsAction({ intl }) {

    const { token, locale, clientID, authUser } = useSelector(({ auth, settings }) => ({
        token: auth.token,
        clientID: auth.clientID,
        locale: settings.locale.locale,
        authUser: auth.authUser
    }))

    const { query } = useSelector(({ chat }) => chat)

    const [loader, setLoader] = React.useState(false);
    const [anchorEl, setAchorEl] = React.useState(null);

    const show = React.useMemo(() => {
        const access = authUser.access;
        return access.includes('chat.unlockchatmany') || access.includes('chat.unlockall') ? true : false
    }, [authUser])

    const haveMenu = React.useMemo(() => {
        const access = authUser.access;
        return access.includes('chat.unlockchatmany') && access.includes('chat.unlockall') ? true : false
    }, [authUser])

    const canUnlockall = React.useMemo(() => {
        const access = authUser.access;
        return access.includes('chat.unlockall')
    }, [authUser])


    const handleClick = (event) => {
        setAchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAchorEl(null);
    };

    const onReleaseAll = (band) => {

        // delete querySearchInput.fixed;
        // delete querySearchInput.page;
        // delete querySearchInput.offset;

        // ...menuFilter,
        // ...dynamicFilters,
        // ...querySearchInput,
        // ...queryGlobal

        const q = query;

        setLoader(true);

        const service = new ContactService()

        service[band ? 'unlockall' : 'unlockchatmany']({
            find: {
                ...q
            },
            update: {
                derivedto: ''
            }
        },
            locale,
            token)
            .then(({ data }) => {
                socket.emit("notification", [
                    `client${clientID}`,
                    {
                        module: "chat",
                        action: "updateMany",
                        data: [],
                        msg: ""
                    }
                ]);
            })
            .finally(() => {
                setLoader(false);
            });
    };


    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >


            {show && (
                <>
                    {haveMenu ? (
                        <>
                            <div>

                                <IconButton
                                    size="small"
                                    aria-label="search"
                                    onClick={handleClick}
                                    style={{
                                        padding: "3px 3px",
                                        borderRadius: "100%",
                                        color: "orange",
                                        opacity: loader ? 0.5 : 1
                                    }}
                                >
                                    <Tooltip
                                        title={
                                            intl.formatMessage({ id: 'releaseConversations' })
                                        }
                                    >
                                        <LockOpenIcon
                                            fontSize='small'
                                            style={{
                                                fontSize: 16
                                            }}
                                        />
                                    </Tooltip>
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <ConfirmPopover
                                        text={'canUnlockallConfirm2'}
                                        triggerButton={
                                            <MenuItem onClick={handleClose}>
                                                <div>
                                                    {intl.formatMessage({ id: 'canUnlockalltitle2' })}
                                                </div>
                                            </MenuItem>
                                        }
                                        onConfirm={() => onReleaseAll(false)}
                                    />
                                    <ConfirmPopover
                                        text={'canUnlockallConfirm'}
                                        triggerButton={
                                            <MenuItem onClick={handleClose}>
                                                <div>
                                                    {intl.formatMessage({ id: 'canUnlockalltitle' })}
                                                </div>
                                            </MenuItem>
                                        }
                                        onConfirm={() => onReleaseAll(true)}
                                    />
                                </Menu>
                            </div>
                        </>
                    ) : (
                        <>
                            <ConfirmPopover
                                text={canUnlockall ? 'canUnlockallConfirm' : 'canUnlockallConfirm2'}
                                triggerButton={
                                    <div>
                                        <IconButton
                                            aria-label="edit"
                                            size="small"
                                            disableRipple={loader}
                                            style={{
                                                padding: "3px 3px",
                                                borderRadius: "100%",
                                                color: "orange"
                                            }}
                                        >
                                            <Tooltip
                                                title={
                                                    intl.formatMessage({ id: 'releaseConversations' })
                                                }
                                            >
                                                <LockOpenIcon fontSize="inherit" color="" />
                                            </Tooltip>
                                        </IconButton>
                                    </div>
                                }
                                onConfirm={() => onReleaseAll(canUnlockall ? true : false)}
                            />
                        </>
                    )}
                </>
            )
            }

            {
                loader && (
                    <CircularProgress
                        size={20}
                        style={{
                            position: "absolute",
                            top: 2,
                            left: 1
                        }}
                    />
                )
            }

        </Box >
    )
}

export default injectIntl(ReleaseConversationsAction)