import React, { useState } from 'react';
import Cell from './Cell';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { injectIntl } from "react-intl";

const useStyles = makeStyles(theme => ({
  root: {
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(4),
    },
  },
}));

const Props = SortableContainer(({ props, searchText, onSetCell, intl }) => {
  const classes = useStyles();

  const view = (item, index) => {
    return (
      <Cell key={index} index={index} contact={item} onSetCell={(ev) => onSetCell(index, ev)} />
    )
  }

  return (
    <Box className={classes.root}>
      {props.map((contact, index) => (
        <React.Fragment key={`SortableContainer-${index}`}>
          {searchText.length > 0 ? (
            <>
              {typeof contact.i18n === "undefined" ? (
                <>
                  {typeof contact.name !== "undefined" && (
                    <>
                      {contact.name.toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) >= 0 && (
                        view(contact, index)
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {typeof intl.formatMessage({ id: contact.i18n }) !== "undefined" && (
                    <>
                      {intl.formatMessage({ id: contact.i18n }).toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase()) >= 0 && (
                        view(contact, index)
                      )}
                    </>
                  )}
                </>
              )}


            </>
          ) : (
            view(contact, index)
          )}
        </React.Fragment>
      ))}
    </Box >
  );
});

const DragAndDrop = (props) => {

  const { data, searchText, onChange, onSetCell, intl } = props;

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(data, oldIndex, newIndex));
  };

  return (
    <React.Fragment>
      <Props props={data} intl={intl} searchText={searchText} onSortEnd={onSortEnd} useDragHandle={true} onSetCell={onSetCell} />
    </React.Fragment>
  );
};

export default injectIntl(DragAndDrop);
