import React from "react";
import { connect } from "react-redux";
import {
  TextField,
  MenuItem,
  Avatar,
  CircularProgress,
  Tooltip
} from "@material-ui/core";

// Rxjs
import { Subject } from "rxjs";

import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

// Helpers
import IntlMessages from "util/IntlMessages";

// Services
import ContactService from "services/ajax/contact.service";
import DealService from "services/ajax/deal.service";

import DynamicListService from "services/ajax/dynamicList.service";

// Components
import Autocomplete from "@material-ui/lab/Autocomplete";

// Icons
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

let search$ = new Subject();

function SearchModel(props) {
  const {
    classes = "",
    q = null,
    type = "user",
    label = null,
    isRequired = true,
    isAutofous = false,
    token,
    locale,
    users,
    defaultValue,
    isMultiple = true,
    onlyName = false
  } = props;
  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    switch (type) {
      case "user":
        setData(users);
        break;

      default:
        break;
    }
  }, [0]);

  React.useEffect(() => {
    if (q) {
      if (q.device_id !== undefined) {
        if (!q.device_id) {
          setData([]);
          setValue(null);
        }
      }
    }
  }, [q]);

  React.useEffect(() => {
    search$
      .pipe(
        debounceTime(900),
        distinctUntilChanged(),
        switchMap(({ search, filter }) => {
          setLoader(true);
          switch (type) {
            case "contact":
              let send = {
                "info.full_name": { 'contains': search }
              };

              if (filter) {
                if (filter.device_id !== undefined) {
                  send = { ...send, device_id: { 'equalto': `${filter.device_id}` } };
                }
              }

              return new ContactService().search(
                {
                  ...send
                },
                locale.locale,
                token
              );
            case "deal":
              return new DealService().search(
                {
                  'name': { 'contains': search }
                },
                locale.locale,
                token
              );
            default:
              break;
          }
        })
      )
      .subscribe(
        resp => {
          const { data, status_codes } = resp.data;
          if (status_codes === 200) {
            setData(data);
          }
          setLoader(false);
        },
        _ => {
          setLoader(false);
        }
      );

    return () => {
      setData([]);
      // search$.unsubscribe()
    };
  }, [0]);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      setData([defaultValue]);
    } else {
      setValue();
      setData([]);
    }
  }, [defaultValue]);

  return (
    <>
      <Tooltip title={<IntlMessages id="search.on" />}>
        <Autocomplete
          autoComplete
          value={value}
          size="medium"
          options={data}
          fullWidth
          getOptionLabel={option => {
            switch (type) {
              case "contact":
                return `${option.info.first_name} ${option.info.last_name}`;
              case "deal":
                return `${option.name}`;
              default:
                break;
            }
          }}
          renderOption={option => {
            switch (type) {
              case "contact":
                return (
                  <React.Fragment>
                    <div className="d-flex align-items-center w-100 position-relative">
                      {onlyName ? (
                        <h5 className="user-name">{`${option.info.first_name} ${option.info.last_name}`}</h5>
                      ) : (
                        <>
                          <Avatar className="user-avatar size-20 bg-primary">
                            <PersonOutlineIcon fontSize="small" />
                          </Avatar>
                          <div className="user-detail">
                            <h5 className="user-name">{`${option.info.first_name} ${option.info.last_name}`}</h5>
                          </div>
                          <div
                            className="text-right"
                            style={{
                              position: "absolute",
                              right: 0
                            }}
                          >
                            <span>
                              {option.info.phone.length > 0
                                ? `${option.info.phone}`
                                : "-"}
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </React.Fragment>
                );
              case "deal":
                return (
                  <React.Fragment>
                    <div className="d-flex align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{`${option.name}`}</h5>
                      </div>
                    </div>
                  </React.Fragment>
                );
              default:
                break;
            }
          }}
          onChange={(_, newValue) => {
            setValue(newValue);
            props.onSelected(newValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={!label ? <IntlMessages id="users" /> : label}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
                required: isRequired
              }}
              className={classes}
              loading={loader}
              InputProps={{
                ...params.InputProps,
                onChange: ev => { },
                onKeyPress: ev => {
                  if (ev.key === "Enter") {
                    if (type !== "user")
                      search$.next({ search: ev.target.value, filter: q });
                  }
                },
                endAdornment: (
                  <React.Fragment>
                    {loader ? (
                      <CircularProgress color="primary" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                placeholder: "Buscar..."
              }}
              required={isRequired}
              autoFocus={isAutofous}
            />
          )}
        />
      </Tooltip>
    </>
  );
}

const mapStateToProps = ({ user, auth, settings }) => {
  const { users } = user;
  const { token } = auth;
  const { locale } = settings;
  return {
    users,
    token,
    locale
  };
};

export default connect(mapStateToProps, {})(SearchModel);
