import axios from "util/Api";
import { base, baseURLajaxPrefix } from "util/Api";

class MessageService {
  constructor() {}

  async index(contactId, params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/message/contact/${contactId}`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async store(msg, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/message/create`, msg, {
      headers: {
        Authorization: token
      }
    });
  }

  async store2(msg, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/message/createfast`, msg, {
      headers: {
        Authorization: token
      }
    });
  }

  async sendTemplate(msg, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/message/sendtemplate`, msg, {
      headers: {
        Authorization: token
      }
    });
  }

  async storeAttach(msg, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/message/create`, msg, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data"
      }
    });
  }

  async delete(id, locale, token) {
    return axios.delete(`${baseURLajaxPrefix}/${locale}/message/deleteone/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }
}

export default MessageService;
