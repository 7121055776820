import React from "react";
import { connect } from "react-redux";
import {
  FormControlLabel,
  MenuItem,
  CircularProgress,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Switch,
  Tabs,
  Tab,
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

// Helpers
import IntlMessages from "util/IntlMessages";

// Icons
import CloseIcon from "@material-ui/icons/Close";


function ModalPlanExpiration(props) {

  const { visible } = props;

  return (
    <Dialog maxWidth="sm" fullWidth open={visible} scroll="body">
      <DialogTitle>
        <div className="d-flex justify-content-end">
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              props.onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="row align-items-center justify-content-center">
          <div className="col-4">
            <img
              src={require("assets/images/logo.png")}
              className="img-fluid"
              alt="logo"
              title="Sofia bot"
            />
          </div>
          <div className="col-7 px-2">
            <h1 className="mb-3">Tu Plan ha expirado</h1>
            <p>Tiene un pago pendiente. Algunas funcionalidades importantes se han deshabilitado en tu cuenta.</p>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.onClose()}
          disabled={false}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, {

})(ModalPlanExpiration);
