import React from "react";
import CircularProgress from "components/CircularProgress/index";
import Button from "@material-ui/core/Button";
import { socket, InitSocket } from "util/Socket";
import Snackbar from "@material-ui/core/Snackbar";
import Auxiliary from "util/Auxiliary";
import { connect } from "react-redux";
import { hideMessage } from "actions/Common";
import IntlMessages from "util/IntlMessages";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

// Actions
import { onMsgNotify } from "actions/Chat";
import AlertChat from "../../app/routes/chat/components/AlertChat";

// Audios
const audio = new Audio("https://s3.us-east-2.wasabisys.com/assets-sofiabot/audio/f37bd2f66651e7d46f6d38440f2bc5dd.mp3");

class InfoView extends React.Component {
  state = {
    isDisconnected: false
  };

  componentDidUpdate() { }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error || nextProps.message) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }

    if (window.location.href.indexOf("/app/chat") <= -1) {
      if (nextProps.msgNotify) {
        const { authUser, msgNotify, contactNotify } = nextProps;
        if (authUser) {
          if (typeof authUser.notify !== "undefined") {
            if (msgNotify) {
              const { contact } = msgNotify;
              const { message } = contact;
              if (contactNotify) {
                if (contactNotify.id !== contact.id) {
                  this.getAlert(contact, message);
                }
              }
            }
          }
        }
      }
    }
  }

  getAlert(contact, msg) {
    const { allDevice, authUser } = this.props;
    const deviceID = msg.info.device_id;
    const device = allDevice.find(d => d.id === deviceID);

    if (typeof device !== "undefined") {
      const status = msg.status;
      if (status !== "error") {
        NotificationManager.success(
          <div>
            <div style={{ position: "absolute", left: 12, top: 17 }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                style={{ height: 35, width: 35 }}
              >
                <g id="message" transform="translate(1971 -2018)">
                  <circle
                    id="ellipse"
                    cx="10"
                    cy="10"
                    r="10"
                    transform="translate(-1971 2018)"
                    fill="#fff"
                  />
                  <path
                    fill={`${device.color}`}
                    id="icon"
                    d="M11.855,2H3.095a1.094,1.094,0,0,0-1.09,1.095L2,12.95l2.19-2.19h7.665A1.1,1.1,0,0,0,12.95,9.665V3.095A1.1,1.1,0,0,0,11.855,2ZM4.19,5.832h6.57V6.927H4.19ZM8.57,8.57H4.19V7.475H8.57Zm2.19-3.285H4.19V4.19h6.57Z"
                    transform="translate(-1968 2021)"
                  />
                </g>
              </svg>
            </div>
            <span>
              {msg?.info.type_msg === "chat" ? (
                msg?.info.body.substring(0, 25) + "..."
              ) : (
                <IntlMessages id={msg?.info.type_msg} />
              )}
            </span>
          </div>,
          `${contact.info.first_name} ${contact.info.last_name}`,
          5000,
          () => {
            localStorage.setItem("contact_id", contact.id);
            this.props.history.push(`/app/chat`);
          }
        );

        if (authUser.sound) {
          audio.play();
        }
        this.props.onMsgNotify(null);
      } else {
        NotificationManager.error(
          <span>
            {msg?.info.type_msg === "chat" ? (
              msg?.info.body.substring(0, 25) + "..."
            ) : (
              <IntlMessages id={msg?.info.type_msg} />
            )}
          </span>,
          `${contact.info.first_name} ${contact.info.last_name}`
        );

        if (authUser.sound) {
          audio.play();
        }
        this.props.onMsgNotify(null);
      }
    }
  }

  render() {
    const { isDisconnected } = this.state;
    const { error, loading, message, location } = this.props;
    const isWidget = typeof this.props.isWidget !== "undefined" ? this.props.isWidget : false;
    const open = error !== "" || message !== "";
    const showAlert = window.location.href.indexOf("/app/chat") <= -1;
    let showMessage = message;
    if (error) {
      showMessage = error;
    }

    return (
      <Auxiliary>
        {loading && (
          <div
            className=""
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              width: "100%",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 100
            }}
          >
            <CircularProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{showMessage}</span>}
        />
        <NotificationContainer />
        <AlertChat isWidget={isWidget} />
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ commonData, devices, chat, auth, router }) => {
  const { location } = router;
  const { error, loading, message } = commonData;
  const { allDevice } = devices;
  const { contactNotify, msgNotify } = chat;
  const { authUser, userID } = auth;
  return {
    userID,
    error,
    loading,
    message,
    allDevice,
    authUser,
    contactNotify,
    msgNotify,
    location
  };
};

export default connect(mapStateToProps, {
  hideMessage,
  onMsgNotify
})(InfoView);
