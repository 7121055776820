import React, { Component } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import "assets/vendors/style";
import defaultTheme from "./themes/defaultTheme";
import AppLocale from "../lngProvider";

import MainApp from "app/index";
import SignInFromChat from "./SignInFromChat";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { setInitUrl } from "../actions/Auth";
import RTL from "util/RTL";
import axios from "util/Api";
import asyncComponent from "util/asyncComponent";

const RestrictedRoute = ({ component: Component, token, ...rest }) => {

  let path = '/signin';

  if (window && window.parent) {
    try {
      if (typeof window.location !== 'undefined' && typeof window.parent.location.origin === 'undefined') {
        path = '/signin'
      }
    } catch (error) {
      path = '/chat-signin'
    }
  }

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: path,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )

};

class App extends Component {
  componentWillMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + nextProps.token;
    }
    // if (nextProps.token && !nextProps.authUser) {
    //   // this.props.getUser()
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('message', () => { })
  }

  render() {
    const {
      match,
      location,
      locale,
      token,
      initURL,
      isDirectionRTL
    } = this.props;

    if (location.pathname === "/") {
      if (token === null) {

        if (window && window.parent) {
          try {
            if (typeof window.location !== 'undefined' && typeof window.parent.location.origin === 'undefined') {
              return <Redirect to={"/signin"} />;
            }
          } catch (error) {
            return <Redirect to={"/chat-signin"} />;
          }
        }

        return <Redirect to={"/signin"} />;
      } else if (initURL === "" || initURL === "/" || initURL === "/signin") {

        if (window && window.parent) {
          if (typeof window.location !== 'undefined' && typeof window.parent.location.origin === 'undefined') {
            return <Redirect to={"/app/chat-iframe"} />;
          }
        }

        if (window && window.parent) {
          try {
            if (typeof window.location !== 'undefined' && typeof window.parent.location.origin === 'undefined') {
              return <Redirect to={"/app/dashboard/main"} />;
            }
          } catch (error) {
            return <Redirect to={"/app/chat-iframe"} />;
          }
        }
        return <Redirect to={"/app/dashboard/main"} />;
      } else {
        return <Redirect to={initURL} />;
      }
    }

    const applyTheme = createMuiTheme(defaultTheme);

    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <>
        <MuiThemeProvider theme={applyTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <RTL>
                <div className="app-main">
                  <Switch>
                    <RestrictedRoute
                      path={`${match.url}app`}
                      token={token}
                      component={MainApp}
                    />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/chat-signin" component={SignInFromChat} />
                    <Route path="/signup" component={SignUp} />
                    <Route
                      path="/500"
                      component={asyncComponent(() =>
                        import("components/Error500")
                      )}
                    />
                    <Route
                      component={asyncComponent(() =>
                        import("components/Error404")
                      )}
                    />

                  </Switch>
                </div>
              </RTL>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { sideNavColor, locale, isDirectionRTL } = settings;
  const { authUser, token, initURL } = auth;
  return { sideNavColor, token, locale, isDirectionRTL, authUser, initURL };
};

export default connect(mapStateToProps, { setInitUrl })(App);
