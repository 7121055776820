import React from "react";
import { connect } from "react-redux";
import {
  TextField,
  MenuItem,
  Avatar,
  CircularProgress,
  Tooltip
} from "@material-ui/core";

// Rxjs
import { Subject } from "rxjs";

import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";

// Helpers
import IntlMessages from "util/IntlMessages";

// Services
import ContactService from "services/ajax/contact.service";
import DealService from "services/ajax/deal.service";
import LeadService from "services/ajax/lead.service";

import DynamicListService from "services/ajax/dynamicList.service";

// Components
import Autocomplete from "@material-ui/lab/Autocomplete";

// Icons
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";


function AutoCompleteAsync(props) {
  const {
    classes = "",
    q = null,
    type = "contact",
    label = null,
    isRequired = true,
    isAutofous = false,
    token,
    locale,
    users,
    defaultValue,
    isMultiple = true,
    onlyName = false,
    variant = "outlined",
    onSelected
  } = props;

  const typeRef = React.useRef(type)
  const search$ = React.useRef(new Subject())

  const [data, setData] = React.useState([]);
  const [value, setValue] = React.useState(isMultiple ? [] : null);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    switch (type) {
      case "user":
        setData(users);
        break;

      default:
        break;
    }

  }, [0]);

  React.useEffect(() => {
    if (type !== typeRef.current) {
      setData([])
      typeRef.current = type;
      setValue(isMultiple ? [] : null)
      onSelected(isMultiple ? [] : null)
    }

  }, [type]);

  React.useEffect(() => {
    if (q) {
      if (q.device_id !== undefined) {
        if (!q.device_id) {
          setData([]);
          setValue(null);
        }
      }
    }
  }, [q]);

  React.useEffect(() => {
    search$.current
      .pipe(
        debounceTime(900),
        distinctUntilChanged(),
        switchMap(({ search, filter, type }) => {
          setLoader(true);
          switch (type) {
            case "contact":
              let send = {
                "info.first_name": search
              };

              if (filter) {
                if (filter.device_id !== undefined) {
                  send = { ...send, device_id: [filter.device_id] };
                }
              }
              return new ContactService().search(
                {
                  ...send
                },
                locale.locale,
                token
              );
            case "deal":
              return new DealService().search(
                {
                  name: search
                },
                locale.locale,
                token
              );
            case "lead":
              let send2 = {}

              if (!Number.isNaN(+search)) {
                send2 = {
                  phone: `${+search}`
                }
              } else {
                send2 = {
                  full_name: `${search}`
                }
              }
              return new LeadService().search(
                send2,
                locale.locale,
                token
              );
            default:
              break;
          }
        })
      )
      .subscribe(
        resp => {
          const { data, status_codes } = resp.data;
          if (status_codes === 200) {
            setData(data);
          }
          setLoader(false);
        },
        _ => {
          setLoader(false);
        }
      );

    return () => {
      setData([]);
      // search$.unsubscribe()
    };
  }, [0]);

  React.useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      setData([defaultValue]);
    } else {
      setValue();
      setData([]);
    }
  }, [defaultValue]);


  return (
    <>
      <Tooltip title={<IntlMessages id="search.on" />}>
        <Autocomplete
          autoComplete
          value={value}
          size="medium"
          options={data}
          fullWidth
          multiple={isMultiple}
          getOptionLabel={option => {
            switch (type) {
              case "contact":
                return `${option.info.full_name}`;
              case "deal":
                return `${option.name}`;
              case "lead":
                return `${option.first_name}`;
              default:
                break;
            }
          }}
          renderOption={option => {
            switch (type) {
              case "contact":
                return (
                  <React.Fragment>
                    <div className="d-flex align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{`${option.info.full_name}`}</h5>
                      </div>
                    </div>
                  </React.Fragment>
                );
              case "deal":
                return (
                  <React.Fragment>
                    <div className="d-flex align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{`${option.name}`}</h5>
                      </div>
                    </div>
                  </React.Fragment>
                );
              case "lead":
                return (
                  <React.Fragment>
                    <div className="d-flex align-items-center">
                      <div className="user-detail">
                        <h5 className="user-name">{`${option.first_name}` `${option.last_name}`}</h5>
                      </div>
                    </div>
                  </React.Fragment>
                );
              default:
                break;
            }
          }}
          onChange={(_, newValue) => {
            setValue(newValue);
            onSelected(newValue);
          }}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              margin="none"
              size="small"
              variant={variant}
              InputLabelProps={{
                shrink: true,
                required: isRequired
              }}
              className={classes}
              loading={loader}
              InputProps={{
                ...params.InputProps,
                onChange: ev => {
                  // if (type !== "user")
                  // search$.current.next({ search: ev.target.value, filter: q, type: type });
                },
                onKeyPress: ev => {
                  if (ev.key === "Enter") {
                    if (type !== "user") {
                      ev.preventDefault();
                      search$.current.next({ search: ev.target.value, filter: q, type: type });
                    }
                  }
                },
                endAdornment: (
                  <React.Fragment>
                    {loader ? (
                      <CircularProgress color="primary" size={15} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                placeholder: "Buscar..."
              }}
              required={isRequired}
              autoFocus={isAutofous}
            />
          )}
        />
      </Tooltip>
    </>
  );
}

const mapStateToProps = ({ user, auth, settings }) => {
  const { users } = user;
  const { token } = auth;
  const { locale } = settings;
  return {
    users,
    token,
    locale
  };
};

export default connect(mapStateToProps, {})(AutoCompleteAsync);
