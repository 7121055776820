import React, { useEffect } from "react";

import { IconButton } from "@material-ui/core";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";

// Components
import Acl from "components/sofia/elements/acl";

// Colors
import { green } from "@material-ui/core/colors";

export default function CellItem(props) {
  const { access, isFlexColumn = false, isCompact = false, isControl = false, showEditBtn = true } = props;
  const [edit, setEdit] = React.useState(isControl);

  useEffect(() => {
    if (!isControl) {
      setEdit(false)
    }
  }, [isControl])

  return (
    <Acl
      access={access}
      component={
        <>
          {isCompact ? (
            <div
              className="media-cellItem d-flex align-items-center justify-content-between px-1"
              style={{ width: edit ? 270 : 150 }}
            >
              <div className="d-flex">{!edit ? props.showTpl : props.editTpl}</div>
              <div className="d-flex align-items-center px-2">
                {edit && (
                  <>
                    <IconButton
                      aria-label="check"
                      className="size-30"
                      size={"small"}
                      onClick={() => {
                        setEdit(false);
                        props.store();
                      }}
                      style={{ color: green[400] }}
                      disabled={props?.error}
                    >
                      <i className="zmdi zmdi-check"></i>
                    </IconButton>
                    <IconButton
                      aria-label="close"
                      className="size-30"
                      size={"small"}
                      onClick={() => {
                        setEdit(false);
                        if (typeof props.cancel !== "undefined") {
                          props.cancel();
                        }
                      }}
                    >
                      <i className="zmdi zmdi-close"></i>
                    </IconButton>
                  </>
                )}
                {(!edit && showEditBtn) ? (
                  <IconButton
                    aria-label="edit"
                    size={"small"}
                    onClick={() => {
                      if (typeof props.onEdit !== "undefined") {
                        props.onEdit();
                      }
                      setEdit(true);
                    }}
                    className={"action"}
                  >
                    <EditIcon />
                  </IconButton>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <div className="media media-cellItem d-flex align-items-center flex-nowrap jr-pro-item-list pb-1 animated  slideInUpTiny animation-duration-3 h-100">
                {!edit ? props.showTpl : props.editTpl}
                <div
                  className={`${isFlexColumn ? "flex-column" : "d-flex"} mx-1`}
                >
                  {!isControl && (
                    <>
                      {edit && (
                        <>
                          <IconButton
                            aria-label="check"
                            className="size-30"
                            size={"small"}
                            onClick={() => {
                              setEdit(false);
                              props.store();
                            }}
                            style={{ color: green[400] }}
                            disabled={props?.error}
                          >
                            <i className="zmdi zmdi-check"></i>
                          </IconButton>
                          <IconButton
                            aria-label="close"
                            className="size-30"
                            size={"small"}
                            onClick={() => {
                              setEdit(false);
                              if (typeof props.cancel !== "undefined") {
                                props.cancel();
                              }
                            }}
                          >
                            <i className="zmdi zmdi-close"></i>
                          </IconButton>
                        </>
                      )}
                      {(!edit && showEditBtn) ? (
                        <IconButton
                          aria-label="edit"
                          size={"small"}
                          onClick={() => {
                            if (typeof props.onEdit !== "undefined") {
                              props.onEdit();
                            }
                            setEdit(true);
                          }}
                          className={"action"}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      }
      componentFalse={
        <div className="media media-cellItem d-flex align-items-center flex-nowrap jr-pro-client-list pb-1 animated slideInUpTiny animation-duration-3">
          {props.showTpl}
        </div>
      }
    />
  );
}
