import React from "react";
import { connect } from "react-redux";
import {
  FormControlLabel,
  MenuItem,
  CircularProgress,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Switch,
  Tabs,
  Tab,
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

// Helpers
import IntlMessages from "util/IntlMessages";

// Icons
import CloseIcon from "@material-ui/icons/Close";


function ModalNotify(props) {

  const { visible, tpl } = props;

  return (
    <Dialog maxWidth="sm" fullWidth open={visible} scroll="body">
      <DialogTitle>
        <div className="d-flex justify-content-end">
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              props.onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: tpl }}>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.onClose()}
          disabled={false}
        >
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, {

})(ModalNotify);
