import {
  FETCH_ALL_PIPELINE,
  ADD_PIPELINE,
  UPDATE_PIPELINE,
  DELETE_PIPELINE
} from "../constants/ActionTypes";

export const fetchPipeline = data => {
  return {
    type: FETCH_ALL_PIPELINE,
    payload: data
  };
};

export const addPipeline = data => {
  return {
    type: ADD_PIPELINE,
    payload: data
  };
};

export const updatePipeline = (id, data) => {
  return {
    type: UPDATE_PIPELINE,
    payload: { id: id, data: data }
  };
};

export const deletePipeline = id => {
  return {
    type: DELETE_PIPELINE,
    payload: id
  };
};
