import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";

import {
  IconButton,
  CircularProgress,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@material-ui/core";

import PhoneInput from "react-phone-number-input";

// Helpers
import { makeStyles } from "@material-ui/core/styles";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import { useUser } from "services/hook/user.service";
import SearchIcon from "@material-ui/icons/Search";
import ExpandMore from "@material-ui/icons/ExpandMore";

// Services

// Actions
import { showMessage } from "actions/Common";

// components
import CustomScrollbars from "util/CustomScrollbars";
import DragAndDrop from "./drag/DragAndDrop";
import Widget from "components/Widget/index";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

function PhoneMask(props) {
  const { inputRef, ...other } = props;
  return (
    <PhoneInput
      {...other}
      className={"MuiInputBase-input MuiInput-input border-0"}
    />
  );
}

function CustomViewModal(props) {
  const { visible, edit, setting_views, intl } = props;

  const [configGlobal, setConfigGlobal] = React.useState(
    setting_views.find(s => s.module === props.module)
  );
  const options = setting_views.find(s => s.module === props.module).columns;
  const kanban = props.authUser.custom_views.find(
    c => c.module === props.module
  ).views.kanban;
  const config_user = props.authUser.custom_views.find(
    c => c.module === props.module
  ).views.kanban.columns;

  const [configUser, setConfigUser] = React.useState(config_user);
  const [aggregate_by, setAggregate_by] = React.useState("");
  const [group_by, setGroup_by] = React.useState("");
  const [order, setOrder] = React.useState("desc");
  const [order_by, setOrder_by] = React.useState("create_date");

  const [cols, setCols] = React.useState([]);

  // !options.length ? "" : options[0].key

  const [configTemp, setConfigTemp] = React.useState([]);
  const [configTempDisabled, setConfigTempDisabled] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  const [configTemp2, setConfigTemp2] = React.useState([]);
  const [configTempDisabled2, setConfigTempDisabled2] = React.useState([]);
  const [searchValue2, setSearchValue2] = React.useState("");

  const { loader, updateUser, statusCode } = useUser({
    id: props.userID,
    clientID: props.clientID,
    token: props.token,
    locale: props.locale.locale
  });

  const onSubmit = () => {
    // console.log(
    //   "columns",
    //   configTemp.map(c => c.key)
    // );
    // props.onStored(configTemp.map(c => c.key));
    const newChat = {
      columns: configTemp.map(c => c.key)
    };

    const modelUpdate = { ...props.authUser };
    const findIndex = modelUpdate.custom_views.findIndex(
      el => el.module === props.module
    );
    modelUpdate.custom_views[findIndex].views.kanban = newChat;

    updateUser({ id: modelUpdate.id, custom_views: modelUpdate.custom_views });
  };

  const generateConfig = () => {
    let arrG = [];
    let arr = [];
    let arr2 = [];

    configGlobal.columns.forEach(el => {
      const find = configUser.find(key => key === el.key);

      if (typeof find !== "undefined") {
        arr.push({
          ...el,
          checked: true
        });
        arrG.push({
          ...el,
          checked: true
        });
      } else {
        arr2.push({
          ...el,
          checked: false
        });
        arrG.push({
          ...el,
          checked: false
        });
      }
    });

    const vector = [];

    configUser.forEach(key => {
      const find = arr.find(el => el.key == key);
      if (typeof find !== "undefined") {
        vector.push(find);
      }
    });

    arr = [...vector];

    setConfigTemp([...arr]);
    setConfigTempDisabled([...arr2]);
  };

  const generateColumns = () => {
    const group = group_by;
    const cols = setting_views.find(s => s.module === props.module).columns;
    const find = cols.find(c => c.key === group);

    let values = props[find.redux].filter(
      item =>
        item.module === props.module && item.field === find.masterlist_field
    );

    if (kanban.order_columns.length > 0) {
      const intersection = (a, b) => a.filter(el => b.indexOf(el.id) !== -1);
      const diff = (a, b) => a.filter(el => b.indexOf(el.id) === -1);
      const _diff = diff(values, kanban.order_columns);
      let _intersection = intersection(values, kanban.order_columns);
      _intersection = kanban.order_columns.map(v =>
        _intersection.find(el => el.id === v)
      );

      values = [..._intersection, ..._diff];
    }

    let arr = [];
    let arr2 = [];

    const values_columns = values.filter(Boolean);
    values_columns.forEach(el => {
      const find = kanban.order_columns.find(key => key === el.id);
      if (typeof find !== "undefined") {
        arr.push({
          ...el,
          checked: true
        });
      } else {
        arr2.push({
          ...el,
          checked: false
        });
      }
    });

    setConfigTemp2([...arr]);
    setConfigTempDisabled2([...arr2]);
  };

  React.useEffect(() => {
    if (edit) {
      setAggregate_by(kanban.aggregate_by);
      setGroup_by(kanban.group_by);
      setOrder(kanban.order);
      setOrder_by(kanban.order_by);
    } else {
      setGroup_by("");
    }
  }, [edit]);

  const controlView = (item, t) => {
    return (
      <div className="d-flex py-1 mb-0 border-bottom bg-white">
        <div className="d-flex justify-content-between w-100 px-3">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mr-1"
              checked={item.checked}
              onChange={ev => {
                const arr = [...configTemp];
                const arrDisabled = [...configTempDisabled];
                const item = { ...arrDisabled[t] };
                item.checked = true;
                arr.push(item);

                setConfigTempDisabled(arrDisabled.filter((_, x) => x !== t));
                setConfigTemp(arr);
                setSearchValue("");
              }}
            />
            <span className="mx-2">
              <IntlMessages id={item.i18n} />
            </span>
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  const controlView2 = (item, t) => {
    return (
      <div className="d-flex py-1 mb-0 border-bottom bg-white">
        <div className="d-flex justify-content-between w-100 px-3">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              className="mr-1"
              checked={item.checked}
              onChange={ev => {
                const arr = [...configTemp2];
                const arrDisabled = [...configTempDisabled2];
                const item = { ...arrDisabled[t] };
                item.checked = true;
                arr.push(item);

                setConfigTempDisabled2(arrDisabled.filter((_, x) => x !== t));
                setConfigTemp2(arr);
                setSearchValue2("");
              }}
            />
            <span className="mx-2">{item.name}</span>
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    generateConfig();
  }, [configUser]);

  React.useEffect(() => {
    const { code, msg, data } = statusCode;
    if (code) {
      if (code === 200) {
        props.showMessage(msg);
        props.onStored();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  return (
    <>
      <Modal
        onClose={() => {
          props.onClose();
          generateConfig();
        }}
        isOpen={visible}
        style={{ zIndex: 2600, maxWidth: "600px", maxHeight: 500 }}
      >
        <ModalHeader>
          <IntlMessages id="select.fields" />
          <div>
            <IconButton
              onClick={() => {
                props.onClose();
                generateConfig();
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            maxHeight: 530,
            overflowY: "scroll",
            background: "#f6f6f6"
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-2">
                <div className="row">
                  <div className="col-6">
                    <Widget styleName="jr-card-full">
                      <div
                        className="px-3 py-2 pb-2 w-100"
                        style={{ background: "#fafafa" }}
                      >
                        <TextField
                          value={searchValue}
                          onChange={ev => setSearchValue(ev.target.value)}
                          size="small"
                          fullWidth
                          placeholder="Buscar"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                      <Divider />
                      <CustomScrollbars
                        className="scrollbar"
                        style={{
                          minHeight: 200,
                          maxHeight: 200
                        }}
                      >
                        {configTempDisabled.map((item, t) => (
                          <React.Fragment key={`disabled-${t}`}>
                            <>
                              {item.column && (
                                <>
                                  {searchValue.length > 0 ? (
                                    <>
                                      {typeof intl.formatMessage({
                                        id: item.i18n
                                      }) !== "undefined" && (
                                        <>
                                          {intl
                                            .formatMessage({
                                              id: item.i18n
                                            })
                                            .toLocaleUpperCase()
                                            .indexOf(
                                              searchValue.toLocaleUpperCase()
                                            ) >= 0 && controlView(item, t)}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    controlView(item, t)
                                  )}
                                </>
                              )}
                            </>
                          </React.Fragment>
                        ))}
                      </CustomScrollbars>
                    </Widget>
                  </div>
                  <div className="col-6">
                    <Widget styleName="jr-card-full">
                      <div
                        className="px-3 py-2 pb-2 w-100"
                        style={{ background: "#fafafa" }}
                      >
                        <h5 className="py-2 small mb-0 font-weight-normal">
                          {`Seleccionados (${configTemp.length})`}
                        </h5>
                      </div>
                      <Divider />
                      <CustomScrollbars
                        className="scrollbar"
                        style={{
                          minHeight: 200,
                          maxHeight: 200
                        }}
                      >
                        <DragAndDrop
                          data={configTemp}
                          searchText={""}
                          onChange={ev => setConfigTemp(ev)}
                          onSetCell={(pos, newValue) => {
                            const arr = [...configTemp];
                            const arrDisabled = [...configTempDisabled];
                            const item = { ...arr[pos] };
                            item.checked = false;
                            arrDisabled.unshift(item);

                            setConfigTemp(arr.filter((_, t) => t !== pos));
                            setConfigTempDisabled(arrDisabled);
                          }}
                        />
                        {!configTemp.length && (
                          <div className="p-5 small text-center w-100">
                            <p style={{ opacity: 0.9 }}>Seleccione un item</p>
                          </div>
                        )}
                      </CustomScrollbars>
                    </Widget>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between px-4">
          <div></div>
          <div>
            <Button
              type="button"
              color="error"
              size="small"
              className="mr-2"
              disabled={loader}
              onClick={() => {
                props.onClose();
                generateConfig();
              }}
            >
              {`Cancelar`}
            </Button>

            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={loader || !configTemp.length}
              disableElevation
              onClick={onSubmit}
            >
              {edit ? `Actualizar` : `Crear`}
            </Button>
            <span className="ml-2 mt-1">
              {loader && <CircularProgress size={20} />}
            </span>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = ({ settings, auth, dynamicList, devices, user }) => {
  const { width, locale, showFilters, setting_views } = settings;
  const { token, clientID, userID, client, authUser } = auth;
  const { items } = dynamicList;
  const { allDevice } = devices;
  const { users } = user;

  return {
    authUser,
    showFilters,
    setting_views,
    client,
    clientID,
    width,
    locale,
    token,
    userID,
    users,
    devices: allDevice,
    sources: items.filter(el => el.field === "source" && el.status),
    status: items.filter(el => el.status),
    branchs: items.filter(
      el => el.module === "all" && el.field === "branch_id" && el.status
    ),
    stages: items.filter(el => el.field === "list_stage" && el.status),
    tags: items.filter(el => el.field === "tags" && el.status),
    paymentMethods: items.filter(
      el =>
        el.module === "payment" && el.field === "payment_method" && el.status
    )
  };
};

export default connect(mapStateToProps, { showMessage })(
  injectIntl(CustomViewModal)
);
