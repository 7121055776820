import React from "react";
import { connect } from "react-redux";
import {
  Avatar,
  CircularProgress,
  TextField,
  IconButton,
  TablePagination,
  InputAdornment,
  Button
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

// Services
import { useNotes, useNote } from "services/hook/note.service";

// Actions
import { showMessage } from "actions/Common";

// Helpers
import { makeStyles } from "@material-ui/core/styles";
import IntlMessages from "util/IntlMessages";
import DisplayDate from "util/DisplayDate";

// Color
import { indigo } from "@material-ui/core/colors";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import SendIcon from "@material-ui/icons/Send";

import Comment from "./comment";

import { getDateTimeValue } from "util/Helper";
// Styles

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  indigo: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500]
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
    fontSize: "13px",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2)
  }
}));

function Comments(props) {
  const classes = useStyles();
  const {
    authUser,
    userID,
    contact,
    contactID,
    state,
    clientID,
    token,
    locale,
    users,
    currentUser,
    notification,
    size = "default",
    showMessage200 = true,
    module = "contact",
    modelID,
    model,
    reload,
    setReload,
    showNoData = true
  } = props;

  const {
    notes,
    loader,
    loaderStore,
    page,
    total,
    rowsPerPage,
    statusCode,
    index,
    setData,
    storeNote
  } = useNotes({
    clientID: clientID,
    token: token,
    userID: userID,
    locale: locale.locale,
    queryGlobal:
      module === "contact"
        ? {
          contact_id: contactID
        }
        : {
          reference_id: modelID
        }
  });

  const [rowQty, setRowQty] = React.useState(1);
  const [textareaValue, setTextareaValue] = React.useState();
  const [onStoreComments, setOnStoreComments] = React.useState([]);
  const placeholder = {
    es: "Añadir un comentario...",
    en: "Add a comment..."
  };
  const elementRef = React.createRef();

  const toFetch = page => {
    index(page);
  };

  const getUser = id => {
    return users.find(user => user.id === id);
  };

  const author = users.find(user => user.id === userID);

  React.useEffect(() => {
    const { action, msg, code, data } = statusCode;

    switch (action) {
      case "create":
        setData([data, ...notes]);
        break;
      default:
        break;
    }

    setRowQty(1);

    if (code === 200) {
      if (showMessage200) {
        props.showMessage(msg);
      }
      setTextareaValue("");
    } else {
      props.showMessage(msg);
    }
  }, [statusCode]);

  React.useEffect(() => {
    const _handlerFocus = () => {
      setRowQty(size === "small" ? 3 : 4);
    };

    const _handlerBlur = ev => {
      setTimeout(() => {
        if (!ev.target.value.length) {
          setRowQty(1);
        }
      });
    };

    if (elementRef.current) {
      elementRef.current.addEventListener("focus", _handlerFocus);
      elementRef.current.addEventListener("blur", _handlerBlur);
    }
    return () => {
      if (elementRef.current) {
        elementRef.current.removeEventListener("focus", _handlerFocus);
        elementRef.current.removeEventListener("blur", _handlerBlur);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (notification) {
      const { module, action, data, ref } = notification;
      if (module === "note") {
        if (data.contact_id === contactID) {
          switch (action) {
            case "create":
              if (!loader) {
                index(page - 1, {}, false);
              }
              break;
            case "update":
              const findIndex = notes.findIndex(t => t.id === data.id);
              if (findIndex >= 0) {
                const arr = notes;
                arr.splice(findIndex, 1, {
                  ...notes[findIndex],
                  ...data
                });
                setData(arr);
              }
              break;
            case "delete":
              setData(notes.filter(t => t.id !== ref));
              break;
            default:
              break;
          }
        }
      }
    }
  }, [notification]);

  React.useEffect(() => {
    toFetch(0);
  }, [0]);
  React.useEffect(() => {
    if (reload) {
      toFetch(0);
      setReload(false);
    }
  }, [reload]);
  React.useEffect(() => {

  }, [onStoreComments]);
  return (
    <>
      {state !== "create"
        ? props.onCount(notes.length)
        : props.onCount(onStoreComments.length)}
      <div style={{ width: "95%" }}>
        <div
          style={{
            position: "sticky",
            top: -1,
            background: "#fff",
            zIndex: 999,
            marginTop: 5
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Avatar
              alt={currentUser && `${currentUser?.name}`}
              src="/static/images/avatar/1.jpg"
              style={{ width: "2rem", height: "2rem" }}
            />
            <textarea
              className="border-1 form-control chat-textarea bg-default-800"
              value={textareaValue}
              // error={errors?.name ? true : false}
              placeholder={placeholder[locale.locale]}
              onChange={ev => setTextareaValue(ev.target.value)}
              style={{
                transition: "height 0.1s ease-in",
                resize: "none",
                minHeight: 50,
                maxHeight: 50,
                margin: 5
              }}
            />
            <IconButton
              disabled={
                textareaValue && textareaValue.length > 0 ? false : true
              }
              onClick={() => {
                if (state === "create") {
                  setOnStoreComments(prevComments => {
                    const newComments = [
                      ...prevComments,
                      {
                        comment: textareaValue,
                        create_at: getDateTimeValue(new Date())
                      }
                    ];
                    props.onStoreComments(newComments);
                    return newComments;
                  });
                  setTextareaValue("");
                } else {
                  storeNote(
                    module === "contact"
                      ? {
                        description: textareaValue,
                        client_id: clientID,
                        user_id: userID,
                        contact_id: contactID,
                        module
                      }
                      : {
                        description: textareaValue,
                        client_id: clientID,
                        user_id: userID,
                        contact_id: contactID,
                        reference_id: modelID,
                        module
                      }
                  );
                }
              }}
            >
              <SendIcon />
            </IconButton>
          </div>
          <div
            style={{ display: "flex", justifyContent: "end", marginBottom: 20 }}
          >
            {/* <Button
              variant="contained"
              color="primary"
              //   disabled={!textareaValue.length}
            >
              <IntlMessages id="send" />
              <SendIcon />
            </Button> */}
          </div>
        </div>
        {state !== "create" && notes.length > 0 ? (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 9
          }}>
            {notes.map((note, i) => (
              <Comment
                size={size}
                key={i}
                data={note}
                author={getUser(note.user_id)}
                userAuthID={userID}
                module={module}
                auth={{
                  locale: locale.locale,
                  token: token,
                  clientID: clientID
                }}
              />
            ))}

            {/* <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page > 0 ? page - 1 : 0}
              onChangePage={(event, newValue) => toFetch(newValue)}
            /> */}
          </div>
        ) : state === "create" && onStoreComments.length > 0 ? (
          <>
            {onStoreComments.map(comment => (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <Avatar
                    alt={currentUser && `${currentUser?.name}`}
                    src="/static/images/avatar/1.jpg"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: 5,
                        gap: 5
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {currentUser && `${currentUser?.name}`}
                      </span>
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <DisplayDate date={comment.create_at} band={true} />
                      </span>
                    </div>
                    <p style={{ marginLeft: 5, whiteSpace: "pre-line" }}>
                      {comment?.comment}
                    </p>
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          <div className="text-center mt-3">
            {showNoData && (
              <>
                <img src={require("assets/images/empty.svg")} />
                <p>
                  <IntlMessages id="noData" />
                </p>
              </>
            )}
          </div>
        )}
      </div >
      {/* {size === "default" && (
        <div className="mt-3">
          <div className="model-item hover">
            <div className="col-auto px-1">
              <div>
                <Avatar className={classes.avatar}>
                  {authUser && `${authUser?.name[0]}`}
                </Avatar>
              </div>
            </div>
            <div className="col con-inf-mw-100">
              <TextField
                size="small"
                value={msg}
                placeholder="Puedes escribir aqui"
                label="Sobre qué se trata esta nota?"
                multiline
                fullWidth
                className="jr-wall-textarea"
                margin="none"
                variant="outlined"
                rows={rowQty}
                inputRef={elementRef}
                onChange={ev => setMsg(ev.target.value)}
                disabled={loaderStore}
              />
            </div>
            {rowQty === 4 && (
              <>
                <div className="col-auto px-1 actions">
                  <IconButton
                    aria-label="cancel"
                    className={classes.margin}
                    size="small"
                    disabled={loaderStore}
                    onClick={() => {
                      setMsg("");
                      setRowQty(1);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <IconButton
                    aria-label="check"
                    color="primary"
                    className={classes.margin}
                    size="small"
                    disabled={!msg.length || loaderStore}
                    onClick={() => {
                      storeNote(
                        module === "contact"
                          ? {
                              description: msg,
                              client_id: clientID,
                              user_id: userID,
                              contact_id: contactID,
                              module
                            }
                          : {
                              description: msg,
                              client_id: clientID,
                              user_id: userID,
                              contact_id: contactID,
                              reference_id: modelID,
                              module
                            }
                      );
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </div>
              </>
            )}
          </div>
          <div className="">
            <div className="w-100">
              {!loader && contact ? (
                <>
                  {notes.length > 0 ? (
                    <div className="">
                      {notes.map((note, i) => (
                        <Comment
                          size={size}
                          key={i}
                          data={note}
                          author={getUser(note.user_id)}
                          userAuthID={userID}
                          module={module}
                          auth={{
                            locale: locale.locale,
                            token: token,
                            clientID: clientID
                          }}
                        />
                      ))}

                      <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page > 0 ? page - 1 : 0}
                        onChangePage={(event, newValue) => toFetch(newValue)}
                      />
                    </div>
                  ) : (
                    <div className="text-center mt-3">
                      <img src={require("assets/images/empty.svg")} />
                      <p>
                        <IntlMessages id="noData" />
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center mt-3">
                  <IntlMessages id="loading" /> <CircularProgress size={15} />
                </div>
              )}
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

const mapStateToProps = ({ settings, auth, user, notification }) => {
  const { width, locale } = settings;
  const { users } = user;
  const { authUser, token, userID, clientID } = auth;
  return {
    width,
    locale,
    authUser,
    userID,
    clientID,
    token,
    users,
    currentUser: users.find(user => user.id === userID),
    notification: notification.notification
  };
};

export default connect(mapStateToProps, {
  showMessage
})(Comments);
