import {
  FETCH_ALL_LISTDYNAMIC_FIELDS,
  FETCH_ALL_LISTDYNAMIC_ITEMS,
  ADD_ITEM_LISTDYNAMIC_ITEMS,
  UPDATE_ITEM_LISTDYNAMIC_ITEMS,
  DELETE_ITEM_LISTDYNAMIC_ITEMS
} from "../constants/ActionTypes";

export const fetchListDynamicFields = (data) => {
  return {
    type: FETCH_ALL_LISTDYNAMIC_FIELDS,
    payload: data
  };
};

export const fetchListDynamicItems = (data) => {
  return {
    type: FETCH_ALL_LISTDYNAMIC_ITEMS,
    payload: data
  };
};

export const addItemListDynamic = (data) => {
  return {
    type: ADD_ITEM_LISTDYNAMIC_ITEMS,
    payload: data
  };
};


export const updateItemListDynamic = (id, data) => {
  return {
    type: UPDATE_ITEM_LISTDYNAMIC_ITEMS,
    payload: {id: id, data: data}
  };
};

export const deleteItemListDynamic = (id) => {
  return {
    type: DELETE_ITEM_LISTDYNAMIC_ITEMS,
    payload: id
  };
}
