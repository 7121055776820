import React from "react";
import IntlMessages from "util/IntlMessages";
import {
  Button,
  CircularProgress,
  TextField,
  Collapse
} from "@material-ui/core";

// Components
import PhoneInput from "react-phone-number-input";

// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// Service
import { useContact } from "services/hook/contact.service";

function PhoneMask(props) {
  const { inputRef, ...other } = props;
  return (
    <PhoneInput
      {...other}
      className={"MuiInputBase-input MuiInput-input border-0"}
    />
  );
}

const CreateContact = props => {
  const { isCreateContact, auth, device } = props;
  const { clientID, token, locale, userID } = auth;
  const [phoneValue, setPhoneValue] = React.useState("");
  const { storeContact, loader, statusCode } = useContact({
    clientID: clientID,
    token: token,
    locale: locale.locale
  });

  const schema = yup.object().shape({
    email: yup.string().email(),
    document: yup.string(),
    name: yup
      .string()
      .min(3)
      .required(),
    country: yup.string()
  });

  const { register, handleSubmit, formState, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: { country: "CL" }
  });

  const onSubmit = value => {
    let phone = phoneValue.replace(/\+/g, "");
    let person = value.name.split(" ");

    storeContact({
      info: {
        country: "",
        phone: phone,
        first_name: person[0],
        last_name: person.length > 0 ? person[1] : "",
        email: value.email,
        document: value.document
      },
      device_id: device
    });
  };

  React.useEffect(() => {
    if (statusCode) {
      const { code, data, info } = statusCode;
      if (code) {
        if (code === 200) {
          props.onStored(data);
          reset({
            phone: null,
            country: "CL",
            name: ""
          });
        } else {
          alert(code);
        }
      }
    }
  }, [statusCode]);

  return (
    <Collapse in={isCreateContact} collapsedHeight={0}>

      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
          <div className="col-12">
         
        <TextField
          style={{ margin: '16px 0' }}
          name="name"
          inputRef={register}
          disabled={loader}
          className="mb-2"
          InputLabelProps={{ shrink: true }}
          label={<IntlMessages id="name" />}
          required
          fullWidth
          autoFocus
        ></TextField>
        </div>
        <div className="col-12">
        <TextField
          value={phoneValue}
          name="phone"
          country="CL"
          type="text"
          label={<IntlMessages id="phone" />}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputComponent: PhoneMask,
            onChange: ev => {
              setPhoneValue(ev);
            }
          }}
          helperText={"Ingrese número de teléfono incluyendo el código de país"}
          required
        />
        </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <TextField
              type="email"
              name="email"
              inputRef={register}
              disabled={loader}
              InputLabelProps={{ shrink: true }}
              label={<IntlMessages id="email" />}
              fullWidth
            ></TextField>
          </div>
          <div className="col-12">
            <TextField
              name="document"
              inputRef={register}
              type="text"
              label={<IntlMessages id="entryDoc" />}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="normal"
            />
          </div>
        </div>
        <div className="modal-box-footer pt-3">
          <div className="content">
            <div className="row">
              <div className={"col-12 text-right"}>
                <div>
                  <Button
                    type="button"
                    variant="contained"
                    className="mr-2"
                    disabled={loader}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <IntlMessages id="cancel" />
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={(!formState.isValid && !device) || loader}
                  >
                    <IntlMessages id="register" />
                  </Button>
                  <span className="ml-2">
                    {loader && <CircularProgress size={20} />}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Collapse>
  );
};

export default CreateContact;
