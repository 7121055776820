import {
  FETCH_ALL_VIEWS,
  UPDATE_VIEW,
  ADD_VIEW,
  DELETE_VIEW
} from "../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  allViews: [],
  popover_newsletter: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_VIEWS: {
      return { ...state, allViews: action.payload };
    }

    case 'FETCH_POPOVER_NEWSLETTER': {
      return { ...state, popover_newsletter: action.payload };
    }

    case ADD_VIEW:
      const find = state.allViews.find(v => v.id === action.payload.id);
      if (find === undefined) {
        return { ...state, allViews: [...state.allViews, action.payload] };
      } else {
        return state;
      }

    case UPDATE_VIEW: {
      const arr = state.allViews;
      const { id, data } = action.payload;
      const findIndex = arr.findIndex(el => el.id === id);
      if (findIndex >= 0) {
        const view = JSON.parse(JSON.stringify(data));
        arr.splice(findIndex, 1, view);
        return { ...state, allViews: [...arr] };
      }
      return state;
    }

    case DELETE_VIEW: {
      const { id } = action.payload;
      const arr = state.allViews.filter(el => el.id !== id);
      return { ...state, allViews: [...arr] };
    }

    default:
      return state;
  }
};
