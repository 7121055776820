import React, { useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Avatar, Box, Badge } from '@material-ui/core';
import { canales } from "util/Helper";


export default function ExtraAvatar({ contact }) {

  const { users } = useSelector(({ user }) => user)

  const owner = useMemo(() => {
    return users.find(user => user.id === contact.derivedto);
  }, [contact])

  const integration = useMemo(() => {
    const integration = canales[contact.device.setting.conect.type];
    return integration;
  }, [contact]);

  const avatarSrc = useMemo(() => {
    return contact?.answerbot
      ? require("assets/images/avatar.png")
      : contact?.derivedto.length > 0
        ? "none"
        : require("assets/images/placeholder.jpg")
  }, [contact]);

  const avatarContent = useCallback(() => {
    return (
      <>
        {
          contact?.answerbot
            ? require("assets/images/avatar.png")
            : contact?.derivedto.length > 0
              ? owner.name[0]
              : require("assets/images/placeholder.jpg")
        }
      </>
    )
  }, [contact])

  return (
    <Box sx={{ position: 'relative' }} title={contact?.device?.name}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        badgeContent={
          <Box>
            {contact?.device?.setting?.conect?.type.length > 0 ? (
              <Avatar
                style={{
                  width: 20,
                  height: 20
                }}
                src={require(`assets/images/${integration.icon}.svg`)}
              />
            ) : null}
          </Box>
        }
      >
        <Avatar
          className="rounded-circle text-uppercase"
          style={{
            width: 35,
            height: 35,
            backgroundColor: !contact?.answerbot ? contact?.device.color : ''
          }}
          src={avatarSrc}
        >
          {avatarContent()}
        </Avatar>
      </Badge>
    </Box>
  )
}