import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  MINI_DRAWER,
  SHOW_FILTERS,
} from 'constants/ActionTypes';

const rltLocale = ['ar'];
const initialSettings = {
  navCollapsed: false,
  drawerType: MINI_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: 'spanish',
    locale: 'es',
    name: 'Español',
    icon: 'cl'
  },
  showFilters: true,
  setting_views: [],
  setting_graphics: []
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)

      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL

      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };


    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    case SHOW_FILTERS:
      return {
        ...state,
        showFilters: action.payload
      };

    case "SET_SETTING_VIEWS": {
      return {
        ...state,
        setting_views: action.payload
      };
    }

    case "SET_SETTING_GRAPHICS": {
      return {
        ...state,
        setting_graphics: action.payload
      };
    }



    default:
      return state;
  }
};

export default settings;
