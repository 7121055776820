import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import {
  InputBase,
  IconButton,
  Checkbox,
  Typography,
  Button,
  Snackbar,
  Drawer,
  CircularProgress,
  TextField,
  Switch,
  Select,
  MenuItem,
  InputAdornment,
  LinearProgress
} from "@material-ui/core";

import { typesMsg, getMaskCellphone } from "util/Helper";

// Helpers
import { makeStyles, withStyles } from "@material-ui/core/styles";

// Components
import MaskedInput from "react-text-mask";
import SearchModel from "components/sofia/search/searchModel";
import EmojiButton from "components/sofia/elements/EmojiButton";

// Form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

// Services

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

function ModalAttach(props) {
  const { visible } = props;
  const formElement = React.useRef();

  const schema = yup.object().shape({
    url: yup.string().url(),
    address: yup.string().required(),
    lat: yup.number().required(),
    lng: yup.number().required()
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    errors,
    formState,
    watch,
    reset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: { address: "" }
  });

  watch(["url", "address", "lat", "lng"]);

  const onSubmit = value => {
    let msg = {};
    msg = {
      type: "location",
      body: `${value.lat};${value.lng}`,
      attachment: {
        lat: `${value.lat}`,
        lng: `${value.lng}`,
        address: value.address
      }
    };
    props.onSend(msg);
    props.onClose();
  };

  const updateCoord = url => {

    if (!errors.url) {
      const split1 = url.split("/");
      if (split1.length >= 5) {
        const cad = split1[4];
        const split2 = cad.split(",");
        if (split2.length >= 2) {
          const lat = split2[0].replace(/\@/g, "");
          const long = split2[1].replace(/\@/g, "");
          if (+lat && +long) {
            setValue("lat", lat);
            setValue("lng", long);
          }
        }
      }
    }
  };
  return (
    <>
      <Modal
        onClose={() => props.onClose()}
        isOpen={visible}
        style={{ zIndex: 2600, maxWidth: "600px" }}
      >
        <ModalHeader>
          <IntlMessages id="location" />
          <div>
            <IconButton onClick={() => props.onClose()} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHeader>
        <form onSubmit={handleSubmit(onSubmit)} ref={formElement}>
          <ModalBody>
            <fieldset>
              {/* <Controller
                control={control}
                name="type"
                disabled
                defaultValue={"location"}
                error={errors?.type ? true : false}
                required
                as={
                  <TextField
                    name="type"
                    inputRef={register}
                    type="text"
                    select
                    label={<IntlMessages id="type" />}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={errors?.type ? true : false}
                  >
                    {typesMsg
                      .filter(t => t !== "chat")
                      .map((m, index) => (
                        <MenuItem key={index} value={m}>
                          <IntlMessages id={m} />
                        </MenuItem>
                      ))}
                  </TextField>
                }
              /> */}
              <TextField
                name="url"
                inputRef={register}
                type="text"
                label={"URL"}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={ev => updateCoord(ev.target.value)}
                error={errors?.url ? true : false}
                helperText={
                  getValues().type !== "location"
                    ? "Ingrese la URL del documento"
                    : ""
                }
              />

              <p className="small text-small">
                Puede ingresar una URL utilizando{" "}
                <a href={`https://www.google.com/maps`} target="_blank">
                  Google Maps
                </a>{" "}
                Ejm. https://www.google.com/maps/@30.9934968,-102.2223722,10.58
              </p>
              <div className="row">
                <div className="col-12 col-md-6">
                  <TextField
                    name="lat"
                    inputRef={register}
                    type="text"
                    label={<IntlMessages id="lat" />}
                    fullWidth
                    margin="normal"
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={errors?.lat ? true : false}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <TextField
                    name="lng"
                    inputRef={register}
                    type="text"
                    label={<IntlMessages id="lng" />}
                    fullWidth
                    required
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={errors?.lng ? true : false}
                  />
                </div>
                <div className="col-12 col-md-6"></div>
              </div>
              <TextField
                name="address"
                required
                inputRef={register}
                type="text"
                label={<IntlMessages id="address" />}
                fullWidth
                multiline
                rows={3}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                helperText={`Puede ingresar una dirección de referencia`}
              />

              <div className="pt-3 text-right">
                <Button
                  className="mr-2"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formState.isValid}
                  disableElevation
                >
                  <IntlMessages id="send" />
                </Button>
                <Button
                  type="button"
                  color="error"
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  <IntlMessages id="cancel" />
                </Button>
              </div>
            </fieldset>
          </ModalBody>
        </form>
      </Modal>
    </>
  );
}

export default ModalAttach;
