import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Typography,
  Table,
} from "@material-ui/core";
import { userSignIn } from "actions/Auth";

// TODO: Recuerda habilitar la validacion en password
// import { matches } from 'util/Helper';

// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// Services
import UserService from "services/ajax/user.service";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// Actions
import { showMessage } from "actions/Common";
import NewsLetterBox from "../components/sofia/NewsLetterBox";

// components

const browsersVersion = { Chrome: 49, Edge: 14, Firefox: 52, Safari: 10 };

const SignIn = ({ authUser, history, userSignIn, showMessage }) => {


  const [modal, setModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    showModal: false,
    majorVersion: 0,
    fullVersion: "0.0.0",
    browserName: "Chrome"
  });

  const schemaValue = {
    email: yup
      .string()
      .email()
      .required(),
    password: yup.string().required()
  };

  const schema = yup.object().shape(schemaValue);
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    userSignIn(data);
  };

  const getInfoBrowser = () => {
    let nVer = navigator.appVersion;
    let nAgt = navigator.userAgent;
    let browserName = navigator.appName;
    let fullVersion = "" + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf("Opera")) != -1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
      (nameOffset = nAgt.lastIndexOf(" ") + 1) <
      (verOffset = nAgt.lastIndexOf("/"))
    ) {
      browserName = nAgt.substring(nameOffset, verOffset);
      fullVersion = nAgt.substring(verOffset + 1);
      if (browserName.toLowerCase() == browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(";")) != -1)
      fullVersion = fullVersion.substring(0, ix);
    if ((ix = fullVersion.indexOf(" ")) != -1)
      fullVersion = fullVersion.substring(0, ix);

    majorVersion = parseInt("" + fullVersion, 10);
    if (isNaN(majorVersion)) {
      fullVersion = "" + parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion, 10);
    }

    if (browsersVersion[browserName] !== undefined) {
      const version = browsersVersion[browserName];
      if (majorVersion < version) {
        setModalInfo({
          showModal: true,
          majorVersion: majorVersion,
          fullVersion: fullVersion,
          browserName: browserName
        });
      }
    } else {
      setModalInfo({
        showModal: true,
        majorVersion: majorVersion,
        fullVersion: fullVersion,
        browserName: browserName
      });
    }
  };

  React.useEffect(() => {
    getInfoBrowser();
  }, [0]);

  return (
    <div className="app-login-container position-relative d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <NewsLetterBox />

      <div className="app-login-main-content">
        <div className="app-logo-content bg-white d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="logo">
            <img
              src={require("assets/images/logo.png")}
              className="img-fluid"
              alt="logo"
              title="Sofia bot"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="signIn" />
            </h1>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <TextField
                  name="email"
                  inputRef={register}
                  type="email"
                  className="mt-1 my-sm-3"
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  margin="normal"
                  autoFocus
                  error={errors?.email ? true : false}
                  required
                  autoComplete="username-email"
                  inputProps={{
                    autoComplete: 'username-email'
                  }}
                />
                <TextField
                  name="password"
                  inputRef={register}
                  type="password"
                  className="mt-1 my-sm-3"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  margin="normal"
                  error={errors?.password ? true : false}
                  required
                />
                {typeof errors.password !== "undefined" && (
                  <span className="text-secondary">
                    <IntlMessages id="validation.password" />
                  </span>
                )}
                <div className="d-flex">
                  <Link className="" onClick={() => setModal(true)}>
                    <IntlMessages id="forgotPassword" />
                  </Link>
                </div>
                <div className="my-3 mt-4 d-flex align-items-center justify-content-between">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isValid}
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>

                  <Link to="/signup">
                    <IntlMessages id="signIn.signUp" />
                  </Link>


                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <Dialog maxWidth="sm" fullWidth open={modalInfo.showModal} scroll="body">
        <DialogContent>
          <div className="d-flex justify-content-center my-2">
            <Typography variant="h5" id="modal-title">
              Plataforma incompatible
            </Typography>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 text-center my-4">
                <img
                  src={require("assets/images/browsers.svg")}
                  className="img-fluid"
                  alt=""
                  title=""
                />
              </div>
              <div className="col-12 col-md-10 text-center">
                <p className="mt-2">
                  Sofia bot es compatible con las versiones más recientes y
                  estables de todos los principales navegadores y plataformas.
                  También es compatible con Internet Explorer 11.
                </p>
                <p className="mt-2">
                  <strong>
                    {" "}
                    ✅ Por favor, debe actualizar su navegador. Actualmente se
                    encuentra utilizando {modalInfo?.browserName}, V.{" "}
                    {modalInfo?.fullVersion}{" "}
                  </strong>
                </p>
              </div>
              <div className="col-12 col-md-10 text-center py-4">
                <Table className="default-table table table-sm table-hover">
                  <thead>
                    <tr>
                      <th align="center">IE</th>
                      <th align="center">Edge</th>
                      <th align="center">Firefox</th>
                      <th align="center">Chrome</th>
                      <th align="center">Safari</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">11</td>
                      <td align="center">&gt;= 14</td>
                      <td align="center">&gt;= 52</td>
                      <td align="center">&gt;= 49</td>
                      <td align="center">&gt;= 10</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ModelForgotPassword
        visible={modal}
        onClose={() => {
          setModal(false);
        }}
        onAlert={ev => {
          showMessage(ev);
        }}
      />
      <InfoView />
    </div>
  );
};

function ModelForgotPassword(props) {
  const { visible, propsApp } = props;
  const [loader, setLoader] = React.useState(false);

  const schemaValue = {
    email: yup
      .string()
      .email()
      .required()
  };

  const schema = yup.object().shape(schemaValue);
  const { register, handleSubmit, errors, watch, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  watch(["email"]);

  const onSubmit = data => {
    setLoader(true);
    new UserService()
      .restore(data.email)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          props.onAlert(data.info);
          props.onClose();
        } else {
          props.onAlert(data.info);
        }
        setLoader(false);
      })
      .catch(function (error) {
        props.onAlert(error.message);

        setLoader(false);
      });
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={visible} scroll="body">
      <DialogTitle>
        <div className="d-flex justify-content-between">
          <div>
            <IntlMessages id="forgotPassword" />
          </div>
          <div>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                props.onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            name="email"
            inputRef={register}
            type="email"
            label={<IntlMessages id="email" />}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            autoFocus
            margin="normal"
            error={errors?.email ? true : false}
            required
          />
          <div className="pt-3 text-right">
            <Button
              className="mr-2"
              type="submit"
              variant="contained"
              color="primary"
              disabled={!formState.isValid || loader}
              disableElevation
            >
              <IntlMessages id="confirm" />
            </Button>
            <Button
              type="button"
              color="error"
              disabled={loader}
              onClick={() => {
                props.onClose();
              }}
            >
              <IntlMessages id="cancel" />
            </Button>
            <span className="ml-2">
              {loader && <CircularProgress size={20} />}
            </span>
          </div>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userSignIn, showMessage })(SignIn);
