import {
  FETCH_ALL_DEVICES,
  ADD_DEVICE,
  UPDATE_DEVICE,
  DELETE_DEVICE
} from "../constants/ActionTypes";
import { colors } from "util/Helper";

const INIT_STATE = {
  allDevice: [],
  setting: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_DEVICES: {
      action.payload.forEach((device, index) => {
        device.color = device.background_color;
      });
      return { ...state, allDevice: [...action.payload] };
    }
    case "FETCH_SETTING": {
      return { ...state, setting: [...action.payload] };
    }
    case ADD_DEVICE: {
      return { ...state, allDevice: [action.payload, ...state.items] };
    }
    case UPDATE_DEVICE: {
      const arr = state.allDevice;
      const { id, data } = action.payload;
      const findIndex = arr.findIndex(el => el.id === id);
      if (findIndex >= 0) {
        const device = JSON.parse(JSON.stringify(data));
        if (
          typeof arr[findIndex].color !== "undefined" &&
          arr[findIndex].selected !== "undefined"
        ) {
          device.color = arr[findIndex].color;
          device.selected = arr[findIndex].selected;
        }
        arr.splice(findIndex, 1, device);
        return { ...state, allDevice: [...arr] };
      }
      return state;
    }
    case DELETE_DEVICE: {
      const id = action.payload;
      const arr = state.allDevice.filter(el => el.id !== id);
      return { ...state, allDevice: [...arr] };
    }
    default:
      return state;
  }
};
