import { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";

// Services
import ChatService from "services/ajax/chat.service.js";
import MessageService from "./message.ajax.service";
import { onNotification } from "actions/Notification";
import { getDateTimeValue } from "util/Helper";
import { loadFromSessionStorage, toFormatFilters, validateChatMessage } from "util/Helper";

import { onSetQuery, onChatContactSelected, deleteAllChatMsg } from "actions/Chat";

export const useConversationsService = () => {

    const dispatch = useDispatch()
    const isInit = useRef(true);
    const { allMsg: messages, chatContactSelected, searchInput } = useSelector(({ chat }) => chat)
    const { token, authUser } = useSelector(({ auth }) => auth)
    const { locale, setting_views } = useSelector(({ settings }) => settings);
    const { allDevice: devices } = useSelector(({ devices }) => devices);
    const { notification } = useSelector(({ notification }) => notification)

    const [conversations, setConversations] = useState([]);
    const [conversationsMarked, setConversationsMarked] = useState(authUser.fixed);
    const [loaderConversations, setLoaderConversations] = useState(false);
    const [paginationConversations, setPaginationConversation] = useState({ total: 0, page: 1, limit: 20 });

    const conversationsFiltered = useMemo(() => {
        return conversations.sort((x, y) => {
            const band1 = !!conversationsMarked.find(id => id === x.contact.id);
            const band2 = !!conversationsMarked.find(id => id === y.contact.id);
            return band1 === band2 ? 0 : band1 ? -1 : 1;
        });
    }, [conversations, conversationsMarked])


    const onfetchConversations = (currentPage) => {

        setLoaderConversations(true)

        const dynamic_filters = filters()

        let query = {
            page: currentPage,
            offset: paginationConversations.limit,
            fixed: chatContactSelected ? [...conversationsMarked, chatContactSelected.id] : conversationsMarked,
            ...dynamic_filters
        }

        const devicesIDs = devices.map((device) => device.id)

        new ChatService()
            .messageContacts(devicesIDs, query, locale.locale, token)
            .then(({ data }) => {

                const new_conversations = data.data;
                const { pagination, offset } = data

                // assign device value to gent
                new_conversations.forEach(({ contact }, i) => {
                    const deviceSelected = devices.find(device => device.id === contact.device_id);
                    if (deviceSelected) {
                        new_conversations[i].contact.device = deviceSelected
                    }
                });

                setPaginationConversation({ total: pagination, page: currentPage, limit: query.offset })
                setConversations(currentPage === 1 ? new_conversations : [...conversations, ...new_conversations])


            }).finally(() => {
                setLoaderConversations(false)
            })

        isInit.current = false

    }

    const onMessage = () => {
        const model = { ...messages[0] };
        const contact = model.contact;

        const device_id = contact.device_id;
        const device = devices.find(d => d.id === device_id);

        if (device !== undefined) {
            model.contact.device = device;
            model.contact.deviceColor = device.background_color;

            if (validate(contact)) {
                if (chatContactSelected) {
                    if (chatContactSelected.id === contact.id) {
                        model.contact.message.numpending = 0;
                        // updateContact(chatContactSelected.id, model.contact);
                        const filterArr = conversations.filter(m => m.contact.id !== contact.id);
                        setConversations([model, ...filterArr]);
                    } else {
                        const filterArr = conversations.filter(m => m.contact.id !== contact.id);
                        setConversations([model, ...filterArr]);
                    }
                } else {
                    const filterArr = conversations.filter(m => m.contact.id !== contact.id);
                    setConversations([model, ...filterArr]);
                }
            }

        }
    };

    const validate = (model) => {

        const columns = setting_views.find(s => s.module === "chat").columns.filter(col => col.filter)
        const dynamic_filters = filters()
        return validateChatMessage(model, columns, dynamic_filters)
    }


    const filters = () => {

        const custom_views = authUser.custom_views.find(el => el.module === "chat");
        const custom_filters = custom_views.filters;
        const local_filters = loadFromSessionStorage("filter.chat");
        const extra_filters = loadFromSessionStorage("filter.chat.query") || {};

        let filter_selected = null;
        let dynamic_filters = initDynamicFilters();
        let query = {}

        try {
            if (Boolean(local_filters)) {
                dynamic_filters = dynamic_filters.map(el => {
                    const findI = local_filters.findIndex(f => f.key === el.key);
                    if (findI <= -1) {
                        return el;
                    } else {
                        return {
                            ...el,
                            value: local_filters[findI].value,
                            checked: true
                        };
                    }
                });
            }
        } catch (error) {

        }

        if (custom_filters.length > 0) {
            filter_selected = custom_filters[0];
            query = filter_selected?.search || {}
        }

        let qSearch = {}
        let query_dynamicFilters = toFormatFilters(dynamic_filters);
        query_dynamicFilters = { ...query_dynamicFilters, ...extra_filters };

        if (searchInput.trim().length > 0) {
            qSearch = { "info.full_name": { 'contains': searchInput } }
        }

        query = { ...query, ...query_dynamicFilters, ...qSearch }

        dispatch(onSetQuery(query))

        return query
    };

    const initDynamicFilters = () => {
        const dynamic_filters = setting_views
            .find(s => s.module === "chat")
            .columns.map(col => {

                let v = "";
                let condition = col.condition.length > 0 ? col.condition[0] : '';

                if (
                    condition === "contains" ||
                    condition === "equalto" ||
                    condition === "gte" ||
                    condition === "lte" ||
                    condition === "lt" ||
                    condition === "gt") {
                    v = col.type === 'number' ? null : ""
                }

                if (condition === "between") {

                    let gte = null;
                    let lte = null;

                    v = { gte: null, lte: null }
                }

                if (condition === "in") {
                    v = []

                }

                return {
                    ...col,
                    value: v,
                    condiction: condition,
                    checked: false
                };
            });

        return dynamic_filters;
    };

    useEffect(() => {
        if (messages.length > 0) {
            onMessage()
        }
    }, [messages])

    useEffect(() => {
        if (notification) {
            const { module, action, data: contact } = notification;

            if (module === "contact" && contact) {
                if (action === "update") {
                    if (!loaderConversations) {
                        const conversationIdx = conversations.findIndex(
                            m => m.contact.id === contact.id
                        );

                        if (conversationIdx > -1) {
                            const _conversation = conversations[conversationIdx];
                            const arr = conversations;
                            arr.splice(conversationIdx, 1, {
                                contact: {
                                    ..._conversation.contact,
                                    ...contact
                                }
                            });
                            setConversations([...arr]);
                        }
                    }
                }
            }

            if (module === "chat") {
                if (action === "updateMany") {
                    onfetchConversations(1);
                }
            }
        }
    }, [notification])


    useEffect(() => {
        if (!isInit.current) {
            onfetchConversations(1)
        }
    }, [searchInput])

    useEffect(() => {
        return () => {
            dispatch(deleteAllChatMsg())
            setConversations([])
        }
    }, [])


    return {
        conversations: conversationsFiltered,
        paginationConversations,
        conversationsMarked,
        setConversationsMarked,
        loaderConversations,
        onfetchConversations
    }

}


export const useConversationService = () => {

    const [messages, setMessages] = useState([])
    const [loaderStore, setLoaderStore] = useState(false);
    const [loaderConversation, setLoaderConversation] = useState(false);
    const [paginationConversation, setPaginationConversation] = useState({ total: 0, page: 1, limit: 25 });

    const dispatch = useDispatch()

    const { allMsg, chatContactSelected } = useSelector(({ chat }) => chat)
    const { notification } = useSelector(({ notification }) => notification)

    const { token, authUser } = useSelector(({ auth }) => auth)
    const { locale } = useSelector(({ settings }) => settings);

    const onCreateMessage = async (message) => {


        // local render message
        dispatch(onNotification({
            action: 'create',
            module: 'chat_message',
            data: message,
            msg: ''
        }));


        const res = await new MessageService()
            .store(
                message,
                locale.locale,
                token
            )

        const { data, info } = res.data;

        // local update render message
        dispatch(onNotification({
            action: 'update',
            module: 'chat_message',
            data: data[0],
            msg: info
        }));


        return res.data
    }

    const onCreateAttachMessage = async (message) => {

        const newMessage = {...message}
        delete newMessage.filename;

        const formData = new FormData();

        Object.getOwnPropertyNames(newMessage).forEach(name => {
          if (name === "attachment") {
            formData.append("attachment", newMessage[name], newMessage[name].name);
          } else {
            formData.append(name, newMessage[name]);
          }
        });

        // local render message
        dispatch(onNotification({
            action: 'create',
            module: 'chat_message',
            data: message,
            msg: ''
        }));


        const res = await new MessageService()
            .storeAttach(
                formData,
                locale.locale,
                token
            )

        const { data, info } = res.data;

        // local update render message
        dispatch(onNotification({
            action: 'update',
            module: 'chat_message',
            data: data[0],
            msg: info
        }));


        return res.data
    }


    const onfetchMessages = (contact_id, currentPage) => {

        setLoaderConversation(true)

        let query = {
            page: currentPage,
            offset: paginationConversation.limit
        }

        new MessageService()
            .index(
                contact_id,
                query,
                locale.locale,
                token
            )
            .then(({ data }) => {

                const new_messages = data.data.reverse();
                const { pagination, offset } = data

                setPaginationConversation({ total: pagination, page: currentPage, limit: query.offset })
                setMessages(currentPage === 1 ? new_messages : [...new_messages, ...messages])


            }).finally(() => {
                setLoaderConversation(false)
            })

    }

    const onMessage = () => {

        const agent = allMsg[0].contact;
        const { message } = agent;

        const msg = {
            body: message.info.body,
            client_id: agent.client_id,
            contact_id: agent.id,
            create_date: agent.create_date,
            device_id: agent.device_id,
            fromMe: message.info.fromMe,
            message_id: message.info.message_id,
            status: message.info.status,
            timestamp: message.info.timestamp,
            type: message.info.type_msg,
            owner: typeof message.info.owner === "undefined" ? "" : message.info.owner
        };


        if (message.info.body) {
            if (chatContactSelected) {
                if (agent.id === chatContactSelected.id) {
                    const findIndexMsg = messages.findIndex(
                        m => m.message_id === msg.message_id
                    );
                    if (findIndexMsg > -1) {
                        const arr = messages;
                        arr[findIndexMsg] = msg;
                        setMessages([...arr]);
                    } else {
                        setMessages([...messages, msg]);
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (allMsg.length > 0) {
            onMessage()
        }
    }, [allMsg])

    useEffect(() => {
        if (notification) {

            const { module, action, data } = notification;

            if (module === "contact" && chatContactSelected) {
                if (action === 'update' && (chatContactSelected.id === data.id)) {
                    dispatch(onChatContactSelected({
                        ...chatContactSelected,
                        ...data
                    }))
                }
            }

            if (module === "chat_message" && chatContactSelected) {
                const message = data;
                switch (action) {
                    case "create":
                        if (chatContactSelected.id === message.contact_id) {
                            setMessages([...messages, {
                                ...message,
                                fromMe: true,
                                timestamp: getDateTimeValue(),
                                owner: authUser.id,
                                status: 'queue'
                            }]);
                        }
                        break;
                    case "update":
                        if (chatContactSelected.id === message.contact_id) {
                            const mesaageIdx = messages.findIndex(
                                m => m?.temp_id === message.temp_id
                            );
                            if (mesaageIdx > -1) {
                                const arr = messages;
                                arr[mesaageIdx] = message;
                                setMessages([...arr]);
                            }
                        }
                        break;
                    default:
                        break;
                }
            }

        }
    }, [notification])

    return {
        onCreateMessage,
        onfetchMessages,
        onCreateAttachMessage,
        messages,
        setMessages,
        paginationConversation,
        loaderStore,
        loaderConversation
    }

}