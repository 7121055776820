import {FETCH_CHAT_DEVICES, UPDATE_CHAT_OPTION, ADD_CHAT_MSG, ON_SELECT_CONTACT_CHAT, DELETE_ALL_CHAT_MSG, ON_CONTACT_NOTIFY_CHAT, ON_MSG_NOTIFY_CHAT} from "../constants/ActionTypes";

export const fetchDevices = (devices) => {
  return {
    type: FETCH_CHAT_DEVICES,
    payload: devices
  }
};

export const updateOption = (n) => {
  return {
    type: UPDATE_CHAT_OPTION,
    payload: n
  }
};

export const addMsg = (value) => {
  return {
    type: ADD_CHAT_MSG,
    payload: value
  };
};

export const onContactSelected = (value) => {
  return {
    type: ON_SELECT_CONTACT_CHAT,
    payload: value
  }
};

export const deleteAllChatMsg = () => {
  return {
    type: DELETE_ALL_CHAT_MSG
  }
};

export const onContactNofify = (value) => {
  return {
    type: ON_CONTACT_NOTIFY_CHAT,
    payload: value
  }
};

export const onMsgNotify = (value) => {
  return {
    type: ON_MSG_NOTIFY_CHAT,
    payload: value
  }
};

export const onShowSideWidgets = (value) => {
  return {
    type: 'SHOW_SIDEBAR_W',
    payload: value
  }
};

export const onShowBtnAgendar = (value) => {
  return {
    type: 'SHOW_BTN_AGENDAR',
    payload: value
  }
};


export const onSetQuery = (value) => {
  return {
    type: 'SET_QUERY',
    payload: value
  }
};

export const onSetSearchInput = (value) => {
  return {
    type: 'SET_SEARCH_INPUT',
    payload: value
  }
};

export const onChatContactSelected = (value) => {
  return {
    type: 'CHAT_CONTACT_SELECTED',
    payload: value
  }
};


export const onChatToggle = (value) => {
  return {
    type: 'onChatToggle',
    payload: value
  }
};