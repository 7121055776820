import {
  ADD_FAVOURITE,
  FETCH_ALL_MSG,
  FETCH_ALL_MSG_SUCCESS,
  CREATE_MSG,
  CREATE_MSG_SUCCESS,
  UPDATE_MSG,
  UPDATE_MSG_SUCCESS,
  DELETE_MSG,
  DELETE_MSG_SUCCESS,
  FILTER_MSG,
  GET_ALL_MSG,
  GET_UNSELECTED_ALL_MSG,
  HANDLE_REQUEST_CLOSE,
  HIDE_MSG_LOADER,
  ON_ADD_MSG,
  ON_ALL_MSG_SELECT,
  ON_MSG_CLOSE,
  ON_MSG_SELECT,
  ON_DELETE_MSG,
  ON_DELETE_SELECTED_MSG,
  ON_MSG_FILTER_OPTION_SELECT,
  ON_SAVE_MSG,
  ON_TOGGLE_DRAWER,
  SHOW_MESSAGE,
  UPDATE_SEARCH_USER
} from 'constants/ActionTypes';

import {socket} from 'util/Socket';

const INIT_STATE = {
  alertMessage: '',
  showMessage: false,
  loader: true,
  selectedSectionId: 1,
  drawerState: false,
  user: {
    name: 'Robert Johnson',
    email: 'robert.johnson@example.com',
    avatar: 'https://via.placeholder.com/150x150'
  },
  searchUser: '',
  filterOption: 'All',
  allMsg: [],
  msgList: [],
  selectedMsg: null,
  selectedMsgs: 0,
  addMsgState: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_FAVOURITE: {
      socket.emit('Apiv1', localStorage.getItem('token'), "msgUpdate" , "es" , "msg", "updateone", { starred: !action.payload.starred} , action.payload.id);
      return {
        ...state,
        alertMessage: action.payload.starred ? 'removedStart' : 'markedStart',
        showMessage: true,
        msgList: state.msgList.map((msg) => msg.id === action.payload.id ? {
          ...msg,
          starred: !action.payload.starred
        } : msg),
        allMsg: state.allMsg.map((msg) => msg.id === action.payload.id ? {
          ...msg,
          starred: !action.payload.starred
        } : msg)
      };
    }
    case FETCH_ALL_MSG: {

      socket.emit('Apiv1', localStorage.getItem('token'), `msgList-${localStorage.getItem('clientid')}` , "es" , "msg", "list", {
        client_id: localStorage.getItem('clientid'),
        ...action.payload,
        deleted: false
      });
      return {
        ...state,
        loader: true,
      };
    }
    case FETCH_ALL_MSG_SUCCESS: {
      return {
        ...state,
        loader: false,
        allMsg: action.payload,
        msgList: action.payload
      }
    }
    case CREATE_MSG: {

      return {
        ...state,
        loader: true,
        allMsg: [action.payload, ...state.allMsg ]
      };
    }
    case CREATE_MSG_SUCCESS: {
      socket.emit('Apiv1', localStorage.getItem('token'), `msgList-${localStorage.getItem('clientid')}` , "es" , "msg", "list", {
        client_id: localStorage.getItem('clientid'),
        deleted: false
      });
      return {
        ...state,
        loader: false,
        alertMessage: 'alert.msg.create.success',
        showMessage: true
      }
    }
    case UPDATE_MSG: {
      socket.emit('Apiv1', localStorage.getItem('token'), `msgUpdate-${localStorage.getItem('clientid')}` , "es" , "msg", "updateone", action.payload.msg , action.payload.id);
      return {
        ...state,
        loader: true,
      };
    }
    case UPDATE_MSG_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: 'alert.msg.update.success',
        showMessage: true
      }
    }
    case DELETE_MSG: {
      socket.emit('Apiv1', localStorage.getItem('token'), "msgDelete" , "es" , "msg", "updateone", { deleted: true} , action.payload);
      return {
        ...state,
        loader: true,
      };
    }
    case DELETE_MSG_SUCCESS: {
      socket.emit('Apiv1', localStorage.getItem('token'), "msgList" , "es" , "msg", "list",{ deleted: false }, null);
      return {
        ...state,
        loader: false,
        alertMessage: 'alert.msg.delete.success',
        showMessage: true
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case ON_MSG_SELECT: {
      action.payload.selected = !action.payload.selected;
      let selectedMsgs = 0;
      const msgList = state.msgList.map(msg => {
          if (msg.selected) {
            selectedMsgs++;
          }
          if (msg.id  === action.payload.id ) {
            if (msg.selected) {
              selectedMsgs++;
            }
            return action.payload;
          } else {
            return msg;
          }
        }
      );
      return {
        ...state, INIT_STATE,
        selectedMsgs: selectedMsgs,
        msgList: msgList
      };
    }
    case ON_ADD_MSG: {
      return {...state, addMsgState: true};
    }
    case ON_MSG_CLOSE: {
      return {...state, addMsgState: false};
    }
    case ON_MSG_FILTER_OPTION_SELECT: {
      let selectedSectionId = 0;
      let filterOption = '';
      let msgList = [];

      switch (action.payload.name) {
        case 'All': {
          selectedSectionId = action.payload.id;
          filterOption = action.payload.name;
          msgList = state.allMsg.filter((msg) => msg.status);
          break;
        }
        case 'AllInactive': {
          selectedSectionId = action.payload.id;
          filterOption = action.payload.name;
          msgList = state.allMsg.filter((msg) => !msg.status);
          break;
        }

        default: {
          selectedSectionId = action.payload.id;
          filterOption = action.payload.name;
          msgList = state.allMsg.filter((msg) => msg.status);
          break;
        }
      }
      return {
        ...state, selectedSectionId, filterOption, msgList, drawerState: false
      };
    }
    case ON_SAVE_MSG: {
      let isNew = true;
      const msgList = state.allMsg.map((msg) => {
        if (msg.id === action.payload.id) {
          isNew = false;
          return action.payload
        } else {
          return msg
        }
      });
      if (isNew) {
        msgList.push(action.payload);
      }
      return {
        ...state,
        alertMessage: isNew ? 'Msg Added Successfully' : 'Msg Updated Successfully',
        showMessage: true,
        msgList: msgList,
        allMsg: msgList,
      }
    }
    case ON_DELETE_MSG: {
      return {
        ...state,
        alertMessage: 'Msg Deleted Successfully',
        showMessage: true,
        allMsg: state.allMsg.filter((msg) => msg.id !== action.payload.id),
        msgList: state.allMsg.filter((msg) => msg.id !== action.payload.id),
      }
    }
    case ON_DELETE_SELECTED_MSG: {
      const msgs = state.allMsg.filter((msg) => !msg.selected);
      return {
        ...state,
        alertMessage: 'Msg Deleted Successfully',
        showMessage: true,
        allMsg: msgs,
        msgList: msgs,
        selectedMsgs: 0
      }
    }
    case FILTER_MSG: {
      const {filterOption, allMsg} = state;
      let msgList = [];
        if (filterOption === 'All') {
          msgList = allMsg.filter((msg) => msg.status);
        } else {
          msgList = allMsg.filter((msg) => !msg.status);
        }
      return {...state, msgList: msgList}
    }
    case GET_ALL_MSG: {
      let msgList = state.allMsg.map((msg) => msg ? {
        ...msg,
        selected: true
      } : msg);
      return {
        ...state,
        selectedMsgs: msgList.length,
        allMsg: msgList,
        msgList: msgList
      }
    }
    case GET_UNSELECTED_ALL_MSG: {
      let msgList = state.allMsg.map((msg) => msg ? {
        ...msg,
        selected: false
      } : msg);
      return {
        ...state,
        selectedMsgs: 0,
        allMsg: msgList,
        msgList: msgList
      }
    }
    case ON_ALL_MSG_SELECT: {
      const selectAll = state.selectedMsgs < state.msgList.length;
      let selectedMsgs = 0;
      let msgList = [];
      if (selectAll) {
        msgList = state.allMsg.map((msg) => msg ? {
          ...msg,
          selected: true
        } : msg);
        selectedMsgs = msgList.length;
      } else {
        msgList = state.allMsg.map((msg) => msg ? {
          ...msg,
          selected: true
        } : msg);
        selectedMsgs = 0;
      }
      return {
        ...state,
        selectedMsgs: selectedMsgs,
        allMsg: msgList,
        msgList: msgList
      }
    }
    case UPDATE_SEARCH_USER: {
      return {...state, searchUser: action.payload}
    }
    case HANDLE_REQUEST_CLOSE: {
      return {...state, showMessage: false}
    }
    case ON_TOGGLE_DRAWER: {
      return {...state, drawerState: !state.drawerState}
    }
    case HIDE_MSG_LOADER: {
      return {...state, loader: false}
    }

    default:
      return state;
  }
}
