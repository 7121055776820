import axios from "util/Api";
import { base, baseURLajaxPrefix } from "util/Api";
import { ajax } from "rxjs/ajax";

class LeadService {
  constructor() { }

  async index(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/search`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async export(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/export`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }


  
  async kanban(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/kanban`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async detail(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/lead/search/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }

  async store(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/create`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async convertContact(data, locale, token) {
    const lead_id = data.lead_id
    delete data.lead_id;
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/convert/${lead_id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async storeAll(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/imports/lead`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async answerbot(id, data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/bot/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async update(id, data, locale, token) {

    delete data.contact;
    
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/lead/updateone/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  async unlockchatmany(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/lead/unlockchatmany`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async unlockall(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/lead/unlockall`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async updateMany(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/lead/updatemany`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async delete(id, locale, token) {
    return axios.delete(
      `${baseURLajaxPrefix}/${locale}/lead/deleteone/${id}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }


  async transfer(id, data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/transfer/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async search(params, locale, token, query = {}) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/lead/search`,
      {
        ...params,
        ...query
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  search$(params, locale, token, query = {}) {
    return ajax({
      url: `${base}/${baseURLajaxPrefix}/${locale}/lead/search`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: {
        ...params,
        ...query
      }
    });
  }

  async fields(locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/listdinamic/fields`, {
      headers: {
        Authorization: token
      }
    });
  }

  async unread(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/lead/unread/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }
}

export default LeadService;
