import axios from "util/Api";
import { base, baseURL, baseURLajaxPrefix } from "util/Api";
import { ajax } from "rxjs/ajax";

class UserService {
  constructor() {}

  async init(clientID, locale, token) {
    return axios.get(
      `${baseURLajaxPrefix}/${locale}/account/search/${clientID}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async index(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/user/search`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async search_all(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/user/search_all`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  

  async detail(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/user/search/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }

  async update(id, data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/user/updateone/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async store(data, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/user/create`, data, {
      headers: {
        Authorization: token
      }
    });
  }

  async updatePassword(id, data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/user/updatepassword/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async search(params, locale, token, query = {}) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/user/list`,
      {
        ...params,
        ...query
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  search$(params, locale, token, query = {}) {
    return ajax({
      url: `${base}/${baseURLajaxPrefix}/${locale}/user/list`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: {
        ...params,
        ...query
      }
    });
  }

  async fields(locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/listdinamic/fields`, {
      headers: {
        Authorization: token
      }
    });
  }

  async restore(email) {
    return axios.post(`restore`, {
      email: email
    });
  }
}

export default UserService;
