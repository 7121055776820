import React from 'react';
import ExtraToolbar from "components/sofia/ExtraToolbar"
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'

import IntlMessages from "util/IntlMessages"
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

import Tooltip from '@material-ui/core/Tooltip';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { onSetSearchInput, onChatToggle } from "actions/Chat";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { userSignOut } from "actions/Auth";

export default function HeaderConversation({ oneConversation = false, isWidget = false }) {

    const [showSearchEl, setShowSearchEl] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const { searchInput } = useSelector(({ chat }) => chat)
    const dispatch = useDispatch()

    const handleKeyDown = (ev) => {
        const trim = search.trim()
        if (`${ev.key}`.toLocaleLowerCase() === 'enter') {
            ev.preventDefault()
            dispatch(onSetSearchInput(search))
        }
    }

    return (
        <Box>
            <ExtraToolbar
                withPaperBackground
                withBorderBottom
                primary={
                    <>
                        {showSearchEl ? (
                            <Fade in={true} mountOnEnter unmountOnExit>
                                <Box>
                                    <Tooltip title="Presione ENTER para confirmar la búsqueda" placement='top'>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            label=""
                                            size='small'
                                            variant="standard"
                                            value={search}
                                            onChange={ev => {
                                                setSearch(ev.target.value)
                                            }}
                                            onKeyDown={handleKeyDown}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment>
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Tooltip>
                                </Box>
                            </Fade>
                        ) : (
                            <Fade in={true} mountOnEnter unmountOnExit>
                                <Typography variant="body2" fontWeight="bold">
                                    <IntlMessages id="conversations" />
                                </Typography>
                            </Fade>
                        )}

                    </>
                }
                secondary={
                    <>

                        {showSearchEl ? (
                            <Fade in={true}>
                                <IconButton title="Cerrar" size="small" aria-label="search" onClick={() => setShowSearchEl(!showSearchEl)}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            </Fade>
                        ) : (
                            <Fade in={true}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 6
                                }}>

                                    {isWidget ? (

                                        <div>
                                            <IconButton
                                                title={'Cerrar sesión'}
                                                size="small"
                                                aria-label=""
                                                style={{
                                                    padding: 2,
                                                    borderRadius: '100%',
                                                    backgroundColor: (theme) => theme.palette.action.hover
                                                }}
                                                onClick={() => {
                                                    dispatch(userSignOut(localStorage.getItem('token')))
                                                }}>
                                                <ExitToAppIcon
                                                    color='primary'
                                                    fontSize="small"
                                                    style={{
                                                        fontSize: 16
                                                    }}
                                                />
                                            </IconButton>
                                        </div>
                                    ) : null}

                                    <div>
                                        <IconButton
                                            title={'Buscar conversación'}
                                            size="small"
                                            aria-label="search"
                                            style={{
                                                padding: 2,
                                                borderRadius: '100%'
                                            }}
                                            onClick={() => setShowSearchEl(!showSearchEl)}
                                        >
                                            <Badge color="primary" variant="dot" invisible={!searchInput.trim().length}>
                                                <SearchIcon
                                                    fontSize='small'
                                                    sx={{
                                                        fontSize: 16,
                                                        backgroundColor: (theme) => theme.palette.action.hover
                                                    }}

                                                />
                                            </Badge>

                                        </IconButton>
                                    </div>
                                    <div>
                                        <IconButton
                                            title={'Cerrar Chat'}
                                            size="small"
                                            aria-label=""
                                            style={{
                                                padding: 2,
                                                borderRadius: '100%',
                                                backgroundColor: (theme) => theme.palette.action.hover
                                            }}
                                            onClick={() => {
                                                if (!isWidget) {
                                                    dispatch(onChatToggle(false))
                                                } else {
                                                    if (window && window.parent) {
                                                        window.parent.postMessage(JSON.stringify({ action: 'close' }), "*")
                                                    }
                                                }
                                            }}>
                                            <CloseIcon
                                                fontSize="small"
                                                style={{
                                                    fontSize: 16
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                </div>
                            </Fade>
                        )}
                    </>
                }
            />
        </Box>
    )
}