import React from "react";
import { connect } from "react-redux";

// services
import { useUser } from "services/hook/user.service";

const LanguageItem = ({
  language,
  switchLanguage,
  handleRequestClose,
  authUser,
  clientID,
  token,
  locale
}) => {
  const { icon, name } = language;

  const { updateUser } = useUser({
    id: authUser.id,
    token: token,
    locale: locale.locale,
    clientID: clientID
  });

  return (
    <li
      className="pointer"
      onClick={() => {
        updateUser({
          id: authUser.id,
          language: language.locale
        });
        handleRequestClose();
        switchLanguage(language);
      }}
    >
      <div className="d-flex align-items-center">
        <i className={`flag flag-24 flag-${icon}`} />
        <h4 className="mb-0 ml-2">{name}</h4>
      </div>
    </li>
  );
};

const mapStateToProps = ({ auth, settings }) => {
  const { locale } = settings;
  const { authUser, token, clientID } = auth;
  return {
    authUser,
    clientID,
    token,
    locale
  };
};

export default connect(mapStateToProps, {})(LanguageItem);
