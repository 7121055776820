import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { TextField, Paper, IconButton, Tooltip, Box } from '@material-ui/core'
import { useConversationService } from "../../Conversations/use-conversation-service"

import SendIcon from '@material-ui/icons/Send';
import SvgIcon from "@material-ui/core/SvgIcon";
import AttachAction from "./AttachAction";
import EmojiButton from "components/sofia/elements/EmojiButton";

function SlashIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="none" d="M0,0h24v24H0V0z" />
            <path d="M19,5v14H5V5H19 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z" />
            <path d="M17.7,7.8l-9.9,9.9l-1.4-1.4l9.9-9.9L17.7,7.8z" />
        </SvgIcon>
    );
}

export default function MessageEditor({ contact }) {

    const { loaderStore, onCreateMessage, onCreateAttachMessage } = useConversationService()
    const { authUser, userID } = useSelector(({ auth }) => auth)
    const { allDevice: devices } = useSelector(({ devices }) => devices);

    const device = useMemo(() => devices.find(d => d.id === contact.device_id), [contact])

    const isUserDerived = useMemo(() => {
        return contact.derivedto.length > 0
            ? contact.derivedto === userID
                ? true
                : false
            : false;
    }, [contact])

    const isDisabled = useMemo(() => {
        return !device?.setting.conect.status ||
            !contact ||
            !authUser.answer ||
            !isUserDerived
    }, [contact, authUser])


    const hasToolTip = useMemo(() => {
        return !authUser.answer || contact.answerbot || !device?.setting.conect.status || !isUserDerived;
    }, [isUserDerived, device, authUser, contact])


    const [text, setTex] = useState('')
    const inputRef = useRef()

    const handleTextChange = (ev) => {
        setTex(ev.target.value)
    }

    const onCreate = async () => {

        let reference_id = uuidv4();
        reference_id = reference_id.split("-");
        reference_id = reference_id.join("");

        const newMessage = {
            type: "chat",
            body: text,
            attachment: "",
            device_id: contact.device.id,
            contact_id: contact.id,
            temp_id: reference_id
        };

        setTex('')

        if (inputRef.current) {
            inputRef.current.focus()
        }

        await onCreateMessage(newMessage)

    };

    const onCreateWithAttach = async (message) => {

        let reference_id = uuidv4();
        reference_id = reference_id.split("-");
        reference_id = reference_id.join("");

        const newMessage = {
            type: message.type,
            body: "",
            attachment: message.attachment,
            filename: message.attachment.name,
            device_id: contact.device.id,
            contact_id: contact.id,
            temp_id: reference_id,
        };

        setTex('')

        if (inputRef.current) {
            inputRef.current.focus()
        }

        if (newMessage.type === "location") {
            await onCreateMessage(newMessage)
        } else {
            await onCreateAttachMessage(newMessage)
        }

    }

    const handleKeyDown = async (ev) => {
        const trim = text.trim()
        if (`${ev.key}`.toLocaleLowerCase() === 'enter' && trim.length > 0) {
            ev.preventDefault()
            await onCreate()
        }
    }

    const getPlaceholder = React.useCallback(() => {
        if (authUser.answer) {
            if (device?.setting.conect.status) {
                if (contact.answerbot) {
                    if (!contact.derivedto.length) {
                        return `Para activar el chat debe tomar la conversación.`;
                    } else {
                        if (contact.derivedto === userID) {
                            return `Para activar el chat debe tomar la conversación.`;
                        } else {
                            return `La conversación ha sido tomada por otro usuario.`;
                        }
                    }
                } else {
                    if (!contact.derivedto.length) {
                        return `Para activar el chat debe tomar la conversación.`;
                    } else {
                        if (contact.derivedto === userID) {
                            return `Escriba y pulse Enter para enviar el mensaje.`;
                        } else {
                            return `La conversación ha sido tomada por otro usuario.`;
                        }
                    }
                }
            } else {
                return `El dispositivo '${device?.name}' no esta activo`;
            }
        } else {
            return `El usuario actual no tiene permiso`;
        }

        return ""

    }, [contact])

    useEffect(() => {
        setTimeout(() => {
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }, 100);
    }, [])

    return (
        <Paper className="chat-textarea" elevation={0} style={{ paddingTop: 5, paddingLeft: 5, paddingRight: 5 }}>
            {device ? (
                <>
                    <TextField
                        multiline
                        fullWidth
                        autoFocus
                        inputRef={inputRef}
                        value={text}
                        InputProps={{
                            inputProps: {
                                id: 'message-input',
                                minRows: 1,
                                maxRows: 1
                            },
                            sx: {
                                py: 0.5,
                                fontSize: 12,
                                background: isDisabled ? '#eee' : '',
                            },
                            endAdornment: (
                                <Box>
                                    <Tooltip title={hasToolTip ? getPlaceholder() : "Enviar"}>
                                        <IconButton
                                            size="small"
                                            disabled={!text.length || isDisabled}
                                            onClick={onCreate}
                                        >
                                            <SendIcon fontSize="small" color={text.length ? "primary" : "disabled"} />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )
                        }}
                        placeholder={getPlaceholder()}
                        disabled={isDisabled}
                        onChange={handleTextChange}
                        onKeyDown={handleKeyDown}

                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 1
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 5
                            }}
                        >
                            <Tooltip title={hasToolTip ? getPlaceholder() : "Adjuntar"}>
                                <Box>
                                    <AttachAction isDisable={isDisabled} onStore={onCreateWithAttach} />
                                </Box>
                            </Tooltip>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 5
                            }}>
                            {/* <IconButton
                        size="small"
                        sx={{
                            padding: 0.4,
                            borderRadius: '100%'
                        }}
                    >
                        <SlashIcon
                            fontSize='small'
                            sx={{
                                fontSize: 16,
                                backgroundColor: (theme) => theme.palette.action.hover
                            }}
                        />
                    </IconButton> */}
                            {/* <IconButton
                        size="small"
                        sx={{
                            padding: 0.4,
                            borderRadius: '100%'
                        }}
                    >
                        <InsertEmoticonIcon
                            fontSize='small'
                            sx={{
                                fontSize: 16,
                                backgroundColor: (theme) => theme.palette.action.hover
                            }}
                        />
                    </IconButton> */}

                            <EmojiButton
                                size={'small'}
                                isDisable={
                                    isDisabled
                                }
                                onChange={emoji => {
                                    setTex(`${text}${emoji}`);
                                }}
                            />
                        </div>
                    </div>

                </>
            ) : null}
        </Paper>
    )
}