import React, { useState } from 'react';
import { Avatar, Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/styles';
import IntlMessages from "util/IntlMessages";

const useStyles = makeStyles({
  dragButton: {
    minWidth: 'auto',
    '&:hover': {
      cursor: 'grab',
    },
    '&:focus, &:active, &:focus-within': {
      cursor: 'grabbing',
    },
  },
});

const DragHandle = SortableHandle(() => <DragIndicatorIcon color="primary" />);

const Cell = props => {
  const classes = useStyles();
  const { contact, onSetCell } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuState, setMenuState] = useState(false);
  const [starred, setStarred] = useState(contact.starred);

  const onContactOptionSelect = event => {
    setMenuState(true);
    setAnchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setMenuState(false);
  };

  const options = ['Edit', 'Delete'];

  return (
    <div className='d-flex py-1 mb-0 border-bottom bg-white' style={{ zIndex: 99 }}>
      <div className='d-flex justify-content-between w-100 px-3'>
        <div className='d-flex align-items-center'>
          <input
            type="checkbox"
            className="mr-1"
            checked={contact.checked}
            onChange={ev => {
              const v = ev.target.value;
              onSetCell(v)
            }}
          />
          <span className="mx-2">{typeof contact.i18n === "undefined" ? contact.name : <IntlMessages id={contact.i18n} />}</span>
        </div>
        <div>
          <span className={classes.dragButton}>
            <DragHandle />
          </span>
        </div>
      </div>
    </div>
  );
};

export default SortableElement(Cell);
