import React, { useEffect } from "react";

// utils
import { socket } from "util/Socket";

// services
import ContactService from "../ajax/contact.service";

export const useContacts = ({ token, locale, queryGlobal }) => {
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });
  const [contacts, setContacts] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isInit, setIsInit] = React.useState(true);



  const index = (page, filters, rPerPage = rowsPerPage) => {
    setLoader(true);
    new ContactService()
      .index(
        {
          offset: rPerPage,
          page: page + 1,
          ...filters,
          ...queryGlobal
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setContacts([...data.data]);
          setTotal(data.pagination);
          setPage(page + 1);
        }
        setLoader(false);
        setIsInit(false);
      })
      .catch(_ => {
        setLoader(false);
        setIsInit(false);
      });

    setRowsPerPage(rPerPage);

  };

  const kanban = (page, filters, rPerPage = rowsPerPage, isLoader = true) => {
    if (isLoader) {
      setLoader(true);
    }
    new ContactService()
      .kanban(
        {
          offset: rPerPage,
          page: page + 1,
          ...filters,
          ...queryGlobal
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setContacts([...data.data]);
          setTotal(data.pagination);
          setPage(page + 1);
          // socket.emit("notification", [
          //   `client${clientID}`,
          //   {
          //     module: "contact",
          //     action: "fetch",
          //     data: {},
          //     msg: ""
          //   }
          // ]);
        }
        setLoader(false);
        setIsInit(false);
      })
      .catch(_ => {
        setLoader(false);
        setIsInit(false);
      });

    setRowsPerPage(rPerPage);

  };

  const setData = newArray => {
    setContacts([...newArray]);
  };

  return {
    // const
    contacts,
    rowsPerPage,
    total,
    page,
    statusCode,
    loader,
    // methods
    index,
    kanban,
    setData
  };
};

export const useContact = ({ id, locale, token, clientID }) => {
  const [contact, setContact] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });

  const getContact = () => {
    setLoader(true);
    new ContactService()
      .detail(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setContact(data.data[0]);
          setStatusCode({
            code: 200,
            action: "detail",
            data: data.data[0],
            msg: data.info
          });
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const storeContact = data => {
    setLoader(true);
    new ContactService()
      .store(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "contact",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const storeContacts = data => {
    setLoader(true);
    new ContactService()
      .storeAll(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };


  const answerbot = (idContact = 0, data) => {
    const ref = idContact > 0 ? idContact : id;

    setLoader(true);
    new ContactService()
      .answerbot(ref, data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setContact(data.data[0]);
          setStatusCode({
            code: 200,
            action: "update",
            data: data.data[0],
            msg: data.info
          });

          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "contact",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const updateContact = (newData, path = null, oldValue = null, cols = []) => {
    setLoader(true);
    new ContactService()
      .update(id, newData, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setContact(data.data[0]);
          setStatusCode({
            code: 200,
            action: "update",
            data: data.data[0],
            msg: data.info
          });

          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "contact",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
          if (path) {
            if (cols.map(col => col.key).indexOf(path) !== -1) {
          
              socket.emit("notification", [
                `client${clientID}`,
                {
                  module: "contact",
                  action: "contactListStatus",
                  data: {
                    data: data.data[0],
                    oldData: oldValue,
                    path: path
                  },
                  msg: data.info
                }
              ]);
            }
          }
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const deleteContact = data => {
    setLoader(true);
    new ContactService()
      .delete(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "delete",
            data: null,
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "contact",
              action: "delete",
              data: data.data[0],
              msg: data.info,
              ref: id
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const setContactData = newContact => {
    setContact(newContact);
  };

  return {
    // const
    contact,
    loader,
    statusCode,
    // methods
    getContact,
    storeContact,
    storeContacts,
    updateContact,
    answerbot,
    deleteContact,
    setContactData
  };
};
