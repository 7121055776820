import React from "react";
import { connect } from "react-redux";

import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { userSignUp } from "../actions/Auth";

import PhoneInput from "react-phone-number-input";

// Icons
import { Height, Visibility, VisibilityOff } from "@material-ui/icons";

// Helper
import {
  matches,
  countries,
  industries,
  timeZones
} from "util/Helper";
import langs from "components/LanguageSwitcher/data";

// Form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// Components
// import MaskedInput from "react-text-mask";
import CustomScrollbars from "util/CustomScrollbars";

// Actions
import { fetchError, showMessage } from "actions/Common";

function PhoneMask(props) {
  const { inputRef, ...other } = props;
  return (
    <PhoneInput
      {...other}
      className={"MuiInputBase-input MuiInput-input border-0"}
    />
  );
}

const SignUp = props => {
  const [showPassword, setShowPassword] = React.useState(false);

  const [phoneValue, setPhoneValue] = React.useState("");

  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [countrySelected, setCountrySelected] = React.useState(
    countries.find(comp => comp.code === "CL")
  );
  const TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    register,
    handleSubmit,
    errors,
    formState,
    control,
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        nameuser: yup
          .string()
          .min(3)
          .required(),
        email: yup
          .string()
          .email()
          .required(),
        country: yup.string(),
        company: yup
          .string()
          .min(3)
          .required(),
        password: yup
          .string()
          .required()
          .matches(matches.password),
        language: yup.string().required(),
        passwordConfirm: yup
          .string()
          .oneOf([yup.ref("password"), null], "Passwords must match"),
        industry: yup.string().required(),
        quantityemple: yup
          .number()
          .positive()
          .min(1)
          .required(),
        timezone: yup.string().required()
      })
    ),
    defaultValues: {
      country: "CL",
      industry: "serviciosSalud",
      timezone: TZ,
      language: "es"
    }
  });

  // React.useEffect(() => {
  //   if (props.showMessage) {
  //     setTimeout(() => {
  //       props.hideMessage();
  //     }, 3000);
  //   }
  // }, [props]);

  const onSubmit = data => {
    data.subdomain = data.company.replace(/\s/g, "");
    data.phone = phoneValue.replace(/\+/g, "");

    delete data.passwordConfirm;
    props.userSignUp(data);
  };

  return (
    <div className="app-login-container mt-3 animated slideInUpTiny animation-duration-3 pt-3 mb-5">
      <CustomScrollbars autoHide={false}>
        <div className="app-login-main-content scroll">
          <div className="app-login-content w-100">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.createAccount" />
              </h1>
            </div>

            <div className="app-login-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <h4>
                  <IntlMessages id="info.client" />
                </h4>
                <fieldset>
                  <TextField
                    name="company"
                    inputRef={register}
                    type="text"
                    label={<IntlMessages id="company" />}
                    fullWidth
                    margin="normal"
                    error={errors?.company ? true : false}
                    autoFocus
                    required
                  />
                  <TextField
                    name="email"
                    inputRef={register}
                    type="email"
                    label={<IntlMessages id="email" />}
                    fullWidth
                    margin="normal"
                    error={errors?.email ? true : false}
                    required
                  />
                  <span className="small">
                    <IntlMessages id="client.info.create.email" />
                  </span>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <FormControl className="mt-3 mb-0" fullWidth>
                        <Controller
                          as={
                            <TextField
                              select
                              fullWidth
                              label={<IntlMessages id="country" />}
                              error={errors?.country ? true : false}
                              InputProps={{
                                onChange: event => {
                                  const code = event.target.value;
                                  const country = countries.find(
                                    comp => comp.code === code
                                  );
                                  setValue("country", code);
                                  setCountrySelected(country);
                                }
                              }}
                            >
                              {countries.map((entry, index) => (
                                <MenuItem key={index} value={entry.code}>
                                  <div className="d-flex align-items-center">
                                    <i
                                      className={`flag flag-24 flag-${entry.code.toLocaleLowerCase()} mx-2`}
                                    />
                                    {entry.name}
                                  </div>
                                </MenuItem>
                              ))}
                            </TextField>
                          }
                          control={control}
                          name="country"
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6">
                      <TextField
                        value={phoneValue}
                        name="phone"
                        country="CL"
                        type="text"
                        label={<IntlMessages id="phone" />}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputComponent: PhoneMask,
                          onChange: ev => {
                            setPhoneValue(ev);
                          }
                        }}
                        helperText={<IntlMessages id="phoneMask" />}
                        required
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <FormControl className="mt-3 mb-0" fullWidth>
                        <Controller
                          as={
                            <TextField
                              select
                              fullWidth
                              label={<IntlMessages id="timezone" />}
                              error={errors?.timezone ? true : false}
                            >
                              {timeZones.map((v, index) => (
                                <MenuItem key={index} value={v}>
                                  {v}
                                </MenuItem>
                              ))}
                            </TextField>
                          }
                          control={control}
                          name="timezone"
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6">
                      <FormControl className="mt-3 mb-0" fullWidth>
                        <Controller
                          as={
                            <TextField
                              select
                              fullWidth
                              label={<IntlMessages id="language" />}
                              error={errors?.language ? true : false}
                            >
                              {langs.map((v, index) => (
                                <MenuItem key={index} value={v.locale}>
                                  {v.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          }
                          defaultValue={"es"}
                          control={control}
                          name="language"
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6">
                      <FormControl className="mt-3 mb-0" fullWidth>
                        <Controller
                          as={
                            <TextField
                              required
                              select
                              fullWidth
                              label={<IntlMessages id="typeIndustry" />}
                              error={errors?.industry ? true : false}
                            >
                              {industries.map((el, index) => (
                                <MenuItem key={index} value={el.value}>
                                  {el.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          }
                          control={control}
                          name="industry"
                        />
                      </FormControl>
                    </div>
                    <div className="col-12 col-md-6">
                      <TextField
                        name="quantityemple"
                        inputRef={register}
                        type="text"
                        label={<IntlMessages id="qtyAgent" />}
                        fullWidth
                        margin="normal"
                        defaultValue={1}
                        error={errors?.quantityemple ? true : false}
                        required
                        inputProps={{
                          type: "number"
                        }}
                      />
                    </div>
                  </div>
                  <h4 className="mt-3">
                    <IntlMessages id="info.user" />
                  </h4>
                  <TextField
                    name="nameuser"
                    inputRef={register}
                    type="text"
                    label={<IntlMessages id="name" />}
                    fullWidth
                    margin="normal"
                    error={errors?.nameuser ? true : false}
                    required
                  />
                  <TextField
                    name="password"
                    inputRef={register}
                    type={showPassword ? "text" : "password"}
                    label={<IntlMessages id="password" />}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    required
                    error={errors?.password ? true : false}
                  />
                  {typeof errors.password !== "undefined" && (
                    <span className="text-secondary">
                      <IntlMessages id="validation.password" />
                    </span>
                  )}
                  <TextField
                    name="passwordConfirm"
                    inputRef={register}
                    type={showPasswordConfirm ? "text" : "password"}
                    label={<IntlMessages id="password.confirm" />}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPasswordConfirm(!showPasswordConfirm)
                            }
                          >
                            {showPasswordConfirm ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={errors?.passwordConfirm ? true : false}
                  />
                  <div className="my-3 d-flex align-items-center justify-content-between">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!formState.isValid}
                    >
                      <IntlMessages id="appModule.regsiter" />
                    </Button>
                    <Link to="/signin">
                      <IntlMessages id="signUp.alreadyMember" />
                    </Link>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </CustomScrollbars>
      <InfoView />
    </div>
  );
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userSignUp, showMessage })(SignUp);
