import moment from "moment";
import "moment-timezone";
import textMask, { conformToMask } from "react-text-mask";

moment.tz.setDefault("America/Santiago");

export const matches = {
  password: /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  number: /^\d+$/,
  email: /^\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b$/,
  url: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/
};

export const variables = [
  {
    value: '$firstname',
    i81n: 'fullname'
  },
  {
    value: '$lastname',
    i81n: 'lastname'
  },
  {
    value: '$username',
    i81n: 'username'
  }
]

export const daysValue = [
  { name: "-7", key: "-7" },
  { name: "-6", key: "-6" },
  { name: "-5", key: "-5" },
  { name: "-4", key: "-4" },
  { name: "-3", key: "-3" },
  { name: "-2", key: "-2" },
  { name: "yesterday", key: "-1" },
  { name: "Today", key: "0" },
  { name: "tomorrow", key: "+1" },
  { name: "+2", key: "+2" },
  { name: "+3", key: "+3" },
  { name: "+4", key: "+4" },
  { name: "+5", key: "+5" },
  { name: "+6", key: "+6" },
  { name: "+7", key: "+7" }
];



export const canales = {
  whatsapp: {
    label: "WhatsApp",
    icon: "ws",
    key: "whatsapp"
  },
  telegram: {
    label: "Telegram",
    icon: "tg",
    key: "telegram"
  },
  messenger: {
    label: "Messenger",
    icon: "fb",
    key: "messenger"
  },
  instagram: {
    label: "Instagram",
    icon: "ig",
    key: "instagram"
  }
};

export const typeModels = [
  { label: "user.qty", value: "user" },
  { label: "message.qty", value: "message" },
  { label: "device.qty", value: "device" },
  { label: "chatgpt.qty", value: "chatgpt" },
  { label: "dialogflow.qty", value: "dialogflow" },
  { label: "yeastar.qty", value: "yeastar" }
];

export const paymentPlatform = [
  { label: "PayPal", value: "paypal" },
  { label: "Flow", value: "flow" },
  { label: "Rebill", value: "rebill" }
];

export const visibilityValues = [
  { label: "public", value: 0 },
  { label: "group", value: 1 },
  { label: "private", value: 2 }
];

export const integrations = [
  { value: "crmx2", label: "CRM X2" },
  { value: "crmzoho", label: "CRM Zoho" },
  { value: "yeastar", label: "PBX Yeastar" }
];

export const operadores = ["and", "or", "contains"];
export const operadoresLog = [
  { value: "equalto", name: "Igual a" },
  { value: "contains", name: "Contiene" },
  { value: "in", name: "Está en la lista" },
  { value: "empty", name: "Está vacío" },
  { value: "notempty", name: "No está vacío" },
  { value: "gte", name: "Mayor igual" },
  { value: "lte", name: "Menor igual" },
  { value: "gt", name: "Mayor" },
  { value: "lt", name: "Menor" },
  { value: "between", name: "Entre" }
];

export const integrationsBot = [
  { value: "dialogflow", label: "DialogFlow" },
  { value: "chatgpt", label: "ChatGPT" }
];

export const serverList = [
  { value: "twilio", label: "Twilio" },
  { value: "chatapi", label: "Chat Api" },
  { value: "maytapi", label: "Maytapi" },
  { value: "business", label: "business" },

  
  { value: "dialog360", label: "Dialog360" },
  { value: "telegram", label: "Telegram" },
  { value: "facebook", label: "Facebook" }
];

export const typeconnectList = [
  { value: "official", label: "Oficial" },
  { value: "unofficial", label: "No oficial" }
];

export const connectList = [
  { value: "whatsapp", label: "Whatsapp" },
  { value: "telegram", label: "Telegram" },
  { value: "facebook", label: "Facebook" }
];

export const appList = [
  { value: "Dentalsoft", label: "Dentalsoft" },
  { value: "Medilink", label: "Medilink" },
  { value: "Dentalink", label: "Dentalink" },
  { value: "Calendly", label: "Calendly" },
  { value: "Standard", label: "Estandar" }
];

export const extractionList = [
  { value: "Dentalsoft", label: "Dentalsoft" },
  { value: "Medilink", label: "Medilink" },
  { value: "Calendly", label: "Calendly" },
  { value: "Dentalink", label: "Dentalink" }
];

export const connectTypeList = [
  { value: "curl", label: "URL" },
  { value: "api", label: "API" }
];

export const prioridadEnvio = ["default", "balanced"];

export const industries = [
  { value: "telecomunicaciones", label: "Telecomunicaciones" },
  { value: "financiera", label: "Financiera" },
  { value: "serviciosFinancieros", label: "Servicios Financieros" },
  { value: "retail", label: "Retail" },
  { value: "serviciosSalud", label: "Servicios de Salud" },
  { value: "serviciosBasicos", label: "Servicios básicos" },
  { value: "viajesTurismo", label: "Viajes y Turismo" }
];

export const CLP = {
  thousandseparator: ".",
  decimalseparator: ",",
  decimalscale: 0,
  prefix: "",
  suffix: ""
};

export const USD = {
  thousandseparator: ",",
  decimalseparator: ".",
  decimalscale: 2,
  prefix: "$",
  suffix: ""
};

export const typesMsg = [
  "chat",
  "image",
  "document",
  "audio",
  "location",
  "video"
];

export const listfield = [
  "firstname",
  "lastname",
  "meetdate",
  "fullname",
  "meethour",
  "meetaddress",
  "branchname",
  "branchcode",
  "branchphone",
  "clientname",
  "specialistcode",
  "specialistname",
  "specialistdoc",
  "specialtycode",
  "specialtyname",
  "source",
  "tags",
  "stage",
  "status",
  "campaign"
];

export const listtrigger = [
  "confyes",
  "confno",
  "notfyes",
  "notfno",
  //"msgsent",
  "msgrecived",
  "recurrent"
];

export const connectVersionList = ["v1", "v2", "v3", "v4", "v5"];

export const colors = [
  "#3f51b5",
  "#4caf50",
  "#f44336",
  "#9c27b0",
  "#2196f3",
  "#ff9800",
  "#e91e63",
  "#795548",
  "#03a9f4",
  "#673ab7",
  "#009688",
  "#8bc34a",
  "#ff5722",
  "#607d8b",
  "#969696",
  "#00bcd4",
  "#00D084",
  "#F78DA7"
];

export const encoding = ["utf-8", "utf-16"];

export const deviceConfig = [
  {
    server: "chatapi",
    service: "whatsapp",
    label: "Chat Api",
    account: [
      { type: "official", qr: false },
      { type: "unofficial", qr: true }
    ],
    file: ["token", "instance"]
    //file: ["token", "instance", "url"]
  },
  {
    server: "maytapi",
    service: "whatsapp",
    label: "MaytApi",
    account: [{ type: "unofficial", qr: true }],
    file: ["token", "instance", "accountID"]
  },
  {
    server: "business",
    service: "whatsapp",
    label: "business",
    account: [{ type: "unofficial", qr: true }],
    file: ["instance"]
  },
  
  {
    server: "telegram",
    service: "telegram",
    label: "Telegram",
    account: [{ type: "official", qr: false }],
    file: ["token"]
  },
  {
    server: "meta",
    service: "meta",
    label: "Messenger",
    account: [{ type: "official", qr: false }],
    file: ["token", "accountID"]
  },
  {
    server: "instagram",
    service: "instagram",
    label: "Instagram",
    account: [{ type: "official", qr: false }],
    file: ["token", "accountID"]
  }
];

export const getMaskCellphone = (country = "CL") => {
  let mask = [];
  country.mask.forEach(value => {
    if (value === "1-9") {
      mask.push(/[1-9]/);
    } else if (value === "d") {
      mask.push(/\d/);
    } else {
      mask.push(value);
    }
  });

  return mask;
};

export const saveToSessionStorage = (key, ev) => {
  const objectString = JSON.stringify(ev);
  sessionStorage.setItem(key, objectString);
};

export const removeFromSessionStorage = key => {
  sessionStorage.removeItem(key);
};

export const loadFromSessionStorage = key => {
  try {
    const objectString = sessionStorage.getItem(key);
    if (objectString) {
      const parsedObject = JSON.parse(objectString);
      return parsedObject;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const getMaskCellphoneAlt = (country = "CL") => {
  let mask = [];
  country.maskAlt.forEach(value => {
    if (value === "1-9") {
      mask.push(/[1-9]/);
    } else if (value === "d") {
      mask.push(/\d/);
    } else {
      mask.push(value);
    }
  });

  return mask;
};

export const phonePipe = (countryCode = "CL", phoneNumber) => {
  const code = countryCode.length > 0 ? countryCode : "CL";
  const number = phoneNumber.toString();
  const country = countries.find(comp => comp.code === code);
  const mask = getMaskCellphoneAlt(country);
  const conformedPhoneNumber = conformToMask(number, mask, {
    guide: false
  });

  return conformedPhoneNumber.conformedValue
    ? `${conformedPhoneNumber.conformedValue}`
    : number;
};

export const getNewDateValue = (format = "YYYY-MM-DD") => {
  return moment(new Date())
    .format(format)
    .toString();
};

export const getNewDateTimeValue = (format = "YYYY-MM-DDTHH:mm") => {
  return moment(new Date())
    .format(format)
    .toString();
};
export const getNewNextDate = (days, format = "YYYY-MM-DDTHH:mm") => {
  return moment(new Date())
    .add(days, "d")
    .format(format)
    .toString();
};
export const getDateTimeValue = (value, format = "YYYY-MM-DDTHH:mm") => {
  const r = moment(value)
    .format(format)
    .toString();
  return r === "Invalid Date" ? r : `${r}:00`;
};

export const getDateValue = (value, format = "YYYY-MM-DD") => {
  return moment(value)
    .format(format)
    .toString();
};

export const pipeDateValue = (value, format = "YYYY-MM-DD") => {
  return moment(value)
    .format(format)
    .toString();
};

export const setDateAdd = (value, qty, prop = "days") => {
  return moment()
    .add(qty, prop)
    .format("YYYY-MM-DD")
    .toString();
};

export const pipeDateMin = value => {
  return moment(value)
    .format("DD MMM, YYYY")
    .toString();
};

export const pipeDateTimeValue = (value, format = "DD MMM, YYYY h:mm a") => {
  return moment(value)
    .format(format)
    .toString();
};

export const initMonthDateTime = () => {
  const now = moment(); // obtener la fecha y hora actual
  const startOfMonth = now.startOf("month"); // obtener el primer día del mes actual
  return startOfMonth.format("YYYY-MM-DD"); // devolver la fecha en el formato solicitado
};

export const getInitDateTime = () => {
  return moment()
    .startOf("date")
    .format("YYYY-MM-DDTHH:mm")
    .toString();
};

export const getEndtDateTime = () => {
  return moment()
    .endOf("date")
    .format("YYYY-MM-DDTHH:mm")
    .toString();
};

export const timeView = (value, format = "LT") => {
  const hra = value.split(":");
  const d = new Date().setHours(+hra[0], +hra[1]);
  return moment(d).format(format);
};
export const compareDatesDiff = (
  dateTimeA,
  dateTimeB,
  format = "YYYY-MM-DDTHH:mm"
) => {
  if (dateTimeB) {
    const momentA = moment(dateTimeA, format);
    const momentB = moment(dateTimeB, format);
    const duration = moment.duration(momentB.diff(momentA));

    const dias = duration.days();
    const horas = duration.hours();
    const minutos = duration.minutes();

    return `${dias} dias, ${horas} hrs, ${minutos} min`;
  } else {
    return "-";
  }
};

export const getTimes = segundos => {
  const duration = moment.duration(segundos.toFixed(2), "second");
  const dias = duration.days();
  const horas = duration.hours();
  const minutos = duration.minutes();
  const seconds = duration.seconds();

  return `${dias} dias, ${horas} hrs, ${minutos} min,  ${seconds} seg`;
};

export const compareDates = (dateTimeA, dateTimeB, format = "DD-MM-YYYY") => {
  var momentA = moment(dateTimeA, format);
  var momentB = moment(dateTimeB, format);
  if (momentA > momentB) return 1;
  // mayor que
  else if (momentA < momentB) return -1;
  // menor que
  else return 0; // igual
};

export const compareDates2 = (dateTimeA, dateTimeB, format = "DD-MM-YYYY") => {
  var momentA = moment(dateTimeA, format);
  var momentB = moment(dateTimeB, format);
  if (momentA >= momentB) return 1;
  // mayor >=
  else return 0;
  // menor <=
};


export const diffDate = date => {
  const postDate = moment(date);
  const currentDate = moment();
  const duration = moment.duration(postDate.diff(currentDate));
  const days = duration.days();
  const horas = duration.hours();
  const minutos = duration.minutes();
  const seg = duration.seconds();

  return `${days === true ? days : 0} ${days >= 2 ? "días" : "día"}, ${!days ? (horas === true ? horas : 0) : days * 24
    } hrs, ${minutos === true ? minutos : 0} min, ${seg === true ? seg : 0} seg.`;
};

export const nowDate = () => {
  return moment()
    .format("YYYY-MM-DD")
    .toString();
};

export const initDate = () => {
  return `${moment()
    .format("YYYY")
    .toString()}-01-01`;
};

export const initDateTime = () => {
  return `${moment()
    .format("YYYY")
    .toString()}-01-01T00:00`;
};

export const endDate = () => {
  return `${moment()
    .format("YYYY")
    .toString()}-12-31`;
};

export const durationDate = d => {
  const postDate = moment(d).format("YYYY-MM-DDTHH:mm:ss");
  const currentDate = moment();
  const duration = moment.duration(currentDate.diff(postDate));
  const hours = duration.asHours() | 0;

  return hours;
};

// week, month, year
export const queryDate = (key = "week") => {
  switch (key) {
    case "today":
      return {
        from: `${moment()
          .format("YYYY-MM-DD")
          .toString()}`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}`
      };
    case "yesterday":
      return {
        from: `${moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
          .toString()}`,
        to: `${moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
          .toString()}`
      };
    case "month":
      return {
        from: `${moment()
          .startOf("month")
          .format("YYYY-MM-DD")
          .toString()}`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}`
      };
    case "year":
      return {
        from: `${initDate()}`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}`
      };
    default:
      return {
        from: `${moment()
          .startOf("week")
          .format("YYYY-MM-DD")
          .toString()}`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}`
      };
  }
};

export const queryDateTime = (key = "week") => {
  switch (key) {
    case "today":
      return {
        from: `${moment()
          .format("YYYY-MM-DD")
          .toString()}T00:00`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}T23:59`
      };
    case "yesterday":
      return {
        from: `${moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
          .toString()}T00:00`,
        to: `${moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
          .toString()}T23:59`
      };
    case "month":
      return {
        from: `${moment()
          .startOf("month")
          .format("YYYY-MM-DD")
          .toString()}T00:00`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}T23:59`
      };
    case "year":
      return {
        from: `${initDateTime()}`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}T23:59`
      };
    default:
      return {
        from: `${moment()
          .startOf("week")
          .format("YYYY-MM-DD")
          .toString()}T00:00`,
        to: `${moment()
          .format("YYYY-MM-DD")
          .toString()}T01:00`
      };
  }
};

export const convertToInternationalC = labelValue => {

  if (Number.isNaN(Number(labelValue))) {
    return '0'
  }
  
  const absValue = Math.abs(Number(labelValue));
  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (absValue >= 1.0e12) {
    return numberWithCommas(Math.floor(absValue / 1.0e12)) + "T";
  }
  if (absValue >= 1.0e9) {
    return numberWithCommas(Math.floor(absValue / 1.0e9)) + "B";
  }
  if (absValue >= 1.0e6) {
    return numberWithCommas(Math.floor(absValue / 1.0e6)) + "M";
  }
  if (absValue >= 1.0e3) {
    return numberWithCommas(Math.floor(absValue / 1.0e3)) + "K";
  }
  return numberWithCommas(absValue);
};

export const whatsappStyles = (format, wildcard, opTag, clTag) => {
  const indices = [];

  const is_aplhanumeric = c => {
    var x = c.charCodeAt();
    return (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || (x >= 48 && x <= 57)
      ? true
      : false;
  };

  for (var i = 0; i < format.length; i++) {
    if (format[i] === wildcard) {
      if (indices.length % 2) {
        if (format[i - 1] !== " ") {
          if (typeof format[i + 1] === "undefined") {
            indices.push(i);
          } else {
            if (!is_aplhanumeric(format[i + 1])) {
              indices.push(i);
            }
          }
        }
      } else {
        if (typeof format[i + 1] !== "undefined") {
          if (format[i + 1] !== " ") {
            if (typeof format[i - 1] === "undefined") {
              indices.push(i);
            } else {
              if (!is_aplhanumeric(format[i - 1])) {
                indices.push(i);
              }
            }
          }
        }
      }
    } else {
      if (format[i].charCodeAt() === 10 && indices.length % 2) {
        indices.pop();
      }
    }
  }

  if (indices.length % 2) {
    indices.pop();
  }

  let e = 0;
  indices.forEach(function (v, i) {
    let t = i % 2 ? clTag : opTag;
    v += e;
    format = format.substr(0, v) + t + format.substr(v + 1);
    e += t.length - 1;
  });

  return format;
};

export function toFormatFilters(filters = [], reset = false) {
  let obj = {};

  if (filters.length > 0) {
    filters
      .filter(el => el.checked)
      .forEach(item => {
        let v = {};
        switch (item.condiction) {
          case "contains":
          case "equalto":
          case "gte":
          case "lte":
          case "lt":
          case "gt":
            switch (item.type) {
              case "text":
              case "email":
              case "passwod":
                v = {};
                v[`${item.condiction}`] = item.value;
                obj[`${item.key}`] = { ...v };
                break;
              case "phone":
                v = {};
                v[`${item.condiction}`] = item.value.replace(/\+/g, "");
                obj[`${item.key}`] = { ...v };
                break;
              case "date":
                v = {};
                v[`${item.condiction}`] = getDateValue(item.value);
                obj[`${item.key}`] = { ...v };
                break;
              case "datetime":
                v = {};
                v[`${item.condiction}`] = getDateTimeValue(item.value);
                obj[`${item.key}`] = { ...v };
                break;
              default:
                v = {};
                v[`${item.condiction}`] = item.value;
                obj[`${item.key}`] = { ...v };
                break;
            }

            break;
          case "in":
            switch (item.type) {
              case "text":
              case "email":
                v = {};
                v[`${item.condiction}`] = item.value;
                obj[`${item.key}`] = { ...v };
                break;
              case "autocomplete":
              case "select":
              case "multiselect":
              case "link":
                v = {};
                if (item.value.length) {
                  if (
                    typeof item.value[0] === "string" ||
                    typeof item.value[0] === "number"
                  ) {
                    v[`${item.condiction}`] = item.value;
                  } else {
                    if (typeof item.value[0].id !== "undefined") {
                      v[`${item.condiction}`] = item.value.map(v => v.id);
                    } else {
                      v[`${item.condiction}`] = item.value.map(v => v.value);
                    }
                  }
                } else {
                  v[`${item.condiction}`] = [];
                }

                obj[`${item.key}`] = { ...v };
                break;
            }
            break;
          case "between":
            switch (item.type) {
              case "date":
                v = {};

                v[`${item.condiction}`] = {
                  gte: getDateValue(item.value.gte),
                  lte: getDateValue(item.value.lte)
                };
                obj[`${item.key}`] = { ...v };
                break;
              case "datetime":
                v = {};
                v[`${item.condiction}`] = {
                  gte: getDateTimeValue(item.value.gte),
                  lte: getDateTimeValue(item.value.lte)
                };
                obj[`${item.key}`] = { ...v };
                break;
              default:
                v = {};
                v[`${item.condiction}`] = {
                  gte: +item.value.gte,
                  lte: +item.value.lte
                };
                obj[`${item.key}`] = { ...v };
                break;
            }
            break;
          case "notempty":
          case "empty":
            v = {};
            v[`${item.condiction}`] = "";
            obj[`${item.key}`] = { ...v };
            break;
          default:
            v = {};
            v[`${item.condiction}`] = item.value;
            obj[`${item.key}`] = { ...v };
            break;
        }

        obj = { ...obj };
      });
  }

  return reset ? {} : obj;
}

export function toFormatFiltersToClear(filters = []) {
  let obj = {};

  if (filters.length > 0) {
    filters
      .filter(el => el.checked)
      .forEach(item => {
        let v = {};
        v[`${item.condiction}`] = item.value;
        obj[`${item.key}`] = { ...v };

        obj = { ...obj };
      });
  }

  return obj;
}

export function validateChatMessage(model, columns, dynamicFilters) {

  const getValue = (path, obj) => {
    return path.split(".").reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  };


  const filters = { ...dynamicFilters }
  delete filters.order
  delete filters.order_by

  let date = ''
  let filterDate = ''
  let gte = ''
  let lte = ''

  // console.log(`\ncolumns: ${JSON.stringify(columns.map((col) => col.key), null, 2)} \n`)
  // console.log(`\nproperties: ${JSON.stringify(Object.getOwnPropertyNames(filters), null, 2)} \n`)

  const properties = Object.getOwnPropertyNames(filters)
  let pass = true

  for (let i = 0; i < properties.length; i++) {

    const property = properties[i]
    const condition = Object.getOwnPropertyNames(filters[property])[0]
    const conditionValue = getValue(condition, filters[property])
    const value = getValue(property, model)
    const column = columns.find(col => col.key === property)

    // console.log(`\nitem ${i + 1}: ${JSON.stringify({
    //   property,
    //   condition,
    //   conditionValue,
    //   value,
    //   column_type: column.type
    // }, null, 2)}`)

    if (column) {
      const type = column.type;
      switch (condition) {
        case "contains":
          let cad = `${value}`.toLocaleLowerCase()
          let compare = `${conditionValue}`.toLocaleLowerCase()
          pass = cad.indexOf(compare) !== -1 ? true : false
          break
        case "equalto":
          pass = JSON.stringify(value) === JSON.stringify(conditionValue) ? true : false
          break;
        case "gte":
          switch (type) {
            case "date":
              date = getDateValue(value)
              filterDate = getDateValue(conditionValue)
              pass = compareDates2(date, filterDate) === 1 ? true : false;
              break;
            case "datetime":
              date = getDateTimeValue(value)
              filterDate = getDateTimeValue(conditionValue)
              console.log(value, conditionValue, compareDates2(date, filterDate, 'YYYY-MM-DDTHH:mm'))
              pass = compareDates2(date, filterDate, 'YYYY-MM-DDTHH:mm') === 1 ? true : false;
              break;
            case "number":
              pass = +value >= +conditionValue ? true : false
              break
            default:
              break;
          }
          break;
        case "lte":
          switch (type) {
            case "date":
              date = getDateValue(value)
              filterDate = getDateValue(conditionValue)
              pass = compareDates2(date, filterDate) === 0 ? true : false;
              break;
            case "datetime":
              date = getDateTimeValue(value)
              filterDate = getDateTimeValue(conditionValue)
              pass = compareDates2(date, filterDate, 'YYYY-MM-DDTHH:mm') === 0 ? true : false
              break;
            case "number":
              pass = +value <= +conditionValue ? true : false
              break
            default:
              break;
          }
          break;
        case "lt":
          switch (type) {
            case "date":
              date = getDateValue(value)
              filterDate = getDateValue(conditionValue)
              pass = compareDates(date, filterDate) === -1 ? true : false;
              break
            case "datetime":
              date = getDateTimeValue(value)
              filterDate = getDateTimeValue(conditionValue)
              pass = compareDates(date, filterDate, 'YYYY-MM-DDTHH:mm') === -1 ? true : false;
              break;
            case "number":
              pass = +value < +conditionValue ? true : false
              break;
            default:
              break;
          }
          break;
        case "gt":
          switch (type) {
            case "date":
              date = getDateValue(value)
              filterDate = getDateValue(conditionValue)
              pass = compareDates(date, filterDate) === 1 ? true : false;
              break;
            case "datetime":
              date = getDateTimeValue(value)
              filterDate = getDateTimeValue(conditionValue)
              pass = compareDates(date, filterDate, 'YYYY-MM-DDTHH:mm') === 1 ? true : false;
              break;
            case "number":
              pass = +value > +conditionValue ? true : false
              break;
            default:
              break;
          }
          break;
        case "in":
          if (Array.isArray(conditionValue)) {
            pass = conditionValue.includes(value)
          }
          break;
        case "notempty":
          pass = `${value}`.length > 0 ? true : false
          break;
        case "empty":
          pass = `${value}`.length <= 0 ? true : false
          break;
        case "between":
          switch (type) {
            case "date":
              date = getDateValue(value)
              gte = getDateValue(conditionValue.gte);
              lte = getDateValue(conditionValue.lte);

              pass = (compareDates2(date, gte) === 1 && compareDates2(date, gte) === 0) ? true : false;
              break;
            case "datetime":
              date = getDateTimeValue(value)
              gte = getDateTimeValue(conditionValue.gte);
              lte = getDateTimeValue(conditionValue.lte);
              pass = (compareDates2(date, gte, 'YYYY-MM-DDTHH:mm') === 1 && compareDates2(date, gte, 'YYYY-MM-DDTHH:mm') === 0) ? true : false
              break;
            case "number":
              const n = parseFloat(value)
              gte = parseFloat(conditionValue.gte);
              lte = parseFloat(conditionValue.lte);
              pass = (n >= gte && n <= lte) ? true : false
              break;
          }
          break;
      }
    }

    if (!pass) {
      break;
    }
  }

  return pass

}

export const countries = [
  {
    name: "Aruba",
    code: "AW"
  },
  {
    name: "Afghanistan",
    code: "AF"
  },
  {
    name: "Angola",
    code: "AO"
  },
  {
    name: "Anguilla",
    code: "AI"
  },
  {
    name: "Åland Islands",
    code: "AX"
  },
  {
    name: "Albania",
    code: "AL"
  },
  {
    name: "Andorra",
    code: "AD"
  },
  {
    name: "United Arab Emirates",
    code: "AE"
  },
  {
    name: "Argentina",
    code: "AR"
  },
  {
    name: "Armenia",
    code: "AM"
  },
  {
    name: "American Samoa",
    code: "AS"
  },
  {
    name: "Antarctica",
    code: "AQ"
  },
  {
    name: "French Southern and Antarctic Lands",
    code: "TF"
  },
  {
    name: "Antigua and Barbuda",
    code: "AG"
  },
  {
    name: "Australia",
    code: "AU"
  },
  {
    name: "Austria",
    code: "AT"
  },
  {
    name: "Azerbaijan",
    code: "AZ"
  },
  {
    name: "Burundi",
    code: "BI"
  },
  {
    name: "Belgium",
    code: "BE"
  },
  {
    name: "Benin",
    code: "BJ"
  },
  {
    name: "Burkina Faso",
    code: "BF"
  },
  {
    name: "Bangladesh",
    code: "BD"
  },
  {
    name: "Bulgaria",
    code: "BG"
  },
  {
    name: "Bahrain",
    code: "BH"
  },
  {
    name: "Bahamas",
    code: "BS"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA"
  },
  {
    name: "Saint Barthélemy",
    code: "BL"
  },
  {
    name: "Belarus",
    code: "BY"
  },
  {
    name: "Belize",
    code: "BZ"
  },
  {
    name: "Bermuda",
    code: "BM"
  },
  {
    name: "Bolivia",
    code: "BO"
  },
  {
    name: "Brazil",
    code: "BR"
  },
  {
    name: "Barbados",
    code: "BB"
  },
  {
    name: "Brunei",
    code: "BN"
  },
  {
    name: "Bhutan",
    code: "BT"
  },
  {
    name: "Bouvet Island",
    code: "BV"
  },
  {
    name: "Botswana",
    code: "BW"
  },
  {
    name: "Central African Republic",
    code: "CF"
  },
  {
    name: "Canada",
    code: "CA"
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC"
  },
  {
    name: "Switzerland",
    code: "CH"
  },
  {
    name: "Chile",
    code: "CL",
    regex: new RegExp(/^569\d{8}$/g)
  },
  {
    name: "China",
    code: "CN"
  },
  {
    name: "Ivory Coast",
    code: "CI"
  },
  {
    name: "Cameroon",
    code: "CM"
  },
  {
    name: "DR Congo",
    code: "CD"
  },
  {
    name: "Republic of the Congo",
    code: "CG"
  },
  {
    name: "Cook Islands",
    code: "CK"
  },
  {
    name: "Colombia",
    code: "CO"
  },
  {
    name: "Comoros",
    code: "KM"
  },
  {
    name: "Cape Verde",
    code: "CV"
  },
  {
    name: "Costa Rica",
    code: "CR"
  },
  {
    name: "Cuba",
    code: "CU"
  },
  {
    name: "Curaçao",
    code: "CW"
  },
  {
    name: "Christmas Island",
    code: "CX"
  },
  {
    name: "Cayman Islands",
    code: "KY"
  },
  {
    name: "Cyprus",
    code: "CY"
  },
  {
    name: "Czech Republic",
    code: "CZ"
  },
  {
    name: "Germany",
    code: "DE"
  },
  {
    name: "Djibouti",
    code: "DJ"
  },
  {
    name: "Dominica",
    code: "DM"
  },
  {
    name: "Denmark",
    code: "DK"
  },
  {
    name: "Dominican Republic",
    code: "DO"
  },
  {
    name: "Algeria",
    code: "DZ"
  },
  {
    name: "Ecuador",
    code: "EC"
  },
  {
    name: "Egypt",
    code: "EG"
  },
  {
    name: "Eritrea",
    code: "ER"
  },
  {
    name: "Western Sahara",
    code: "EH"
  },
  {
    name: "Spain",
    code: "ES"
  },
  {
    name: "Estonia",
    code: "EE"
  },
  {
    name: "Ethiopia",
    code: "ET"
  },
  {
    name: "Finland",
    code: "FI"
  },
  {
    name: "Fiji",
    code: "FJ"
  },
  {
    name: "Falkland Islands",
    code: "FK"
  },
  {
    name: "France",
    code: "FR"
  },
  {
    name: "Faroe Islands",
    code: "FO"
  },
  {
    name: "Micronesia",
    code: "FM"
  },
  {
    name: "Gabon",
    code: "GA"
  },
  {
    name: "United Kingdom",
    code: "GB"
  },
  {
    name: "Georgia",
    code: "GE"
  },
  {
    name: "Guernsey",
    code: "GG"
  },
  {
    name: "Ghana",
    code: "GH"
  },
  {
    name: "Gibraltar",
    code: "GI"
  },
  {
    name: "Guinea",
    code: "GN"
  },
  {
    name: "Guadeloupe",
    code: "GP"
  },
  {
    name: "Gambia",
    code: "GM"
  },
  {
    name: "Guinea-Bissau",
    code: "GW"
  },
  {
    name: "Equatorial Guinea",
    code: "GQ"
  },
  {
    name: "Greece",
    code: "GR"
  },
  {
    name: "Grenada",
    code: "GD"
  },
  {
    name: "Greenland",
    code: "GL"
  },
  {
    name: "Guatemala",
    code: "GT"
  },
  {
    name: "French Guiana",
    code: "GF"
  },
  {
    name: "Guam",
    code: "GU"
  },
  {
    name: "Guyana",
    code: "GY"
  },
  {
    name: "Hong Kong",
    code: "HK"
  },
  {
    name: "Honduras",
    code: "HN"
  },
  {
    name: "Croatia",
    code: "HR"
  },
  {
    name: "Haiti",
    code: "HT"
  },
  {
    name: "Hungary",
    code: "HU"
  },
  {
    name: "Indonesia",
    code: "ID"
  },
  {
    name: "Isle of Man",
    code: "IM"
  },
  {
    name: "India",
    code: "IN"
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO"
  },
  {
    name: "Ireland",
    code: "IE"
  },
  {
    name: "Iran",
    code: "IR"
  },
  {
    name: "Iraq",
    code: "IQ"
  },
  {
    name: "Iceland",
    code: "IS"
  },
  {
    name: "Israel",
    code: "IL"
  },
  {
    name: "Italy",
    code: "IT"
  },
  {
    name: "Jamaica",
    code: "JM"
  },
  {
    name: "Jersey",
    code: "JE"
  },
  {
    name: "Jordan",
    code: "JO"
  },
  {
    name: "Japan",
    code: "JP"
  },
  {
    name: "Kazakhstan",
    code: "KZ"
  },
  {
    name: "Kenya",
    code: "KE"
  },
  {
    name: "Kyrgyzstan",
    code: "KG"
  },
  {
    name: "Cambodia",
    code: "KH"
  },
  {
    name: "Kiribati",
    code: "KI"
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN"
  },
  {
    name: "South Korea",
    code: "KR"
  },
  {
    name: "Kosovo",
    code: "XK"
  },
  {
    name: "Kuwait",
    code: "KW"
  },
  {
    name: "Laos",
    code: "LA"
  },
  {
    name: "Lebanon",
    code: "LB"
  },
  {
    name: "Liberia",
    code: "LR"
  },
  {
    name: "Libya",
    code: "LY"
  },
  {
    name: "Saint Lucia",
    code: "LC"
  },
  {
    name: "Liechtenstein",
    code: "LI"
  },
  {
    name: "Sri Lanka",
    code: "LK"
  },
  {
    name: "Lesotho",
    code: "LS"
  },
  {
    name: "Lithuania",
    code: "LT"
  },
  {
    name: "Luxembourg",
    code: "LU"
  },
  {
    name: "Latvia",
    code: "LV"
  },
  {
    name: "Macau",
    code: "MO"
  },
  {
    name: "Saint Martin",
    code: "MF"
  },
  {
    name: "Morocco",
    code: "MA"
  },
  {
    name: "Monaco",
    code: "MC"
  },
  {
    name: "Moldova",
    code: "MD"
  },
  {
    name: "Madagascar",
    code: "MG"
  },
  {
    name: "Maldives",
    code: "MV"
  },
  {
    name: "Mexico",
    code: "MX"
  },
  {
    name: "Marshall Islands",
    code: "MH"
  },
  {
    name: "Macedonia",
    code: "MK"
  },
  {
    name: "Mali",
    code: "ML"
  },
  {
    name: "Malta",
    code: "MT"
  },
  {
    name: "Myanmar",
    code: "MM"
  },
  {
    name: "Montenegro",
    code: "ME"
  },
  {
    name: "Mongolia",
    code: "MN"
  },
  {
    name: "Northern Mariana Islands",
    code: "MP"
  },
  {
    name: "Mozambique",
    code: "MZ"
  },
  {
    name: "Mauritania",
    code: "MR"
  },
  {
    name: "Montserrat",
    code: "MS"
  },
  {
    name: "Martinique",
    code: "MQ"
  },
  {
    name: "Mauritius",
    code: "MU"
  },
  {
    name: "Malawi",
    code: "MW"
  },
  {
    name: "Malaysia",
    code: "MY"
  },
  {
    name: "Mayotte",
    code: "YT"
  },
  {
    name: "Namibia",
    code: "NA"
  },
  {
    name: "New Caledonia",
    code: "NC"
  },
  {
    name: "Niger",
    code: "NE"
  },
  {
    name: "Norfolk Island",
    code: "NF"
  },
  {
    name: "Nigeria",
    code: "NG"
  },
  {
    name: "Nicaragua",
    code: "NI"
  },
  {
    name: "Niue",
    code: "NU"
  },
  {
    name: "Netherlands",
    code: "NL"
  },
  {
    name: "Norway",
    code: "NO"
  },
  {
    name: "Nepal",
    code: "NP"
  },
  {
    name: "Nauru",
    code: "NR"
  },
  {
    name: "New Zealand",
    code: "NZ"
  },
  {
    name: "Oman",
    code: "OM"
  },
  {
    name: "Pakistan",
    code: "PK"
  },
  {
    name: "Panama",
    code: "PA"
  },
  {
    name: "Pitcairn Islands",
    code: "PN"
  },
  {
    name: "Peru",
    code: "PE"
  },
  {
    name: "Philippines",
    code: "PH"
  },
  {
    name: "Palau",
    code: "PW"
  },
  {
    name: "Papua New Guinea",
    code: "PG"
  },
  {
    name: "Poland",
    code: "PL"
  },
  {
    name: "Puerto Rico",
    code: "PR"
  },
  {
    name: "North Korea",
    code: "KP"
  },
  {
    name: "Portugal",
    code: "PT"
  },
  {
    name: "Paraguay",
    code: "PY"
  },
  {
    name: "Palestine",
    code: "PS"
  },
  {
    name: "French Polynesia",
    code: "PF"
  },
  {
    name: "Qatar",
    code: "QA"
  },
  {
    name: "Réunion",
    code: "RE"
  },
  {
    name: "Romania",
    code: "RO"
  },
  {
    name: "Russia",
    code: "RU"
  },
  {
    name: "Rwanda",
    code: "RW"
  },
  {
    name: "Saudi Arabia",
    code: "SA"
  },
  {
    name: "Sudan",
    code: "SD"
  },
  {
    name: "Senegal",
    code: "SN"
  },
  {
    name: "Singapore",
    code: "SG"
  },
  {
    name: "South Georgia",
    code: "GS"
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ"
  },
  {
    name: "Solomon Islands",
    code: "SB"
  },
  {
    name: "Sierra Leone",
    code: "SL"
  },
  {
    name: "El Salvador",
    code: "SV"
  },
  {
    name: "San Marino",
    code: "SM"
  },
  {
    name: "Somalia",
    code: "SO"
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM"
  },
  {
    name: "Serbia",
    code: "RS"
  },
  {
    name: "South Sudan",
    code: "SS"
  },
  {
    name: "São Tomé and Príncipe",
    code: "ST"
  },
  {
    name: "Suriname",
    code: "SR"
  },
  {
    name: "Slovakia",
    code: "SK"
  },
  {
    name: "Slovenia",
    code: "SI"
  },
  {
    name: "Sweden",
    code: "SE"
  },
  {
    name: "Swaziland",
    code: "SZ"
  },
  {
    name: "Sint Maarten",
    code: "SX"
  },
  {
    name: "Seychelles",
    code: "SC"
  },
  {
    name: "Syria",
    code: "SY"
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC"
  },
  {
    name: "Chad",
    code: "TD"
  },
  {
    name: "Togo",
    code: "TG"
  },
  {
    name: "Thailand",
    code: "TH"
  },
  {
    name: "Tajikistan",
    code: "TJ"
  },
  {
    name: "Tokelau",
    code: "TK"
  },
  {
    name: "Turkmenistan",
    code: "TM"
  },
  {
    name: "Timor-Leste",
    code: "TL"
  },
  {
    name: "Tonga",
    code: "TO"
  },
  {
    name: "Trinidad and Tobago",
    code: "TT"
  },
  {
    name: "Tunisia",
    code: "TN"
  },
  {
    name: "Turkey",
    code: "TR"
  },
  {
    name: "Tuvalu",
    code: "TV"
  },
  {
    name: "Taiwan",
    code: "TW"
  },
  {
    name: "Tanzania",
    code: "TZ"
  },
  {
    name: "Uganda",
    code: "UG"
  },
  {
    name: "Ukraine",
    code: "UA"
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM"
  },
  {
    name: "Uruguay",
    code: "UY"
  },
  {
    name: "United States",
    code: "US"
  },
  {
    name: "Uzbekistan",
    code: "UZ"
  },
  {
    name: "Vatican City",
    code: "VA"
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC"
  },
  {
    name: "Venezuela",
    code: "VE"
  },
  {
    name: "British Virgin Islands",
    code: "VG"
  },
  {
    name: "United States Virgin Islands",
    code: "VI"
  },
  {
    name: "Vietnam",
    code: "VN"
  },
  {
    name: "Vanuatu",
    code: "VU"
  },
  {
    name: "Wallis and Futuna",
    code: "WF"
  },
  {
    name: "Samoa",
    code: "WS"
  },
  {
    name: "Yemen",
    code: "YE"
  },
  {
    name: "South Africa",
    code: "ZA"
  },
  {
    name: "Zambia",
    code: "ZM"
  },
  {
    name: "Zimbabwe",
    code: "ZW"
  }
];

export const timeZones = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Godthab",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qyzylorda",
  "Asia/Rangoon",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Johnston",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Wake",
  "Pacific/Wallis"
];
export const getRelativeTime = (date, language = "es", size = "short") => {
  // Convert the date to a Date object
  const dateObject = new Date(date);

  // Get the current date and time
  const now = new Date();

  const languageMap = {
    es: "es-MX",
    en: "en-US"
    // Agregar más idiomas aquí si es necesario
  };
  language = languageMap[language] || "en-US";

  // Calcular la diferencia entre las dos fechas en segundos
  const differenceInSeconds = Math.floor((now - dateObject) / 1000);

  // Validar si la diferencia es negativa o positiva
  let minutes;
  if (differenceInSeconds < 0) {
    // Calcular los minutos transcurridos desde la fecha y hora actual
    minutes = Math.floor(-differenceInSeconds / 60);
    // Restar los minutos de una hora si han transcurrido menos de una hora
    if (minutes < 60) {
      minutes = 60 - minutes;
    }
  } else {
    // Calcular los minutos transcurridos desde la fecha y hora actual
    minutes = Math.floor(differenceInSeconds / 60);
  }

  // Calcular la diferencia en horas, días, semanas, meses y años
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  const units = ["year", "month", "week", "day", "hour", "minute", "second"];
  const values = [
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    differenceInSeconds
  ];
  const index = values.findIndex(value => value > 0);
  const value = values[index];
  const unit = units[index];

  // Obtener la cadena de tiempo relativo
  let relativeTimeString;
  try {
    relativeTimeString = new Intl.RelativeTimeFormat(language, {
      numeric: "auto"
    }).format(-value, unit);
  } catch (error) {
    relativeTimeString = "";
  }
  return relativeTimeString;
};


export const generateHours15 = () => {
  let arr = [];
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 4; j++) {
      arr.push(`${!i ? '00' : i <= 9 ? `0${i}` : i}:${j === 0 ? `00` : 15 * j}`);
    }
  }

  return arr;
}