import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_TOKEN_SET,
  USER_DATA,
  CLIENT_DATA
} from "../constants/ActionTypes";
import axios from "util/Api";
import { push } from "connected-react-router";
import { DestroySocket } from "util/Socket";

export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};

export const userSignUp = data => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("signup", data)
      .then(({ data }) => {
        switch (data.status_codes) {
          case 200:
            dispatch({ type: FETCH_SUCCESS, payload: data.info });
            dispatch(push("/signin"));
            break;
          case 401:
          case 412:
          case 406:
            dispatch({ type: FETCH_ERROR, payload: data.info });
            break;
          default:
            dispatch({ type: FETCH_ERROR, payload: data.info });
            break;
        }
      })
  };
};

export const userSignIn = ({ email, password }, fromChat = false) => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .post("login", {
        email: email,
        password: password
      })
      .then(({ data }) => {
        switch (data.status_codes) {
          case 200:
            const { access_token, clientid, userid } = data.data;
            localStorage.setItem("token", access_token);
            localStorage.setItem("client_id", clientid);
            localStorage.setItem("user_id", userid);
            axios.defaults.headers.common["access-token"] = access_token;

            if (window && window.parent && fromChat) {
              window.parent.postMessage(JSON.stringify({ action: 'sign-in', ...data.data }), "*")
            }

            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: USER_TOKEN_SET,
              payload: {
                token: access_token,
                clientID: clientid,
                userID: userid
              }
            });
            dispatch(push(!fromChat ? "/app/dashboard/main" : "/app/chat-iframe"));
            break;
          case 401:
          case 412:
          case 406:
            dispatch({ type: FETCH_ERROR, payload: data.info });
            break;
          default:
            dispatch({ type: FETCH_ERROR, payload: data.info });
            break;
        }
      })
  };
};

export const setModule = m => {
  return dispatch => {
    dispatch({ type: 'set_module', payload: m });
  };
};


export const fetchUser = user => {
  return dispatch => {
    dispatch({ type: USER_DATA, payload: user });
  };
};

export const fetchFixed = data => {
  return dispatch => {
    dispatch({ type: 'USER_FIXED', payload: data });
  };
};


export const fetchClient = client => {
  return dispatch => {
    dispatch({ type: CLIENT_DATA, payload: client });
  };
};

export const userSignOut = token => {
  return dispatch => {
    dispatch({ type: FETCH_START });
    axios
      .get("logout", {
        headers: {
          Authorization: token
        }
      })
      .then(({ data }) => {
        if (data.status_codes === 200) {
          dispatch({ type: FETCH_SUCCESS });

          Object.getOwnPropertyNames(sessionStorage).forEach((key) => {
            let value = sessionStorage.getItem(key);
            if (value) {
              try {
                value = JSON.parse(value)
                if (Array.isArray(value)) {
                  sessionStorage.removeItem(key)
                } else {
                  if (key.indexOf('filter.') >= 0) {
                    sessionStorage.removeItem(key)
                  }
                }
              } catch (error) {

              }
            }
          })


          localStorage.removeItem("token");
          localStorage.removeItem("user_id");
          localStorage.removeItem("client_id");
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SIGNOUT_USER_SUCCESS });
          dispatch(push("/"));
          DestroySocket();
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.info });
        }
      })
      .catch(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("client_id");
        dispatch({ type: FETCH_ERROR, payload: "" });
        dispatch(push("/"));
      })
  };
};
