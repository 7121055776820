import indigo from "@material-ui/core/colors/indigo";
import deepOrange from "@material-ui/core/colors/deepOrange";
import green from "@material-ui/core/colors/green";

export default {
  palette: {
    primary: {
      light: indigo[300],
      main: indigo[500],
      dark: indigo[700],
      contrastText: "#fff"
    },
    secondary: {
      light: deepOrange[300],
      main: deepOrange["A200"],
      dark: deepOrange[700],
      contrastText: "#fff"
    },
    success: green,
    warning: deepOrange
  },
  status: {
    danger: "orange"
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: "capitalize"
    }
  }
};
