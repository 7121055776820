import axios from "util/Api";
import { base, baseURLajaxPrefix } from "util/Api";

class ChatService {
  constructor() {}

  async messageContacts(devicesIds, query, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/message/contact`,
      {
        // list_device: [...devicesIds],
        ...query
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
}

export default ChatService;
