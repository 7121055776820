import React from "react";
import { Box, Fade, Typography } from "@material-ui/core";

export default function NohasMore({ show }) {

    return (
        <Fade in={show}>
            <Box justifyContent={'center'} py={2}>
                <Typography variant="body2" textAlign={'center'}>
                    {`😊 ${'¡Sí! Lo has visto todo'}`}
                </Typography>
            </Box>
        </Fade >
    )
}