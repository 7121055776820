import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  CLIENT_DATA,
  USER_TOKEN_SET
} from "../constants/ActionTypes";

const INIT_STATE = {
  token: localStorage.getItem("token"),
  clientID: localStorage.getItem("client_id"),
  userID: localStorage.getItem("user_id"),
  initURL: "",
  authUser: null,
  client: null,
  setting_views: [],
  module: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'set_module': {
      return { ...state, module: action.payload };
    }

    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ""
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload
      };
    }

    case 'USER_FIXED': {
      state.authUser.fixed = [...action.payload];
      return {
        ...state
      };
    }

    case CLIENT_DATA: {
      return {
        ...state,
        client: action.payload
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload.token,
        clientID: action.payload.clientID,
        userID: action.payload.userID
      };
    }

    default:
      return state;
  }
};
