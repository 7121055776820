import React from "react";
import { connect } from "react-redux";
import {
  IconButton,
  CircularProgress,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Grow,
  Box,
  Button
} from "@material-ui/core";
import { orange, red, indigo } from "@material-ui/core/colors";
import { NavLink } from "react-router-dom";

// Actions
import { showMessage } from "actions/Common";

// Services
import { useTasks, useTask } from "services/hook/task.service";
import {
  compareDates,
  getNewDateValue,
  getNewNextDate,
  getNewDateTimeValue
} from "util/Helper";

// Components
import IntlMessages from "util/IntlMessages";
import ModalTask from "components/sofia/modals/task";
import DisplayDate from "util/DisplayDate";
import CustomScrollbars from "util/CustomScrollbars";

// Icon
import DoneIcon from "@material-ui/icons/Done";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

function TasksButton(props) {
  const {
    token,
    locale,
    clientID,
    userID,
    isGlobal = false,
    notification
  } = props;
  const { tasks, loader, total, statusCode, index, setData } = useTasks({
    clientID: clientID,
    token: token,
    locale: locale.locale,
    userID: userID,
    queryGlobal: {
      page: 0,
      offset: 100,
      owner: [userID],
      complete: false,
      sort: "due_date",
      to: `${getNewDateValue()}`
    }
  });

  const [show, setShow] = React.useState(false);
  const [tasksTemp, setTasksTemp] = React.useState([]);
  const [sync, setSync] = React.useState(false);

  const [modalTask, setModalTask] = React.useState({
    visible: false,
    state: "create"
  });

  const getTasksDue = () => {
    const arr = tasks.filter(
      task =>
        compareDates(
          task.due_date,
          getNewDateTimeValue(),
          "YYYY-MM-DDTHH:mm:ss"
        ) === -1 && !task.is_notified
    );

    return arr;
  };

  const isExistTaskDue = () => {
    const arr = tasks.filter(
      task =>
        compareDates(
          task.due_date,
          getNewDateTimeValue(),
          "YYYY-MM-DDTHH:mm:ss"
        ) === -1
    );
    return arr.length > 0 ? true : false;
  };

  React.useEffect(() => {
    const { action } = statusCode;
    if (action === "change" || action === "delete") {
      index(0);
    }
    setTasksTemp([...getTasksDue()]);
  }, [statusCode]);

  React.useEffect(() => {
    setTasksTemp([...getTasksDue()]);
  }, [tasks]);

  React.useEffect(() => {
    setTasksTemp([...getTasksDue()]);
  }, [sync]);

  React.useEffect(() => {
    if (notification) {
      const { module, action, data, ref } = notification;

      if (module === "task") {
        switch (action) {
          case "create":
            index(0, {}, false);
            break;
          case "update":
            const findIndex = tasks.findIndex(t => t.id === data.id);

            if (findIndex >= 0) {
              const model = { ...tasks[findIndex] };
              if (!data.complete) {
                const arr = tasks;
                arr.splice(findIndex, 1, {
                  ...tasks[findIndex],
                  ...data
                });
                setData(arr);
              } else {
                setData([...tasks.filter((t, i) => i !== findIndex)]);
              }
            }
            break;
          case "delete":
            setData(tasks.filter(t => t.id !== ref));
            break;
          default:
            break;
        }
        setTasksTemp([...getTasksDue()]);
      }
    }
  }, [notification]);

  React.useEffect(() => {
    const id = setInterval(() => {
      setSync(change => !change);
    }, 5000);

    index(0);

    return () => clearInterval(id);
  }, [0]);

  return (
    <>
      {tasksTemp.map((task, i) => (
        <TaskCell
          isTemp={true}
          task={task}
          key={i}
          isGlobal={isGlobal}
          auth={{
            token: token,
            clientID: clientID,
            locale: locale.locale
          }}
          onEnter={(task, status) => {
            setModalTask({
              ...modalTask,
              id: task.id,
              state: status,
              visible: true
            });
          }}
          onMsg={msg => props.showMessage(msg)}
          onSuccess={() => index()}
        />
      ))}
      <Grow in={show}>
        <Card
          className="bg-grey lighten-5 "
          style={
            !isGlobal
              ? {
                  width: 310,
                  position: "absolute",
                  maxHeight: 450,
                  top: 45,
                  left: -330
                }
              : {
                  width: 310,
                  position: "absolute",
                  maxHeight: 450,
                  top: ".5rem",
                  right: "5rem",
                  zIndex: 3
                }
          }
        >
          <div className="d-flex flex-row py-2 px-3 bg-warning text-white">
            <h4 className="mb-0">
              <IntlMessages id="tasksincomplete" /> ({tasks.length})
            </h4>
            <span
              className="text-muted ml-auto pointer d-none d-sm-inline-flex align-items-sm-center"
              onClick={() => setShow(false)}
            >
              <i className="zmdi zmdi-close mr-1 text-white" />
            </span>
          </div>
          <CardContent>
            <CustomScrollbars
              className=" scrollbar"
              style={{
                height: 380
              }}
            >
              <div className="conatiner-fluid mx-2">
                {loader ? (
                  <>
                    <div
                      className="text-center mt-3"
                      style={{ minHeight: "250px" }}
                    >
                      <img
                        src={require("assets/images/tasks.svg")}
                        style={{ width: "100px" }}
                      />
                      <p className="mt-3">
                        <IntlMessages id="loading" />{" "}
                        <span className="ml-2">
                          {loader && <CircularProgress size={20} />}
                        </span>
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {tasks.length > 0 ? (
                      <div className="w-100">
                        {tasks.map((task, i) => (
                          <TaskCell
                            task={task}
                            key={i}
                            auth={{
                              token: token,
                              clientID: clientID,
                              locale: locale.locale
                            }}
                            isGlobal={isGlobal}
                            onEnter={(task, status) => {
                              setModalTask({
                                ...modalTask,
                                id: task.id,
                                state: status,
                                visible: true
                              });
                            }}
                            onMsg={msg => props.showMessage(msg)}
                            onSuccess={() => index(0, {}, false)}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="text-center mt-5">
                        <div>
                          <img
                            src={require("assets/images/empty.svg")}
                            style={{ width: "100px" }}
                          />
                        </div>
                        <div>
                          <p className={``}>
                            <IntlMessages id="noDataTask" />
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </CustomScrollbars>
          </CardContent>
        </Card>
      </Grow>
      {modalTask.visible && (
        <ModalTask
          model={modalTask}
          onClose={() => {
            setModalTask({ ...modalTask, visible: false });
          }}
          onSet={data => {
            setModalTask({ ...modalTask, ...data });
          }}
        />
      )}
      <IconButton
        aria-label="tasks"
        className={`icon-button ${isExistTaskDue() &&
          "animated infinite headShake"}`}
        style={{ color: "#ff9800" }}
        onClick={() => setShow(!show)}
      >
        {isExistTaskDue() ? <NotificationsActiveIcon /> : <NotificationsIcon />}
      </IconButton>
    </>
  );
}

function TaskCell(props) {
  const { isTemp = false, task, isGlobal, auth, onMsg, onSuccess } = props;
  const { clientID, locale, token } = auth;
  const { title, complete, color, due_date, description, contact } = task;
  const contactName = `${contact?.[0]?.info?.first_name} ${contact?.[0]?.info?.last_name}`;
  const { updateTask, loader, statusCode } = useTask({
    id: task.id,
    clientID: clientID,
    token: token,
    locale: locale
  });

  const isDue = compareDates(
    due_date,
    getNewDateTimeValue(),
    "YYYY-MM-DDTHH:mm:ss"
  );

  const dateCompare = dueDate => {
    const date = dueDate.split("T")[0];
    const currentDate = getNewDateTimeValue().split("T")[0];
    const nextDate = getNewNextDate(5).split("T")[0];
    const time = dueDate;
    const currentTime = getNewDateTimeValue();

    switch (true) {
      // due 2023-09-29  hoy 2023-09-14 5d 2023-09-19
      case date > currentDate && date != currentDate && date <= nextDate:
        return {
          style: "primary",
          text: "task.next.to.expire",
          icon: "time-countdown"
        };
        break;
      case date > nextDate:
        return { style: "success", text: "task.expire", icon: "calendar" };
        break;
      case date < currentDate:
        return {
          style: "danger",
          text: "task.expired",
          icon: "time-countdown"
        };
        break;
      case date === currentDate && time >= currentTime:
        return {
          style: "warning",
          text: "task.today.expires",
          icon: "time-countdown"
        };
        break;
      case date === currentDate && time < currentTime:
        return {
          style: "danger",
          text: "task.overdue",
          icon: "time-countdown"
        };
        break;
      default:
        return { style: "info", text: "task.invalid", icon: "alert-octagon" };
        break;
    }
  };

  const comp = dateCompare(due_date);
  React.useEffect(() => {
    const { action, msg, code } = statusCode;
    if (action === "update") {
      onMsg(msg);
    }
  }, [statusCode]);

  return isTemp ? (
    <Grow in={true}>
      <Card
        className=""
        style={
          !isGlobal
            ? {
                width: 310,
                position: "absolute",
                minHeight: 200,
                top: 45,
                left: -330
              }
            : {
                width: 310,
                position: "absolute",
                minHeight: 200,
                right: "5rem",
                top: ".5rem",
                zIndex: 3
              }
        }
      >
        <div
          className="d-flex flex-row py-3 px-3 text-white"
          style={{ background: indigo[500] }}
        >
          <h4 className="mb-0">
            <IntlMessages id="notification" />
          </h4>
        </div>

        <CardContent>
          <div className="card mb-0 shadow">
            <div
              class={`px-3 py-2 alert-${comp.style}`}
              style={{
                borderRadius: "0.375rem 0.375rem 0 0"
              }}
            >
              <span
                className={`d-flex align-items-center text-${comp.style} h3 mb-0`}
              >
                <i className={`zmdi zmdi-${comp.icon} mr-2`}></i>
                <span className="d-flex align-items-center">
                  <p className="mr-1 mb-0 jr-text-grey jr-fs-sm jr-mb-0">
                    <IntlMessages id={comp.text} />
                  </p>
                  {!comp ? (
                    <DisplayDate date={due_date} band={true} />
                  ) : (
                    <DisplayDate date={due_date} />
                  )}
                </span>
              </span>
            </div>
            <div className="py-3 px-3">
              <div>
                <Tooltip title={title}>
                  <a
                    className={`pointer task-name text-primary`}
                    onClick={() => props.onEnter(task, "view")}
                  >
                    {title.length > 30 ? title.substring(0, 30) + "..." : title}
                  </a>
                </Tooltip>
              </div>
              <div>
                <p className="mt-2 mb-3 text-muted">{description}</p>
              </div>
              <div>
                <div className="d-flex align-tems-center small">
                  {task.module === "deal" && (
                    <span className="d-flex align-tems-center mr-3 jr-fs-sm">
                      <span className="mr-1">Relacionado:</span>
                      <NavLink
                        className="text-truncate"
                        style={{ width: 110 }}
                        to={`/app/deals/detail/${task?.reference_id}`}
                      >
                        {`${task.reference[0].name}`}
                      </NavLink>
                    </span>
                  )}

                  {task.module === "contact" && (
                    <span className="d-flex align-tems-center mr-3 jr-fs-sm">
                      <span className="mr-1">Relacionado:</span>
                      <NavLink
                        className="text-truncate"
                        style={{ minWidth: 110 }}
                        to={`/app/contacts/detail/${task?.contact_id}`}
                      >
                        {`${task.contact[0]?.info.first_name} ${task.contact[0]?.info.last_name}`}
                      </NavLink>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="model-item align-items-center w-100 border-0 pb-0">
            <div className="pr-2 text-small small text-red">
              <IntlMessages id="title" />:
            </div>
            <div className="col-auto d-flex flex-grow-1 align-items-center text-small small p-0">
              <Tooltip title={title}>
                <a
                  className={`text-primary pointer task-name text-dark mr-3`}
                  onClick={() => props.onEnter(task, "view")}
                >
                  {title.length > 20 ? title.substring(0, 20) + "..." : title}
                </a>
              </Tooltip>
            </div>
          </div>
          <div className="model-item align-items-center w-100 border-0">
            <div className="pr-2 text-small small text-small small text-red">
              <IntlMessages id="contact" />:
            </div>
            <div className="col-auto d-flex flex-grow-1 align-items-center text-small small p-0">
              <Tooltip title={contactName}>
                <NavLink to={`/app/contacts/detail/${contact[0]?.id}`}>
                  {contactName.length > 20
                    ? contactName.substring(0, 20) + "..."
                    : contactName}
                </NavLink>
              </Tooltip>
            </div>
          </div>
          <div className="model-item align-items-center w-100 border-0 pt-0 pb-2">
            <div className="pr-2 text-small small text-small small text-red">
              <IntlMessages id="overdue" />:
            </div>
            <div className="col-auto d-flex flex-grow-1 align-items-center text-small small p-0">
              <span className="d-flex align-items-center text-red">
                <i className="zmdi zmdi-timer-off mr-1"></i>
                <DisplayDate date={due_date} />
              </span>
            </div>
          </div>
          <div>
            <p
              className="my-2 p-3 border text-small small"
              style={{ borderRadius: 4 }}
            >
              {description}
            </p>
          </div> */}
        </CardContent>
        <CardActions className="d-flex justify-content-end border-top">
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className="mr-1"
              disableElevation
              disabled={loader}
              onClick={() => {
                updateTask({
                  complete: true
                });
              }}
            >
              Completar
            </Button>
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              disabled={loader}
              onClick={() => {
                updateTask({
                  is_notified: true
                });
              }}
            >
              Ignorar
            </Button>
            <span className="ml-2">
              {loader && <CircularProgress size={15} />}
            </span>
          </div>
        </CardActions>
      </Card>
    </Grow>
  ) : (
    <div className="card mb-3 shadow">
      <div class={`px-3 py-2 alert-${comp.style}`}>
        <span
          className={`d-flex align-items-center text-${comp.style} h3 mb-0`}
        >
          <i className={`zmdi zmdi-${comp.icon} mr-2`}></i>
          <span className="d-flex align-items-center">
            <p className="mr-1 mb-0 jr-text-grey jr-fs-sm jr-mb-0">
              <IntlMessages id={comp.text} />
            </p>
            {!comp ? (
              <DisplayDate date={due_date} />
            ) : (
              <DisplayDate date={due_date} band={true} />
            )}
          </span>
        </span>
      </div>
      <div className="d-flex py-3 px-3">
        <div>
          <div>
            <Tooltip title={title}>
              <a
                className={`pointer task-name text-primary`}
                onClick={() => props.onEnter(task, "view")}
              >
                {title.length > 25 ? title.substring(0, 25) + "..." : title}
              </a>
            </Tooltip>
          </div>
          <div>
            <p className="mt-2 mb-3 text-muted">
              {description.length > 40
                ? description.substring(0, 40) + "..."
                : description}
            </p>
          </div>
          <div>
            <div className="d-flex align-tems-center small">
              {task.module === "deal" && (
                <span className="d-flex align-tems-center mr-3 jr-fs-sm">
                  <span className="mr-1">Relacionado:</span>
                  <NavLink
                    className="text-truncate"
                    style={{ width: 110 }}
                    to={`/app/deals/detail/${task?.reference_id}`}
                  >
                    {`${
                      task.reference[0].name.length > 15
                        ? task.reference[0].name.substring(0, 15) + "..."
                        : task.reference[0].name
                    }`}
                  </NavLink>
                </span>
              )}

              {task.module === "contact" && (
                <span className="d-flex align-tems-center mr-3 jr-fs-sm">
                  <span className="mr-1">Relacionado:</span>
                  <NavLink
                    className="text-truncate"
                    style={{ minWidth: 110 }}
                    to={`/app/contacts/detail/${task?.contact_id}`}
                  >
                    {`${task.contact[0]?.info.first_name} ${task.contact[0]?.info.last_name}`}
                  </NavLink>
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center px-0 actions">
          <Tooltip title="Completar">
            <IconButton
              disabled={loader}
              className={`mx-1 size-30`}
              aria-label="done"
              size="small"
              onClick={() => {
                updateTask({
                  complete: true
                });
              }}
            >
              <DoneIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>

    // <Card
    //   className="model-item hover mb-1"
    //   style={{
    //     // borderLeft: `5px solid ${color}`,
    //     opacity: loader ? 0.3 : 1
    //   }}
    // >
    //   <div className="col con-inf-mw-100 px-0 ">
    //     <p className="mb-0 ">
    //       <Tooltip title={title}>
    //         <a
    //           className={`text-primary pointer task-name text-dark mr-3 small text-small`}
    //           onClick={() => props.onEnter(task, "view")}
    //         >
    //           {complete ? (
    //             <i className="zmdi zmdi-check-circle text-success mr-2"></i>
    //           ) : (
    //             ""
    //           )}
    //           <strong>
    //             {complete ? (
    //               <del>
    //                 {title.length > 39 ? title.substring(0, 25) + "..." : title}
    //               </del>
    //             ) : title.length > 39 ? (
    //               title.substring(0, 25) + "..."
    //             ) : (
    //               title
    //             )}
    //           </strong>
    //         </a>
    //       </Tooltip>
    //     </p>
    //     <div>
    //       <span
    //         className={`d-flex small text-small ${isDue == -1 && "text-red"}`}
    //       >
    //         <span className="mr-1">
    //           {isDue == -1 ? (
    //             <IntlMessages id="overdue" />
    //           ) : (
    //             <IntlMessages id="due" />
    //           )}
    //           :{" "}
    //         </span>
    //         {isDue == -1 ? (
    //           <span className="d-flex align-items-center">
    //             <i className="zmdi zmdi-timer-off mr-1"></i>
    //             <DisplayDate date={due_date} />
    //           </span>
    //         ) : (
    //           <>
    //             <DisplayDate date={due_date} band={true} />
    //           </>
    //         )}
    //       </span>
    //     </div>
    //   </div>

    //   <div className="col-auto px-0 actions ">
    //     <Tooltip title="Completar">
    //       <IconButton
    //         disabled={loader}
    //         className={`mx-1 size-30`}
    //         aria-label="done"
    //         size="small"
    //         onClick={() => {
    //           updateTask({
    //             complete: true
    //           });
    //         }}
    //       >
    //         <DoneIcon />
    //       </IconButton>
    //     </Tooltip>
    //   </div>
    // </Card>
  );
}

const mapStateToProps = ({ settings, auth, notification }) => {
  const { locale } = settings;
  const { token, authUser, userID, clientID } = auth;

  return {
    locale,
    token,
    authUser,
    userID,
    clientID,
    notification: notification.notification
  };
};

export default connect(mapStateToProps, { showMessage })(TasksButton);
