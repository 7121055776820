import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Tooltip } from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import {
  withStyles,
} from "@material-ui/core/styles";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import Acl from "components/sofia/elements/acl";
import { orange } from "@material-ui/core/colors";

const ColorButton = withStyles(theme => ({
  root: {
    backgroundColor: orange[500],
    padding: "5px 13px 6px 5px",
    borderRadius: 50,
    fontSize: 12,
    "&:hover": {
      backgroundColor: orange[700]
    }
  }
}))(Button);

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path
    this.state = { open: false }

    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        const parentLiEle = that.closest(this, "li");
        if (menuLi[i].classList.contains("menu") && parentLiEle !== null) {
          event.stopPropagation();

          if (menuLi[i].classList.contains("open")) {
            menuLi[i].classList.remove("open", "active");
          } else {
            menuLi[i].classList.add("open", "active");
          }
        } else {
          for (let j = 0; j < menuLi.length; j++) {
            const parentLi = that.closest(this, "li");
            if (
              menuLi[j] !== this &&
              (parentLi === null || !parentLi.classList.contains("open"))
            ) {
              menuLi[j].classList.remove("open");
            } else {
              if (menuLi[j].classList.contains("open")) {
                menuLi[j].classList.remove("open");
              } else {
                menuLi[j].classList.add("open");
              }
            }
          }
        }
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function (fn) {
        if (typeof document.body[fn] === "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  render() {
    const { authUser, allDevice } = this.props;
    return (
      <>
        <CustomScrollbars className=" scrollbar">
          <div className="position-relative" style={{ overflow: 'hidden' }}>
            <ul className="nav-menu">
              <li className="nav-header">
                <IntlMessages id="sidebar.main" />
              </li>
              <li className="menu collapse-box">
                <Button>
                  <i className="zmdi zmdi-widgets zmdi-hc-fw" />
                  <span className="nav-text">{"Dashboard"}</span>
                </Button>

                <ul className="sub-menu">

                  <li>
                    <NavLink className="prepend-icon" to="/app/dashboard/main">
                      <span className="nav-text">
                        <IntlMessages id="dashboard.basic" />
                      </span>
                    </NavLink>
                  </li>

                  <Acl
                    access={"dashboard.advanced"}
                    component={
                      <>
                        <li>
                          <NavLink
                            className="prepend-icon"
                            to="/app/dashboard/advanced"
                          >
                            <span className="nav-text">
                              <IntlMessages id="dashboard.advanced" />
                            </span>
                          </NavLink>
                        </li>
                      </>
                    }
                  />
                  <Acl
                    access={"dashboard.confirmation"}
                    component={
                      <>
                        <li>
                          <NavLink
                            className="prepend-icon"
                            to="/app/dashboard/confirmations"
                          >
                            <span className="nav-text">
                              <IntlMessages id="dashboard.confirmations" />
                            </span>
                          </NavLink>
                        </li>
                      </>
                    }
                  />
                </ul>
              </li>
              <Acl
                access={"chat.view"}
                component={
                  <li className="menu no-arrow">
                    <span>
                      <NavLink to="/app/chat">
                        <i className="zmdi zmdi-comments zmdi-hc-fw" />
                        <span className="nav-text">Chat</span>
                      </NavLink>
                    </span>
                  </li>
                }
              />
              <Acl
                access={"contact.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/contacts">
                      <i className="zmdi zmdi-accounts-list zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="contacts" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />
               <Acl
                access={"lead.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/leads">
                      <i className="zmdi zmdi-accounts-list zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="leads" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />

              <Acl
                access={"device.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/devices">
                      <i className="zmdi zmdi-smartphone-android zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="devices" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />

              <Acl
                access={"deal.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/deals">
                      <i className="zmdi zmdi-balance-wallet zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="deals" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"payment.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/payments">
                      <i className="zmdi zmdi-receipt zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="payments" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"task.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/tasks">
                      <i className="zmdi zmdi-check-circle-u zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="tasks" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"agenda.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/agendas">
                      <i className="zmdi zmdi-calendar-alt zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="agendas" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"marketing.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/diffusions">
                      <i className="zmdi zmdi-cast zmdi-hc-fw" />
                      <span className="nav-text">{"Marketing"}</span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"confirmation.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/confirmations">
                      <i className="zmdi zmdi-cast zmdi-hc-fw" />
                      <span className="nav-text"><IntlMessages id="confirmation" />{" "}</span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"storage.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/storage">
                      <i className="zmdi zmdi-cloud zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="storage" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />
              <Acl
                access={"setting.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/settings">
                      <i className="zmdi zmdi-settings zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="popup.setting" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              />

            </ul>
            <ul
              className="nav-menu"
              style={{
                paddingBottom: 50
              }}
            >
              {authUser.account === "master" && (
                <li className="nav-header">
                  <IntlMessages id="admin" />
                </li>
              )}
              <Acl
                access={"client.view"}
                component={
                  <>
                    <li className="menu no-arrow">
                      <NavLink to="/app/clients">
                        <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
                        <span className="nav-text">
                          <IntlMessages id="clients" />{" "}
                        </span>
                      </NavLink>
                    </li>
                  </>
                }
              />
              {authUser.account === "master" && (
                <li className="menu no-arrow">
                  <NavLink to="/app/usersAdmin">
                    <i className="zmdi zmdi-accounts-list zmdi-hc-fw" />
                    <span className="nav-text">
                      <IntlMessages id="users" />{" "}
                    </span>
                  </NavLink>
                </li>
              )}
              <Acl
                access={"plan.view"}
                component={
                  <>
                    <li className="menu no-arrow">
                      <NavLink to="/app/plans">
                        <i className="zmdi zmdi-pin-assistant zmdi-hc-fw" />
                        <span className="nav-text">
                          <IntlMessages id="plans" />{" "}
                        </span>
                      </NavLink>
                    </li>
                  </>
                }
              />
              <Acl
                access={"feature.view"}
                component={
                  <>
                    <li className="menu no-arrow">
                      <NavLink to="/app/features">
                        <i className="zmdi zmdi-delicious zmdi-hc-fw" />
                        <span className="nav-text">
                          <IntlMessages id="features" />{" "}
                        </span>
                      </NavLink>
                    </li>
                  </>
                }
              />
              {/* <Acl
                access={"billing.view"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/billing">
                      <i className="zmdi zmdi-file-text zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="billing" />{" "}
                      </span>
                    </NavLink>
                  </li>
                }
              /> */}
              <Acl
                access={"deviceAdmin.manager"}
                component={
                  <li className="menu no-arrow">
                    <NavLink to="/app/devicesAdmin">
                      <i className="zmdi zmdi-smartphone-setup zmdi-hc-fw" />
                      <span className="nav-text">
                        <IntlMessages id="gestorDispositivos" />
                      </span>
                    </NavLink>
                  </li>
                }
              />
            </ul>
          </div>
        </CustomScrollbars >
        <div>
          <ul className="nav-menu" style={{ overflow: 'hidden' }}>
            <Acl
              access={"billing.view"}
              component={
                <li className="" style={{ overflow: 'hidden' }}>
                  <div>

                    <Link className="jr-link" to="/app/billing/plans">


                      <Tooltip title={'Marketplace'}>

                        <div className="" style={{ overflow: 'hidden' }}>
                          <span className="nav-text2" style={{ animationDelay: '10s !important', overflow: 'hidden' }}>
                            <ColorButton variant="contained" color="primary" style={{
                              width: 185,
                              display: 'flex',
                              borderRadius: 50,
                              position: 'relative',
                              zIndex: 2
                            }}>
                              <div
                                className="d-flex align-items-center justify-content-center rounded-circle mr-2"
                                style={{
                                  width: 30,
                                  height: 30,
                                  backgroundColor: orange[300]
                                }}
                              >
                                <BeenhereIcon fontSize="small" style={{
                                  color: 'white'
                                }} />
                              </div>
                              <span
                                style={{
                                  color: 'white'
                                }}>
                                Marketplace
                              </span>
                            </ColorButton>
                          </span>

                          <span
                            className="nav-text3 d-flex align-items-center justify-content-center rounded-circle"
                            style={{
                              width: 30,
                              height: 30,
                              backgroundColor: orange[500],
                              position: 'fixed',
                              left: 25,
                              bottom: 15,
                              zIndex: 0,
                            }}
                          >
                            <BeenhereIcon fontSize="small" color="white" style={{
                              color: 'white'
                            }} />
                          </span>


                        </div>
                      </Tooltip>
                    </Link>
                  </div>
                </li>
              }
            />
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth, devices }) => {
  const { client, authUser } = auth;
  const { allDevice } = devices;

  return {
    client,
    authUser,
    allDevice
  };
};

export default connect(mapStateToProps, {})(withRouter(SidenavContent));
