import React from "react";
import { injectIntl } from 'react-intl';
import { IconButton, Box, Tooltip, CircularProgress, Button, Popover, MenuItem, TextField } from '@mui/material';
import { Badge } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ContactService from "services/ajax/contact.service";
import { socket } from "util/Socket";

// Icons
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';


// Actions
import { showMessage } from "actions/Common";
import { useDispatch, useSelector } from "react-redux";

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 1px ${theme.palette.background.paper}`,
    width: 1,
    left: 5,
    top: 12,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""'
    }
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0
    }
  }
}))(Badge);


function TransferAction(props) {

  const { contact } = props
  const { token, authUser, userID, clientID } = useSelector(({ auth }) => auth)
  const { locale } = useSelector(({ settings }) => settings);
  const { users } = useSelector(({ user }) => user)

  const [userTransfer, setUserTransfer] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSelect, setShowSelect] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


  const onTransfer = () => {
    setLoader(true);
    new ContactService()
      .transfer(
        contact.id,
        { user_id: userTransfer.id },
        locale.locale,
        token
      )
      .then(
        ({ data }) => {
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "contact",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
          setAnchorEl(null);
          setLoader(false);
          setUserTransfer(null);
        },
        () => {
          setLoader(false);
        }
      );
  };


  return (
    <>
      {contact.derivedto === userID ? (
        <Box sx={{
          position: 'relative'
        }}>
          <Tooltip title={"Transferir conversación"}>
            <IconButton
              size="small"
              aria-label=""
              sx={{
                padding: 0.4,
                borderRadius: '100%'
              }}
              onClick={(ev) => setAnchorEl(ev.currentTarget)}>
              <TransferWithinAStationIcon
                fontSize="small"
                style={{
                  fontSize: 16
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}


      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Box p={2} width={250}>
          <div className="mb-4">
            <h5>Transferir conversación</h5>
          </div>
          <TextField
            id="filled-select-currency"
            size={"small"}
            select
            label="Usuario"
            value={userTransfer}
            onChange={ev => {
              setUserTransfer(ev.target.value);
            }}
            SelectProps={{
              onOpen: () => setShowSelect(true),
              onClose: () => setShowSelect(false)
            }}
            variant="outlined"
            fullWidth
          >
            {users
              .filter(user => user.id !== userID)
              .map(option => (
                <MenuItem key={option.id} value={option}>
                  {showSelect ? (
                    <>
                      {option.online ? (
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left"
                          }}
                          variant="dot"
                        >
                          <span className="ml-4">
                            {option.name}
                          </span>
                        </StyledBadge>
                      ) : (
                        <>
                          <span
                            className={`text-grey`}
                            style={{
                              position: "absolute",
                              fontSize: 30
                            }}
                          >
                            •
                          </span>{" "}
                          <span className="ml-4">
                            {option.name}
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span className="">{option.name}</span>
                  )}
                </MenuItem>
              ))}
          </TextField>

          <div className="d-flex justify-content-end mt-3">
            <Button
              variant="contained"
              disabled={!userTransfer}
              size="small"
              color="primary"
              onClick={() => onTransfer()}
            >
              Transferir
            </Button>
            {loader && (
              <span className="mx-2">
                <CircularProgress color="primary" size={15} />
              </span>
            )}
          </div>
        </Box>
      </Popover>
    </>
  );
}

export default injectIntl(TransferAction)