import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box, List, CircularProgress, Fab } from '@material-ui/core'
import CircularProgressContent from "./ui/CircularProgressContent"
import NohasMore from "./ui/NohasMore"
import ConversationItem from './ui/ConverstionItem';
import { useConversationsService } from './use-conversation-service';
import SubHeaderConversation from '../Subheader';
import AddIcon from "@material-ui/icons/Add";

import ModalCreateMsg from "app/routes/chat/sidebars/contacts/Modal";

export default function Conversations(props) {

    const { isWidget } = props

    const {
        conversations,
        conversationsMarked,
        setConversationsMarked,
        loaderConversations: loader,
        paginationConversations,
        onfetchConversations,
    } = useConversationsService()

    const { authUser, token, userID, clientID } = useSelector(({ auth }) => auth);
    const { locale, setting_views } = useSelector(({ settings }) => settings);
    const { allDevice: devices } = useSelector(({ devices }) => devices);
    const { page, total } = useMemo(() => paginationConversations, [paginationConversations])

    const customView = authUser.custom_views.find(c => c.module === "chat").views.kanban
    const settingView = useMemo(() => setting_views.find(s => s.module === "chat", [authUser]))
    const [modalCreateMsg, setModalCreateMsg] = React.useState(false);


    useEffect(() => {
        onfetchConversations(1)
    }, [])

    return (
        <Box sx={{ position: 'relative' }}>
            <SubHeaderConversation isWidget={isWidget} total={total ? total + conversationsMarked.length : 0} dataLength={conversations.length} />
            <Box
                component={'div'}
                id="scroll-conversations"
                className="module-side-scroll scrollbar"
                style={{
                    height: isWidget ? '100vh' : `calc(100vh - 250px)`,
                    scrollBehavior: "smooth",
                    overflow: 'scroll'
                }}
            >
                <List
                    subheader={<li />}
                    disablePadding
                    style={{
                        padding: 0
                    }}
                >
                    <InfiniteScroll
                        dataLength={conversations.length}
                        next={() => onfetchConversations(page + 1)}
                        hasMore={conversations.length !== total}
                        scrollableTarget={'scroll-conversations'}
                        loader={
                            <Box sx={{ position: 'relative', minHeight: 100 }}>
                                <CircularProgressContent show={loader} />
                            </Box>
                        }
                        endMessage={
                            <NohasMore show={!loader && total > 0} />
                        }
                    >
                        {conversations.map((conversation, i) => (
                            <ConversationItem
                                key={`conversation-${i}`}
                                data={conversation}
                                index={i}
                                conversationsMarked={conversationsMarked}
                                pagination={paginationConversations}
                                customView={customView}
                                settingView={settingView}
                                setConversationsMarked={setConversationsMarked}
                            />
                        ))}
                    </InfiniteScroll>
                </List>

                <Fab
                    color="primary"
                    aria-label="add"
                    size="small"
                    style={{
                        position: "absolute",
                        bottom: isWidget ? "95px" : "3%",
                        left: "calc(100% - 55px)",
                        zIndex: 9999,
                        backgroundColor: "white !important",
                        color: "#fff",
                        zIndex: 2
                    }}
                    onClick={() => setModalCreateMsg(true)}
                >
                    <AddIcon />
                </Fab>
            </Box>
            <ModalCreateMsg
                isWidget={isWidget}
                devices={devices}
                visible={modalCreateMsg}
                auth={{
                    token,
                    userID,
                    clientID,
                    locale
                }}
                onClose={() => setModalCreateMsg(false)}
                onStored={msg => {
                    setModalCreateMsg(false);
                    // addMsg(msg);
                }}
            />
            {(loader) ? (
                <Box sx={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    zIndex: 1
                }}>
                    <CircularProgress />
                </Box>
            ) : null}
        </Box>
    )
}

// const mapStateToProps = ({
//     settings,
//     auth,
//     devices,
//     user,
//     chat,
//     notification,
//     dynamicList
// }) => {
//     const { token, clientID, userID, authUser } = auth;
//     const { width, locale, setting_views } = settings;
//     const { allDevice } = devices;
//     const { users } = user;
//     const { options, optionSelected, allMsg, chatContactSelected } = chat;
//     const { items } = dynamicList;

//     return {
//         token,
//         clientID,
//         userID,
//         locale,
//         devices: allDevice,
//         users,
//         options,
//         optionSelected,
//         allMsg,
//         authUser,
//         chatContactSelected,
//         notification: notification.notification,
//         setting_views,
//         sources: items.filter(
//             el => el.module === "contact" && el.field === "source" && el.status
//         ),
//         status: items.filter(
//             el => el.module === "contact" && el.field === "list_status" && el.status
//         ),
//         branchs: items.filter(
//             el => el.module === "all" && el.field === "branch_id" && el.status
//         ),
//         stages: items.filter(
//             el => el.module === "contact" && el.field === "list_stage" && el.status
//         ),
//         tags: items.filter(
//             el => el.module === "contact" && el.field === "tags" && el.status
//         )
//     };
// };
