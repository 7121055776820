import React from "react";

// utils
import { socket } from "util/Socket";

// services
import NoteService from "../ajax/note.service";

export const useNotes = ({ token, locale, clientID, queryGlobal }) => {
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });
  const [notes, setNotes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const [loaderStore, setLoaderStore] = React.useState(false);

  const rowsPerPage = 20;

  const index = (page, filters, isLoader = true, noQueryGlobal = false) => {

    const q = !noQueryGlobal ? {
      ...filters,
      ...queryGlobal
    } : {
      ...filters
    }

    if (isLoader) {
      setLoader(true);
    }

    new NoteService()
      .index(
        {
          offset: rowsPerPage,
          page: page + 1,
          ...q

        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setNotes([...data.data]);
          setTotal(data.pagination);
          setPage(page + 1);
        }
        setLoader(false);
      })
      .finally(_ => {
        setLoader(false);
      });
  };

  const setData = newArray => {
    setNotes([...newArray]);
  };

  const storeNote = data => {
    setLoaderStore(true);
    new NoteService()
      .store(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "note",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
          setTotal(total + 1);
        }
        setLoaderStore(false);
      })
      .finally(_ => {
        setLoaderStore(false);
      });
  };

  return {
    // const
    notes,
    rowsPerPage,
    total,
    page,
    statusCode,
    loader,
    loaderStore,
    // methods
    index,
    setData,
    storeNote
  };
};

export const useNote = ({ id, locale, token, clientID }) => {
  const [note, setNote] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });

  const getNote = () => {
    setLoader(true);
    new NoteService()
      .detail(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setNote(data.data[0]);
          setStatusCode({
            code: 200,
            action: "detail",
            data: data.data[0],
            msg: data.info
          });
        }
        setLoader(false);
      })
      .finally(_ => {
        setLoader(false);
      });
  };

  const storeNote = data => {
    setLoader(true);
    new NoteService()
      .store(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "note",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const updateNote = data => {
    setLoader(true);
    new NoteService()
      .update(id, data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setNote(data.data[0]);
          setStatusCode({
            code: 200,
            action: "update",
            data: data.data[0],
            msg: data.info
          });

          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "note",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .finally(_ => {
        setLoader(false);
      });
  };

  const deleteNote = (noteData) => {
    setLoader(true);
    new NoteService()
      .delete(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "delete",
            data: null,
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "note",
              action: "delete",
              data: noteData,
              msg: data.info,
              ref: id
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const setNoteData = newNote => {
    setNote(newNote);
  };

  return {
    // const
    note,
    loader,
    statusCode,
    // methods
    getNote,
    storeNote,
    updateNote,
    deleteNote,
    setNoteData
  };
};
