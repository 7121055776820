import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import {
  FormControlLabel,
  RadioGroup,
  Radio,
  Menu,
  MenuItem,
  Checkbox,
  CircularProgress,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Divider,
  Switch,
  Collapse,
  Typography,
  Tab,
  Tabs,
  Box,
  Chip,
  Avatar,
  FormGroup,
  Tooltip,
  InputAdornment
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

// Helpers
import IntlMessages from "util/IntlMessages";
import {
  visibilityValues,
  getNewDateValue,
  pipeDateTimeValue,
  getDateValue,
  generateHours15,
  getDateTimeValue
} from "util/Helper";
import DisplayDate from "util/DisplayDate";

import DynamicListService from "services/ajax/dynamicList.service";
import { socket } from "util/Socket";
// Form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
// Icons
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

// Services
import { useTask } from "services/hook/task.service";
// Actions
import { showMessage } from "actions/Common";
import SweetAlert from "react-bootstrap-sweetalert";
// Components
import SearchUsers from "components/sofia/search/searchUsers";
import SelectColors from "components/sofia/elements/SelectColors";
import CellItem from "components/sofia/elements/CellItem";

import Acl from "components/sofia/elements/acl";
import SearchModel from "components/sofia/search/searchModel";
import SearchSelectModel from "components/sofia/search/searchSelectModel";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Comments from "components/Comments";
import AutoCompleteAsync from "./autocompleted"

const NOTIFY = [{
  label: "Email",
  value: "email"
},
{
  label: "Pop-up",
  value: "pop-up"
}]

function ModalTask(props) {
  const {
    model,
    agent,
    reference,
    locale,
    statusList,
    userID,
    clientID,
    token,
    users,
    module = null,
    hiddenSelectors = false,
    tags
  } = props;
  const { visible, state, id } = model;
  const {
    task,
    statusCode,
    loader,
    storeTask,
    updateTask,
    deleteTask,
    getTask
  } = useTask({
    id,
    clientID,
    token,
    locale: locale.locale
  });

  const history = useHistory();

  const hours = useRef(generateHours15())
  const [status, setStatus] = React.useState([]);
  const [contact, setContact] = React.useState(null);
  const [color, setColor] = React.useState("#3f51b5");
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [visibility, setVisibility] = React.useState(0);
  const [realted, setRealted] = React.useState(module ? module : "contact");
  const [modelAlt, setModelAlt] = React.useState(null);
  const [isNotified, setIsNotified] = React.useState(false);
  const [timeSelected, setTimeSelected] = React.useState('08:00');

  const modules = ["contact", "deal", "lead"];

  const [owner, setOwners] = React.useState([]);
  const [receivers, setReceivers] = React.useState([]);
  const [channels, setChannels] = React.useState();
  const [notifyBeforeMin, setNotifyBeforeMin] = React.useState(
    task?.notify_before || 0
  );

  const [tagsValue, setTagsValue] = React.useState([]);
  const [notifyChannel, setNotifyChannel] = React.useState([NOTIFY[1]]);
  const [loadertagsValue, setLoadertagsValue] = React.useState(false);
  const filter = createFilterOptions();
  const [menu, setMenu] = React.useState({
    anchorEl: undefined,
    menuState: false
  });

  const [CommentsCount, setCommentsCount] = React.useState(0);
  const [StoreComments, setStoreComments] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);

  const schema = yup.object().shape({
    title: yup
      .string()
      .min(3)
      .max(50)
      .required(),
    // description: yup.string(),
    priority: yup.string().required(),
    due_date: yup.date().required(),
    notify: yup.string().nullable(true),
    status_id: yup.string(),
    complete: yup.boolean(),
    visibility: yup.string().required()
  });

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    errors,
    formState
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues:
      state === "create"
        ? {
          complete: false,
          due_date: getDateTimeValue(),
          notify: getDateValue(),
          priority: "medium"
        }
        : {}
  });

  watch([
    "title",
    "description",
    "priority",
    "due_date",
    "complete",
    "status_id",
    "visibility",
    "notify"
  ]);

  React.useEffect(() => {
    if (state !== "create" && visible) {
      getTask(id);
    }
    if (state === "create" && visible) {

      if (typeof agent !== "undefined") {
        setContact(agent);
      } else {
        setContact(null);
      }
      if (typeof reference !== "undefined") {
        setModelAlt(reference);
      } else {
        setModelAlt(null);
      }

      setIsNotified(false)
      setTimeSelected('08:00')
      setOwners(users.filter(user => user.id === userID));
      setColor("#3f51b5");
      setIsCompleted(false);
      setVisibility(2);
      setValue("priority", "low");
      setValue("related", module);

    }
  }, [state]);

  React.useEffect(() => {
    if (state !== "create" && visible && task) {

      if (task.notify_enable) {
        setNotifySend(true);
      }
      if (task.tags.length > 0) {
        const arr = [];
        task.tags.forEach(id => {
          const find = tags.find(tag => tag.id === id);
          if (typeof find !== "undefined") {
            arr.push(find);
          }
        });
        setTagsValue([...arr]);
      }


      setIsCompleted(task.complete);
      setColor(task.color);
      setOwners(task.owner);
      if (task.module === "lead") {
        task.lead[0].id =
          typeof task.lead[0].id !== "undefined"
            ? task?.lead?.[0]?.id
            : task?.lead?.[0]?._id.$oid;
        setModelAlt(task?.reference?.[0]);
      }

      else if (task.module !== "contact") {
        task.reference[0].id =
          typeof task.reference[0].id !== "undefined"
            ? task.reference[0].id
            : task.reference[0]._id.$oid;
        setModelAlt(task.reference[0]);
      }
      setValue('related', task.module);
      setRealted(task.module);

      setContact(task.contact !== undefined ? task.contact[0] : null);
      setVisibility(task.visibility);
      setValue('due_date', getDateTimeValue(task?.due_date))
      setValue('visibility', getDateValue(task?.visibilitye))
      setValue('status_id', task.status_id)
      setValue('complete', task.complete)

      try {

        if (task?.notify_before.length) {

          const [dateTime, time] = task?.notify_before.split('T')
          const [hours, minutes] = time.split(':')

          setIsNotified(true);
          setValue('notify', dateTime)
          setTimeSelected(`${hours}:${minutes}`)


        } else {
          setIsNotified(false);
        }

      } catch (error) {

      }

      setReload(true)
    }
  }, [task]);

  React.useEffect(() => {
    const { code, msg, data, action } = statusCode;
    if (code) {
      if (code !== 200) {
        props.showMessage(msg);
      } else {
        props.showMessage(msg);
        if (state === "create") {
          setContact(null);
          setOwners([]);
          props.onSet({
            state: "view",
            id: data.id
          });
        }
        if (action === "update") {
          setContact(null);
          props.onSet({
            state: "view",
            id: data.id
          });
        }

        if (action === "delete") {

          props.onClose();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  const onSubmit = formValue => {
    if (state === "create") {
      // Create

      const value = {
        title: formValue.title,
        description: (formValue.description) ? '' : formValue.description,
        priority: formValue.priority,
        complete: formValue.complete,
        comments: StoreComments.map(el => el.comment),
        due_date: getDateTimeValue(formValue.due_date),
        owner: owner.map(user => user.id),
        visibility: visibility,
        module: realted,
        tags: tagsValue.map(t => t.id),
        // notify_channel: notifyChannel.map(el => el.value),
        notify_channel: 'email',
      };

      if (contact) {
        value.contact_id = contact.id;
      }


      if (typeof formValue.status_id !== "undefined") {
        value.status_id = formValue.status_id
      } else {
        value.status_id = ""
      }

      if (isNotified) {
        const time = timeSelected ?? "08:00"
        const notifyDate = formValue?.notify ? getDateValue(formValue.notify_before) : getDateValue()
        value.notify_before = `${notifyDate}T${time}:00`
      }

      if (realted !== "contact") {
        value.reference_id = modelAlt.id;
      }

      storeTask(value);

    } else {
      // const value = {
      //   title: formValue.title,
      //   description: formValue.description,
      //   priority: formValue.priority,
      //   complete: isCompleted,
      //   color: color,
      //   due_date: getDateValue(formValue.due_date),
      //   contact_id: contact.id,
      //   visibility: visibility,
      //   module: realted,
      //   tags: tagsValue.map(t => t.id),
      //   notify_enable: notifySend,
      //   notify_channel: channels,
      //   notify_before:
      //     parseInt(notifyBeforeMin) !== null ? parseInt(notifyBeforeMin) : 0,
      //   notify_recipients: receivers.map(r => r.id),
      //   owner: owner.map(user => {
      //     if (typeof user === "string") {
      //       return user;
      //     } else {
      //       return user.id;
      //     }
      //   })
      // };

      // if (realted !== "contact") {
      //   value.reference_id = modelAlt.id;
      // }
      // updateTask(value);
    }
  };

  const toCreateTag = name => {
    setLoadertagsValue(true);
    new DynamicListService()
      .store(
        {
          client_id: clientID,
          module: "task",
          field: "tags",
          name: name,
          color: "#f44336"
        },
        locale.locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          const arr = JSON.parse(JSON.stringify(status));
          arr.push(data.data[0]);
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "listdinamic",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
          setTagsValue([...tagsValue, data.data[0]]);
        }
        setLoadertagsValue(false);
      })
      .catch(error => {
        setLoadertagsValue(false);
      });
  };

  const getStatusView = id => {
    const findStatus = statusList.find(s => s.id === id);
    return typeof findStatus !== "undefined" ? (
      <span
        className="jr-rounded-xs badge text-white"
        style={{ background: findStatus.color }}
      >
        {findStatus.name}
      </span>
    ) : (
      "-"
    );
  };

  const getUsersView = (ids = []) => {
    const arr = [];
    ids.forEach(id => {
      const find = users.find(user => user.id === id);
      if (typeof find !== "undefined") {
        arr.push(find);
      }
    });


    if (!arr.length) return "-";



    return (
      <div className="d-flex align-items-center" style={{
        flexWrap: 'wrap',
        gap: 4
      }}>
        {arr.map(user => (
          <div>
            <Chip size="small" avatar={<Avatar>{user.name[0]}</Avatar>} label={user.name} />
          </div>
        ))}
      </div>
    );
  };

  const userDefault = users.find(user => user.id === userID);

  const getVisibilityView = index => {
    const find = visibilityValues.find(el => el.value === index);
    return typeof find !== "undefined" ? (
      <span>
        <IntlMessages id={find.label} />
      </span>
    ) : (
      <span>-</span>
    );
  };

  const getViewReference = () => {
    if (task) {
      switch (task.module) {
        case "deal":
          return (
            <a
              className="pointer text-primary small"
              onClick={() => {
                history.push(`/app/deals/detail/${task.reference_id}`);
              }}
            >
              {`${task?.reference[0].name}`}
            </a>
          );
        case "lead":
          return (
            <a
              className="pointer text-primary small"
              onClick={() => {
                history.push(`/app/leads/detail/${task.reference_id}`);
              }}
            >
              {`${task?.reference[0].first_name} ${task?.reference[0].last_name}`}
            </a>
          );
        default:
          break;
      }
    } else {
      return "";
    }
  };

  const getTagsViews = (ids = []) => {
    const arr = [];
    ids.forEach(id => {
      const find = tags.find(tag => tag.id === id);
      if (typeof find !== "undefined") {
        arr.push(find);
      }
    });

    if (!arr.length) {
      return <span>-</span>
    }
    return (
      <div className="d-flex align-items-center" style={{
        flexWrap: 'wrap',
        gap: 8
      }}>
        {arr.map((agent, index) => (
          <span
            key={index}
            className={`jr-rounded-xs badge text-white mb-0 mr-0`}
            style={{ background: agent.color }}
          >
            {agent.name}
          </span>
        ))}
      </div>
    );
  };

  const [notifySend, setNotifySend] = useState(false);


  const viewControls = () => {

    return (
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          <div className="col-7">
            <CustomScrollbars
              autoHide={true}
              className="module-side-scroll scrollbar"
              style={{
                height: 495
              }}
            >

              <div
                className="px-2"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 18
                }}
              >
                <CellItem
                  isControl={state === 'create' ? true : false}
                  access="task.edit"
                  error={errors?.title}
                  showTpl={
                    <div className="w-100 px-2">
                      <div>
                        <Typography variant="body2" component="p" color="initial" gutterBottom>
                          {`Título`}
                          <span className="small text-red mx-1"></span>
                        </Typography>

                      </div>
                      <div>
                        <Typography variant="body1" component="p" color="initial" gutterBottom>
                          <b>{task?.title ?? "-"}</b>
                        </Typography>
                      </div>
                    </div>
                  }
                  editTpl={
                    <div className="w-100 px-2">
                      <div>
                        <Typography variant="body2" color="initial" gutterBottom>
                          {`Título`}
                          <span className="small text-red mx-1">{`*`}</span>
                        </Typography>

                      </div>
                      <div>
                        <TextField
                          name="title"
                          inputRef={register}
                          type="text"
                          fullWidth
                          size="small"
                          variant="outlined"
                          margin="none"
                          autoFocus
                          InputLabelProps={{
                            shrink: true
                          }}
                          error={errors?.title ? true : false}
                          defaultValue={state === "edit" ? task?.title : ""}
                          required

                        />
                      </div>
                    </div>
                  }
                  store={() => {
                    const { title } = getValues()
                    updateTask({ title: title });

                  }}
                />

                <CellItem
                  isControl={state === 'create' ? true : false}
                  access="task.edit"
                  error={errors?.description}
                  showTpl={
                    <div className="w-100 px-2">
                      <div>
                        <Typography variant="body2" component="p" color="initial" gutterBottom>
                          {`Descripción`}
                          <span className="small text-red mx-1"></span>
                        </Typography>

                      </div>
                      <div className="">
                        <TextareaAutosize
                          className="border-1 form-control"
                          maxRows={4}
                          cols={10}
                          value={task?.description ?? ""}
                          readOnly
                          style={{
                            resize: 'none',
                            overflow: 'scroll',
                            minHeight: 120,
                            maxHeight: 210,
                            marginLeft: 0,
                            marginTop: 5,
                            background: "transparent"
                          }}
                        />
                      </div>
                    </div>
                  }
                  editTpl={
                    <div className="w-100 px-2">
                      <div>
                        <Typography variant="body2" color="initial" gutterBottom>
                          {`Descripción`}
                        </Typography>

                      </div>
                      <div>
                        <Controller
                          as={
                            <TextareaAutosize
                              className="border-1 form-control"
                              error={errors?.description ? true : false}
                              name="description"
                              inputRef={register}
                              maxRows={4}
                              cols={10}
                              autoFocus={state === "create" ? false : true}
                              defaultValue={state === "create" ? "" : task?.description}
                              onChange={(ev) => {
                                setValue("description", ev.target.value)
                              }}
                              style={{
                                resize: 'none',
                                overflow: 'scroll',
                                minHeight: 120,
                                maxHeight: 210,
                                marginLeft: 0,
                                marginTop: 5
                              }}
                            />
                          }
                          name="description"
                          control={control}
                        />
                      </div>
                    </div>
                  }
                  store={() => {
                    const { description } = getValues()
                    updateTask({ description: description });

                  }}
                />


                <div>
                  <Tabs value={0}>
                    <Tab
                      selected
                      className=""
                      label={
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                          }}
                        >
                          <IntlMessages id={`dashboard.comments`} />
                          <span
                            style={{
                              background: "lightgray",
                              color: "gray",
                              height: 20,
                              width: 20,
                              lineHeight: "20px",
                              borderRadius: "50%",
                              marginLeft: 4,
                              fontSize: ".80rem"
                            }}
                          >
                            {CommentsCount || 0}
                          </span>
                        </span>
                      }
                    />
                  </Tabs>
                  <div className="" style={{ height: "auto" }}>
                    <Comments
                      showMessage200={state === "create" ? false : true}
                      contactID={contact?.id ?? null}
                      contact={contact ?? null}
                      model={modelAlt}
                      modelID={task?.id ?? null}
                      module={'task'}
                      state={state}
                      reload={reload}
                      showNoData={state === "create" ? false : true}
                      setReload={setReload}
                      onStoreComments={data => setStoreComments(data)}
                      onCount={data => setCommentsCount(data)}
                    />
                  </div>
                </div>
              </div>
            </CustomScrollbars>
          </div>
          <div className="col-5">
            <CustomScrollbars
              autoHide={true}
              className="module-side-scroll scrollbar"
              style={{
                height: 495
              }}
            >
              <div
                className="px-2"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8
                }}>



                {state !== 'create' && (
                  <>
                    <div className="w-100">


                      <CellItem
                        isControl={state === 'create' ? true : false}
                        access="task.edit"
                        error={errors?.description}
                        showTpl={
                          <div className="row align-items-center no-gutters p-2 w-100">
                            <div className="col-4 text-truncate pr-0">
                              <span className="small"><IntlMessages id="task.currentcondition" /></span>
                            </div>
                            <div className="col-8 pl-2">
                              {task?.complete ? (
                                <span className={`jr-rounded-xs badge text-white bg-success mb-0`}>
                                  <IntlMessages id="completed" />
                                </span>
                              ) : (
                                <span className={`jr-rounded-xs badge text-white bg-warning mb-0`}>
                                  <IntlMessages id="pending" />
                                </span>
                              )}
                            </div>
                          </div>
                        }
                        editTpl={
                          <div className="row align-items-center no-gutters px-2 w-100">
                            <div className="col-4  text-truncate pr-0">
                              <span className="small"><IntlMessages id="task.currentcondition" /></span>
                            </div>
                            <div className="col-8 pl-2">

                              <Controller
                                as={
                                  <TextField
                                    select
                                    size="small"
                                    type="text"
                                    variant="outlined"
                                    margin="none"
                                    fullWidth
                                    name="complete"
                                    inputRef={register}
                                    autoFocus={state === "create" ? false : true}
                                    error={errors?.complete ? true : false}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    onChange={ev => {
                                      setValue('complete', ev.target.value)
                                    }}
                                  >
                                    {
                                      [
                                        {
                                          label: "no",
                                          value: false
                                        },
                                        {
                                          label: "yes",
                                          value: true
                                        }
                                      ].map((option, i) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          <span className={`jr-rounded-xs badge text-white bg-${!i ? 'warning' : 'success'} mb-0`}>
                                            <IntlMessages id={option.label} />
                                          </span>
                                        </MenuItem>
                                      ))}
                                  </TextField>
                                }
                                name="complete"
                                control={control}
                                defaultValue={state === "create" ? false : task?.complete}
                              />
                            </div>
                          </div>
                        }
                        store={() => {
                          const { complete } = getValues()
                          updateTask({ complete: complete });
                        }}
                      />
                    </div>
                  </>
                )}


                <div className="w-100">


                  <CellItem
                    isControl={state === 'create' ? true : false}
                    access="task.edit"
                    error={errors?.description}
                    showTpl={
                      <div className="row align-items-center no-gutters p-2 w-100">
                        <div className="col-4 text-truncate pr-0">
                          <span className="small"><IntlMessages id="task.stage" /></span>
                        </div>
                        <div className="col-8 pl-2">
                          {task?.status_id ? (
                            <>
                              {getStatusView(task?.status_id)}
                            </>
                          ) : (
                            <IntlMessages id="noInformation" />
                          )}
                        </div>
                      </div>
                    }
                    editTpl={
                      <div className="row align-items-center no-gutters px-2 w-100">
                        <div className="col-4  text-truncate pr-0">
                          <span className="small"><IntlMessages id="task.stage" /></span>
                        </div>
                        <div className="col-8 pl-2">
                          <Controller
                            as={
                              <TextField
                                name="status_id"
                                inputRef={register}
                                variant="outlined"
                                margin="none"
                                fullWidth
                                size="small"
                                type="text"
                                select
                                InputLabelProps={{
                                  shrink: true
                                }}
                              >
                                {statusList.map(option => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            }
                            control={control}
                            name="status_id"
                            defaultValue={task?.status_id}
                          />
                        </div>
                      </div>
                    }
                    store={() => {
                      const { status_id } = getValues()
                      updateTask({ status_id: status_id });
                    }}
                  />
                </div>

                <div className="w-100">
                  <CellItem
                    isControl={state === 'create' ? true : false}
                    access="task.edit"
                    error={errors?.description}
                    showTpl={
                      <div className="row align-items-center no-gutters p-2 w-100">
                        <div className="col-4 text-truncate pr-0">
                          <span className="small">{`Prioridad`}</span>
                        </div>
                        <div className="col-8 pl-2 small">
                          {task?.priority ? (
                            <IntlMessages id={task?.priority} />
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </div>
                    }
                    editTpl={
                      <div className="row align-items-center no-gutters px-2 w-100">
                        <div className="col-4  text-truncate pr-0">
                          <span className="small">{`Prioridad`}</span>
                        </div>
                        <div className="col-8 pl-2">
                          <Controller
                            as={
                              <TextField
                                select
                                size="small"
                                type="text"
                                variant="outlined"
                                margin="none"
                                fullWidth

                                name="priority"
                                inputRef={register}
                                error={errors?.priority ? true : false}
                                autoFocus={state === "create" ? false : true}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                onChange={ev => {
                                  setValue('priority', ev.target.value)
                                }}
                              >
                                {
                                  [
                                    { label: "low", value: "low" },
                                    { label: "medium", value: "medium" },
                                    { label: "high", value: "high" }
                                  ].map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                      <IntlMessages id={option.label} />
                                    </MenuItem>
                                  ))}
                              </TextField>
                            }
                            name="priority"
                            control={control}
                            defaultValue={state === "create" ? "medium" : task?.priority}
                          />
                        </div>
                      </div>
                    }
                    store={() => {
                      const { priority } = getValues()
                      updateTask({ priority: priority });
                    }}
                  />
                </div>

                <div className="w-100">
                  <CellItem
                    isControl={state === 'create' ? true : false}
                    access="task.edit"
                    error={errors?.due_date}
                    showTpl={
                      <div className="row align-items-center no-gutters p-2 w-100">
                        <div className="col-4 text-truncate pr-0">
                          <span className="small">{`Vencimiento`}</span>
                        </div>
                        <div className="col-8 pl-2">
                          {task?.due_date ? (
                            <DisplayDate date={task?.due_date} band={true} />
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </div>
                    }
                    editTpl={
                      <div className="row align-items-center no-gutters px-2 w-100">
                        <div className="col-4  text-truncate pr-0">
                          <span className="small">
                            {`Vencimiento`}
                            <span className="small text-red mx-1">{`*`}</span>
                          </span>
                        </div>
                        <div className="col-8 pl-2">
                          <TextField
                            type="datetime-local"
                            size="small"
                            variant="outlined"
                            margin="none"
                            name="due_date"
                            inputRef={register}
                            onChange={ev => {
                              setValue('due_date', ev.target.value)
                            }}
                            inputProps={{
                              min: getDateTimeValue()
                            }}
                            fullWidth
                            error={errors?.due_date ? true : false}
                            autoFocus={state === "create" ? false : true}
                            defaultValue={state === "create" ? null : getDateTimeValue(task?.due_date)}
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </div>
                      </div>
                    }
                    store={() => {
                      const { due_date } = getValues()
                      updateTask({ due_date: getDateTimeValue(due_date) });
                    }}
                  />
                </div>

                {!hiddenSelectors && (
                  <div className="w-100">
                    <CellItem
                      isControl={state === 'create' ? true : false}
                      access="task.edit"
                      showTpl={
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}>

                          <div className="row align-items-center no-gutters p-2 w-100">
                            <div className="col-4 text-truncate pr-0 small">
                              <IntlMessages id={`contact`} />
                            </div>
                            <div className="col-8 pl-2">
                              {task?.contact.length > 0 ? (
                                <a
                                  className="pointer text-primary small"
                                  onClick={() => {
                                    history.push(
                                      `/app/contacts/detail/${task.contact_id}`
                                    );
                                  }}
                                >
                                  {`${task?.contact[0].info?.first_name} ${task?.contact[0].info?.last_name}`}
                                </a>
                              ) : "-"}
                              {task?.lead.length > 0 ? (
                                <a
                                  className="pointer text-primary small"
                                  onClick={() => {
                                    history.push(
                                      `/app/leads/detail/${task.reference_id}`
                                    );
                                  }}
                                >
                                  {`${task?.lead?.[0]?.full_name}`}
                                </a>
                              ) : "-"}
                            </div>
                          </div>
                          {task?.module === "deal" && (
                            <div className="row align-items-center no-gutters p-2 w-100">
                              <div className="col-4 text-truncate pr-0 small">
                                {task?.module ? (
                                  <IntlMessages id={task.module} />
                                ) : (
                                  "Relación"
                                )}
                              </div>
                              <div className="col-8 pl-2">
                                {getViewReference()}
                              </div>
                            </div>
                          )}

                        </div>
                      }
                      editTpl={

                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}>
                          <div className="row align-items-center no-gutters p-2 w-100">
                            <div className="col-4 text-truncate pr-0">
                              <span className="small">
                                {`Relación`}
                                <span className="small text-red mx-1">{`*`}</span>
                              </span>
                            </div>
                            <div className="col-8 pl-2">
                              <Controller
                                as={
                                  <TextField
                                    select
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    margin="none"
                                    name="related"
                                    inputRef={register}
                                    error={errors?.related ? true : false}
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      onChange: ev => {
                                        setValue("related", ev.target.value)
                                        setRealted(ev.target.value)
                                      }
                                    }}

                                  >
                                    {modules.map(option => (
                                      <MenuItem key={option} value={option}>
                                        <IntlMessages id={option} />
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                }
                                name="related"
                                control={control}
                                defaultValue={state === "create" ? realted : task?.module}
                              />

                            </div>
                          </div>

                          {realted && (
                            <>

                              <div className="row align-items-center no-gutters px-2 w-100">
                                <div className="col-4 text-truncate pr-0">
                                  <span className="small">
                                    {realted === "contact" ? "Contacto" : ""}
                                    {realted === "deal" ? "Presupuesto" : ""}
                                    {realted === "lead" ? "Cliente" : ""}
                                    <span className="small text-red mx-1">{`*`}</span>
                                  </span>
                                </div>
                                <div className="col-8 pl-2">
                                  {realted === "contact" ?
                                    <AutoCompleteAsync
                                      isMultiple={false}
                                      required={false}
                                      type={'contact'}
                                      variant="outlined"
                                      defaultValue={state === "create" ? null : contact}
                                      onSelected={agent => {
                                        if (agent) {
                                          setContact(agent);
                                        } else {
                                          setContact(null)
                                        }
                                      }}
                                    />
                                    : null}

                                  {realted === "lead" ?
                                    <AutoCompleteAsync
                                      isMultiple={false}
                                      required={false}
                                      type={'lead'}
                                      variant="outlined"
                                      defaultValue={state === "create" ? null : modelAlt}
                                      onSelected={lead => {
                                        if (lead) {
                                          setModelAlt(lead);
                                        } else {
                                          setContact(null);
                                          setModelAlt(null);
                                        }
                                      }}
                                    />
                                    : null}
                                  {realted === "deal" ?
                                    <AutoCompleteAsync
                                      isMultiple={false}
                                      required={false}
                                      type={'deal'}
                                      variant="outlined"
                                      defaultValue={state === "create" ? modelAlt ? modelAlt : null : modelAlt}
                                      onSelected={deal => {
                                        if (deal) {
                                          const newContact = deal.contact[0];
                                          setContact(newContact);
                                          setModelAlt(deal);
                                        } else {
                                          setContact(null);
                                          setModelAlt(null);
                                        }
                                      }}
                                    /> : null}

                                </div>
                              </div>
                            </>
                          )}


                        </div>


                      }
                      store={() => {
                        const value = { contact_id: contact.id, module: realted };

                        if (realted !== "contact") {
                          value.reference_id = modelAlt.id;
                        }

                        updateTask({ ...value });

                      }}
                    />
                  </div>

                )}


                <div className="w-100">
                  <CellItem
                    isControl={state === 'create' ? true : false}
                    access="task.edit"
                    showTpl={
                      <div className="row align-items-center no-gutters p-2 w-100">
                        <div className="col-4 text-truncate pr-0">
                          <span className="small">{`Etiquetas`}</span>
                        </div>
                        <div className="col-8 pl-2 small">
                          {getTagsViews(task?.tags)}
                        </div>
                      </div>
                    }
                    editTpl={
                      <div className="row align-items-center no-gutters px-2 w-100">
                        <div className="col-4  text-truncate pr-0">
                          <span className="small">{`Etiquetas`}</span>
                        </div>
                        <div className="col-8 pl-2">
                          <Autocomplete
                            multiple
                            size="small"
                            options={[...tags]}
                            getOptionLabel={option => option.name}
                            value={tagsValue}
                            disabled={loadertagsValue}
                            loading={loadertagsValue}
                            renderInput={params => (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                margin="none"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (newValue.length > 0) {
                                if (
                                  typeof newValue[newValue.length - 1].isNew !==
                                  "undefined"
                                ) {
                                  toCreateTag(
                                    newValue[newValue.length - 1].inputValue
                                  );
                                } else {
                                  setTagsValue(newValue);
                                }
                              } else {
                                setTagsValue(newValue);
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              if (!filtered.length) {
                                if (params.inputValue !== "") {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    name: `Agregar "${params.inputValue}"`,
                                    isNew: true
                                  });
                                }
                              }

                              return filtered;
                            }}
                          />

                        </div>
                      </div>
                    }
                    store={() => {
                      updateTask({ tags: tagsValue.map(t => t.id) });
                    }}
                  />
                </div>

                <div className="w-100">
                  <CellItem
                    isControl={state === 'create' ? true : false}
                    access="task.edit"
                    error={errors?.visibility}
                    showTpl={
                      <div className="row align-items-center no-gutters p-2 w-100">
                        <div className="col-4 text-truncate pr-0">
                          <span className="small">{`Visibilidad`}</span>
                        </div>
                        <div className="col-8 pl-2 small">
                          {getVisibilityView(task?.visibility)}
                        </div>
                      </div>
                    }
                    editTpl={
                      <div className="row align-items-center no-gutters px-2 w-100">
                        <div className="col-4  text-truncate pr-0">
                          <span className="small">{`Visibilidad`}</span>
                        </div>
                        <div className="col-8 pl-2">

                          <Controller
                            as={
                              <TextField
                                fullWidth
                                size="small"
                                type="text"
                                variant="outlined"
                                margin="none"
                                select
                                autoFocus={state === "create" ? false : true}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                onChange={ev => {
                                  setValue("visibility", ev.target.value)
                                }}
                              >
                                {visibilityValues.map(option => (
                                  <MenuItem key={option.value} value={option.value}>
                                    <IntlMessages id={option.label} />
                                  </MenuItem>
                                ))}
                              </TextField>
                            }
                            name="visibility"
                            control={control}
                            defaultValue={state === "create" ? 2 : task?.visibility}
                          />
                        </div>
                      </div>
                    }
                    store={() => {
                      const { visibility } = getValues()
                      updateTask({ visibility: visibility });
                    }}
                  />
                </div>
                <div className="w-100">
                  <CellItem
                    isControl={state === 'create' ? true : false}
                    access="task.edit"
                    showTpl={
                      <div className="row align-items-center no-gutters p-2 w-100">
                        <div className="col-4 text-truncate pr-0">
                          <span className="small">{`Participantes`}</span>
                        </div>
                        <div className="col-8 pl-2">
                          {getUsersView(task?.owner)}
                        </div>
                      </div>
                    }
                    editTpl={
                      <div className="row align-items-center no-gutters px-2 w-100">
                        <div className="col-4  text-truncate pr-0">
                          <span className="small">{`Participantes`}</span>
                        </div>
                        <div className="col-8 pl-2">
                          <SearchUsers
                            limitTags={1}
                            isMultiple={true}
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="none"
                            showLabel={false}
                            defaultValue={
                              state === "create"
                                ? [userDefault]
                                : task?.owner
                            }
                            onSelected={users => {
                              setOwners([...users]);
                            }}
                          />
                        </div>
                      </div>
                    }
                    store={() => {
                      const newOwner = owner.map(user => {
                        if (typeof user === "string") {
                          return user;
                        } else {
                          return user.id;
                        }
                      })
                      updateTask({ owner: newOwner });


                    }}
                  />
                </div>

                <div className="w-100 pb-3">


                  <CellItem
                    isControl={state === 'create' ? true : false}
                    showEditBtn={state === 'create' ? true : task?.notify_sent ? false : true}
                    access="task.edit"
                    showTpl={
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}>
                        <div className="row align-items-center no-gutters p-2 w-100">
                          <div className="col-4 text-truncate pr-0">
                            <span className="small">{`Recordatorio`}</span>
                          </div>
                          <div className="col-8 pl-2">
                            <span className="small">{!isNotified ? 'No definido' : 'Email'}</span>
                          </div>
                        </div>
                        {isNotified && (
                          <>
                            <div className="row align-items-center no-gutters p-2 w-100">
                              <div className="col-4 text-truncate pr-0">
                                <span className="small">{`Notificación`}</span>
                              </div>
                              <div className="col-8 pl-2">
                                <span className="small">
                                  {typeof task?.notify_before === "string" ? (
                                    <DisplayDate date={task?.notify_before} band={true} />
                                  ) : (
                                    <span>-</span>
                                  )}
                                </span>
                              </div>
                            </div>
                            {task?.notify_sent ? (
                              <div className="row align-items-center no-gutters p-2 w-100">
                                <div className="col-4 text-truncate pr-0">
                                  <span className="small">{``}</span>
                                </div>
                                <div className="col-8 pl-2">
                                  <span className="small">
                                    <span className="small"><i class="zmdi zmdi-check-circle text-success mr-1"></i>{`Notificación enviada`}</span>
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    }
                    editTpl={

                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                      }}>
                        <div className="row align-items-center no-gutters p-2 w-100">
                          <div className="col-4 text-truncate pr-0">
                            <span className="small">
                              {`Recordatorio`}
                            </span>
                          </div>
                          <div className="col-8 pl-2">
                            <FormGroup>
                              <FormControlLabel control={<Checkbox checked={isNotified} size="small" color="primary" onChange={(event) => setIsNotified(event.target.checked)} />} label={
                                <span className="small" style={{
                                  fontSize: 13
                                }}>
                                  Notificar por Email
                                </span>
                              } />
                            </FormGroup>


                          </div>
                        </div>
                        {isNotified && (
                          <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 10,
                            paddingBottom: '1.5rem'
                          }}>

                            <div className="row align-items-center no-gutters px-2 w-100">
                              <div className="col-4 text-truncate pr-0">
                                <span className="small">
                                  {`Fecha`}
                                </span>
                              </div>
                              <div className="col-8 pl-2">
                                <TextField
                                  type="date"
                                  size="small"
                                  variant="outlined"
                                  margin="none"
                                  name="notify"
                                  inputRef={register}
                                  inputProps={{
                                    min: getDateValue()
                                  }}
                                  onChange={ev => {
                                    setValue('notify', ev.target.value)
                                  }}
                                  fullWidth
                                  error={errors?.notify ? true : false}
                                  autoFocus={state === "create" ? false : true}
                                  defaultValue={state === "create" ? null : getDateValue(task?.notify)}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                              </div>
                            </div>
                            <div className="row align-items-center no-gutters px-2 w-100">
                              <div className="col-4 text-truncate pr-0">
                                <span className="small">
                                  {`Hora`}
                                </span>
                              </div>
                              <div className="col-8 pl-2">
                                <Autocomplete
                                  disableClearable
                                  options={hours.current}
                                  defaultValue={timeSelected}
                                  getOptionLabel={(option) => option}
                                  onChange={(_, value) => {
                                    if (value) {
                                      setTimeSelected(value)
                                    }
                                  }}
                                  renderInput={(params) =>
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      size="small"
                                      margin="none"
                                      placeholder=""
                                      defaultValue={timeSelected}
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    }
                    store={() => {
                      // TODO
                      try {

                        if (isNotified) {
                          const time = timeSelected ?? "08:00"
                          const { notify } = getValues()
                          const notifyDate = notify ? getDateValue(notify) : getDateValue()
                          updateTask({ notify_before: `${notifyDate}T${time}:00` });
                        } else {
                          updateTask({ notify_before: `` });
                        }

                      } catch (error) {

                      }
                    }}
                  />
                </div>
              </div>
            </CustomScrollbars>
          </div>
        </div >
        {state === "create" && (
          <div>
            <div className="py-2" style={{
              width: '100%',
              textAlign: 'right'
            }}>
              <Button
                className="mr-2"
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !isValid
                }
                disableElevation
              >
                <IntlMessages id="create" />
              </Button>
              <Button
                type="button"
                color="error"
                disabled={loader}
                onClick={() => {
                  props.onClose();
                }}
              >
                <IntlMessages id="cancel" />
              </Button>
              <span className="ml-2">
                {loader && <CircularProgress size={20} />}
              </span>
            </div>
          </div>
        )
        }

      </div >
    )
  }

  const isValid = React.useMemo(() => {

    if (loader) {
      return false
    }

    if (!owner.length) {
      return false
    }

    if (!owner.length) {
      return false
    }

    if (realted === "contact" && !contact) {
      return false
    }

    if (realted === "deal" && !contact && !modelAlt) {
      return false
    }

    if (realted === "lead" && !modelAlt) {
      return false
    }

    return true;

  }, [formState, state, realted, modelAlt, loader])

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={visible}
        PaperProps={{
          style: {
            position: 'relative',
            maxHeight: "85vh", // Esto establece la altura máxima al 80% del viewport height
            overflowY: "auto", // Añade una barra de desplazamiento vertical si el contenido es más largo que la altura máxima
            maxWidth: 820
          }
        }}
      >
        <div className="py-2 px-4 d-flex align-items-center justify-content-between">
          <div>
          </div>
          <div className="d-flex align-items-center">

            {state !== "create" && (
              <div className="d-flex align-items-center mx-2" style={{
                gap: 4
              }}>

                {!loader && (
                  <>
                    {(!task?.complete) && (
                      <>
                        <Acl
                          access="task.edit"
                          component={
                            <Tooltip title="Completar tarea">
                              <IconButton
                                style={{
                                  width: "1.75rem",
                                  height: "1.75rem",
                                  fontSize: "1.3rem"
                                }}
                                onClick={ev => {
                                  updateTask({ complete: true });
                                }}
                              >
                                <i class="zmdi zmdi-check-circle text-success"></i>

                              </IconButton>
                            </Tooltip>
                          } />
                      </>
                    )}

                    <Acl
                      access="task.delete"
                      component={
                        <Tooltip title="Eliminar tarea">
                          <IconButton
                            style={{
                              width: "1.75rem",
                              height: "1.75rem",
                              fontSize: "1.3rem"
                            }}
                            onClick={ev => {
                              setConfirm(true)
                            }}
                          >
                            <i class="zmdi zmdi-delete text-gray"></i>

                          </IconButton>
                        </Tooltip>
                      } />

                  </>
                )}


              </div>
            )}
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => {
                props.onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>


        <DialogContent>
          <div className="position-relative">
            <form
              onSubmit={handleSubmit(onSubmit)}>
              {viewControls()}
            </form>

            {loader && (
              <div
                className="loader-view position-absolute"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  top: 0,
                  height: '100%',
                  background: '#fefefe85',
                  zIndex: 9999
                }}
              >
                <div className="text-center">
                  <div><CircularProgress /></div>
                  <p className="text-center mt-2 small">Por favor, espere</p>
                </div>
              </div>
            )}
          </div>
        </DialogContent>


        <Menu
          id="long-menu"
          anchorEl={menu.anchorEl}
          open={menu.menuState}
          onClose={() => {
            setMenu({ ...menu, menuState: false });
          }}
          MenuListProps={{
            style: {
              width: 150
            }
          }}
        >
          <Acl
            access="task.edit"
            component={
              <MenuItem
                onClick={() => {
                  setMenu({ ...menu, menuState: false });
                  props.onSet({ state: "edit", id: task.id });
                }}
              >
                <IntlMessages id={"edit"} />
              </MenuItem>
            }
          />
          <Acl
            access="task.edit"
            component={
              <MenuItem
                onClick={() => {
                  setMenu({ ...menu, menuState: false });
                  setConfirm(true);
                }}
              >
                <IntlMessages id={"delete"} />
              </MenuItem>
            }
          />
          <Acl
            access="task.create"
            component={
              <>
                <Divider />
                <MenuItem
                  onClick={() => {
                    setMenu({ ...menu, menuState: false });
                    props.onSet({ state: "create" });
                  }}
                >
                  <IntlMessages id={"task.new"} />
                </MenuItem>
              </>
            }
          />
        </Menu>
      </Dialog>

      {confirm && (
        <SweetAlert
          show={confirm}
          warning
          showCancel
          confirmBtnText={<IntlMessages id="yes" />}
          cancelBtnText={<IntlMessages id="cancel" />}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
          title={<IntlMessages id="sweetAlerts.areYouSure" />}
          onConfirm={() => {
            setConfirm(false);
            deleteTask();
            setMenu({ ...menu, menuState: false });
          }}
          onCancel={() => {
            setConfirm(false);
            setMenu({ ...menu, menuState: false });
          }}
        ></SweetAlert>
      )}
    </>
  );
}

const mapStateToProps = ({ dynamicList, auth, user, settings }) => {
  const { users } = user;
  const { locale } = settings;
  const { items } = dynamicList;
  const { userID, token, clientID } = auth;
  return {
    token,
    clientID,
    locale,
    statusList: items.filter(
      el => el.module === "task" && el.field === "status_id" && el.status
    ),
    userID,
    users,
    tags: items.filter(el => el.module === "task" && el.field === "tags")
  };
};

export default connect(mapStateToProps, {
  showMessage
})(ModalTask);
