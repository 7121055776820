import {
  FETCH_CHAT_DEVICES,
  UPDATE_CHAT_OPTION,
  ADD_CHAT_MSG,
  ON_SELECT_CONTACT_CHAT,
  DELETE_ALL_CHAT_MSG,
  ON_CONTACT_NOTIFY_CHAT,
  ON_MSG_NOTIFY_CHAT
} from "../constants/ActionTypes";

const INIT_STATE = {
  devices: [],
  allMsg: [],
  options: [
    {
      id: 0,
      intl: "myContacts"
    },
    {
      id: 1,
      intl: "myDeriveds"
    },
    {
      id: 2,
      intl: "floatCOntacts"
    },
    {
      id: 3,
      intl: "todayMsg"
    },
    {
      id: 4,
      intl: "unread"
    },
    {
      id: 5,
      intl: "allMessages"
    },
  ],
  contactSelected: null,
  chatContactSelected: null,
  optionSelected: 5,
  msgNotify: null,
  contactNotify: null,
  showSidebarW: false,
  hasBtnAgendar: false,
  query: {},
  searchInput: "",
  showChat: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CHAT_DEVICES: {
      return { ...state, devices: action.payload };
    }
    case UPDATE_CHAT_OPTION: {
      return { ...state, optionSelected: action.payload };
    }
    case ADD_CHAT_MSG: {
      return {
        ...state,
        allMsg: [action.payload, ...state.allMsg]
      };
    }
    case DELETE_ALL_CHAT_MSG: {
      return {
        ...state,
        allMsg: []
      };
    }
    case ON_SELECT_CONTACT_CHAT: {
      return {
        ...state,
        contactSelected: action.payload
      };
    }
    case ON_CONTACT_NOTIFY_CHAT: {
      return {
        ...state,
        contactNotify: action.payload
      };
    }
    case ON_MSG_NOTIFY_CHAT: {
      return {
        ...state,
        msgNotify: action.payload
      };
    }
    case "SHOW_SIDEBAR_W": {
      return {
        ...state,
        showSidebarW: action.payload
      };
    }
    case "SHOW_BTN_AGENDAR": {
      return {
        ...state,
        hasBtnAgendar: action.payload
      };
    }
    case "SET_QUERY": {
      return {
        ...state,
        query: action.payload
      };
    }
    case "SET_SEARCH_INPUT": {
      return {
        ...state,
        searchInput: action.payload
      };
    }
    case "CHAT_CONTACT_SELECTED": {
      return {
        ...state,
        chatContactSelected: action.payload
      };
    }
    case "onChatToggle": {
      return {
        ...state,
        showChat: action.payload
      };
    }
    default:
      return state;
  }
};
