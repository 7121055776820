import React from "react";
import { connect } from "react-redux";
import {
  Avatar,
  CircularProgress,
  TextField,
  IconButton,
  TablePagination,
  InputAdornment
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

// Services
import { useNotes, useNote } from "services/hook/note.service";

// Actions
import { showMessage } from "actions/Common";

// Helpers
import { makeStyles } from "@material-ui/core/styles";
import IntlMessages from "util/IntlMessages";
import DisplayDate from "util/DisplayDate";

// Color
import { indigo } from "@material-ui/core/colors";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";

// Styles

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  indigo: {
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500]
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: theme.palette.getContrastText(indigo[500]),
    backgroundColor: indigo[500],
    fontSize: "13px",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2)
  }
}));

const Comment = props => {
  const { data, author, userAuthID, auth, size, module } = props;
  const classes = useStyles();

  const { deleteNote, updateNote, loader, statusCode } = useNote({
    id: data.id,
    clientID: auth.clientID,
    token: auth.token,
    locale: auth.locale
  });

  const [edit, setEdit] = React.useState(false);
  const [msgValue, setMsgValue] = React.useState("");

  React.useEffect(() => {
    const { code, msg, data, action } = statusCode;
    if (code) {
      if (code === 200) {
        setEdit(false);
        setMsgValue("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCode]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: `1px solid #eee`,
          gap: 8
        }}
      >
        <Avatar
          style={{ width: "2rem", height: "2rem" }}
        >
          {author && `${author?.name[0]}`}
        </Avatar>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {author && `${author?.name}`}
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
              <DisplayDate date={data.create_date} band={true} />
            </span>
          </div>
          <p style={{ marginLeft: 5, whiteSpace: "pre-line" }}>
            {data?.description}
          </p>
        </div>
      </div>
    </>
    //  <div
    //    className="model-item"
    //    style={{ minHeight: "50px", opacity: loader ? 0.6 : 1 }}
    //  >
    //    {size === "default" && (
    //      <div className="col-auto px-1">
    //        <div>
    //          <Avatar className={classes.avatar}>
    //            {author && `${author?.name[0]}`}
    //          </Avatar>
    //        </div>
    //      </div>
    //    )}
    //    <div
    //      className={`col con-inf-mw-100 ${
    //        size === "small" ? "text-small small" : ""
    //      }`}
    //    >
    //      <div>
    //        {size === "default" ? (
    //          <div className="d-flex align-tems-center ">
    //            <span>
    //              {module === "contact" && (
    //                <>
    //                  {data.module === "deal" && (
    //                    <span className="d-flex align-tems-center mr-3 jr-fs-sm">
    //                      <span className="mr-1">
    //                        <IntlMessages id="deal" />:
    //                      </span>
    //                      <NavLink
    //                        className="text-truncate"
    //                        style={{ width: 110 }}
    //                        to={`/app/deals/detail/${data?.reference_id}`}
    //                      >
    //                        {`${data.reference[0].name}`}
    //                      </NavLink>
    //                    </span>
    //                  )}
    //                </>
    //              )}

    //              {module === "deal" && (
    //                <>
    //                  {data.module === "contact" && (
    //                    <span className="d-flex align-tems-center mr-3 jr-fs-sm">
    //                      <span className="mr-1">
    //                        <IntlMessages id="contact" />:
    //                      </span>
    //                      <NavLink
    //                        className="text-truncate"
    //                        style={{ minWidth: 110 }}
    //                        to={`/app/contacts/detail/${data?.contact_id}`}
    //                      >
    //                        {`${data.contact[0]?.info.first_name} ${data.contact[0]?.info.last_name}`}
    //                      </NavLink>
    //                    </span>
    //                  )}
    //                </>
    //              )}
    //            </span>
    //            <span className="d-flex align-items-center mr-4">
    //              <i class="zmdi zmdi-time text-primary mr-1"></i>
    //              <span className="font-weight-normal">
    //                <DisplayDate date={data.create_date} band={true} />
    //              </span>
    //              <span className="mx-1 text-muted small">-</span>
    //              <span className="small">
    //                <span>
    //                  {`por `}
    //                  <b>{`${author?.name}`}</b>
    //                </span>
    //                <span className="text-muted mx-1">{`<${author?.email}>`}</span>
    //              </span>
    //            </span>
    //          </div>
    //        ) : (
    //          <div className="">
    //            <div className="">
    //              {module === "contact" && (
    //                <>
    //                  {data.module === "deal" && (
    //                    <span className="d-flex align-tems-center mr-3 jr-fs-sm">
    //                      <span className="mr-1">
    //                        <IntlMessages id="deal" />:
    //                      </span>
    //                      <NavLink
    //                        className="text-truncate"
    //                        style={{ width: 110 }}
    //                        to={`/app/deals/detail/${data?.reference_id}`}
    //                      >
    //                        {`${data.reference[0].name}`}
    //                      </NavLink>
    //                    </span>
    //                  )}
    //                </>
    //              )}

    //              {module === "deal" && (
    //                <>
    //                  {data.module === "contact" && (
    //                    <span className="d-flex align-tems-center mr-3 jr-fs-sm">
    //                      <span className="mr-1">
    //                        <IntlMessages id="contact" />:
    //                      </span>
    //                      <NavLink
    //                        className="text-truncate"
    //                        style={{ minWidth: 110 }}
    //                        to={`/app/contacts/detail/${data?.contact_id}`}
    //                      >
    //                        {`${data.contact[0]?.info.first_name} ${data.contact[0]?.info.last_name}`}
    //                      </NavLink>
    //                    </span>
    //                  )}
    //                </>
    //              )}
    //            </div>
    //            <div className="d-flex align-items-center">
    //              <i class="zmdi zmdi-time text-primary mr-1"></i>
    //              <span className="font-weight-normal">
    //                <DisplayDate date={data.create_date} band={true} />
    //              </span>
    //            </div>
    //            <div className="d-flex align-items-center">
    //              <span>
    //                {`por `}
    //                <b>{`${author?.name}`}</b>
    //              </span>
    //            </div>
    //          </div>
    //        )}
    //        <div className="mt-1">
    //          <p className="mb-0">
    //            {!edit ? (
    //              data?.description
    //            ) : (
    //              <TextField
    //                value={msgValue}
    //                multiline
    //                fullWidth
    //                className="jr-wall-textarea"
    //                margin="none"
    //                variant="outlined"
    //                rows="4"
    //                autoFocus
    //                disabled={loader}
    //                onChange={ev => setMsgValue(ev.target.value)}
    //                InputProps={{
    //                  endAdornment: (
    //                    <InputAdornment position="end">
    //                      {size === "small" && (
    //                        <>
    //                          <IconButton
    //                            aria-label="cancel"
    //                            className={classes.margin}
    //                            size="small"
    //                            disabled={loader}
    //                            onClick={() => setEdit(false)}
    //                          >
    //                            <CloseIcon />
    //                          </IconButton>
    //                          <IconButton
    //                            aria-label="check"
    //                            color="primary"
    //                            className={classes.margin}
    //                            size="small"
    //                            disabled={
    //                              data?.description === msgValue || loader
    //                            }
    //                            onClick={() => {
    //                              updateNote({
    //                                ...data,
    //                                description: msgValue
    //                              });
    //                            }}
    //                          >
    //                            <CheckIcon />
    //                          </IconButton>
    //                        </>
    //                      )}
    //                    </InputAdornment>
    //                  )
    //                }}
    //              />
    //            )}
    //          </p>
    //        </div>
    //      </div>
    //    </div>
    //    {author.id === userAuthID && (
    //      <div className="col-auto px-1 actions">
    //        {!edit ? (
    //          <>
    //            <div className="col-12">
    //              <IconButton
    //                aria-label="edit"
    //                className={classes.margin}
    //                size="small"
    //                disabled={loader}
    //                onClick={() => {
    //                  setMsgValue(data.description);
    //                  setEdit(true);
    //                }}
    //              >
    //                <EditIcon />
    //              </IconButton>
    //              <IconButton
    //                aria-label="delete"
    //                className={classes.margin}
    //                size="small"
    //                disabled={loader}
    //                onClick={() => {
    //                  deleteNote(data);
    //                }}
    //              >
    //                <DeleteOutlineRoundedIcon />
    //              </IconButton>
    //            </div>
    //          </>
    //        ) : (
    //          <>
    //            {size !== "small" && (
    //              <div className="col-12">
    //                <IconButton
    //                  aria-label="cancel"
    //                  className={classes.margin}
    //                  size="small"
    //                  disabled={loader}
    //                  onClick={() => setEdit(false)}
    //                >
    //                  <CloseIcon />
    //                </IconButton>
    //                <IconButton
    //                  aria-label="check"
    //                  color="primary"
    //                  className={classes.margin}
    //                  size="small"
    //                  disabled={data?.description === msgValue || loader}
    //                  onClick={() => {
    //                    updateNote({
    //                      ...data,
    //                      description: msgValue
    //                    });
    //                  }}
    //                >
    //                  <CheckIcon />
    //                </IconButton>
    //              </div>
    //            )}
    //          </>
    //        )}
    //      </div>
    //    )}
    //  </div>
  );
};

const mapStateToProps = ({ settings, auth, user, notification }) => {
  const { width, locale } = settings;
  const { users } = user;
  const { authUser, token, userID, clientID } = auth;
  return {
    width,
    locale,
    authUser,
    userID,
    clientID,
    token,
    users,
    notification: notification.notification
  };
};

export default connect(mapStateToProps, {
  showMessage
})(Comment);
