import React from 'react';
// import Joyride from 'react-joyride';

class Tour extends React.PureComponent {

  render() {
    const { joyride } = this.props;
    return (<></>)

  }
}

export default Tour;

