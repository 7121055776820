import axios from "util/Api";
import { base, baseURLajaxPrefix } from "util/Api";
import { ajax } from "rxjs/ajax";

class DealService {
  constructor() {}

  async index(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/deal/search`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async detail(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/deal/search/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }

  async store(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/deal/create`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async storeAll(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/imports/deal`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async update(id, data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/deal/updateone/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  async updateMany(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/deal/updatemany`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  async diffusionMessage(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/diffusionmsg/import`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  async delete(id, locale, token) {
    return axios.delete(`${baseURLajaxPrefix}/${locale}/deal/deleteone/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }
  async deleteMany(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/deal/deletemany`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  // async index(params, locale, token) {
  //   return axios.post(
  //     `${baseURLajaxPrefix}/${locale}/deal/list`,
  //     {
  //       ...params
  //     },
  //     {
  //       headers: {
  //         Authorization: token
  //       }
  //     }
  //   );
  // }

  // async show(id, locale, token) {
  //   return axios.post(`${baseURLajaxPrefix}/${locale}/deal/list/${id}`, {
  //     headers: {
  //       Authorization: token
  //     }
  //   });
  // }

  async search(params, locale, token, query = {}) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/deal/list`,
      {
        ...params,
        ...query
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  search$(params, locale, token, query = {}) {
    return ajax({
      url: `${base}/${baseURLajaxPrefix}/${locale}/deal/list`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: {
        ...params,
        ...query
      }
    });
  }

  // async fields(locale, token) {
  //   return axios.get(`${baseURLajaxPrefix}/${locale}/listdinamic/fields`, {
  //     headers: {
  //       Authorization: token
  //     }
  //   });
  // }
}

export default DealService;
