import {
  FETCH_ALL_USERS,
  UPDATE_USER
} from "../constants/ActionTypes";

export const fetchUsers = (data) => {
  return {
    type: FETCH_ALL_USERS,
    payload: data
  };
};

export const updateUser = (id, data) => {
  return {
    type: UPDATE_USER,
    payload: {id: id, data: data}
  };
};
