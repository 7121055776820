import React from "react";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import TasksButton from "components/sofia/elements/tasksButton";

const Footer = props => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Sofia Bot &copy; 2021</span>
      {/* <Button
          href="https://codecanyon.net/cart/configure_before_adding/20978545?license=regular&ref=phpbits&size=source&support=bundle_12month&_ga=2.172338659.1340179557.1515677375-467259501.1481606413"
          target="_blank"
          size="small"
          color="primary"
        ><IntlMessages id="eCommerce.buyNow"/></Button> */}
      {/*props.history.location.pathname != "/app/dashboard/main" && (
        <>{props.client?.subscribe_id.length > 0 && <TasksButton isGlobal />}</>
      ) */}
    </footer>
  );
};
export default Footer;
