const INIT_STATE = {
  notification: {
    action: null,
    data: null,
    code: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "NOTIFICATION": {
      return { ...state, notification: action.payload };
    }
    default:
      return state;
  }
};
