import React from 'react';
import { Box, Card } from '@mui/material'
import Conversations from "./ui/Conversations"
import HeaderConversation from './ui/Header';
import Conversation from './ui/Conversation';

export default function Chat({ oneConversation = false }) {
  return (
    <Card sx={{ position: 'relative', width: 325 }}>
      <HeaderConversation />
      <Conversations />
      <Conversation oneConversation={oneConversation} />
    </Card>
  )
}