import React, { useEffect } from "react";
import { NotificationManager } from "react-notifications";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import debounce from "lodash.debounce";

// actions
import { onContactSelected } from "actions/Chat";

const audio = new Audio('https://s3.us-east-2.wasabisys.com/assets-sofiabot/audio/f37bd2f66651e7d46f6d38440f2bc5dd.mp3');

const AlertChat = props => {

  const { isWidget, module, userID, authUser, allMsg, allDevice, contactSelected, onContactSelected } = props;
  const history = useHistory()


  const playSound = () => {
    try {
      const candSound = document.querySelector('.notification-container-empty')
      if (candSound) {
        audio.play();
        if (window && window.parent && isWidget) {
          window.parent.postMessage(JSON.stringify({ action: 'play-audio' }), "*")
        }
      }
    } catch (error) {
      console.error("Error al reproducir el audio:", error);
    }
  };

  const onNotify = () => {

    if (isWidget) {
      document.querySelector('.notification-container').style.zoom = 0.8
    }

    try {
      const model = { ...allMsg[0] };
      const contact = model.contact;
      const { message, derivedto } = contact;

      let color = "#fff";
      const deviceID = contact.message.info.device_id;
      const device = allDevice.find(d => d.id === deviceID);

      if (!device) {
        return null
      }

      if (device !== undefined) {
        color = device.background_color;
      }

      if (message?.info?.status === "recive") {

        const status = message.status;

        const { push_notification, notifications, sound_notification } = authUser;

        const canSound = sound_notification.find(m => module === m) ? true : false;
        const canNotify = push_notification.find(m => module === m) ? true : false;

        if (!canNotify) {
          return null;
        }

        if (!notifications.length) {
          return null;
        } else {

          const isChatAssigned = notifications.find(key => key === "chat_assigned");
          const isChatAssignedOtthers = notifications.find(key => key === "chat_assigned_others");
          const isChatUnassignedOwner = notifications.find(key => key === "chat_unassigned_owner");
          const isChatUnassignedUnowner = notifications.find(key => key === "chat_unassigned_unowner");
          const isChatUnassignedOwnerOthers = notifications.find(key => key === "chat_unassigned_owner_others");

          const owners = contact.owner;
          const isOwner = owners.find((id) => id === authUser.id)

          // "chat_assigned": "Chat asignados a mi", 
          // "chat_assigned_others":"Chat asignados a otros",  
          // "chat_unassigned_owner": "Chat no asignados (Soy propietario)", 
          // "chat_unassigned_unowner": "Chat no asignados (Contacto sin propietario)", 
          // "chat_unassigned_owner_others" : "Chat no asignado (Otros son propietarios del contacto)"


          if (derivedto.length > 0) {

            if (derivedto === authUser.id && !isChatAssigned) {
              return null;
            }

            if (derivedto !== authUser.id && !isChatAssignedOtthers) {
              return null;
            }

          } else {

            if (owners.length > 0) {

              if (isOwner && !isChatUnassignedOwner) {
                return null
              }

              if (!isOwner && !isChatUnassignedUnowner) {
                return null
              }

            } else {

              if (!isChatUnassignedOwnerOthers) {
                return null
              }

            }

          }
        }

        if (status !== "error") {
          NotificationManager.success(
            <>
              <div style={{ position: "absolute", left: 12, top: 17 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  style={{ height: 35, width: 35 }}
                >
                  <g id="message" transform="translate(1971 -2018)">
                    <circle
                      id="ellipse"
                      cx="10"
                      cy="10"
                      r="10"
                      transform="translate(-1971 2018)"
                      fill="#fff"
                    />
                    <path
                      fill={`${color}`}
                      id="icon"
                      d="M11.855,2H3.095a1.094,1.094,0,0,0-1.09,1.095L2,12.95l2.19-2.19h7.665A1.1,1.1,0,0,0,12.95,9.665V3.095A1.1,1.1,0,0,0,11.855,2ZM4.19,5.832h6.57V6.927H4.19ZM8.57,8.57H4.19V7.475H8.57Zm2.19-3.285H4.19V4.19h6.57Z"
                      transform="translate(-1968 2021)"
                    />
                  </g>
                </svg>
              </div>
              <span>
                {message?.info.type_msg === "chat" ? (
                  message?.info.body.substring(0, 25) + "..."
                ) : (
                  <IntlMessages id={message?.info.type_msg} />
                )}
              </span>
            </>,
            `${contact.info.first_name} ${contact.info.last_name}`,
            5000,
            () => {
              onContactSelected({ ...contact, device: device });
              if (module !== 'chat') {
                history.push('/app/chat')
              }
            }
          );

          if (canSound) {
            playSound();
          }

        } else {
          NotificationManager.error(
            <span>
              {message?.info.type_msg === "chat" ? (
                message?.info.body.substring(0, 25) + "..."
              ) : (
                <IntlMessages id={message?.info.type_msg} />
              )}
            </span>,
            `${contact.info.first_name} ${contact.info.last_name}`
          );
        }

      }
    } catch (error) {

    }
  }

  React.useEffect(() => {
    if (allMsg.length > 0) {
      onNotify();
    }
  }, [allMsg]);

  return null;
};

const mapStateToProps = ({ auth, chat, devices }) => {

  const { authUser, userID, module } = auth;
  const { allMsg, contactSelected } = chat;
  const { allDevice } = devices;

  return {
    module,
    userID,
    authUser,
    allMsg,
    contactSelected,
    allDevice
  };

};

export default connect(mapStateToProps, { onContactSelected })(AlertChat);

