import React from "react";

// utils
import { socket } from "util/Socket";

// services
import UserService from "../ajax/user.service";

export const useUsers = ({ token, locale, queryGlobal }) => {
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });
  const [users, setUsers] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const rowsPerPage = 20;

  const index = (page, filters, isLoader = true) => {
    if (isLoader) {
      setLoader(true);
    }
    new UserService()
      .index(
        {
          // offset: rowsPerPage,
          // page: page + 1,
          ...filters,
          ...queryGlobal
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setUsers([...data.data]);
          setTotal(data.pagination);
          setPage(page+1);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const setData = newArray => {
    setUsers([...newArray]);
  };

  return {
    // const
    users,
    rowsPerPage,
    total,
    page,
    statusCode,
    loader,
    // methods
    index,
    setData
  };
};

export const useUser = ({ id, locale, token, clientID }) => {
  const [user, setUser] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });

  const getUser = () => {
    setLoader(true);
    new UserService()
      .detail(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setUser(data.data[0]);
          setStatusCode({
            code: 200,
            action: "detail",
            data: data.data[0],
            msg: data.info
          });
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const storeUser = data => {
    setLoader(true);
    new UserService()
      .store(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "user",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "userList",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const storeManyUser = data => {
    setLoader(true);
    new UserService()
      .storeMany(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "user",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "userList",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const updateUser = user => {
    setLoader(true);
    new UserService()
      .update(user.id, user, locale, token)
      .then(({ data }) => {

        if (data.status_codes === 200) {
          setUser(data.data[0]);
          setStatusCode({
            code: 200,
            action: "update",
            data: data.data[0],
            msg: data.info
          });

          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "user",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);

          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "userList",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const deleteUser = data => {
    setLoader(true);
    new UserService()
      .delete(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "delete",
            data: null,
            msg: data.info
          });
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "user",
              action: "delete",
              data: data.data[0],
              msg: data.info,
              ref: id
            }
          ]);
          socket.emit("notification", [
            `client${clientID}`,
            {
              module: "userList",
              action: "update",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const updatePasswordUser = newValue => {
    setLoader(true);
    new UserService()
      .updatePassword(id, newValue, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "update",
            data: null,
            msg: data.info
          });
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  return {
    // const
    user,
    loader,
    statusCode,
    // methods
    getUser,
    storeUser,
    storeManyUser,
    updateUser,
    deleteUser,
    updatePasswordUser
  };
};
