import React from "react";

// utils
import { socket } from "util/Socket";

// services
import NewsService from "../ajax/news.service";

export const useNewss = ({ token, locale, queryGlobal }) => {
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });
  const [newss, setNewss] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const rowsPerPage = 20;

  const index = (page, filters, isLoader = true) => {
    if (isLoader) {
      setLoader(true);
    }
    new NewsService()
      .index(
        {
          offset: rowsPerPage,
          page: page + 1,
          ...filters,
          ...queryGlobal
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setNewss([...data.data]);
          setTotal(data.pagination);
          setPage(page + 1);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const setData = newArray => {
    setNewss([...newArray]);
  };

  return {
    // const
    newss,
    rowsPerPage,
    total,
    page,
    statusCode,
    loader,
    // methods
    index,
    setData
  };
};

export const useNews = ({ id, locale, token, clientID }) => {
  const [news, setNews] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });

  const getNews = () => {
    setLoader(true);
    new NewsService()
      .detail(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setNews(data.data[0]);
          setStatusCode({
            code: 200,
            action: "detail",
            data: data.data[0],
            msg: data.info
          });
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const storeNews = data => {
    setLoader(true);
    new NewsService()
      .store(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("template", [
            `client${clientID}`,
            {
              module: "template",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const updateNews = data => {
    setLoader(true);
    new NewsService()
      .update(id, data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setNews(data.data[0]);
          setStatusCode({
            code: 200,
            action: "update",
            data: data.data[0],
            msg: data.info
          });

          socket.emit("template", [
            `client${clientID}`,
            {
              module: "template",
              action: "update",
              data: data.data[0],
              msg: data.info,
              ref: id
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const deleteNews = data => {
    setLoader(true);
    new NewsService()
      .delete(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "delete",
            data: null,
            msg: data.info
          });
          socket.emit("template", [
            `client${clientID}`,
            {
              module: "template",
              action: "delete",
              data: data.data[0],
              msg: data.info,
              ref: id
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  return {
    // const
    news,
    loader,
    statusCode,
    // methods
    getNews,
    storeNews,
    updateNews,
    deleteNews
  };
};
