import React from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import IntlMessages from "util/IntlMessages";

export default function CircularProgressContent(props) {

    const { show } = props
    
    return (
        <>
            {show ? (
                <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 2,
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    bottom: 0,
                    left: '50%',
                    transform: `translateX(-50%)`,
                    zIndex: 10
                }}>
                    <CircularProgress style={{ position: 'relative' }} />
                    <Typography variant="body2" style={{ position: 'relative' }}>
                        <IntlMessages id="loading" />
                    </Typography>
                </Box>
            ) : null}
        </>
    )
}