import React from "react";
import { Redirect, Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";

function IsDiffUserRoute({
  component: C,
  appProps,
  client,
  authUser,
  ...rest
}) {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useState(() => {
    const { access } = appProps;
    if (access !== undefined) {
      if (authUser.account !== "user") {
        const band = client.access.find(a => a === access);
        if (typeof band === "undefined") {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      } else {
        setIsVisible(false);
      }
    } else {
      setIsVisible(true);
    }
  }, [appProps, client, authUser]);

  return (
    <Route
      {...rest}
      render={props =>
        appProps.account !== "user" && isVisible ? (
          <C {...props} {...appProps} />
        ) : (
          <Redirect to={`/app/401`} />
        )
      }
    />
  );
}

const mapStateToProps = ({ auth }) => {
  const { client, authUser } = auth;
  return {
    client,
    authUser
  };
};

export default connect(mapStateToProps, {})(IsDiffUserRoute);
