import {
  FETCH_ALL_VIEWS,
  UPDATE_VIEW,
  ADD_VIEW,
  DELETE_VIEW
} from "../constants/ActionTypes";

export const fetchAllViews = data => {
  return {
    type: FETCH_ALL_VIEWS,
    payload: data
  };
};

export const fetchPopoverNewsLetter = data => {
  return {
    type: 'FETCH_POPOVER_NEWSLETTER',
    payload: data
  };
};


export const updateView = (id, data) => {
  return {
    type: UPDATE_VIEW,
    payload: { id: id, data: data }
  };
};

export const addView = data => {
  return {
    type: ADD_VIEW,
    payload: data
  };
};

export const deleteView = (id, data) => {
  return {
    type: DELETE_VIEW,
    payload: { id: id, data: data }
  };
};
