import {
  FETCH_ALL_PIPELINE,
  ADD_PIPELINE,
  UPDATE_PIPELINE,
  DELETE_PIPELINE
} from "../constants/ActionTypes";

const INIT_STATE = {
  items: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PIPELINE: {
      return { ...state, items: [...action.payload] };
    }
    case ADD_PIPELINE: {

      const arr = state.items;
      const { id } = action.payload;
      const findIndex = arr.findIndex(el => el.id === id);
      if (findIndex <= -1) {
        return { ...state, items: [action.payload, ...state.items] };
      }

      return state;
    }
    case UPDATE_PIPELINE: {
      const arr = state.items;
      const { id, data } = action.payload;
      const findIndex = arr.findIndex(el => el.id === id);
      if (findIndex >= 0) {
        arr.splice(findIndex, 1, data);
        return { ...state, items: [...arr] };
      }
      return state;
    }
    case DELETE_PIPELINE: {
      const id = action.payload;
      const arr = state.items.filter(el => el.id !== id);
      return { ...state, items: [...arr] };
    }
    default:
      return state;
  }
};
