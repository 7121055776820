import io from "socket.io-client";
import { baseSocketURL } from "./Api";

export let socket = null;

export const InitSocket = () => {
  socket = io(`${baseSocketURL}`, { transports: ['websocket'] });
};

export const DestroySocket = () => {
  socket.disconnect();
  socket = null;
};
