import axios from 'util/Api';
import { baseURLajaxPrefix } from 'util/Api';

class DynamicListService {

  constructor() {
  }

  async fields(locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/listdinamic/fields`, {
      headers: {
        'Authorization': token
      }
    });
  }

  async children(locale, token, client_id) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/listdinamic/search`, { client_id: client_id }, {
      headers: {
        'Authorization': token
      }
    });
  }

  async update(id, params, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/listdinamic/updateone/${id}`, {
      ...params
    }, {
      headers: {
        'Authorization': token
      }
    });
  }

  async store(params, locale, token) {
    return axios.post(`${baseURLajaxPrefix}/${locale}/listdinamic/create`, {
      ...params
    }, {
      headers: {
        'Authorization': token
      }
    });
  }

  async delete(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/listdinamic/deleteone/${id}`, {
      headers: {
        'Authorization': token
      }
    });
  }

}

export default DynamicListService;
