import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CircularProgress, Button } from "@material-ui/core";

// Components
import Header from "components/Header/index";
import Sidebar from "containers/SideNav/index";
import Footer from "components/Footer";
import Tour from "../components/Tour/index";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION
} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import ModalPlanExpiration from "../components/sofia/modals/expiration";
import ModalNotify from "../components/sofia/modals/notify";

// Helpers
import asyncComponent from "../util/asyncComponent";
import TopNav from "components/TopNav";
import langs from "components/LanguageSwitcher/data";

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

// Action
import {
  fetchListDynamicItems,
  addItemListDynamic,
  updateItemListDynamic,
  deleteItemListDynamic
} from "actions/DynamicList";
import { switchLanguage } from "actions/Setting";

import { fetchAllViews, updateView, addView, deleteView, fetchPopoverNewsLetter } from "actions/View";
import { onNotification } from "actions/Notification";
import { fetchUser, fetchClient } from "actions/Auth";
import { fetchUsers, updateUser } from "actions/User";
import { fetchDevices, updateDevice, fetchSetting } from "actions/Device";
import { showMessage } from "actions/Common";
import { addMsg, onMsgNotify } from "actions/Chat";
import {
  fetchPipeline,
  addPipeline,
  updatePipeline,
  deletePipeline
} from "actions/Pipeline";

// Services
import UserService from "services/ajax/user.service";

import { socket, InitSocket } from "util/Socket";
import IntlMessages from "util/IntlMessages";

// Components
import InfoView from "components/InfoView";
import SweetAlert from "react-bootstrap-sweetalert";
import ChatButton from "components/sofia/ChatWidget/ChatButton";

// Guards
import IsUserMasterRoute from "../guards/isUserMaster";
import IsDiffUserRoute from "../guards/isDiffUser";
import IsUserAccessRoute from "../guards/isUserAccess";
import { setSettingViews, setSettingGraphics } from "../actions/Setting";
import NewsLetterBox from "../components/sofia/NewsLetterBox";

class App extends React.Component {
  state = {
    loader: true,
    alertSuccess: {
      visible: false,
      info: ""
    },
    alertError: {
      visible: false,
      info: ""
    },
    modalNotify: {
      visible: false,
      tpl: ""
    },
    modalExpiration: false,
    list_newsletter: []
  };

  componentDidMount() {
    const { clientID, userID, token, locale } = this.props;
    this.setState({ ...this.state, loader: true });

    InitSocket();

    new UserService().init(clientID, locale.locale, token).then(({ data }) => {
      const account = data.data[0].account;

      // account.user["custom_views"] = [{
      //   "module": "contact",
      //   "filters": [{
      //     "starred": false,
      //     "name": "",
      //     "search": {}
      //   }],
      //   "views": {
      //     "list": {
      //       "columns": [],
      //       "order_by": "",
      //     },
      //     "kanban": {
      //       "columns": [],
      //       "group_by": "",
      //       "add_by": "",
      //       "order_by": "",
      //       "order": "desc"
      //     }
      //   }
      // }];

      this.props.fetchListDynamicItems(account.list_dinamic);
      this.props.fetchUser(account.user);
      this.props.fetchClient(account.client);
      this.props.fetchUsers(account.list_user);
      this.props.fetchDevices(account.list_device);
      this.props.fetchAllViews(account.views);
      this.props.fetchSetting(account.setting_device);
      this.props.fetchPipeline(account.list_pipeline);
      this.props.setSettingViews(account.setting_views);
      this.props.setSettingGraphics(account.setting_graphics);
      this.props.fetchPopoverNewsLetter(account.popover_newsletter)

      this.setState({ ...this.state, loader: false, list_newsletter: account.modal_newsletter });

      const user = account.user;
      const client = account.client;

      if (typeof account.client.is_paid !== "undefined") {
        if (!account.client.is_paid && account.client.plan_id) {
          this.setState({ ...this.state, modalExpiration: true });
        }
      }

      if (user.account === "client") {
        if (!client.access.length) {
          this.props.history.push("/app/billing/plans");
        }
      }

      if (user.language !== undefined) {
        if (user.language !== "es") {
          const find = langs.find(l => l.locale === user.language);
          if (find !== undefined) {
            this.props.switchLanguage(find);
          }
        }
      }
    });

    // Globals
    socket.on(`client`, resp => {
      const [_, model] = resp;
      const { module, action, msg, ref, data } = model;
      switch (module) {
        case "client":
        case "device":
        case "feature":
        case "plan":
          this.props.onNotification({
            action: action,
            module: module,
            data: data,
            msg: msg,
            ref: ref
          });
          break;
      }
    });

    socket.on(`client${clientID}`, resp => {
      const [_, model] = resp;
      const {
        module,
        action,
        msg,
        ref,
        data,
        pipelineID,
        statusID,
        preStatusID
      } = model;

      switch (module) {
        case "client":
          switch (action) {
            case "update":
              const item = data;
              const client = this.props.client;

              client.name = item.name;
              client.email = item.email;
              client.subscribe_id = item.subscribe_id;
              client.plan_id = item.plan_id;
              client.imglogo = item.imglogo;
              client.access = item.access;
              client.subdomain = item.subdomain;
              client.phone = item.phone;
              this.props.fetchClient(client);

              break;
          }
          break;
        case "user":
          switch (action) {
            case "update":
              const user = data;
              this.props.updateUser(user.id, {
                id: user.id,
                email: user.email,
                name: user.name,
                phone: user.phone,
                account: user.account,
                online: user.login.online,
                last_login: user.login.last_login,
                sound: user.sound,
                notify: user.notify,
                analytics: user.analytics
              });
              if (user.id === this.props.userID) {

                const auth = this.props.authUser;
                auth.name = user.name;
                auth.email = user.email;
                auth.phone = user.phone;
                auth.online = user.login.online;
                auth.last_login = user.login.last_login;
                auth.sound = user.sound;
                auth.notify = user.notify;
                auth.custom_views = user.custom_views

                this.props.fetchUser(auth);
              }
              break;
            default:
              break;
          }
          break;
        case "device":
          switch (action) {
            case "update":
              this.props.updateDevice(data.id, data);
              break;
            default:
              break;
          }
          break;
        case "view":
          switch (action) {
            case "create":
              this.props.addView(data);
              break;
            case "update":
              this.props.updateView(data.id, data);
              break;
            case "delete":
              this.props.deleteView(data.id, data);
              break;
            default:
              break;
          }
          break;
        case "listdinamic":
          switch (action) {
            case "create":
              this.props.addItemListDynamic(data);
              break;
            case "update":
              this.props.updateItemListDynamic(data.id, data);
              this.props.onNotification({
                action: action,
                module: module,
                data: model.data,
                msg: msg
              });
              break;
            case "delete":
              this.props.deleteItemListDynamic(ref);
              break;
            default:
              break;
          }
          break;
        case "pipeline":
          switch (action) {
            case "create":
              this.props.addPipeline(data);
              break;
            case "update":
              this.props.updatePipeline(data.id, data);
              this.props.onNotification({
                action: action,
                module: module,
                data: model.data,
                msg: msg
              });
              break;
            case "updateAlt":
              this.props.updatePipeline(data.id, data);
              break;
            case "delete":
              this.props.deletePipeline(ref);
              break;
            default:
              break;
          }
          break;
        case "deal":
          this.props.onNotification({
            action: action,
            module: module,
            data: model.data,
            msg: msg,
            ref: ref,
            pipelineID,
            statusID,
            preStatusID
          });
          break;
        default:
          // Modules: todos, menos los anteriores (device, listdinamic)
          this.props.onNotification({
            action: action,
            module: module,
            data: model.data,
            msg: msg,
            ref: ref
          });
          break;
      }
    });

    // Notify
    socket.on(`notifyGlobal`, resp => {
      this.setState({
        ...this.state,
        modalNotify: {
          visible: true,
          tpl: resp
        }
      });
    });
    socket.on(`notify${clientID}`, resp => {
      this.setState({
        ...this.state,
        modalNotify: {
          visible: true,
          tpl: resp
        }
      });
    });
    socket.on(`notify${userID}`, resp => {
      this.setState({
        ...this.state,
        modalNotify: {
          visible: true,
          tpl: resp
        }
      });
    });

    socket.on(`alert${userID}`, ({ type, description, truncate, title }) => {
      switch (type) {
        case "info":
          NotificationManager.info(description, title, 5000);
          break;
        case "success":
          NotificationManager.success(description, title, 5000);
          break;
        case "warning":
          NotificationManager.warning(description, title, 5000);
          break;
        case "error":
          NotificationManager.error(description, title, 5000);
          break;
      }
    });

    // Disconnet
    socket.on(`disconnectGlobal`, resp => { });
    socket.on(`disconnect${clientID}`, resp => { });
    socket.on(`disconnect${userID}`, resp => { });

    // Payment
    socket.on(`payment${clientID}`, ({ status_codes, info }) => {
      if (status_codes === 200) {
        this.setState({
          ...this.state,
          alertSuccess: {
            visible: true,
            info: info
          }
        });
      } else {
        this.setState({
          ...this.state,
          alertError: {
            visible: true,
            info: info
          }
        });
      }
    });

    // Chat's messages
    socket.on(`message${clientID}`, resp => {
      const contact = Array.isArray(resp) ? resp[1] : resp;

      if (contact) {
        const { message } = contact;

        if (message.info.fromMe) {
          this.props.addMsg({ contact: contact });
        } else {
          this.props.addMsg({ contact: contact });
          if (window.location.href.indexOf("/app/chat") <= -1) {
            this.props.onMsgNotify({ contact: contact });
          }
        }
      }
    });
  }

  componentWillUnmount() {
    const { clientID, userID } = this.props;

    // // globals
    // socket.off(`client`);
    // socket.off(`client${clientID}`);

    // // Notify
    // socket.off(`notifyGlobal`);
    // socket.off(`notify${clientID}`);
    // socket.off(`notify${userID}`);

    // // Disconnet
    // socket.off(`disconnectGlobal`);
    // socket.off(`disconnect${clientID}`);
    // socket.off(`disconnect${userID}`);

    // // Payment
    // socket.off(`payment${clientID}`);

    // // Chat's messages
    // socket.off(`message${clientID}`);
  }

  render() {
    const {
      history,
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition
    } = this.props;

    const { alertSuccess, alertError, loader } = this.state;

    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
        ? "collapsible-drawer"
        : "mini-drawer";

    const isRouterChat = this.props.history.location.pathname === "/app/chat";
    const isRouterDeals =
      this.props.history.location.pathname === "/app/deals/list";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {this.state.loader ? (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <div className="d-flex">
              <div className={`jr-card-header-color`}>
                <div className="jr-card-header-top justify-content-center text-center">
                  <img
                    className="rounded-circle size-90 shadow mb-3"
                    src={require("assets/images/avatar.png")}
                    alt="Logo de la empresa"
                  />
                </div>
                <div className="jr-card-hd-content justify-content-center text-center">
                  <h4 className="jr-fs-md mb-4">
                    <IntlMessages id="loading" />
                  </h4>
                  <div className="text-center">
                    <CircularProgress size={30} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>

            {this.props.history.location.pathname === "/app/chat-iframe" ? (
              <>
                <Switch>
                  <IsUserAccessRoute
                    path={`${match.url}/chat-iframe`}
                    access="chat.view"
                    component={asyncComponent(() => import("./routes/chat-iframe"))}
                  />
                </Switch>
                <InfoView history={this.props.history} isWidget />
              </>
            ) : (
              <>
                <Tour />
                <Sidebar history={this.props.history} />
                <div className="app-main-container">
                  <div
                    style={{ zIndex: 3 }}
                    className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION
                      ? "app-header-horizontal"
                      : ""
                      }
                ${!isRouterChat ? "" : "hmin-0"}
                `}
                  >
                    {navigationStyle === HORIZONTAL_NAVIGATION &&
                      horizontalNavPosition === ABOVE_THE_HEADER && (
                        <TopNav styleName="app-top-header" />
                      )}
                    {!isRouterChat && <Header />}
                    {navigationStyle === HORIZONTAL_NAVIGATION &&
                      horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
                  </div>

                  <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                      <Switch>
                        <Route
                          path={`${match.url}/imports`}
                          component={asyncComponent(() =>
                            import("./routes/imports")
                          )}
                        />

                        <Route
                          path={`${match.url}/payments`}
                          component={asyncComponent(() =>
                            import("./routes/payment")
                          )}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/devicesAdmin`}
                          access="deviceAdmin.manager"
                          component={asyncComponent(() =>
                            import("./routes/deviceAdmin")
                          )}
                        />
                        <Route
                          path={`${match.url}/dashboard`}
                          component={asyncComponent(() =>
                            import("./routes/dashboard")
                          )}
                        />
                        <Route
                          path={`${match.url}/profile`}
                          component={asyncComponent(() =>
                            import("./routes/profile")
                          )}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/clients`}
                          access="client.view"
                          component={asyncComponent(() =>
                            import("./routes/client")
                          )}
                        />
                        <Route
                          path={`${match.url}/billing`}
                          component={asyncComponent(() =>
                            import("./routes/billing")
                          )}
                        />
                        {/*<IsDiffUserRoute
                      path={`${match.url}/billing`}
                      appProps={{ account: this.props.authUser.account }}
                      component={asyncComponent(() =>
                        import("./routes/billing")
                      )}
                    />*/}
                        <Route
                          path={`${match.url}/users`}
                          component={asyncComponent(() => import("./routes/user"))}
                        />
                        <Route
                          path={`${match.url}/usersAdmin`}
                          component={asyncComponent(() => import("./routes/users"))}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/settings`}
                          access="setting.view"
                          component={asyncComponent(() =>
                            import("./routes/setting")
                          )}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/tasks`}
                          access="task.view"
                          component={asyncComponent(() => import("./routes/task"))}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/agendas`}
                          access="agenda.view"
                          component={asyncComponent(() => import("./routes/agenda"))}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/deals`}
                          access="deal.view"
                          component={asyncComponent(() => import("./routes/deal"))}
                        />

                        <IsUserAccessRoute
                          path={`${match.url}/features`}
                          access="feature.view"
                          component={asyncComponent(() =>
                            import("./routes/feature")
                          )}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/plans`}
                          access="plan.view"
                          component={asyncComponent(() => import("./routes/plan"))}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/devices`}
                          access="device.view"
                          component={asyncComponent(() =>
                            import("./routes/device")
                          )}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/diffusions`}
                          access="marketing.view"
                          component={asyncComponent(() =>
                            import("./routes/diffusion")
                          )}
                        />
                        <IsUserAccessRoute
                          path={`${match.url}/confirmations`}
                          access="confirmation.view"
                          component={asyncComponent(() =>
                            import("./routes/confirmation")
                          )}
                        />

                        <IsUserAccessRoute
                          path={`${match.url}/contacts`}
                          access="contact.view"
                          component={asyncComponent(() =>
                            import("./routes/contact")
                          )}
                        />

                        <IsUserAccessRoute
                          path={`${match.url}/leads`}
                          access="lead.view"
                          component={asyncComponent(() =>
                            import("./routes/lead")
                          )}
                        />

                        <IsUserAccessRoute
                          path={`${match.url}/chat`}
                          access="chat.view"
                          component={asyncComponent(() => import("./routes/chat"))}
                        />

                        <IsUserAccessRoute
                          path={`${match.url}/chat-iframe`}
                          access="chat.view"
                          component={asyncComponent(() => import("./routes/chat-iframe"))}
                        />

                        <IsUserAccessRoute
                          path={`${match.url}/storage`}
                          access="storage.view"
                          component={asyncComponent(() =>
                            import("./routes/storage")
                          )}
                        />

                        {/* <IsUserAccessRoute
                      path={`${match.url}/diffusions`}
                      access="marketing.view"
                      component={asyncComponent(() =>
                        import("./routes/diffusion")
                      )}
                    /> */}
                        <Route
                          path={`${match.url}/view`}
                          component={asyncComponent(() => import("./routes/view"))}
                        />
                        <Route
                          path={`${match.url}/dragnDrop`}
                          component={asyncComponent(() =>
                            import("./routes/dragnDrop")
                          )}
                        />
                        <Route
                          path={`${match.url}/404`}
                          component={asyncComponent(() =>
                            import("components/Error404")
                          )}
                        />
                        <Route
                          path={`${match.url}/401`}
                          component={asyncComponent(() =>
                            import("components/Error401")
                          )}
                        />
                        <Route
                          path={`${match.url}/500`}
                          component={asyncComponent(() =>
                            import("components/Error500")
                          )}
                        />
                      </Switch>
                      <InfoView history={this.props.history} />
                    </div>
                    {!isRouterChat && !isRouterDeals && (
                      <Footer history={history} client={this.props.client} />
                    )}
                  </main>
                  <SweetAlert
                    show={alertSuccess.visible}
                    success
                    title={alertSuccess.info}
                    IntlMessages
                    confirmBtnText={<IntlMessages id="update" />}
                    allowEscape
                    onConfirm={() => {
                      this.setState({
                        ...this.state,
                        alertSuccess: {
                          ...this.state.alertSuccess,
                          visible: false
                        }
                      });
                      // eslint-disable-next-line no-restricted-globals
                      location.reload();
                    }}
                    onCancel={() => {
                      this.setState({
                        ...this.state,
                        alertSuccess: {
                          ...this.state.alertSuccess,
                          visible: false
                        }
                      });
                      // eslint-disable-next-line no-restricted-globals
                      location.reload();
                    }}
                  ></SweetAlert>
                  <SweetAlert
                    show={alertError.visible}
                    error
                    title={alertError.info}
                    allowEscape
                    onConfirm={() => {
                      this.setState({
                        ...this.state,
                        alertError: {
                          ...this.state.alertError,
                          visible: false
                        }
                      });
                    }}
                    onCancel={() => {
                      this.setState({
                        ...this.state,
                        alertError: {
                          ...this.state.alertError,
                          visible: false
                        }
                      });
                    }}
                  ></SweetAlert>
                </div>
              </>
            )}

          </>
        )
        }

        <ModalPlanExpiration
          visible={this.state.modalExpiration}
          onClose={() =>
            this.setState({ ...this.state, modalExpiration: false })
          }
        />
        <ModalNotify
          visible={this.state.modalNotify.visible}
          tpl={this.state.modalNotify.tpl}
          onClose={() =>
            this.setState({
              ...this.state,
              modalNotify: {
                tpl: "",
                visible: false
              }
            })
          }
        />
        <NewsLetterBox
          data={this.state.list_newsletter}
        />

        {!loader ? <ChatButton /> : null}

        {/* <NotificationContainer /> */}
      </div >
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { token, clientID, userID, client, authUser } = auth;
  const {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    locale
  } = settings;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    token,
    clientID,
    client,
    userID,
    locale,
    authUser
  };
};
export default withRouter(
  connect(mapStateToProps, {
    switchLanguage,
    showMessage,
    // Notification
    onNotification,
    // User
    fetchClient,
    fetchUser,
    fetchUsers,
    updateUser,
    // LisDynamic
    fetchListDynamicItems,
    addItemListDynamic,
    updateItemListDynamic,
    deleteItemListDynamic,
    // Devices
    fetchDevices,
    updateDevice,
    fetchSetting,
    //Chat
    addMsg,
    onMsgNotify,
    // views
    fetchAllViews,
    updateView,
    addView,
    deleteView,
    // Pipeline
    fetchPipeline,
    addPipeline,
    updatePipeline,
    deletePipeline,
    //
    setSettingViews,
    setSettingGraphics,
    fetchPopoverNewsLetter
  })(App)
);
