import React, { useCallback, useMemo, useRef } from "react";
import { Box, Avatar, Typography, IconButton, Fade, Tooltip } from '@material-ui/core';
import ExtraToolbar from "components/sofia/ExtraToolbar"

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import MinimizeIcon from '@material-ui/icons/Minimize';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import RemoveIcon from '@material-ui/icons/Remove';
import IntlMessages from "util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { onChatContactSelected, onChatToggle, onContactSelected } from "actions/Chat";
import ExtraAvatar from "../../Conversations/ui/ConverstionItem/ui/ExtraAvatar";
import BotAction from "../../Conversations/ui/ConverstionItem/ui/BotAction";
import TransferAction from "./TransferAction";
import { useHistory } from "react-router";

export default function HeaderConversation({ oneConversation, isWidget }) {

    const dispatch = useDispatch()
    const history = useHistory()
    const { chatContactSelected } = useSelector(({ chat }) => chat)
    const { users } = useSelector(({ user }) => user)

    const owner = useMemo(() => {

        try {
            const user = users.find(u => u.id === chatContactSelected?.derivedto);
            if (!user) {
                return <IntlMessages id="chat.not_assigned_conversation" />;
            } else {
                return user.name
            }

        } catch (error) {
            return ''
        }

    }, [chatContactSelected])

    const render = useCallback(() => {
        return (
            <>
                {chatContactSelected ? (
                    <ExtraToolbar
                        withPaperBackground
                        withBorderBottom
                        disableGutters
                        style={{
                            paddingRight: 4,
                            paddingLeft: 0
                        }}
                        primary={
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                                paddingLeft: 5
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }}>

                                    <ExtraAvatar contact={chatContactSelected} />
                                    <div
                                        style={{
                                            maxWidth: 150,
                                            margin: 5
                                        }}
                                    >
                                        <Typography variant="body2" component={'h6'} lineHeight={1.4} noWrap>
                                            <b>{chatContactSelected?.info.full_name}</b>
                                        </Typography>
                                        <div>
                                            <Typography variant="caption" component={'p'} lineHeight={1} color={'text.secondary'} noWrap>
                                                {owner}
                                            </Typography>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        secondary={
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4
                            }}>

                                <div>
                                    <BotAction isConversation contact={chatContactSelected} />
                                </div>
                                <div>
                                    <TransferAction contact={chatContactSelected} />

                                </div>
                                {!isWidget ? (
                                    <div>
                                        <Tooltip title={'Expandir Chat'}>
                                            <IconButton
                                                size="small"
                                                aria-label=""
                                                style={{
                                                    borderRadius: '100%',
                                                    // backgroundColor: (theme) => theme.palette.action.hover
                                                }}
                                                onClick={() => {
                                                    // dispatch(onChatToggle(false));
                                                    localStorage.setItem("contact_id", chatContactSelected.id);
                                                    dispatch(onContactSelected(chatContactSelected))
                                                    history.push('/app/chat')

                                                }}>
                                                <AspectRatioIcon
                                                    fontSize="small"
                                                    style={{
                                                        fontSize: 16
                                                    }}
                                                />
                                            </IconButton>

                                        </Tooltip>
                                    </div>
                                ) : null}
                                {/* <IconButton
                                    size="small"
                                    aria-label=""
                                    style={{
                                        padding: 0.4,
                                        borderRadius: '100%',
                                        backgroundColor: (theme) => theme.palette.action.hover
                                    }}
                                    onClick={() => null}>
                                    <MoreVertIcon
                                        fontSize="small"
                                        style={{
                                            fontSize: 16
                                        }}
                                    />
                                </IconButton> */}
                                {!oneConversation ? (
                                    <div>
                                        <IconButton
                                            size="small"
                                            aria-label=""
                                            color="primary"
                                            style={{
                                                borderRadius: '100%',
                                                // backgroundColor: (theme) => theme.palette.primary.main,
                                                // "&:hover": {
                                                //     backgroundColor: (theme) => theme.palette.primary.dark
                                                // }
                                            }}
                                            onClick={() => {
                                                dispatch(onChatContactSelected(null))
                                            }}>
                                            <RemoveIcon
                                                fontSize="small"
                                                style={{
                                                    fontSize: 16,
                                                }}
                                            />
                                        </IconButton>
                                    </div>
                                ) : null}
                                <div>

                                    <IconButton
                                        title={'Cerrar Chat'}
                                        size="small"
                                        aria-label=""
                                        color="primary"
                                        style={{
                                            borderRadius: '100%',
                                            // backgroundColor: (theme) => theme.palette.primary.main,
                                            // "&:hover": {
                                            //     backgroundColor: (theme) => theme.palette.primary.dark
                                            // }
                                        }}
                                        onClick={() => {

                                            if (!isWidget) {
                                                dispatch(onChatToggle(false))
                                            } else {
                                                if (window && window.parent) {
                                                    window.parent.postMessage(JSON.stringify({ action: 'close' }), "*")
                                                }
                                            }
                                        }}>
                                        <CloseIcon
                                            fontSize="small"
                                            style={{
                                                fontSize: 16,
                                            }}
                                        />
                                    </IconButton>
                                </div>

                            </div>
                        }
                    />
                ) : null}
            </>
        )
    }, [chatContactSelected])

    return (
        <>
            {render()}
        </>
    )
}
