import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";

import { userSignIn } from "actions/Auth";

// TODO: Recuerda habilitar la validacion en password
// import { matches } from 'util/Helper';

// Form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// Services
import UserService from "services/ajax/user.service";

// Icons
import CloseIcon from "@material-ui/icons/Close";

// Actions
import { showMessage } from "actions/Common";

// components

const SignInFromChat = ({ authUser, history, userSignIn, showMessage }) => {


  const [modal, setModal] = React.useState(false);
  const [modalInfo, setModalInfo] = React.useState({
    showModal: false,
    majorVersion: 0,
    fullVersion: "0.0.0",
    browserName: "Chrome"
  });

  const schemaValue = {
    email: yup
      .string()
      .email()
      .required(),
    password: yup.string().required()
  };

  const schema = yup.object().shape(schemaValue);
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema)
  });

  const onSubmit = data => {
    userSignIn(data, true);
  };

  return (
    <div className="position-relative align-items-center animated slideInUpTiny animation-duration-3 w-100 bg-white">

      <div className="" style={{
        position: 'absolute',
        top: 8,
        right: 8
      }}>
        <IconButton
          title={'Cerrar Chat'}
          size="small"
          aria-label=""
          color="primary"
          style={{
            borderRadius: '100%',
          }}
          onClick={() => {
            if (window && window.parent) {
              window.parent.postMessage(JSON.stringify({ action: 'close' }), "*")
            }
          }}>
          <CloseIcon
            fontSize="small"
            style={{
              fontSize: 16,
            }}
          />
        </IconButton>
      </div>
      <div className="p-4">
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={require("assets/images/logo.png")}
            className="img-fluid mb-4"
            alt="logo"
            title="Sofia bot"
            style={{ width: 180 }}
          />
        </div>

        <div className="">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="signIn" />
            </h1>
          </div>

          <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset>
                <TextField
                  name="email"
                  inputRef={register}
                  type="email"
                  className="mt-1 my-sm-3"
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  margin="normal"
                  autoFocus
                  error={errors?.email ? true : false}
                  required
                  autoComplete="username-email"
                  inputProps={{
                    autoComplete: 'username-email'
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  name="password"
                  inputRef={register}
                  type="password"
                  className="mt-1 my-sm-3"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  margin="normal"
                  error={errors?.password ? true : false}
                  required
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                {typeof errors.password !== "undefined" && (
                  <span className="text-secondary">
                    <IntlMessages id="validation.password" />
                  </span>
                )}
                {/* <div className="d-flex">
                  <Link className="" onClick={() => setModal(true)}>
                    <IntlMessages id="forgotPassword" />
                  </Link>
                </div> */}
                <div className="my-3 mt-4 d-flex align-items-center justify-content-between">
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!formState.isValid}
                  >
                    <IntlMessages id="appModule.signIn" />
                  </Button>

                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <InfoView />
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { userSignIn, showMessage })(SignInFromChat);
