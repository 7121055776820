import {
  FETCH_ALL_LISTDYNAMIC_FIELDS,
  FETCH_ALL_LISTDYNAMIC_ITEMS,
  ADD_ITEM_LISTDYNAMIC_ITEMS,
  UPDATE_ITEM_LISTDYNAMIC_ITEMS,
  DELETE_ITEM_LISTDYNAMIC_ITEMS,
} from "../constants/ActionTypes";

const INIT_STATE = {
  fields: [],
  items: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_LISTDYNAMIC_FIELDS: {
      return { ...state, fields: [...action.payload] };
    }
    case FETCH_ALL_LISTDYNAMIC_ITEMS: {
      return { ...state, items: [...action.payload] };
    }
    case ADD_ITEM_LISTDYNAMIC_ITEMS: {
      return { ...state, items: [action.payload, ...state.items] };
    }
    case UPDATE_ITEM_LISTDYNAMIC_ITEMS: {
      const arr = [...state.items];
      const { id, data } = JSON.parse(JSON.stringify(action.payload));
      const findIndex = arr.findIndex((el) => el.id === id);
      if (findIndex >= 0) {
        arr[findIndex] = data;
        return { ...state, items: [...arr] };
      }
      return state;
    }
    case DELETE_ITEM_LISTDYNAMIC_ITEMS: {
      const id = action.payload;
      const arr = state.items.filter((el) => el.id !== id);
      return { ...state, items: [...arr] };
    }
    default:
      return state;
  }
};

