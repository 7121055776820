import React from "react";
import { useSelector } from "react-redux";

// Components
import DisplayDate from "util/DisplayDate";
import Item from "./item";
import { Typography, Container, Box, Stack } from '@mui/material'

const Messages = React.memo(props => {

  const { data, messages, contact, onDeleted, pagination } = props;
  const { authUser } = useSelector(({ auth }) => auth)

  const groups = data
    // .filter(c => c.contact_id === contact.id)
    .reduce((groups, message) => {

      const date = message.timestamp.split("T")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});

  const groupArrays = Object.keys(groups).map(date => {
    return {
      date,
      messages: groups[date]
    };
  });

  const groups2 = messages.reduce((groups, message) => {
    const date = message.timestamp.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  const groupArrays2 = Object.keys(groups2).map(date => {
    return {
      date,
      messages: groups2[date]
    };
  });

  const posParent = groupArrays2.length - 1;
  const posChildren =
    groupArrays2[posParent] !== undefined
      ? groupArrays2[posParent].messages.length - 1
      : 0;

  return (
    <Stack
      sx={{
        px: 1,
        pb: 2
      }}
    >
      {groupArrays.map((parent, parentIdx) => (
        <Stack key={parentIdx}>
          {parent.date.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                py: 1
              }}
            >
              <Box
                sx={{
                  backgroundColor: (theme) => theme.palette.action.hover,
                  px: 2,
                  py: 0.2,
                  borderRadius: 10
                }}
              >
                <Typography
                  variant="caption"
                  color="initial"
                  sx={{
                    fontSize: 10
                  }}
                >
                  <DisplayDate plainText date={parent.date} onlyDate={true} />
                </Typography>
              </Box>
            </Box>
          )}
          <Stack gap={1}>
            {parent.messages.map((conversation, index) => (
              <Item
                key={index}
                index={parent.messages.length - (parentIdx + index)}
                contact={contact}
                conversation={conversation}
                pagination={pagination}
                onDeleted={onDeleted}
              />
            ))}
          </Stack>
        </Stack>
      ))}

    </Stack>
  );
});

export default Messages;
