import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

// Services
import MessagebagService from "services/ajax/messagebag.service";
import IntlMessages from "util/IntlMessages";
import { convertToInternationalC } from "util/Helper";
// Components
import Aux from "util/Auxiliary";

function MessageBalance(props) {
  const { token, locale, authUser, client } = props;
  const [loader, setLoader] = React.useState(false);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    getBalance();
  }, [0]);

  const getBalance = () => {
    setLoader(true);
    new MessagebagService()
      .messageBalance(
        token,
        locale.locale,
        typeof props.id !== "undefined" ? props.id : null
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setData(data.data[0]);
        }
        setLoader(false);
      })
      .catch(error => {
        setLoader(false);
      });
  };

  const plan = data?.message.available.plan;
  const bags = data?.message.available.bags;
  const sum = plan + bags;
  const p1 = ((plan * 100) / sum).toFixed(2);
  const p2 = ((bags * 100) / sum).toFixed(2);

  return (
    <>
      <h4 className="mb-2 mb-lg-3">
        Balance de mensajes disponibles{" "}
        {loader && <CircularProgress size={15} color="secondary" />}
      </h4>
      <div className="row">
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
          <div className="d-flex mb-2">
            <Tooltip title={sum}>
              <h3 className="mr-2 mb-0 jr-fs-xxxl jr-font-weight-medium">
                <i className="zmdi zmdi-comment-alt-text zmdi-hc-xs mr-2 text-muted"></i>
                {convertToInternationalC(sum) || 0}
              </h3>
            </Tooltip>
          </div>
          <div className="d-flex flex-row mb-3">
            {authUser.account === "client" && client?.subscribe_id.length > 0 && (
              <Link
                to="/app/billing/features"
                className="jr-btn jr-btn-sm bg-warning text-white"
              >
                <IntlMessages id="buy" />
              </Link>
            )}
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 px-0 mx-0">
          <div className="jr-site-dash">
            <h5 className="text-muted">Mensajes disponibles</h5>
            <ul className="jr-line-indicator jr-fs-sm">
              <li>
                <Aux>
                  <div className="d-flex flex-row">
                    <p className="mr-1">{`Plan`}</p>
                    <p className="text-grey">| {plan}</p>
                  </div>
                  <div className="jr-line-indi-info">
                    <div
                      className={`jr-line-indi bg-${`success`}`}
                      style={{
                        width: `${!loader ? (isNaN(p1) ? 0 : p1) : 0}%`
                      }}
                    />
                    <span className="jr-line-indi-count ml-2">
                      {`${!loader ? (isNaN(p1) ? 0 : p1) : 0}%`}
                    </span>
                  </div>
                </Aux>
              </li>
              <li>
                <Aux>
                  <div className="d-flex flex-row">
                    <p className="mr-1">{`Bolsas`}</p>
                    <p className="text-grey">| {bags}</p>
                  </div>
                  <div className="jr-line-indi-info">
                    <div
                      className={`jr-line-indi bg-${`info`}`}
                      style={{
                        width: `${!loader ? (isNaN(p2) ? 0 : p2) : 0}%`
                      }}
                    />
                    <span className="jr-line-indi-count ml-2">
                      {`${!loader ? (isNaN(p2) ? 0 : p2) : 0}%`}
                    </span>
                  </div>
                </Aux>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { token, authUser, client } = auth;

  return {
    locale,
    token,
    authUser,
    client
  };
};

export default connect(mapStateToProps, {})(MessageBalance);
