import React from "react";

// services
import MessageService from "./message.ajax.service";

export const useMessages = ({ locale, token, clientID, query = {} }) => {
  const [messages, setMessages] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [loaderStore, setLoaderStore] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [noData, setNoData] = React.useState(false);
  const rowsPerPage = 50;

  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });

  const index = (page, contactID, filters, isLoader = true) => {
    if (isLoader) {
      setLoader(true);
    }
    new MessageService()
      .index(
        contactID,
        {
          offset: rowsPerPage,
          page: page + 1,
          ...filters,
          ...query
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          const arr = data.data;
          if (arr.length > 0) {
            setMessages([...arr.reverse()]);
            setTotal(data.pagination);
            setPage(page + 1);
          } else {
            setTotal(data.pagination);
            setPage(page + 1);
            setNoData(true);
          }
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const indexByPhone = (page, filters, isLoader = true) => {

    setLoader(true);
    new MessageService()
      .indexByPhone(
        {
          offset: rowsPerPage,
          page: page + 1,
          ...filters,
          ...query
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          const arr = data.data;
          // if (arr.length > 0) {
          //   setMessages([...arr.reverse()]);
          //   setTotal(data.pagination);
          //   setPage(page + 1);
          // } else {
          //   setTotal(data.pagination);
          //   setPage(page + 1);
          //   setNoData(true);
          // }
          setMessages([...arr.reverse()]);
          setTotal(data.pagination);
          setPage(0);
        }
      })
      .finally(_ => {
        setLoader(false);
      });
  };

  const storeMessage = newMessage => {
    setLoaderStore(true);

    new MessageService()
      .store({ ...newMessage }, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            info: data.info
          });
          // socket.emit("notification", [
          //   `message${clientID}`,
          //   {
          //     ...data.data[0]
          //   }
          // ]);
        }
        setLoaderStore(false);
      })
      .catch(_ => {
        setLoaderStore(false);
      });
  };

  const sendTemplate = newMessage => {
    setLoaderStore(true);

    new MessageService()
      .sendTemplate({ ...newMessage }, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            info: data.info
          });
          // socket.emit("notification", [
          //   `message${clientID}`,
          //   {
          //     ...data.data[0]
          //   }
          // ]);
        }
        setLoaderStore(false);
      })
      .catch(_ => {
        setLoaderStore(false);
      });
  };


  const storeMessage2 = newMessage => {
    setLoaderStore(true);

    new MessageService()
      .store2({ ...newMessage }, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            info: data.info
          });
          // socket.emit("notification", [
          //   `message${clientID}`,
          //   {
          //     ...data.data[0]
          //   }
          // ]);
        }
        setLoaderStore(false);
      })
      .catch(_ => {
        setLoaderStore(false);
      });
  };



  const storeAttachMessage = newMessage => {

    delete newMessage.filename;
    const formData = new FormData();
    Object.getOwnPropertyNames(newMessage).forEach(name => {
      if (name === "attachment") {
        formData.append("attachment", newMessage[name], newMessage[name].name);
      } else {
        formData.append(name, newMessage[name]);
      }
    });

    setLoaderStore(true);

    new MessageService()
      .storeAttach(formData, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            info: data.info
          });
        }
        setLoaderStore(false);
      })
      .catch(_ => {
        setLoaderStore(false);
      });

  };

  return {
    // const
    noData,
    page,
    total,
    messages,
    loader,
    loaderStore,
    statusCode,
    // methods
    index,
    indexByPhone,
    storeMessage,
    storeMessage2,
    storeAttachMessage,
    sendTemplate
  };
};
