import React, { useState, useMemo, useEffect } from 'react';
import { Box, List, CircularProgress, Fab, Popover, IconButton } from '@material-ui/core'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import { useHistory } from "react-router";

import Chat from "../index"
import { useDispatch, useSelector } from "react-redux";
import { onChatToggle, onChatContactSelected } from "actions/Chat";

export default function ChatButton({ size = "default", position = 'absolute', contactSelected = null, oneConversation = false, onClick = () => null }) {

    const { module } = useSelector(({ auth }) => auth)
    const { showChat } = useSelector(({ chat }) => chat)
    const { allDevice } = useSelector(({ devices }) => devices)
    const history = useHistory()


    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClick = (event) => {
        if (contactSelected) {
            try {
                const deviceID = contactSelected?.device_id;
                const device = allDevice.find(d => d.id === deviceID);
                if (device) {
                    dispatch(onChatContactSelected({ ...contactSelected, device: device }))
                }

            } catch (error) {

            }
        }
        dispatch(onChatToggle(true))
        onClick()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        dispatch(onChatToggle(false))
        setAnchorEl(null);

        setTimeout(() => {
            if (contactSelected) {
                dispatch(onChatContactSelected(null))
            }
        }, 1000)
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover-chat' : undefined;

    useEffect(() => {
        if (!showChat) {
            handleClose()
        }
    }, [showChat])

    return (
        <>
            {(module !== 'chat' && history.location.pathname !== "/app/chat-iframe") ? (
                <>
                    {position === "absolute" ? (
                        <Fab
                            className=''
                            title="Chat"
                            color="primary"
                            aria-label="chat"
                            size="small"
                            style={{
                                position: "absolute",
                                zIndex: 9999,
                                bottom: 6,
                                right: 25,
                                color: "#fff",
                                zIndex: 4,
                                boxShadow: 0,
                                backgroundColor: '#1565c0'
                            }}
                            onClick={handleClick}
                        >
                            <ForumOutlinedIcon />
                        </Fab>
                    ) : null}
                    {position === "static" ? (
                        <IconButton
                            className={`${open ? "text-primary" : ""}`}
                            aria-label="chat"
                            onClick={handleClick}
                        >
                            <i className={`zmdi zmdi-comments`} />
                        </IconButton>
                    ) : null}

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div>
                            <Chat oneConversation={oneConversation} />
                        </div>

                    </Popover>
                </>
            ) : null}
        </>
    )
}