import React, { useEffect, useState } from "react";
import { Tooltip, IconButton, Box } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import Acl from "components/sofia/elements/acl";
import { useDispatch, useSelector } from "react-redux";

// services
import { socket } from "util/Socket";
import ContactService from "services/ajax/contact.service";

//  icons
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

function BotAction({
    intl,
    contact,
    isConversation = false,
    onFocus = () => null
}) {

    const { token, locale, clientID, authUser } = useSelector(({ auth, settings }) => ({
        token: auth.token,
        clientID: auth.clientID,
        locale: settings.locale.locale,
        authUser: auth.authUser
    }))

    const [haveBot, setHaveBot] = useState(contact.answerbot);
    const [haveBotLoader, setHaveBotLoader] = useState(false);

    const onUpdateHaveBot = () => {

        onFocus()

        setHaveBotLoader(true);

        const isBotActive = contact.answerbot ? false : true;

        new ContactService()
            .answerbot(
                contact.id,
                {
                    answerbot: isBotActive
                },
                locale,
                token
            )
            .then(({ data }) => {

                setHaveBot(data.data[0].answerbot);
                socket.emit("notification", [
                    `client${clientID}`,
                    {
                        module: "contact",
                        action: "update",
                        data: data.data[0],
                        msg: data.info
                    }
                ]);
            })
            .finally(err => {
                setHaveBotLoader(false);
            });
    }

    useEffect(() => {
        setHaveBot(contact.answerbot)
    }, [contact])

    return (
        <Box
            style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            {(!haveBot && contact.derivedto.length > 0) ? (
                <Acl
                    title={intl.formatMessage({ id: 'chat.donttakeConversation' })}
                    access="chat.releasechat"
                    component={
                        <Tooltip title={intl.formatMessage({ id: 'chat.donttakeConversation' })} placement="top">

                            <IconButton
                                size="small"
                                aria-label=""
                                style={{
                                    padding: 0.4,
                                    borderRadius: '100%',
                                    opacity: haveBotLoader ? 0.4 : 1,
                                }}
                                disabled={haveBotLoader}
                                onClick={onUpdateHaveBot}
                            >

                                <LockIcon
                                    fontSize='small'
                                    style={{
                                        fontSize: 16,
                                        color: 'orangered'
                                    }}

                                />
                            </IconButton>
                        </Tooltip>
                    }
                />
            ) : (
                <Acl
                    access="chat.lockchat"
                    component={
                        <Tooltip title={intl.formatMessage({ id: 'chat.takeConversation' })} placement="top">
                            <IconButton
                                className={isConversation ? "" : "conversations-action"}
                                size="small"
                                aria-label=""
                                style={{
                                    padding: 0.4,
                                    borderRadius: '100%',
                                    opacity: haveBotLoader ? 0.4 : 1,
                                    display: isConversation ? 'inline-flex' : 'none'
                                }}
                                disabled={haveBotLoader}
                                onClick={onUpdateHaveBot}
                            >
                                <LockOpenIcon
                                    fontSize='small'
                                    style={{
                                        fontSize: 16,
                                        color: 'green'
                                    }}

                                />
                            </IconButton>
                        </Tooltip>

                    } />
            )}
            {/* {true ? (
                <CircularProgress 
                color="primary"
                size={18}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                }}
                />
            ) : null} */}
        </Box>
    )
}

export default injectIntl(BotAction)