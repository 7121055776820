
import React, { useEffect } from 'react';
import { useCallback, useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Popover from "@mui/material/Popover";
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IntlMessages from "util/IntlMessages";

import Container from '@material-ui/core/Container';
import SurroundSoundIcon from '@material-ui/icons/SurroundSound';
import CloseIcon from '@material-ui/icons/Close';
import CustomScrollbars from "util/CustomScrollbars";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Badge from '@material-ui/core/Badge';
import CardMedia from '@material-ui/core/CardMedia';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import ExtraToolbar from './ExtraToolbar';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import { green, indigo, blue, red, orange } from "@material-ui/core/colors";
import AssistantIcon from '@material-ui/icons/Assistant';
import { newLetterStatus, newLetterCategories, newLetterImpacts } from '../../app/routes/setting/routes/news/detail/constants'
import { fetchPopoverNewsLetter } from "actions/View";
import SearchIcon from '@material-ui/icons/Search';
import { useNewss, useNews } from "services/hook/news.service";

import BugReportIcon from '@material-ui/icons/BugReport';
import WidgetsIcon from '@material-ui/icons/Widgets';
import UpdateIcon from '@material-ui/icons/Update';
import DisplayDate from '../../util/DisplayDate';
import NewsLetterBox from './NewsLetterBox';



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        cursor: 'pointer',
        position: 'relative',
        zIndex: 2
    },
    paper: {
        padding: theme.spacing(0.5),
        margin: 'auto',
        maxWidth: 500,
        "&:hover": {
            backgroundColor: indigo[50]
        }
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function NewsLetterPopover({
    popover_newsletter,
    vertical = false,
    fetchPopoverNewsLetter,
    userID,
    clientID,
    locale,
    token
}) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const [scrollEl, setScrollEl] = React.useState(null);
    const [selected, setSelected] = React.useState(null);
    const iconButtonRef = useRef()
    const isInit = useRef(true);
    const [show, setShow] = React.useState(false);

    const {
        newss,
        loader,
        page,
        total,
        rowsPerPage,
        statusCode,
        index,
        setData
    } = useNewss({
        clientID: clientID,
        token: token,
        userID: userID,
        locale: locale.locale
    });

    const observer = React.useRef();

    const [items, setItems] = useState([]);

    const hasMore = React.useMemo(() => items.length < total ? true : false, [loader, items, total]);

    const lastElementRef = React.useCallback(
        node => {
            if (loader) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && hasMore && !loader) {
                    index(page)
                }
            });
            if (node) observer.current.observe(node);
        },
        [loader, hasMore]
    );

    const handleClick = useCallback((event) => {
        setAnchorEl(event?.currentTarget);
        fetchPopoverNewsLetter([])
        setShow(false)
        setData([])
        index(0)
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        fetchPopoverNewsLetter([])
        setShow(false)
        setData([])
        index(0)

        try {
            document.querySelector(`[name="newsletter-search"]`).value = ''
        } catch (error) {

        }
    }, []);

    useEffect(() => {
        if (newss.length) {

            if (!page) {
                setItems([...newss])
            } else {
                const searchText = document.querySelector(`[name="newsletter-search"]`).value;

                if (page === 1 && searchText.length > 0) {
                    setItems([...newss])
                } else {
                    if (items.length !== newss.length) {
                        setItems([...items, ...newss])
                    }
                }
            }
        }
    }, [newss])


    useEffect(() => {
        if (popover_newsletter.length && !vertical) {
            setData([])
            setShow(true)
        }
        if (isInit.current) {
            setData([])
            index(0);
            isInit.current = false;
        }
    }, [])

    return (
        <>
            <div className='position-relative'>
                <span onClick={handleClick} className='news-letterspopover'>
                    <IconButton aria-label="" className={`${show ? 'animated pulse infinite' : ''}`}>
                        <Badge color="secondary" variant="dot" invisible={!show}>
                            <AssistantIcon style={{ color: orange[500] }} ref={iconButtonRef} />
                        </Badge>
                    </IconButton>
                </span>
            </div>
            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={!vertical ? {
                    vertical: 'bottom',
                    horizontal: 'right',
                } : {
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={!vertical ? {
                    vertical: 'top',
                    horizontal: 'right',
                } : {
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    mt: 2
                }}
            >
                <Grid container style={{ width: 300, position: 'relative' }}>
                    <Grid item xs={12} className='pb-2'>
                        <ExtraToolbar
                            withPaperBackground
                            primary={
                                <Typography variant='body2'>
                                    <IntlMessages id="newsletters" /> {`(${total})`}
                                </Typography>
                            }
                            secondary={
                                <IconButton size='small' aria-label="" onClick={handleClose}>
                                    <CloseIcon fontSize='small' />
                                </IconButton>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} style={{ background: '#eee' }}>
                        <div className='w-full w-100 px-2 pt-2'>
                            <Tooltip title={<IntlMessages id="search.on" />}>
                                <TextField
                                    size='small'
                                    variant='outlined'
                                    name='newsletter-search'
                                    fullWidth
                                    style={{ width: '100%' }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize='small' />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                                {loader ? <CircularProgress color="primary" size={20} /> : null}
                                            </React.Fragment>
                                        ),
                                        onChange: ev => null,
                                        onKeyPress: ev => {

                                            if (ev.key === "Enter") {
                                                ev.preventDefault();
                                                setItems([])
                                                if (ev.target.value.length > 0) {
                                                    index(0, { title: { contains: ev.target.value } })
                                                } else {
                                                    index(0)
                                                }

                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Tooltip>
                        </div>


                        <CustomScrollbars
                            className="module-side-scroll scrollbar"
                            style={{
                                height: `calc(100vh - 300px)`,
                                scrollBehavior: "smooth",
                                background: '#eee',
                                position: 'relative'

                            }}
                            onScroll={event => {
                                setScrollEl(event.target);
                            }}
                        >

                            {loader && (
                                <div
                                    className="loader-view w-100"
                                    style={{
                                        position: "sticky",
                                        height: 0,
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        zIndex: 3,
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            )}

                            <div style={{
                                width: '100%',
                                padding: '17px 10px',
                                gap: '4px',
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                {items.map((item, i) => (
                                    <React.Fragment key={i}>
                                        <Item key={i} data={item} onSelected={() => {
                                            setSelected(item)
                                        }} />
                                        {items.length - 2 === i && !loader && hasMore && (
                                            <>
                                                <div ref={lastElementRef}></div>
                                            </>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>

                            {!hasMore && (
                                <div className="w-full w-100 text-center d-flex flex-column justify-content-center">
                                    <div>
                                        <p className="my-2 pb-4">
                                            <IntlMessages id="noResults" />
                                        </p>
                                    </div>
                                </div>
                            )}


                            {!items.length && (
                                <div className="w-full w-100 text-center d-flex flex-column justify-content-center">
                                    <div>
                                        <img src={require("assets/images/empty.svg")} />
                                    </div>
                                    <div>
                                        <p className="mt-2 text-grey">
                                            <IntlMessages id="noData" />
                                        </p>
                                    </div>
                                </div>
                            )}



                        </CustomScrollbars>

                    </Grid>

                    <img
                        className="position-absolute w-full"
                        style={{
                            width: '100%',
                            bottom: 0,
                            zIndex: 1,
                            opacity: 0.5,
                            height: 120
                        }}
                        src={require('assets/images/undraw_personal_text_re_vqj3.svg')}
                        alt=""
                    />
                </Grid>
            </Popover>
            {selected && (
                <NewsLetterBox isOpen={selected} data={[selected]} onClose={() => setSelected(null)} />
            )}
        </>
    );
}

function Item({ data, onSelected, key }) {
    const classes = useStyles();
    const theme = useTheme();

    const viewCategory = (ca) => {
        const value = newLetterStatus.find((el) => el.value === ca)

        if (!value) {
            return null;
        }

        return (
            <span className={`jr-rounded-xs badge text-white`} style={{
                backgroundColor: value.color,
                padding: '1px 8px'
            }}>
                <IntlMessages id={value?.i18n} />
            </span>
        )

    }

    return (
        <div className={classes.root} onClick={onSelected}>
            <Paper className={classes.paper}>
                <Grid container spacing={1}>
                    <Grid item xs={2} justifyContent='center' alignItems='center' style={{
                        textAlign: 'center',
                        alignItems: 'center',
                        display: 'flex'
                    }}>
                        {data.category === 'newfeature' && (
                            <WidgetsIcon fontSize='' style={{ color: blue[500] }} />
                        )}
                        {data.category === 'maintenance' && (
                            <UpdateIcon fontSize='' style={{ color: green[500] }} />
                        )}
                        {data.category === 'bugfix' && (
                            <BugReportIcon fontSize='' style={{ color: indigo[500] }} />
                        )}
                    </Grid>
                    <Grid item xs={10} container>
                        <Grid item xs container direction="column" spacing={0}>
                            <Grid item xs>
                                <Typography title={data.title} gutterBottom variant="caption" noWrap>
                                    <b>{data.title} {key}</b>
                                </Typography>

                                <div className='d-flex' style={{
                                    gap: 10,
                                    alignItems: 'center',
                                    marginTop: 4
                                }}>
                                    <div>
                                        {viewCategory(data.status)}
                                    </div>
                                    <div>
                                        <Typography title={data.title} gutterBottom variant="caption" noWrap>
                                            <DisplayDate date={data.release_date} onlyDate={true} />
                                        </Typography>
                                    </div>
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>



            </Paper>
        </div>
    );
}

const mapStateToProps = ({ auth, view, settings }) => {
    const { authUser, client, clientID, userID, token } = auth;
    const { locale } = settings;
    const { popover_newsletter } = view
    return {
        authUser,
        client,
        popover_newsletter,
        userID,
        clientID,
        locale,
        token
    };
};

export default withRouter(
    connect(mapStateToProps, { fetchPopoverNewsLetter })(NewsLetterPopover)
);
