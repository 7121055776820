import React from "react";
import moment from "moment";
import "moment-timezone";
import IntlMessages from "util/IntlMessages";

const DisplayDate = ({
  date,
  band,
  specialFormat = false,
  isChat = false,
  onlyDate = false,
  onlyHours = false,
  fontSize = "10px",
  plainText = false
}) => {
  let trLocale = require("moment/locale/es-mx");
  moment.tz.setDefault("America/Santiago");
  moment.locale("es-mx", trLocale);
  date = typeof date === "object" ? date.toISOString() : date;
  const postDate = moment(
    typeof date !== "undefined" ? date.replace(/\T+/g, ":") : date,
    "YYYY-MM-DD:hh:mm:ss"
  );
  const currentDate = moment();
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = duration.asMinutes() | 0;
  const hours = duration.asHours() | 0;

  const diffInDays = currentDate.diff(postDate, 'days');
  let displayDate;
  if (currentDate.isSame(postDate, 'day')) {
    displayDate = postDate.format('h:mm a');
  } else if (currentDate.day() - 1 === postDate.day() && currentDate.month() === postDate.month()) {
    displayDate = `ayer`;
  } else if (diffInDays <= 7) {
    displayDate = postDate.format('dddd');
  } else {
    displayDate = postDate.format('D/M/Y');
  }


  switch (true) {
    case onlyHours:
      if (plainText) {
        return (
          <>
            {moment(date).format("hh:mm a")}
          </>
        )
      }
      return (
        <p className="mb-0 jr-text-grey jr-fs-sm jr-mb-0" style={{ width: 80 }}>
          {moment(date).format("hh:mm a")}
        </p>
      );
    case onlyDate:
      if (plainText) {
        return (
          <>
            {postDate.format("DD MMM, YYYY")}
          </>
        )
      }
      return (
        <p
          className="mb-0 jr-text-grey jr-fs-sm jr-mb-0"
          style={{ width: 80, textTransform: "capitalize" }}
        >
          {postDate.format("DD MMM, YYYY")}
        </p>
      );
    case band:
      return (
        <p className="mb-0 jr-text-grey jr-fs-sm jr-mb-0">
          {postDate.format("DD MMM, YYYY h:mm a")}
        </p>
      );
    case specialFormat:
      return (
        <span className="mb-0 jr-text-grey jr-fs-xs jr-mb-0" style={{ width: 80, textAlign: "right" }}>
          {displayDate}
        </span>
      );

    case minutes === 0:
      return !isChat ? (
        <p className="mb-0 jr-text-grey jr-fs-sm jr-mb-0" style={{ width: 80 }}>
          <IntlMessages id="justnow" />
        </p>
      ) : (
        <p className="mb-0 jr-text-grey jr-fs-sm jr-mb-0" style={{ width: 80 }}>
          {postDate.format("h:mm a")}
        </p>
      );
    case minutes < 60:
      return (
        <>
          {minutes > 0 && (
            <>
              {!isChat ? (
                <p
                  className="mb-0 jr-text-grey jr-fs-sm jr-mb-0"
                  style={{ width: 80 }}
                >
                  {minutes} <IntlMessages id="min" />
                </p>
              ) : (
                <p
                  className="mb-0 jr-text-grey jr-fs-sm jr-mb-0"
                  style={{ width: 80 }}
                >
                  {postDate.format("h:mm a")}
                </p>
              )}
            </>
          )}
        </>
      );
    case hours < 24:
      return (
        <>
          {!isChat ? (
            <p
              className="mb-0 jr-text-grey jr-fs-sm jr-mb-0"
              style={{ width: 80 }}
            >
              {hours} <IntlMessages id="hours" />
            </p>
          ) : (
            <p
              className="mb-0 jr-text-grey jr-fs-sm jr-mb-0"
              style={{ width: 80 }}
            >
              {postDate.format("h:mm a")}
            </p>
          )}
        </>
      );
    default:
      return !isChat ? (
        <p className="mb-0 jr-text-grey jr-fs-sm jr-mb-0">
          {postDate.format("DD MMM, YYYY h:mm a")}
        </p>
      ) : (
        <p className="mb-0 jr-text-grey jr-fs-sm jr-mb-0" style={{ width: 80 }}>
          {postDate.format("h:mm a")}
        </p>
      );
  }
};

export default DisplayDate;
