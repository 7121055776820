import React from "react";
import { NavLink } from "react-router-dom";

import { Avatar, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut } from "actions/Auth";
import IntlMessages from "util/IntlMessages";

import Acl from "components/sofia/elements/acl";

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  contactNameTruncate = fullname => {
    const name = fullname.substring(0, 16) + "...";
    return (
      <Tooltip title={fullname}>
        <span>{fullname.length > 16 ? name : fullname}</span>
      </Tooltip>
    );
  };

  render() {
    const { authUser, history, token } = this.props;
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        {/* <Avatar
          alt="..."
          src={require("assets/images/placeholder.jpg")}
          className="user-avatar "
          onClick={this.handleClick}
        /> */}
        <Avatar
          alt="..."
          style={{ backgroundColor: "#f4f4f7", color: "#3f51b5" }}
          className="user-avatar"
          onClick={this.handleClick}
        >
          {(() => {
            if (authUser?.name) {
              let nameParts = authUser.name.split(" ");
              nameParts = nameParts.filter(Boolean);
               
              if (nameParts.length > 1) {
                return (nameParts[0][0] + nameParts[1][0]).toUpperCase();
              }
              return authUser.name[0].toUpperCase();
            }
            return "";
          })()}
        </Avatar>
        <div className="user-detail  pointer">
          <h4 className="user-name " onClick={this.handleClick}>
            {this.contactNameTruncate(authUser?.name)}
            <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
          </h4>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.history.push("/app/profile");
            }}
          >
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          {/* {authUser.account !== "master" && (
            <Acl
              access={"billing.view"}
              component={
                <>
                  {authUser.account !== "user" && (
                    <MenuItem
                      onClick={() => {
                        this.handleRequestClose();
                        history.push("/app/billing");
                      }}
                      className="nav-item"
                    >
                      <i className="zmdi zmdi-file-text zmdi-hc-fw mr-2" />
                      <IntlMessages id="billing" />
                    </MenuItem>
                  )}
                </>
              }
            />
          )} */}

          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut(token);
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale } = settings;
  const { authUser, token } = auth;
  return { locale, authUser, token };
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);
