import React from "react";

// utils
import { socket } from "util/Socket";

// services
import TemplateService from "../ajax/template.service";

export const useTemplates = ({ token, locale, queryGlobal }) => {
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });
  const [templates, setTemplates] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loader, setLoader] = React.useState(false);
  const rowsPerPage = 20;

  const index = (page, filters, isLoader = true) => {
    if (isLoader) {
      setLoader(true);
    }
    new TemplateService()
      .index(
        {
          offset: rowsPerPage,
          page: page + 1,
          ...filters,
          ...queryGlobal
        },
        locale,
        token
      )
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setTemplates([...data.data]);
          setTotal(data.pagination);
          setPage(page + 1);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const setData = newArray => {
    setTemplates([...newArray]);
  };

  return {
    // const
    templates,
    rowsPerPage,
    total,
    page,
    statusCode,
    loader,
    // methods
    index,
    setData
  };
};

export const useTemplate = ({ id, locale, token, clientID }) => {
  const [template, setTemplate] = React.useState();
  const [loader, setLoader] = React.useState(false);
  const [statusCode, setStatusCode] = React.useState({
    code: null,
    msg: "",
    data: null,
    action: null
  });

  const getTemplate = () => {
    setLoader(true);
    new TemplateService()
      .detail(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setTemplate(data.data[0]);
          setStatusCode({
            code: 200,
            action: "detail",
            data: data.data[0],
            msg: data.info
          });
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const storeTemplate = data => {
    setLoader(true);
    new TemplateService()
      .store(data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "create",
            data: data.data[0],
            msg: data.info
          });
          socket.emit("template", [
            `client${clientID}`,
            {
              module: "template",
              action: "create",
              data: data.data[0],
              msg: data.info
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const updateTemplate = data => {
    setLoader(true);
    new TemplateService()
      .update(id, data, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setTemplate(data.data[0]);
          setStatusCode({
            code: 200,
            action: "update",
            data: data.data[0],
            msg: data.info
          });

          socket.emit("template", [
            `client${clientID}`,
            {
              module: "template",
              action: "update",
              data: data.data[0],
              msg: data.info,
              ref: id
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  const deleteTemplate = data => {
    setLoader(true);
    new TemplateService()
      .delete(id, locale, token)
      .then(({ data }) => {
        if (data.status_codes === 200) {
          setStatusCode({
            code: 200,
            action: "delete",
            data: null,
            msg: data.info
          });
          socket.emit("template", [
            `client${clientID}`,
            {
              module: "template",
              action: "delete",
              data: data.data[0],
              msg: data.info,
              ref: id
            }
          ]);
        }
        setLoader(false);
      })
      .catch(_ => {
        setLoader(false);
      });
  };

  return {
    // const
    template,
    loader,
    statusCode,
    // methods
    getTemplate,
    storeTemplate,
    updateTemplate,
    deleteTemplate
  };
};
