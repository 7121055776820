import React from "react";
import { Popover } from "@material-ui/core";
import { IconButton } from '@mui/material'

// Helpers
import { colors } from "util/Helper";
import { CirclePicker } from "react-color";

// Icon
import EmojiEmotionsOutlinedIcon from "@material-ui/icons/EmojiEmotionsOutlined";

// Components
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";

function EmojiButton(props) {
  const { color, isDisable, size = 'defualt' } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {size == 'default' ? (
        <IconButton
          disabled={isDisable} aria-label="toggle emoji"
          onClick={handleClick}>
          <EmojiEmotionsOutlinedIcon />
        </IconButton>
      ) : (
        <IconButton
          size="small"
          sx={{
            padding: 0.4,
            borderRadius: '100%'
          }}
          disabled={isDisable}
          onClick={handleClick}
        >
          <EmojiEmotionsOutlinedIcon
            fontSize='small'
            sx={{
              fontSize: 16,
              backgroundColor: (theme) => theme.palette.action.hover
            }}
          />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className="p-3">
          <Picker
            className={`no-shadow border-0 w-100`}
            onEmojiClick={(event, emojiObject) => {
              props.onChange(emojiObject.emoji);
            }}
            disableAutoFocus={true}
            skinTone={SKIN_TONE_NEUTRAL}
          />
        </div>
      </Popover>
    </>
  );
}

export default EmojiButton;
