import React from "react";
import { connect } from "react-redux";
import {
  pipeDateValue,
  pipeDateTimeValue,
  visibilityValues
} from "util/Helper";
import NumberFormat from "react-number-format";
import { Avatar, Tooltip, IconButton } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";

const ModelView = props => {
  const {
    schema,
    model,
    module,
    client,
    intl,
    hideHyphen = false,
    noLink = false,
    onEventClick = () => null,
    maxWidth = 0,
    devices
  } = props;
  const {
    decimalseparator,
    prefix,
    suffix,
    decimalscale,
    thousandseparator
  } = client;
  const history = useHistory();

  const getValue = (path, obj) => {
    return path.split(".").reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
  };

  const getDevicesView = (ids = []) => {

    const arr = [];
    ids.forEach(id => {
      const find = devices.find(d => d.id === id);
      if (typeof find !== "undefined") {
        arr.push(find);
      }
    });


    if (!arr.length) {
      return "-"
    } else {
      return (
        <>
          {arr.map(device => (
            <span
              className={`jr-rounded-xs badge text-white bg-primary bg-primary border-radius-16`}
            >
              {device.name}
            </span>
          ))}
        </>
      );
    }

  };


  const getLabelsViews = (config, values) => {
    const options = config.option
    const arr = [];
    values.forEach(key => {
      const find = options.find(op => op.i18n === key);
      if (typeof find !== "undefined") {
        arr.push(find);
      }
    });

    if (!arr.length) {
      return "-"
    } else {
      return (
        <>
          {arr.map(op => (
            <span
              className={`jr-rounded-xs badge text-white bg-primary bg-primary border-radius-16`}
            >
              <IntlMessages id={op?.i18n} />
            </span>
          ))}
        </>
      );
    }

  }

  const getView = () => {

    try {

      let m = { ...model };
      let getMessageType =
        m?.message?.info?.type_msg.length > 0 ? m?.message?.info?.type_msg : null;
      let value = getValue(schema.key, m);
      let link = "";
      let id = "";


      if (schema.type === "button") {
        return (
          <Tooltip title={intl.formatMessage({ id: "conversation" })}>
            <IconButton
              size="small"
              className="icon-btn"
              onClick={() => onEventClick({ ...model })}
            >
              <i className={schema.icon} />
            </IconButton>
          </Tooltip>
        );
      }

      if (schema.type === "event") {
        value = getValue(schema.key, m)
        return (
          <div className="text-truncate" style={{ width: !maxWidth ? 150 : maxWidth }}>
            {schema.icon && (
              <i
                title={intl.formatMessage({ id: schema.i18n })}
                className={`${schema.icon} mr-2`}
                style={{ color: value?.color, fontSize: 10 }}
              ></i>
            )}
            <a className="text-primary" title={value.length ? value : "-"}  onClick={() => onEventClick({ ...model })}>{value.length ? value : "-"}</a>
          </div>
        );
      }


      if (typeof schema.redux !== "undefined") {
        value =
          typeof value === "string" ? value : value.length > 1 ? value : value[0];
        if (typeof value !== "undefined") {
          if (Array.isArray()) {
            return "isArray";
          } else {
            if (schema.redux !== "local") {
              value = props[schema.redux].find(obj => obj.id === value);
            } else {
              // if (schema.i18n === "priority") {
              //   value = ["low", "medium", "hight"].find(s => s === value);
              //   if (typeof value !== "undefined") {
              //     value = {
              //       value: value,
              //       name: intl.formatMessage({ id: value })
              //     };
              //   }
              // }
            }
          }
        }
      } else {
        value =
          typeof value === "string" ||
            typeof value === "number" ||
            typeof value === "boolean"
            ? value
            : value[0];
      }

      if (schema.key === "visibility") {
        value = {
          name: intl.formatMessage({ id: visibilityValues[value].label })
        };
      }

      switch (schema.type) {
        case "link":
          id = getValue(schema.key, m);
          value = !value.length
            ? getValue(schema.description, value)
            : getValue(schema.description, m);


          // console.log(id, value, schema.description)

          switch (schema.module) {
            case "deal":
              link = `/app/deals/detail/${id}`;
              break;
            case "device":
              link = `/app/devices/detail/${id}`;
              break;
            case "lead":
              link = `/app/leads/detail/${id}`;
              break;
            default:
              const relId = Array.isArray(id) ? id[0].id : id;
              link = `/app/contacts/detail/${relId}`;
              break;
          }

          return (
            <div className="text-truncate" style={{ maxWidth: !maxWidth ? 150 : maxWidth }}>
              <span>
                {schema.icon && (
                  <i
                    title={intl.formatMessage({ id: schema.i18n })}
                    className={`${schema.icon} mr-2`}
                    style={{ color: schema.module !== 'device' ? value?.color : model?.device?.background_color, fontSize: 10 }}
                  ></i>
                )}
                {!noLink ? (
                  <a
                    href={link}
                    target="_blank"
                    className="text-primary pointer"
                  >
                    <span title={value}>{value}</span>
                  </a>
                ) : (
                  <span className="" title={value}>{value}</span>
                )}
              </span>
            </div>
          );

        case "message":
          let vText =
            value.length && getMessageType === "chat"
              ? value
              : getMessageType !== null
                ? intl.formatMessage({ id: getMessageType || "" })
                : intl.formatMessage({ id: "noMessage" })

          return (
            <div

              className="text-truncate"
              style={{ width: !maxWidth ? 150 : maxWidth }}
            >
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={vText}>
                {vText}
              </span>
            </div>
          );
          break;

        case "email":
          if (hideHyphen && !value.length) {
            return "";
          }
          return (
            <div
              className="text-truncate"
              style={{ maxWidth: !maxWidth ? 160 : maxWidth }}
            >
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={value}>
                {!value.length ? (
                  "-"
                ) : (
                  <a href={`mailto:${value}?subject="Sofia Bot"`}>{value}</a>
                )}
              </span>
            </div>
          );
        case "phone":
          if (hideHyphen && !value.length) {
            return <div />;
          }
          return (
            <div
              className="text-truncate"
              style={{ maxWidth: !maxWidth ? 200 : maxWidth }}
            >
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={value}>
                {!value.length ? (
                  "-"
                ) : (
                  <a href={`tel:+${value}`}>{`+${value}`}</a>
                )}
              </span>
            </div>
          );
        case "datetime":
          if (hideHyphen && !value.length) {
            return <div />;
          }
          return (
            <div
              className="text-truncate"
              style={{ maxWidth: !maxWidth ? 150 : maxWidth }}
            >
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={value}>{!value.length ? "-" : pipeDateTimeValue(value)}</span>
            </div>
          );
        case "date":
          if (hideHyphen && !value.length) {
            return <div />;
          }
          return (
            <div
              className="text-truncate"
              style={{ maxWidth: !maxWidth ? 150 : maxWidth }}
            >
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={value}>{!value.length ? "" : pipeDateValue(value)}</span>
            </div>
          );

        case "boolean":
          return (
            <div
              className="d-flex align-items-center text-center"
              style={{ maxWidth: !maxWidth ? 100 : maxWidth }}
            >
              {value ? (
                <i className="zmdi zmdi-check-circle text-success"></i>
              ) : (
                <i
                  className="zmdi zmdi-check-circle"
                  style={{ opacity: 0.2 }}
                ></i>
              )}
            </div>
          );

        case "band":
          if (hideHyphen && !value.length) {
            return "";
          }
          return (
            <div
              className="d-flex align-items-center text-center"
              style={{ maxWidth: !maxWidth ? 50 : maxWidth }}
            >
              {value ? (
                <i
                  className={`mr-1 flag flag-16 flag-${value.toLocaleLowerCase()}`}
                />
              ) : (
                "-"
              )}
            </div>
          );
        case "password":
          return (
            <div className="text-truncate" style={{ width: !maxWidth ? 150 : maxWidth }}>
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span>{value.replace(/./g, '*')}</span>
            </div>
          )
        case "currency":
          return (
            <div className="text-truncate" style={{ maxWidth: !maxWidth ? 200 : maxWidth }}>
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <NumberFormat
                thousandSeparator={thousandseparator}
                value={value}
                displayType="text"
                allowNegative="false"
                decimalSeparator={decimalseparator}
                decimalScale={decimalscale}
                prefix={prefix}
                suffix={suffix}
              />
            </div>
          );
        case "image":
          return <Avatar style={{ width: 25, height: 25 }} alt="" src={value} />;
        case "number":
          return (
            <div style={{ width: 150 }}>
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={value}>{value || 0}</span>
            </div>
          );
        case "text":
          if (typeof value === "number") {
            return (
              <div className="text-truncate" style={{ width: !maxWidth ? 150 : maxWidth }}>
                {schema.icon && (
                  <i
                    title={intl.formatMessage({ id: schema.i18n })}
                    className={`${schema.icon} mr-2`}
                    style={{ color: value?.color, fontSize: 10 }}
                  ></i>
                )}
                <span title={value}>{value || 0}</span>
              </div>
            );
          }

          if (hideHyphen && !value.length) {
            return "";
          }

          return (
            <div className="text-truncate" style={{ width: !maxWidth ? 150 : maxWidth }}>
              {schema.icon && (
                <i
                  title={intl.formatMessage({ id: schema.i18n })}
                  className={`${schema.icon} mr-2`}
                  style={{ color: value?.color, fontSize: 10 }}
                ></i>
              )}
              <span title={value.length ? value : "-"}>{value.length ? value : "-"}</span>
            </div>
          );
        case "select":
          if (typeof value === "undefined") {
            return (
              <div className="text-truncate" style={{ width: 150 }}>
                {schema.icon && (
                  <i
                    title={intl.formatMessage({ id: schema.i18n })}
                    className={`${schema.icon} mr-2`}
                    style={{ color: value?.color, fontSize: 10 }}
                  ></i>
                )}
                <span />
              </div>
            );
          }

          if (typeof value === "string") {
            if (typeof schema.source !== "undefined" || typeof schema.option !== "undefined") {
              const results = schema.source ?? schema.option;
              const rText = results.find(s => s.value === value);

              const vText = value.length
                ? rText !== undefined
                  ? intl.formatMessage({ id: rText.i18n })
                  : "-"
                : "-"

              return (
                <div className="text-truncate" style={{ width: !maxWidth ? 150 : maxWidth }}>
                  {schema.icon && (
                    <i
                      title={intl.formatMessage({ id: schema.i18n })}
                      className={`${schema.icon} mr-2`}
                      style={{ color: value?.color, fontSize: 10 }}
                    ></i>
                  )}
                  <span title={vText}>
                    {vText}
                  </span>
                </div>
              );
            }
          }
          return (
            <>
              {typeof value !== "undefined" ? (
                <>
                  {typeof value?.color !== "undefined" ? (
                    <div className="d-flex align-items-center" style={{ gap: 0 }}>
                      {schema.icon && (
                        <i
                          title={intl.formatMessage({ id: schema.i18n })}
                          className={`${schema.icon} mr-2`}
                          style={{ color: value?.color, fontSize: 10 }}
                        ></i>
                      )}

                      <div
                        title={value.name}
                        className="text-truncate flex-wrap"
                        style={{ width: 'auto !important', maxWidth: !maxWidth ? 150 : maxWidth, textAlign: 'left' }}
                      >
                        <span
                          className="text-truncate jr-rounded-xs small"
                          style={{ borderRadius: 3 }}
                        >
                          {value.name}
                        </span>
                      </div>
                    </div>

                  ) : (
                    <div
                      className="text-truncate"
                      style={{ maxWidth: !maxWidth ? 150 : maxWidth }}
                    >
                      {schema.icon && (
                        <i
                          title={intl.formatMessage({ id: schema.i18n })}
                          className={`${schema.icon} mr-2`}
                          style={{ color: value?.color, fontSize: 10 }}
                        ></i>
                      )}
                      <span title={value.name} className="text-truncate">{value.name}</span>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="text-truncate"
                  style={{ maxWidth: !maxWidth ? 150 : maxWidth }}
                >
                  {schema.icon && (
                    <i
                      title={intl.formatMessage({ id: schema.i18n })}
                      className={`${schema.icon} mr-2`}
                      style={{ color: value?.color, fontSize: 10 }}
                    ></i>
                  )}
                  <span></span>
                </div>
              )}
            </>
          );
        case "multiselect":

          if (typeof schema?.condiction !== "undefined") {
            if (!schema.value.length) {
              return `-`
            } else {
              if (schema?.module === 'device') {
                return schema.value.some(el => typeof el === 'string') ? getDevicesView(schema.value) : getDevicesView(schema.value.map((d) => d.id))
              } else {
                return getLabelsViews(schema, schema.value)
              }
            }
          }
          if (typeof value === "undefined") {
            return "";
          }
          return (
            <>
              {typeof value !== "undefined" ? (
                <>
                  {typeof value?.color !== "undefined" ? (
                    <div className="d-flex align-items-center" style={{ gap: 0 }}>

                      {schema.icon && (
                        <i
                          title={intl.formatMessage({ id: schema.i18n })}
                          className={`${schema.icon} mr-2`}
                          style={{ color: value?.color, fontSize: 10 }}
                        ></i>
                      )}

                      <div
                        title={value.name}
                        className="text-truncate flex-wrap"
                        style={{ width: 'auto !important', maxWidth: !maxWidth ? 150 : maxWidth, textAlign: 'left' }}
                      >
                        <span
                          className="text-truncate jr-rounded-xs small"
                          style={{ borderRadius: 3 }}
                        >
                          {value.name}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="text-truncate"
                      style={{ maxWidth: !maxWidth ? 150 : maxWidth }}
                    >

                      {schema.icon && (
                        <i
                          title={intl.formatMessage({ id: schema.i18n })}
                          className={`${schema.icon} mr-2`}
                          style={{ color: value?.color, fontSize: 10 }}
                        ></i>
                      )}

                      <span title={value.name} className="text-truncate">{value.name}</span>
                    </div>
                  )}
                </>
              ) : (
                <div
                  className="text-truncate"
                  style={{ maxWidth: !maxWidth ? 150 : maxWidth }}
                >
                  {schema.icon && (
                    <i
                      title={intl.formatMessage({ id: schema.i18n })}
                      className={`${schema.icon} mr-2`}
                      style={{ color: value?.color, fontSize: 10 }}
                    ></i>
                  )}
                  <span></span>
                </div>
              )}
            </>
          );
        default:
          return (
            <div className="text-truncate" style={{ width: 200 }}>
              {"Por definir"}
            </div>
          );
      }

    } catch (error) {
      return (
        <div className="text-truncate" style={{ width: 200 }}>
          {"-"}
        </div>
      );
    }
  };

  return <>{getView()}</>;
};

const mapStateToProps = ({
  settings,
  auth,
  dynamicList,
  devices,
  user,
  pipeline
}) => {
  const { width, locale } = settings;
  const { token, userID, clientID, client } = auth;
  const { items } = dynamicList;
  const { allDevice } = devices;
  const { users } = user;

  return {
    client,
    clientID,
    width,
    locale,
    token,
    userID,
    users,
    devices: allDevice,
    panels: pipeline.items.filter(el => el.module === "deal"),
    sources: items.filter(el => el.field === "source" && el.status),
    status: items.filter(el => el.status),
    branchs: items.filter(
      el => el.module === "all" && el.field === "branch_id" && el.status
    ),
    stages: items.filter(el => el.field === "list_stage" && el.status),
    tags: items.filter(el => el.field === "tags" && el.status),
    paymentMethods: items.filter(
      el =>
        el.module === "payment" && el.field === "payment_method" && el.status
    )
  };
};

export default connect(mapStateToProps, {})(injectIntl(ModelView));
