import axios from "util/Api";
import { base, baseURLajaxPrefix } from "util/Api";
import { ajax } from "rxjs/ajax";

class ContactService {
  constructor() { }

  async index(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/search`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async export(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/export`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }


  
  async kanban(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/kanban`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async detail(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/contact/search/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }

  async store(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/create`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async storeAll(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/imports/contact`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async answerbot(id, data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/bot/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async update(id, data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/contact/updateone/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
  async unlockchatmany(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/contact/unlockchatmany`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async unlockall(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/contact/unlockall`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async updateMany(data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/contact/updatemany`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async delete(id, locale, token) {
    return axios.delete(
      `${baseURLajaxPrefix}/${locale}/contact/deleteone/${id}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }


  async transfer(id, data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/transfer/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async search(params, locale, token, query = {}) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/contact/list`,
      {
        ...params,
        ...query
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  search$(params, locale, token, query = {}) {
    return ajax({
      url: `${base}/${baseURLajaxPrefix}/${locale}/contact/list`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
      body: {
        ...params,
        ...query
      }
    });
  }

  async fields(locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/listdinamic/fields`, {
      headers: {
        Authorization: token
      }
    });
  }

  async unread(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/contact/unread/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }
}

export default ContactService;
