import axios from "util/Api";
import { base, baseURLajaxPrefix } from "util/Api";

class TaskService {
  constructor() {}

  async index(params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/task/search`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async detail(id, locale, token) {
    return axios.get(`${baseURLajaxPrefix}/${locale}/task/search/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }

  async store(data, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/task/create`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async update(id, data, locale, token) {
    return axios.put(
      `${baseURLajaxPrefix}/${locale}/task/updateone/${id}`,
      {
        ...data
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async delete(id, locale, token) {
    return axios.delete(`${baseURLajaxPrefix}/${locale}/task/deleteone/${id}`, {
      headers: {
        Authorization: token
      }
    });
  }
}

export default TaskService;
