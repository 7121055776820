import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import User from "./User";
import DynamicList from "./DynamicList";
import Device from "./Device";
import Msg from "./Msg";
import Chat from "./Chat";
import View from "./View";
import Notification from "./Notification";
import Pipeline from "./Pipeline";
import Dashboard from "./Dashboard";


export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    commonData: Common,
    dynamicList: DynamicList,
    user: User,
    devices: Device,
    msgs: Msg,
    chat: Chat,
    view: View,
    notification: Notification,
    pipeline: Pipeline,
    dashboard: Dashboard
  });
