import {
  FETCH_ALL_USERS,
  UPDATE_USER
} from "../constants/ActionTypes";

const INIT_STATE = {
  users: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS: {
      return { ...state, users: [...action.payload] };
    }
    case UPDATE_USER: {
      const arr = state.users;
      const { id, data } = action.payload;
      const findIndex = arr.findIndex(el => el.id === id);
      if (findIndex >= 0) {
        const user = JSON.parse(JSON.stringify(data));
        arr.splice(findIndex, 1, user);
        return { ...state, users: [...arr] };
      }
      return state;
    }
    default:
      return state;
  }
};

