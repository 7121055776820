import React from "react";
import { IconButton, Popover } from "@material-ui/core";

// Helpers
import { colors } from "util/Helper";
import { CirclePicker } from "react-color";

// Icon
import PaletteIcon from '@material-ui/icons/Palette';


function SelectColors(props) {
  const { color } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="d-flex">
      <CirclePicker
          width={218}
          color={color}
          colors={colors.slice(0,5)}
          onChange={ev => {
            props.onSet(ev.hex);
          }}
        />
         <IconButton size="small" aria-describedby={id} component="span" onClick={handleClick}>
            <i className="zmdi zmdi-palette" style={{ color: color, fontSize: 25}}></i>
        </IconButton>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="p-3">
        <CirclePicker
          color={color}
          colors={colors.slice(5, colors.lenght)}
          onChange={ev => {
            props.onSet(ev.hex);
          }}
        />
        </div>
      </Popover>
    </>
  );
}

export default SelectColors;
