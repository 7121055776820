import React from "react";
import { connect } from "react-redux";
import { TextField, MenuItem, Avatar } from "@material-ui/core";

// Helpers
import IntlMessages from "util/IntlMessages";

// Services
import ContactService from "services/ajax/contact.service";
import DynamicListService from "services/ajax/dynamicList.service";

// Components
import Autocomplete from "@material-ui/lab/Autocomplete";

// Icons
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

function SearchUsers(props) {
  const {
    classes = "",
    size = "small",
    label = null,
    showLabel = true,
    isRequired = false,
    isAutofous = false,
    usersData,
    defaultValue = [],
    userID,
    isMultiple = true,
    noAdmin = false,
    disabled = false,
    variant = "standard",
    margin = "normal",
    limitTags = 3
  } = props;
  const [value, setValue] = React.useState([]);


  const users = !noAdmin
    ? usersData
    : usersData.filter(u => ["client", "master"].indexOf(u.account) <= -1);

  React.useEffect(() => {

    if (defaultValue.length > 0) {
      const arr = [];
      defaultValue.forEach(id => {
        if (typeof id === "string") {
          const find = users.find(user => user.id === id);
          if (typeof find !== "undefined") {
            arr.push(find);
          }
        } else {
          arr.push(id);
        }
      });
      setValue([...arr]);
      props.onSelected([...arr]);
    }

  }, [0]);

  return (
    <>
      <Autocomplete
        autoComplete
        value={value}
        size={size}
        options={users}
        multiple
        getOptionLabel={option => `${option.name}`}
        fullWidth
        disabled={disabled}
        variant={variant}
        limitTags={limitTags}
        renderOption={option => {
          return (
            <React.Fragment>
              <div className="d-flex align-items-center">
                <Avatar className="user-avatar size-20 bg-primary">
                  <PersonOutlineIcon fontSize="small" />
                </Avatar>
                <div className="user-detail">
                  <h5 className="user-name">{`${option.name}`}</h5>
                </div>
              </div>
            </React.Fragment>
          );
        }}
        onChange={(_, newValue) => {
          if (isMultiple) {
            setValue(newValue);
            props.onSelected(newValue);
          } else {
            if (newValue.length > 0) {
              setValue([newValue[newValue.length - 1]]);
              props.onSelected([newValue[newValue.length - 1]]);
            } else {
              setValue(newValue);
              props.onSelected(newValue);
            }
          }
        }}
        renderInput={params => (
          showLabel ? (
            <TextField
              {...params}
              label={!label ? <IntlMessages id="users" /> : label}
              fullWidth
              margin={margin}
              variant={variant}
              className={classes}
              InputLabelProps={{
                shrink: true,
                required: isRequired
              }}
              InputProps={{
                ...params.InputProps,
                placeholder: "Buscar..."
              }}
              disabled={disabled}
              autoFocus={isAutofous}
            />
          ) : (
            <TextField
              {...params}
              fullWidth
              margin={margin}
              variant={variant}
              className={classes}
              InputLabelProps={{
                shrink: true,
                required: isRequired
              }}
              InputProps={{
                ...params.InputProps,
                placeholder: "Buscar..."
              }}
              disabled={disabled}
              autoFocus={isAutofous}
            />
          )
        )}
      />
    </>
  );
}

const mapStateToProps = ({ user, auth, settings }) => {
  const { users } = user;
  const { token, userID } = auth;
  const { locale } = settings;
  return {
    usersData: users.filter(u => u.email.length > 0),
    token,
    locale,
    userID
  };
};

export default connect(mapStateToProps, {})(SearchUsers);
