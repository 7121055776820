import React from 'react';
import {AppBar, Typography, Toolbar, Box} from '@material-ui/core';

export default function ExtraToolbar({ primary, secondary, variant = 'dense', disableGutters, withBorderBottom, withBorderTop, withPaperBackground, style = {} }) {

    return (
        <AppBar
            component="div"
            position="static"
            elevation={0}
            color="white"
            style={{
                ...style
            }}
        >
            <Toolbar
                variant={variant}
                disableGutters={disableGutters}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    overflow: 'hidden',
                    gap: 1,
                    borderBottom: withBorderBottom ? `1px solid #eee` : '',
                    borderTop: withBorderTop ? `1px solid #eee` : '',
                    backgroundColor: withPaperBackground ? 'background.paper' : 'white',
                    paddingRight: 8,
                    paddingLeft: 10,
                    ...style
                }}>
                <Box style={{ flex: 1}}>
                    {primary}
                </Box>
                <Box>
                    {secondary}
                </Box>
            </Toolbar>
        </AppBar>
    );
}