import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Typography, Divider, Box, Tooltip, Badge, Grow, Paper, IconButton } from '@mui/material';
import { Fab, Fade } from '@material-ui/core';

import { injectIntl } from 'react-intl';
import DisplayDate from "util/DisplayDate";
import ExtraAvatar from "./ui/ExtraAvatar";
import IntlMessages from "util/IntlMessages";
import ModelView from "app/routes/contact/routes/list/elements/dynamicTable/ModelView";
import Acl from "components/sofia/elements/acl";
import BotAction from "./ui/BotAction"

// services
import { socket } from "util/Socket";
import ContactService from "services/ajax/contact.service";
import UserService from "services/ajax/user.service.js";

import { useDispatch, useSelector } from "react-redux";
import { onChatContactSelected } from "actions/Chat";
import { onNotification } from "actions/Notification";

//  icons
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

function ConversationItem({
    intl,
    data,
    index,
    conversationsMarked,
    setConversationsMarked = () => null,
    customView,
    settingView,
    pagination }) {

    const { limit } = pagination
    const dispatch = useDispatch()
    const columns = useMemo(() => settingView.columns, [settingView, customView, data])
    const { token, locale, clientID, authUser } = useSelector(({ auth, settings }) => ({
        token: auth.token,
        clientID: auth.clientID,
        locale: settings.locale.locale,
        authUser: auth.authUser
    }))

    const page = useRef(Math.floor(index / limit))
    const currentIndex = useRef(index - (page.current * limit))
    const typeEvent = useRef(0)
    const isMarked = useMemo(() => conversationsMarked.includes(data.contact.id), [conversationsMarked])

    const displayDate = useCallback(() => {
        try {
            return <DisplayDate date={data?.contact.message.info.timestamp} specialFormat={true} />
        } catch (error) {
            return ''
        }
    }, [data])

    const handleOpenConversation = (ev) => {

        if (typeEvent.current !== 0) {
            typeEvent.current = 0
            return;
        }

        typeEvent.current = 0

        const contact = data.contact;
        contact.message.numpending = 0;
        contact.message.numtotal = 0

        // local contact update 
        dispatch(onNotification({
            module: "contact",
            action: "update",
            data: contact,
            msg: ''
        }))

        // new contact selected
        dispatch(onChatContactSelected(contact))

        // emit contact update 
        updateContact(contact);

    }

    const updateContact = (contactData) => {

        const contact = { ...contactData };
        delete contact.deviceColor;
        delete contact.device;


        new ContactService()
            .update(contact.id, contact, locale, token)
            .then(({ data }) => {
                socket.emit("notification", [
                    `client${clientID}`,
                    {
                        module: "contact",
                        action: "update",
                        data: data.data[0],
                        msg: data.info
                    }
                ]);
            });
    };

    const onMarkConversation = () => {

        typeEvent.current = 1;

        const marked = isMarked
            ? [...conversationsMarked.filter(contact_id => contact_id !== data.contact.id)]
            : [data.contact.id, ...conversationsMarked];

        new UserService().update(
            authUser.id,
            {
                fixed: marked
            },
            locale,
            token
        );

        setConversationsMarked(marked)

    }

    try {
        return (
            <Fade
                in={true}
                style={{ transformOrigin: 'center center' }}
                {...(true ? { timeout: currentIndex.current * 50 } : {})}
            >
                <Paper elevation={0}>
                    <ListItem
                        alignItems="flex-start"
                        onClick={handleOpenConversation}
                        sx={{
                            position: 'relative',
                            paddingTop: 0.2,
                            paddingBottom: 0.2,
                            paddingRight: 1,
                            paddingLeft: 0.5,
                            '&:hover': {
                                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'background' : 'grey.100',
                                ".conversations-action": {
                                    display: 'inline-flex'
                                }
                            },
                            '&:active': {
                                backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'background' : 'grey.300'
                            },
                        }}
                    >
                        <ListItemAvatar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                minWidth: 50
                            }}
                        >
                            <ExtraAvatar contact={data.contact} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box fontSize={12}>
                                    {customView.columns.map((key, i) => (
                                        <ModelView
                                            key={`column-${i}`}
                                            noLink
                                            model={data.contact}
                                            schema={columns.find(col => col.key === key)}
                                            module={'chat'}
                                            maxWidth={'100%'}
                                        />
                                    ))}

                                </Box>
                            }
                        />
                        <ListItemText
                            sx={{
                                minWidth: 55,
                                maxWidth: 55,
                                textAlign: 'end'
                            }}
                            primary={
                                <Box sx={{ display: 'flex', mt: 0.2 }}>
                                    {displayDate()}
                                </Box>
                            }
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                top: '50%',
                                right: 18,
                                transform: 'translateY(-50%)',
                                gap: 0.5
                            }}
                        >
                            <BotAction contact={data.contact} onFocus={() => typeEvent.current = 2} />

                            {isMarked ? (
                                <IconButton
                                    size="small"
                                    aria-label=""
                                    sx={{
                                        padding: 0.4,
                                        borderRadius: '100%'
                                    }}
                                    onClick={onMarkConversation}
                                >

                                    <BookmarkIcon
                                        fontSize='small'
                                        style={{
                                            fontSize: 16
                                        }}

                                    />
                                </IconButton>
                            ) : (
                                <IconButton
                                    className="conversations-action"
                                    size="small"
                                    aria-label=""
                                    sx={{
                                        padding: 0.4,
                                        borderRadius: '100%',
                                        opacity: 1,
                                        display: 'none'
                                    }}
                                    onClick={onMarkConversation}
                                >

                                    <BookmarkBorderIcon
                                        fontSize='small'
                                        style={{
                                            fontSize: 16
                                        }}

                                    />
                                </IconButton>
                            )}




                            {data?.contact.message.numpending > 0 && (
                                <Box
                                    component="span"
                                    sx={{
                                        backgroundColor: (theme) => theme.palette.primary.dark,
                                        color: (theme) => theme.palette.common.white,
                                        padding: 1,
                                        borderRadius: "50%",
                                        width: 20,
                                        height: 20,
                                        fontSize: 10,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center"
                                    }}
                                >
                                    {data?.contact.message.numpending || 0}
                                </Box>
                            )}
                        </Box>
                    </ListItem>
                    <Divider component="li" />
                </Paper>
            </Fade>

        )

    } catch (error) {

        return (
            <>
                <ListItemButton
                    alignItems="flex-start"
                    sx={{
                        paddingTop: 0.2,
                        paddingBottom: 0.2,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.mode === 'dark' ? 'background' : 'grey.100'
                        },
                    }}
                >
                    <ListItemAvatar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'cenetr',
                            minWidth: 50
                        }}
                    >
                        <Avatar />
                    </ListItemAvatar>
                    <ListItemText
                        primary={<IntlMessages id="extraPages.500Ms" />}
                    />
                </ListItemButton>
                <Divider component="li" />
            </>
        )
    }
}

export default injectIntl(ConversationItem)