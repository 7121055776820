import React from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import {
  IconButton,
  CircularProgress,
  TextField,
  MenuItem,
  InputAdornment,
  Divider,
  Button
} from "@material-ui/core";

import { getNewDateTimeValue } from "util/Helper";

// Helpers
import { makeStyles } from "@material-ui/core/styles";

// Components
import SearchModel from "components/sofia/search/searchModel";
import EmojiButton from "components/sofia/elements/EmojiButton";
import CreateContact from "./components/createContact";
import Widget from "components/Widget/index";
import CustomScrollbars from "util/CustomScrollbars";

// Form
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

// Icons
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

// Services
import { useMessages } from "../../../conversation/services/message.service";
import { useTemplates } from "services/hook/template.service";

// Actions
import { showMessage } from "actions/Common";

const useStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  }
});

function ModalCreateMsg(props) {
  const { visible, auth, devices, contact, authUser, hiddenContact = false, isWidget = false } = props;
  const { clientID, token, locale, userID } = auth;
  const deviceInit = devices.find(d => d.status && d.setting.conect.status);
  const [contactSelected, setContactSelected] = React.useState(null);
  const [contactTempSelected, setContactTempSelected] = React.useState(null);

  const [isOfficial, setIsOfficial] = React.useState(false);
  const [type, setType] = React.useState("");
  const [bodys, setBodys] = React.useState([]);
  const [example, setExample] = React.useState("");


  const [isCreateContact, setIsCreateContact] = React.useState(false);
  const [device, setDevice] = React.useState(deviceInit ? deviceInit.id : null);
  const [deviceSelected, setDeviceSelected] = React.useState(
    deviceInit ? deviceInit : null
  );
  const formElement = React.useRef();

  const { storeMessage, storeMessage2, sendTemplate, loaderStore, statusCode } = useMessages({
    clientID: clientID,
    token: token,
    locale: locale.locale
  });

  const schema = yup.object().shape({
    type: yup.string(),
    msg: yup.string().required(),
    device: yup.string().required()
  });

  const use_templates = useTemplates({
    clientID: clientID,
    token: token,
    userID: userID,
    locale: locale.locale
  });

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    errors,
    formState,
    reset
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      country: "CL",
      type: "chat",
      device: deviceInit !== undefined ? deviceInit.id : null
    }
  });

  const onSubmit = value => {

    const send = {
      type: "chat",
      body: value.msg,
      attachment: "",
      device_id: value.device,
      contact_id: contactSelected.id
    };

    storeMessage2(send);
  };

  const onSubmit2 = () => {
    const schema = JSON.parse(JSON.stringify(type.schema));
    const arr = [...type.schema.template.components[0].parameters];
    schema.template.components[0].parameters = [...bodys];
    let cad = `${type.example}`;

    arr.forEach(({ text, type }, i) => {
      switch (text) {
        case "$fullname":
          cad = cad.replace(/\$fullname/g, bodys[i].text);
          break;
        case "$firstname":
          cad = cad.replace(/\$firstname/g, bodys[i].text);
          break;
        case "$lastname":
          cad = cad.replace(/\$lastname/g, bodys[i].text);
          break;
        case "$username":
          cad = cad.replace(/\$username/g, bodys[i].text);
          break;
        default:
          cad = cad.replace(text, bodys[i].text);
          break;
      }
    });

    const send = {
      type: "chat",
      body: cad,
      attachment: "",
      template: schema,
      device_id: device,
      contact_id: contactSelected.id,
      template_id: type.id
    };

    sendTemplate(send);
  };

  React.useEffect(() => {
    if (device) {
      const d = devices.find(d => d.id === device);
      if (d != undefined) {
        setDeviceSelected(d);
      }
    } else {
      setDeviceSelected(null);
    }
  }, [device]);

  React.useEffect(() => {
    if (deviceSelected) {
      if (deviceSelected?.setting?.conect?.type === "whatsapp" &&
        deviceSelected?.setting?.conect?.config?.typeconnect ===
        "official") {
        setIsOfficial(true);
      } else {
        setIsOfficial(false);
      }
    }
  }, [deviceSelected])


  React.useEffect(() => {
    if (contact) {
      setContactSelected(contact)
    }
  }, [contact]);


  React.useEffect(() => {
    if (statusCode) {
      const { code, data, info } = statusCode;
      if (code) {
        if (code === 200) {
          // const newMessage = {
          //   contact: {
          //     answerbot: true,
          //     client_id: clientID,
          //     create_date: getNewDateTimeValue(),
          //     crm: {
          //       campaign: "",
          //       conversiondate: "",
          //       description: "",
          //       donotcall: false,
          //       donotemail: false,
          //       donotmsg: false,
          //       id: "",
          //       list_stage: "",
          //       list_status: "",
          //       nameapp: "",
          //       priority: "",
          //       source: "",
          //       tags: []
          //     },
          //     deleted: false,
          //     id: contactSelected.id,
          //     ...contactSelected,
          //     message: {
          //       info: {
          //         body: data.body,
          //         device_id: data.device_id,
          //         fromMe: data.fromMe,
          //         id: data.id,
          //         status: data.status,
          //         timestamp: data.timestamp,
          //         type_msg: "chat"
          //       },
          //       last_recive: "",
          //       last_sent: "",
          //       list_device: [data.device_id],
          //       numpending: 0,
          //       numtotal: 0
          //     }
          //   }
          // };

          // props.onStored(newMessage);
          props.onClose()
          reset({
            type: "chat",
            device: null,
            msg: ""
          });
          props.showMessage(info);
        } else {
          props.showMessage(info);
        }
      }
    }
  }, [statusCode]);


  React.useEffect(() => {
    if (visible) {
      if (typeof contact !== "undefined") {
        if (contact.derivedto.length) {
          if (contact.derivedto !== userID) {
            props.showMessage("La conversación ha sido tomada por otro usuario");
            props.onClose()
          }
        }
      }

      if (deviceInit) {
        setDevice(deviceInit.id)
        setDeviceSelected(deviceInit)
        setValue("device", deviceInit.id);
      }

      use_templates.index(0, { offset: 100, type: "chat" });
    } else {
      setDevice(null);
      setDeviceSelected(null);
      setContactSelected(null);
      setType(null);
      setIsOfficial(false);
    }
  }, [visible]);

  React.useEffect(() => {
    if (type) {
      const schema = JSON.parse(JSON.stringify(type.schema));
      const arr = [...schema.template.components[0].parameters];
      const agent = contactSelected;
      const newArr = [];
      let msg = `${type.example}`;

      arr.forEach(({ text, type }, i) => {
        let obj = { type };
        switch (text) {
          case "$fullname":
            obj = { ...obj, text: agent.info.full_name }
            msg = msg.replace(/\$fullname/g, `{${i + 1}}`);
            break;
          case "$firstname":
            obj = { ...obj, text: agent.info.first_name }
            msg = msg.replace(/\$firstname/g, `{${i + 1}}`);
            break;
          case "$lastname":
            obj = { ...obj, text: agent.info.last_name }
            msg = msg.replace(/\$lastname/g, `{${i + 1}}`);
            break;
          case "$username":
            obj = { ...obj, text: authUser.name }
            msg = msg.replace(/\$username/g, `{${i + 1}}`);
            break;
          default:
            msg = msg.replace(`${text}`, `{${i + 1}}`);
            obj = { ...obj, text };
            break;
        }


        newArr.push(obj);
      });

      setBodys(newArr);
      setExample(msg);

    }
  }, [type])

  return (
    <>

      <Modal
        onClose={() => {
          props.onClose();
          setDevice(null);
          setContactSelected(null);
          setIsCreateContact(false);
        }}
        isOpen={visible}
        style={{ zIndex: 2600, minWidth: !isWidget ? "400px" : '' }}
      >
        <ModalHeader>
          <IntlMessages id="newMsg" />
          <div>
            <IconButton
              onClick={() => {
                props.onClose();
                setDevice(null);
                setContactSelected(null);
                setIsCreateContact(false);
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </ModalHeader>

        <form onSubmit={handleSubmit(onSubmit)} ref={formElement}>
          <ModalBody>
            <fieldset>
              <Controller
                control={control}
                name="device"
                error={errors?.device ? true : false}
                required
                as={
                  <TextField
                    name="device"
                    inputRef={register}
                    type="text"
                    select
                    label={<IntlMessages id="device" />}
                    fullWidth
                    margin="normal"
                    required
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      onChange: ev => {
                        setValue("device", ev.target.value);
                        setDevice(ev.target.value);
                        if (contactSelected) {
                          setContactSelected(null);
                        }
                      }
                    }}
                    error={errors?.device ? true : false}
                  >
                    {devices.map((d, index) => (
                      <MenuItem
                        key={index}
                        value={d.id}
                        disabled={!d.status || !d.setting.conect.status}
                      >
                        {d.name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              />

              {deviceSelected && (
                <>

                  {!hiddenContact && (
                    <div className="row no-gutters w-100 align-items-center">
                      <div className="col-11">
                        {!isCreateContact ? (
                          <SearchModel
                            q={{ device_id: device }}
                            defaultValue={contactSelected}
                            type="contact"
                            label={<IntlMessages id="contact" />}
                            onSelected={contact => {
                              setContactSelected(contact);
                              if (!contactTempSelected) {
                                setContactTempSelected(contact)
                              }
                            }}
                          />
                        ) : (
                          <>
                            <div className="pt-5 pb-2">
                              <h4>
                                <IntlMessages id="contact.new" />
                              </h4>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-1">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            setIsCreateContact(!isCreateContact)
                          }
                        >
                          {isCreateContact ? (
                            <CloseIcon />
                          ) : (
                            <PersonAddIcon />
                          )}
                        </IconButton>
                      </div>
                    </div>
                  )}


                  {hiddenContact && (
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          value={contact.info.full_name}
                          type="text"
                          label={`Contacto`}
                          fullWidth
                          disabled
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            required: true
                          }}
                        >
                        </TextField>
                      </div>
                    </div>
                  )}

                  <CreateContact
                    device={device}
                    auth={auth}
                    isCreateContact={isCreateContact}
                    onClose={() => setIsCreateContact(false)}
                    onStored={ev => {
                      setContactSelected(ev);
                      setIsCreateContact(false);
                    }}
                  />


                  {(isOfficial && contactSelected) && (
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          value={type}
                          type="text"
                          label={`Template`}
                          fullWidth
                          select
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                            required: true
                          }}
                          onChange={ev => {
                            setType(ev.target.value);
                          }}
                        >
                          {use_templates.templates.map(option => (
                            <MenuItem
                              key={option.id}
                              value={option}
                            >
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div className="col-12 col-md-6">

                      </div>
                    </div>
                  )}

                  {!isCreateContact && (
                    <>
                      {isOfficial ? (
                        <div className="row mt-3">
                          <div className="col-12 col-md-6">
                            {bodys.length ? (
                              <Widget styleName="jr-card-full">
                                <div className='px-3 py-2 pb-2 w-100' style={{ background: "#fafafa" }}>
                                  <h5 className="py-2 small mb-0 font-weight-normal">
                                    {`Parámetros`}
                                  </h5>
                                </div>
                                <Divider />
                                <CustomScrollbars
                                  className="scrollbar"
                                  style={{
                                    minHeight: 200,
                                    maxHeight: 200,

                                  }}
                                >
                                  <div>
                                    {bodys.map((body, i) => (
                                      <div className="border-bottom" key={`col-${i}`}>
                                        <div className="px-2 py-1">
                                          <TextField
                                            value={body.text}
                                            type="text"
                                            label={`Body: {${i + 1}}`}
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            InputLabelProps={{
                                              shrink: true,
                                              required: true
                                            }}
                                            onChange={ev => {
                                              const newArr = [...bodys];
                                              newArr[i].text = ev.target.value;
                                              setBodys(newArr);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                  </div>

                                </CustomScrollbars>
                              </Widget>
                            ) : ""}
                          </div>
                          <div className="col-12 col-md-6">
                            {bodys.length ? (
                              <Widget styleName="jr-card-full">
                                <div className='px-3 py-2 pb-2 w-100' style={{ background: "#fafafa" }}>
                                  <h5 className="py-2 small mb-0 font-weight-normal">
                                    {`Vista previa`}
                                  </h5>
                                </div>
                                <Divider />
                                <CustomScrollbars
                                  className="scrollbar"
                                  style={{
                                    minHeight: 200,
                                    maxHeight: 200,

                                  }}
                                >
                                  <div className="bg-ws" style={{ minHeight: "100%" }}>
                                    <div className="chat-main-content">
                                      <div className="w-100 px-1 py-2">
                                        <div className="d-flex flex-nowrap chat-item">
                                          <div className="bubble jambo-card ml-0" style={{ borderRadius: "0px 20px 20px" }}>
                                            <div className="message d-flex"><p className="m-0">{example}</p></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </CustomScrollbars>
                              </Widget>
                            ) : ""}
                          </div>
                        </div>
                      ) : (
                        <TextField
                          inputRef={register}
                          name="msg"
                          variant="outlined"
                          color="primary"
                          placeholder="Escriba y pulse Enter para enviar el mensaje"
                          multiline
                          label={<IntlMessages id="message" />}
                          fullWidth
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EmojiButton
                                  onChange={emoji => {
                                    setValue(
                                      "msg",
                                      `${getValues()["msg"]}${emoji}`
                                    );
                                  }}
                                />
                                <IconButton
                                  type="submit"
                                  color="primary"
                                  disabled={
                                    !formState.isValid ||
                                    !contactSelected ||
                                    loaderStore
                                  }
                                  aria-label="Send message"
                                >
                                  <i className="zmdi  zmdi-mail-send" />
                                </IconButton>
                                <span className="ml-2">
                                  {loaderStore && (
                                    <CircularProgress size={20} />
                                  )}
                                </span>
                              </InputAdornment>
                            )
                          }}
                          onKeyPress={ev => {
                            if (
                              ev.key === "Enter" &&
                              formState.isValid &&
                              contactSelected
                            ) {
                              ev.preventDefault();
                              formElement.current.dispatchEvent(
                                new Event("submit")
                              );
                            }
                          }}
                          rows={5}
                          style={{ resize: "none" }}
                        />
                      )}
                    </>
                  )}


                </>
              )}
            </fieldset>


          </ModalBody>

          {isOfficial && (
            <ModalFooter className="d-flex justify-content-between px-4">
              <div>
              </div>
              <div>
                <Button
                  type="button"
                  color="error"
                  size="small"
                  disabled={loaderStore}
                  className="mr-3"
                  onClick={() => {
                    props.onClose()
                  }}
                >
                  {`Cancelar`}
                </Button>

                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  size="small"
                  disableElevation
                  disabled={loaderStore || !contactSelected || !type || !bodys.every((b) => b.text.length > 0)}
                  onClick={onSubmit2}
                >
                  {`Enviar`}
                </Button>
                <span className="ml-2 mt-1">
                  {loaderStore && <CircularProgress size={20} />}
                </span>
              </div>
            </ModalFooter>
          )}
        </form>
      </Modal >
    </>
  );
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  showMessage
})(ModalCreateMsg);
