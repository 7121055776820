import React from "react";
import { Redirect, Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";

function IsUserAccessRoute({
  component: C,
  appProps,
  client,
  authUser,
  access,
  ...rest
}) {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useState(() => {
    if (authUser.account === "user") {
      const band = authUser.access.find(a => a === access);
      if (typeof band === "undefined") {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      const band = client.access.find(a => a === access);
      if (typeof band === "undefined") {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    }
  }, [client, authUser, access]);

  return (
    <Route
      {...rest}
      render={props =>
        isVisible ? (
          <C {...props} {...appProps} />
        ) : (
          <Redirect to={`/app/401`} />
        )
      }
    />
  );
}

const mapStateToProps = ({ auth }) => {
  const { client, authUser } = auth;
  return {
    client,
    authUser
  };
};

export default connect(mapStateToProps, {})(IsUserAccessRoute);
