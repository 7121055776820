import React from "react";
import { connect } from "react-redux";

function Acl(props) {
  const { access, componentFalse, client, authUser, market = false } = props;
  const [isVisible, setIsVisible] = React.useState(false);

  React.useState(() => {
    if (authUser.account === "user") {
      const band = authUser.access.find(a => a === access);
      if (typeof band === "undefined") {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    } else {
      if (!market) {
        const band = client.access.find(a => {
          return a === access;
        });
        if (typeof band === "undefined") {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
      } else {
        setIsVisible(true);
      }
    }
  }, [client, authUser, access]);

  return (
    <>
      {isVisible && props.component}
      {!isVisible && componentFalse}
    </>
  );
}

const mapStateToProps = ({ auth }) => {
  const { client, authUser } = auth;
  return {
    client,
    authUser
  };
};

export default connect(mapStateToProps, {})(Acl);
