import React from "react";
import { injectIntl } from 'react-intl';
import { IconButton, Box } from '@material-ui/core';
import { SpeedDialAction, SpeedDial } from '@mui/material';
import ModalAttach from "./modal/attach";

// Icons
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ImageIcon from '@material-ui/icons/Image';
import MovieIcon from '@material-ui/icons/Movie';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import RoomIcon from '@material-ui/icons/Room';

// Actions
import { showMessage } from "actions/Common";
import { useDispatch } from "react-redux";


function AttachAction(props) {

  const { intl, isDisable = false, onStore = () => null } = props;
  const dispatch = useDispatch()

  const [type, setType] = React.useState("image");
  const hiddenFileInput = React.useRef(null);
  const [modalAttach, setModalAttach] = React.useState(false);

  const handleClick = (type, ev) => {

    setType(type);

    try {
      document.getElementById('message-input').focus()
    } catch (err) {

    }

    switch (type) {
      case "location":
        setModalAttach(true)
        break;
      case "image":
        hiddenFileInput.current.accept = "image/*";
        break;
      case "audio":
        hiddenFileInput.current.accept = "audio/*";
        break;
      case "video":
        hiddenFileInput.current.accept = "video/*";
        break;
      default:
        hiddenFileInput.current.accept =
          "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain,/";
        break;
    }
    if (type !== "location") hiddenFileInput.current.click();
  };


  const onFileChange = ev => {
    const value = ev.target.files[0];
    if (value !== undefined) {
      if (value.size <= 10000000) {
        const msg = {
          type: type,
          body: "",
          attachment: value
        };

        onStore(msg);

      } else {
        dispatch(showMessage(intl.formatMessage({ id: 'fileMax10mb' })))
      }
    }
  };


  return (
    <>
      <Box style={{
        position: 'relative'
      }}>
        {isDisable ? (
          <IconButton
            size="small"
            disabled={isDisable}
          >
            <AttachFileIcon
              fontSize='small'
              sx={{
                fontSize: 16,
                backgroundColor: (theme) => theme.palette.action.hover
              }}
            />
          </IconButton>
        ) : (
          <SpeedDial
            ariaLabel=""
            icon={<AttachFileIcon fontSize="small" style={{ fontSize: 16 }} />}
            sx={{
              position: 'absolute',
              top: '50%',
              height: 16,
              width: 16,
              minHeight: 16,
              transform: 'translateY(-50%)',
              padding: 0,
              left: 9,
              top: 5,
              "& .MuiSpeedDial-fab": {
                height: 16,
                width: 16,
                minHeight: 16,
                padding: 1.5,
                
              }
            }}
          >
            <SpeedDialAction
              icon={<InsertDriveFileIcon />}
              tooltipTitle={intl.formatMessage({ id: 'document' })}
              onClick={(ev) => handleClick('document', ev)}
            />
            <SpeedDialAction
              icon={<ImageIcon />}
              tooltipTitle={intl.formatMessage({ id: 'image' })}
              onClick={(ev) => handleClick('image', ev)}
            />
            <SpeedDialAction
              icon={<MovieIcon />}
              tooltipTitle={intl.formatMessage({ id: 'video' })}
              onClick={(ev) => handleClick('video', ev)}
            />
            <SpeedDialAction
              icon={<AudiotrackIcon />}
              tooltipTitle={intl.formatMessage({ id: 'audio' })}
              onClick={(ev) => handleClick('audio', ev)}
            />
            <SpeedDialAction
              icon={<RoomIcon />}
              tooltipTitle={intl.formatMessage({ id: 'location' })}
              onClick={(ev) => handleClick('location', ev)}
            />
          </SpeedDial>
        )}
      </Box >
      <form>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={onFileChange}
          style={{ display: "none" }}
        />
      </form>
      <ModalAttach
        visible={modalAttach}
        onClose={() => setModalAttach(false)}
        onSend={onStore}
      />
    </>
  );
}

export default injectIntl(AttachAction)