import axios from "axios";
import { store } from "../store";
import { showMessage } from "actions/Common";

// const development = process.env.NODE_ENV === "development";
const { host } = window.location;
const development = process.env.NODE_ENV === "development" ? true : host === "dev.sofiabot.io" ? true : false;

export const baseURL = development ?
    "dev.api.sofiabot.io" :
    "api.sofiabot.io";


export const baseSocketURL = development ?
    "https://dev.ws.sofiabot.io" :
    "https://socket.sofia-bot.com";
//"https://socket.sofia-bot.com"; / https://ws.sofiabot.io
//export const baseSocketURL = "https://dev.ws.sofiabot.io";
export const baseURLajaxPrefix = "/v1";
export const base = `${development ? "https" : "https"}://${baseURL}`;

const Axios = axios.create({
    baseURL: base,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
    }
});

Axios.interceptors.response.use(
    response => {
        // const { dispatch } = store;
        // const { info } = response.data;
        // dispatch(showMessage(info));
        return response;
    },
    error => {
        const { dispatch } = store;
        const originalRequest = error.config;
        if (typeof error.response === "undefined") {
            dispatch(showMessage("A server/network error occurred"));
            return Promise.reject(error);
        }
        const { info } = error.response.data;
        switch (error.response.status) {
            case 404:
                window.location.href = "/404";
                dispatch(showMessage(info));
                return Promise.reject(null);
            case 500:
            case 401:
                if (info === "Expired Token") {
                    try {
                        Object.getOwnPropertyNames(sessionStorage).forEach((key) => {
                            let value = sessionStorage.getItem(key);
                            if (value) {
                                try {
                                    value = JSON.parse(value)
                                    if (Array.isArray(value)) {
                                        sessionStorage.removeItem(key)
                                    } else {
                                        if (key.indexOf('filter.') >= 0) {
                                            sessionStorage.removeItem(key)
                                        }
                                    }
                                } catch (error) {

                                }
                            }
                        })
                    } catch (error) {

                    }
                    localStorage.removeItem("user_id");
                    localStorage.removeItem("token");
                    localStorage.removeItem("client_id");

                    window.location.href = "/";
                }
                dispatch(showMessage(info));
                return Promise.reject(null);
            case 412:
            case 406:
                dispatch(showMessage(info));
                return Promise.reject(error);
            case 500:
                window.location.href = "/500";
                return Promise.reject(null);
            default:
                dispatch(showMessage(info));
                return Promise.reject(error);
        }
    }
);

export default Axios;