import {
    FETCH_DASHBOARD
} from "../constants/ActionTypes";

const INIT_STATE = {
    data: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD: {
            return { ...state, data: action.payload };
        }
        default:
            return state;
    }
};
