import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';

import SettingsIcon from '@material-ui/icons/Settings';
import CustomModal from "../../../CustomViewModal"
import { injectIntl } from "react-intl";

function CustomViewAction({ intl }) {

    const [customModal, setCustomModal] = React.useState({});

    return (
        <>
            <IconButton
                size="small"
                aria-label="search"
                onClick={() => {
                    setCustomModal({
                        open: true,
                        edit: true
                    });
                }}
                style={{
                    padding: "3px 3px",
                    borderRadius: "100%"
                }}
            >
                <Tooltip
                    title={
                        intl.formatMessage({ id: 'customView' })
                    }
                >
                    <SettingsIcon
                        fontSize='small'
                        style={{
                            fontSize: 16
                        }}
                    />
                </Tooltip>
            </IconButton>
            <CustomModal
                module={"chat"}
                edit={customModal.edit}
                visible={customModal.open}
                onClose={() => setCustomModal({})}
                onStored={() => {
                    setCustomModal({});
                }}
            />
        </>
    )
}


export default injectIntl(CustomViewAction)