import axios from "util/Api";
import { baseURLajaxPrefix } from "util/Api";

class MessagebagService {
  constructor() {}

  async update(id, params, locale, token) {
    return axios.post(
      `${baseURLajaxPrefix}/${locale}/messagebag/updateone/${id}`,
      {
        ...params
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
  }

  async messageBalance(token, locale, id) {
    return axios.get(
      `${baseURLajaxPrefix}/${locale}/client/analitics${id ? `/${id}` : ""}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
  }
}

export default MessagebagService;
