import React, { useCallback, useMemo, useRef } from "react";
import { connect } from "react-redux";

// Hepers
import IntlMessages from "util/IntlMessages";
import DisplayDate from "util/DisplayDate";
import { whatsappStyles } from "util/Helper";

// Components
import {
  Fade,
  Box,
  Stack,
  Typography
} from "@mui/material";

import Lightbox from "react-image-lightbox";
import Acl from "components/sofia/elements/acl";

// Icons
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// style
import "react-image-lightbox/style.css";

// files icons
import IconPdf from "assets/images/icons/pdf.png";
import IconDoc from "assets/images/icons/doc.png";
import IconXls from "assets/images/icons/xls.png";
import IconZip from "assets/images/icons/zip.png";


const Item = React.memo(
  ({
    contact,
    conversation,
    users,
    token,
    locale,
    onDeleted,
    userID,
    authUser,
    pagination,
    index
  }) => {

    const [imageShow, setImageShow] = React.useState(false);
    const limit = useMemo(() => pagination.limit, [pagination])
    const page = useRef(Math.floor(index / limit))
    const currentIndex = useRef(index - (page.current * limit))

    const getTextView = text => {
      try {
        if (/fb:.[^\s]*/g.test(text)) {
          text = text.replace(
            /fb:.[^\s]*/g,
            (match, i) => `<a href="${match}" target="_black">Facebook link</a>`
          );
          return (
            <>
              <p className="m-0" dangerouslySetInnerHTML={{ __html: text }}></p>
            </>
          );
        }
        text = text.replace(
          /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
          (match, i) => `<a href="${match}" target="_black">${match}</a>`
        );

        text = whatsappStyles(text, "_", "<i>", "</i>");
        text = whatsappStyles(text, "*", "<b>", "</b>");
        text = whatsappStyles(text, "~", "<s>", "</s>");

        return (
          <>
            <p
              className="m-0"
              dangerouslySetInnerHTML={{
                __html: text.replace(/\n/g, "<br />")
              }}
            ></p>
          </>
        );
      } catch (error) {
        return "";
      }
    };

    const getAudioView = url => {
      if (typeof url === "boolean") {
        return <IntlMessages id="loading" />;
      }
      const split = url.split(".");

      return (
        <>
          <audio
            src={url}
            type={`audio/${split[split.length - 1]}`}
            controls
            preload="none"
            style={{
              zoom: 0.8
            }}
          ></audio>
        </>
      );
    };

    const getVideoView = url => {
      if (typeof url === "boolean") {
        return <IntlMessages id="loading" />;
      }

      const split = url.split(".");
      return (
        <>
          <video height={133} width={246} src={url} controls preload="none" />
        </>
      );
    };

    const getDocView = url => {
      if (typeof url === "boolean") {
        return <IntlMessages id="loading" />;
      }

      if (url) {
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
          const ext = url.split(".").pop();
          const styles = {
            padding: ".5rem",
            border: "1px solid #dedede",
            borderRadius: "1rem",
            background: "#DDD",
            width: "100%",
            display: "flex",
            cursor: "pointer"
          };
          switch (ext) {
            case "pdf":
              return (
                <a
                  onClick={() => window.open(url, "_blank")}
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <div style={styles}>
                    <img src={IconPdf} />
                  </div>
                </a>
              );
            case "xls":
              return (
                <a
                  onClick={() => window.open(url, "_blank")}
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <div style={styles}>
                    <img src={IconXls} />
                  </div>
                </a>
              );
            case "zip":
              return (
                <a
                  onClick={() => window.open(url, "_blank")}
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <div style={styles}>
                    <img src={IconZip} />
                  </div>
                </a>
              );
            default:
              return (
                <a
                  onClick={() => window.open(url, "_blank")}
                  style={{ cursor: "pointer", width: "100%" }}
                >
                  <div style={styles}>
                    <img src={IconDoc} />
                  </div>
                </a>
              );
          }
        } else {
          return <IntlMessages id="loading" />;
        }
      }
      return "";
    };

    const getImageView = url => {
      if (typeof url === "boolean") {
        return <IntlMessages id="loading" />;
      }

      if (url) {
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
          return (
            <>
              <div
                className="pointer"
                onClick={() => setImageShow(!imageShow)}
                style={{
                  height: 200,
                  width: 200,
                  backgroundImage: `url(${url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center"
                }}
              />
            </>
          );
        } else {
          return <IntlMessages id="loading" />;
        }
      }
      return "";
    };

    const getMap = value => {
      if (typeof value === "boolean") {
        return <IntlMessages id="loading" />;
      }

      let split = value.split(";");
      let lat = "";
      let lng = "";

      if (split.length > 1) {
        lat = split[0];
        lng = split[1];
      } else {
        split = value.split(" ");
        if (split.length > 1) {
          lat = split[0];
          lng = split[1];
        }
      }

      if (!split.length) {
        return <IntlMessages id="noInformation" />;
      }

      return (
        <>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
            target="_blank"
          >
            {`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
          </a>
        </>
      );
    };

    const SentMessageCell = ({
      conversation,
      users,
      locale,
      token,
      onDeleted,
      userID,
      authUser
    }) => {
      const band =
        typeof conversation.owner === "undefined"
          ? 0
          : conversation.owner.length;
      const user = users.find(u => u.id === conversation.owner);
      const [deleteLoader, setDeleteLoader] = React.useState(false);

      return (
        <Stack sx={{
          px: 1,
          py: 0.5,
          ml: 4,
          backgroundColor: '#e4e7f6',
          borderRadius: 1
        }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography variant="caption" sx={{ color: '#3f51b5' }}>
                {user && user.name}
              </Typography>
            </Box>
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              {conversation?.timestamp && (
                <Box
                  component={'span'}
                  sx={{
                    fontSize: 10
                  }}
                >
                  <DisplayDate
                    plainText
                    date={conversation?.timestamp}
                    onlyHours={true}
                  />
                </Box>

              )}
              {conversation?.status === "queue" && (
                <QueryBuilderIcon
                  fontSize="small"
                  color="primary"
                  style={{
                    fontSize: 12,
                  }}
                />
              )}
              {(conversation?.status === "error" ||
                conversation?.status === "cancel") && (
                  <CloseRoundedIcon
                    fontSize="small"
                    style={{
                      fontSize: 12,
                      color: 'red'
                    }}
                  />
                )}
              {(conversation?.status === "sent" ||
                conversation?.status === "recive") && (
                  <DoneAllIcon
                    fontSize="small"
                    color="green"
                    style={{
                      fontSize: 12,
                      color: 'green'
                    }}
                  />
                )}
            </Stack>
          </Stack>
          <Box>
            <Typography variant="caption" component={'div'} color="initial">
              {conversation?.type === "chat" && getTextView(conversation?.body)}
              {conversation?.type === "audio" &&
                getAudioView(conversation?.body)}
              {conversation?.type === "video" &&
                getVideoView(conversation?.body)}
              {conversation?.type === "document" &&
                getDocView(conversation?.body)}
              {conversation?.type === "image" &&
                getImageView(conversation?.body)}
              {conversation?.type === "location" && getMap(conversation?.body)}
            </Typography>
          </Box>
        </Stack>
      );
    };

    const ReceivedMessageCell = ({ contact, conversation }) => {
      return (
        <Stack sx={{
          px: 1,
          py: 0.5,
          mr: 4,
          backgroundColor: (theme) => theme.palette.action.hover,
          borderRadius: 1
        }}>
          <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box>
              <Typography variant="caption" color='orange'>
                {contact && contact.info.full_name}
              </Typography>
            </Box>
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              {conversation?.timestamp && (
                <Box
                  component={'span'}
                  sx={{
                    fontSize: 10
                  }}
                >
                  <DisplayDate
                    plainText
                    date={conversation?.timestamp}
                    onlyHours={true}
                  />
                </Box>

              )}
              {conversation?.status === "queue" && (
                <QueryBuilderIcon
                  fontSize="small"
                  color="primary"
                  style={{
                    fontSize: 12,
                  }}
                />
              )}
              {(conversation?.status === "error" ||
                conversation?.status === "cancel") && (
                  <CloseRoundedIcon
                    fontSize="small"
                    style={{
                      fontSize: 12,
                      color: 'red'
                    }}
                  />
                )}
              {(conversation?.status === "sent" ||
                conversation?.status === "recive") && (
                  <DoneAllIcon
                    fontSize="small"
                    color="green"
                    style={{
                      fontSize: 12,
                      color: 'green'
                    }}
                  />
                )}
            </Stack>
          </Stack>
          <Box>
            <Typography variant="caption" component={'div'} color="initial">
              {conversation?.type === "chat" && getTextView(conversation?.body)}
              {conversation?.type === "audio" &&
                getAudioView(conversation?.body)}
              {conversation?.type === "video" &&
                getVideoView(conversation?.body)}
              {conversation?.type === "document" &&
                getDocView(conversation?.body)}
              {conversation?.type === "image" &&
                getImageView(conversation?.body)}
              {conversation?.type === "location" && getMap(conversation?.body)}
            </Typography>
          </Box>
        </Stack>
      );
    };

    const view = useCallback(() => {
      return (
        <>
          <Fade
            in={true}
            style={{ transformOrigin: 'center center' }}
            {...(true ? { timeout: currentIndex.current * 50 } : {})}
          >
            <Box>
              {(currentIndex.current === limit - 1) ? (
                <span className="marked"></span>
              ) : null}
              {conversation.fromMe === true ? (
                <SentMessageCell
                  userID={userID}
                  users={users}
                  contact={contact}
                  conversation={conversation}
                  token={token}
                  locale={locale}
                  onDeleted={onDeleted}
                  authUser={authUser}
                />
              ) : (
                <ReceivedMessageCell contact={contact} conversation={conversation} />
              )}
            </Box>
          </Fade>
        </>
      )
    }, [conversation])

    return (
      <>
        {view()}
        {imageShow && (
          <Lightbox
            mainSrc={conversation?.body}
            enableZoom={false}
            onCloseRequest={() => {
              setImageShow(!imageShow);
            }}
          />
        )}
      </>
    );
  }
);

const mapStateToProps = ({ user, settings, auth }) => {
  const { token, userID, authUser } = auth;
  const { users } = user;
  const { locale } = settings;
  return {
    users,
    token,
    locale: locale.locale,
    userID,
    authUser
  };
};

export default connect(mapStateToProps, {})(Item);
